import Stripe from 'stripe';

import ApiClient from '../api.service';

const stripe = new Stripe(process.env.REACT_APP_STRIPE_SECRET_KEY);

type getProductUrlType = {
  productId: string;
  quantity: number;
  successUrl: string;
  cancelUrl: string;
  customerId: string;
  trialPeriodDays?: number;
};

export const getProductUrl = async ({
  productId,
  quantity,
  successUrl,
  cancelUrl,
  customerId,
  trialPeriodDays,
}: getProductUrlType) => {
  const { data } = await ApiClient.get('stripe/checkout', {
    params: {
      product_id: productId,
      quantity,
      success_url: successUrl,
      cancel_url: cancelUrl,
      customer_id: customerId,
      trial_period_days: trialPeriodDays,
    },
  });
  return data;
};

export const getBillingPortalUrl = async ({ customerId, returnUrl }: any) => {
  const { data } = await ApiClient.get('stripe/billing_portal', {
    params: {
      customer_id: customerId,
      return_url: returnUrl,
    },
  });
  return data;
};

// Stripe API

export const getStripeCustomerData = async ({ customerId }: { customerId: string }) => {
  const data = await stripe.customers.retrieve(customerId);
  return data;
};

export const getStripeSubscriptionData = async ({ customerId }: { customerId: string }) => {
  const data = await stripe.subscriptions.list({
    customer: customerId,
  });
  return data;
};

export const getStripeInvoiceData = async ({ customerId }: { customerId: string }): Promise<Stripe.Invoice[]> => {
  try {
    const invoices = await stripe.invoices.list({
      customer: customerId,
    });
    return invoices.data;
  } catch (error) {
    // eslint-disable-next-line
    console.error(error);
    throw error; // Now throwing the error to be handled by the caller
  }
};

export const getDefaultPaymentMethod = async ({ customerId }: { customerId: string }) => {
  const { data } = await ApiClient.get('stripe/default_payment', {
    params: {
      customer_id: customerId,
    },
  });
  return data;
};
