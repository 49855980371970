import { ActionType, createAsyncAction } from 'typesafe-actions';

import {
  UpdateBillingInfoMutationVariables,
  UpdateCompanyProfileMutationVariables,
} from '~/core/services/graphql/account/account.queries.generated';
import {
  PlatformAccountInputSchema,
  PlatformAccountSchema,
  PlatformAccountUpdateSchema,
  QueryAccountsArgs,
} from '~/core/types/graphql.types';

export enum AccountTypes {
  Account = '[AccountTypes] Account',
  AccountSuccess = '[AccountTypes] AccountSuccess',
  AccountFailed = '[AccountTypes] AccountFailed',
  CreateAccount = '[AccountTypes] CreateAccount',
  CreateAccountSuccess = '[AccountTypes] CreateAccountSuccess',
  CreateAccountFailed = '[AccountTypes] CreateAccountFailed',
  UpdatePlatformAccount = '[AccountTypes] UpdatePlatformAccount',
  UpdatePlatformAccountSuccess = '[AccountTypes] UpdatePlatformAccountSuccess',
  UpdatePlatformAccountFailed = '[AccountTypes] UpdatePlatformAccountFailed',
  AllAccounts = '[AccountTypes] AllAccounts',
  AllAccountsSuccess = '[AccountTypes] AllAccountsSuccess',
  AllAccountsFailed = '[AccountTypes] AllAccountsFailed',
  UpdateAccountProfileInfoAction = '[AccountTypes] UpdateAccountProfileInfoAction',
  UpdateAccountActionProfileInfoSuccess = '[AccountTypes] UpdateAccountProfileInfoActionSuccess',
  UpdateAccountActionProfileInfoFailed = '[AccountTypes] UpdateAccountProfileInfoActionFailed',
  UpdateAccountBillingInfoAction = '[AccountTypes] UpdateAccountBillingInfoAction',
  UpdateAccountBillingInfoActionSuccess = '[AccountTypes] UpdateAccountBillingInfoActionSuccess',
  UpdateAccountBillingInfoActionFailed = '[AccountTypes] UpdateAccountBillingInfoActionFailed',
  DeleteAccountAction = '[AccountTypes] DeleteAccountAction',
  DeleteAccountActionSuccess = '[AccountTypes] DeleteAccountActionSuccess',
  DeleteAccountActionFailed = '[AccountTypes] DeleteAccountActionFailed',
}

export const accountAction = createAsyncAction(
  AccountTypes.Account,
  AccountTypes.AccountSuccess,
  AccountTypes.AccountFailed,
)<number, PlatformAccountSchema, string>();

export const createAccountAction = createAsyncAction(
  AccountTypes.CreateAccount,
  AccountTypes.CreateAccountSuccess,
  AccountTypes.CreateAccountFailed,
)<PlatformAccountInputSchema, number, string>();

export const updatePlatformAccountAction = createAsyncAction(
  AccountTypes.UpdatePlatformAccount,
  AccountTypes.UpdatePlatformAccountSuccess,
  AccountTypes.UpdatePlatformAccountFailed,
)<{ accountId: number; accountData: PlatformAccountUpdateSchema }, PlatformAccountSchema, string>();

export const allAccountsAction = createAsyncAction(
  AccountTypes.AllAccounts,
  AccountTypes.AllAccountsSuccess,
  AccountTypes.AllAccountsFailed,
)<QueryAccountsArgs, any, string>();

export const updateAccountProfileInfoAction = createAsyncAction(
  AccountTypes.UpdateAccountProfileInfoAction,
  AccountTypes.UpdateAccountActionProfileInfoSuccess,
  AccountTypes.UpdateAccountActionProfileInfoFailed,
)<UpdateCompanyProfileMutationVariables, PlatformAccountUpdateSchema, string>();

export const updateAccountBillingInfoAction = createAsyncAction(
  AccountTypes.UpdateAccountBillingInfoAction,
  AccountTypes.UpdateAccountBillingInfoActionSuccess,
  AccountTypes.UpdateAccountBillingInfoActionFailed,
)<UpdateBillingInfoMutationVariables, any, string>();

export const deleteAccountAction = createAsyncAction(
  AccountTypes.DeleteAccountAction,
  AccountTypes.DeleteAccountActionSuccess,
  AccountTypes.DeleteAccountActionFailed,
)<number, void, string>();

export type AccountActionUnion = ActionType<
  | typeof accountAction
  | typeof createAccountAction
  | typeof updatePlatformAccountAction
  | typeof allAccountsAction
  | typeof updateAccountBillingInfoAction
  | typeof updateAccountProfileInfoAction
  | typeof deleteAccountAction
>;
