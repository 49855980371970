import { Alert, Link, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { AuthContainer } from '~/core/components/layouts/AuthContainer';
import { CreateAccountPayload } from '~/core/types/auth.types';
import { createAccountAction } from '~/store/actions/auth.action';
import { authErrorSelector, authLoadingSelector } from '~/store/selectors/auth.selector';

import { CreateAccountForm } from './components/CreateAccountForm';
import { TermsText } from './components/CreateAccountForm/styles';
import * as Styled from './styles';

const CreateAccount = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [code, setCode] = useState(null);

  const authError = useSelector(authErrorSelector);
  const isLoading = useSelector(authLoadingSelector);

  const token = searchParams.get('jwt');

  const onSubmitLogin = ({ givenName, familyName, password, email, companyName }: CreateAccountPayload) => {
    dispatch(
      createAccountAction.request({
        token,
        givenName,
        familyName,
        password,
        email,
        companyName,
      }),
    );
    setCode(email);
  };

  useEffect(() => {
    if (!authError && code && !isLoading) {
      navigate('../confirm-email', { replace: true, state: { email: code } });
    }
  }, [authError, navigate, code, dispatch, isLoading]);

  return (
    <AuthContainer>
      <Typography
        align="center"
        fontWeight={700}
        sx={{
          fontSize: {
            xs: '12px',
            sm: '24px',
          },
        }}
      >
        Create a New Account
      </Typography>
      {authError && (
        <Alert data-testid="alert" severity="error">
          <Typography fontSize={16}>User already exists</Typography>
        </Alert>
      )}
      <Styled.FormContainer>
        <CreateAccountForm defaultEmail={code} onSubmit={onSubmitLogin} />
        <TermsText
          sx={{
            maxWidth: '80%',
            textAlign: 'center',
          }}
        >
          {`By signing up, you agree to the MotiveMetrics `}
          <Link href="https://www.motivemetrics.com/terms-of-service" target="_blank" rel="noreferrer">
            Terms of Service
          </Link>
          {` and `}
          <Link href="https://www.motivemetrics.com/privacy-policy" target="_blank" rel="noreferrer">
            Privacy Policy
          </Link>
          .
        </TermsText>
        {!token && (
          <Styled.FooterTitle>
            Already have an account?
            <Link underline="none" href="/auth/sign-in">
              Sign In
            </Link>
          </Styled.FooterTitle>
        )}
      </Styled.FormContainer>
    </AuthContainer>
  );
};

export default CreateAccount;
