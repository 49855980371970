import Logo2022 from '~/assets/logo/logo_2022.png';
import AIPlatformImage from '~/assets/logo/plusai_logo.svg';

import * as Styled from './styles';
import { AuthContainerProps } from './types';

const AuthContainer: React.FC<AuthContainerProps> = ({ children, isSignIn, ...elementProps }) => (
  <Styled.Container {...elementProps}>
    <Styled.AppBar>
      <Styled.ImageContainer>
        <Styled.NavImage src={Logo2022} alt="AI white logo" />
      </Styled.ImageContainer>
    </Styled.AppBar>
    {isSignIn && (
      <Styled.SignInWrapper>
        <Styled.TitleContainer>
          <Styled.Image data-testid="aiPlatformImage" src={AIPlatformImage} alt="AI Platform Logo" />
        </Styled.TitleContainer>
        <Styled.Content>{children}</Styled.Content>
        <Styled.LinksContainer>
          <Styled.FooterLink href="https://www.motivemetrics.com/terms-of-service" target="_blank" rel="noopener">
            Terms
          </Styled.FooterLink>
          <Styled.FooterLink href="https://www.motivemetrics.com/privacy-policy" target="_blank" rel="noopener">
            Privacy
          </Styled.FooterLink>
          <Styled.FooterLink
            href="https://motivemetrics.atlassian.net/servicedesk/customer/portals"
            target="_blank"
            rel="noopener"
          >
            Support
          </Styled.FooterLink>
        </Styled.LinksContainer>
      </Styled.SignInWrapper>
    )}
    {!isSignIn && (
      <>
        <Styled.TitleContainer>
          <Styled.Image data-testid="aiPlatformImage" src={AIPlatformImage} alt="AI Platform Logo" />
        </Styled.TitleContainer>
        <Styled.Content>{children}</Styled.Content>
      </>
    )}
  </Styled.Container>
);

export default AuthContainer;
