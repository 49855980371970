import { Skeleton, Table, TableBody } from '@mui/material';

import { TableHead } from '~/core/components/shared';
import { renderSkeletonRows } from '~/core/helpers';
import { SortType } from '~/core/types/table.types';

import { CustomTablePagination } from '../components';
import * as Styled from '../styles';
import { headerCells } from './tableHeader.data';

type LoadingTableProps = {
  desc?: boolean;
  orderBy?: string;
  page?: number;
  pageSize?: number;
};

const LoadingTable = ({ desc = true, orderBy = null, page = 0, pageSize = 25 }: LoadingTableProps) => {
  const skeletonRows = 5;

  return (
    <>
      <Styled.TableContainer>
        {/* {headerState ? (
          <Styled.TableHeader>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '8px',
              }}
            >
              <Styled.Circle
                sx={{
                  backgroundColor: circleColor,
                }}
              />
              {circleText}
            </Box>
          </Styled.TableHeader>
        ) : (
          <Styled.SkeletonContainer>
            <Skeleton
              variant="text"
              width={200}
              height={24}
              animation="wave"
              sx={{
                marginLeft: '24px',
              }}
            />
          </Styled.SkeletonContainer>
        )} */}
        <Table stickyHeader aria-label="sticky table">
          <TableHead
            data-testid="tableHead"
            headCells={headerCells}
            order={desc ? SortType.Desc : SortType.Asc}
            orderBy={orderBy}
          />

          <TableBody
            sx={{
              boxShadow: 'none',
            }}
          >
            {renderSkeletonRows(skeletonRows, 25)}
          </TableBody>
        </Table>
      </Styled.TableContainer>
      <CustomTablePagination
        sx={{
          backgroundColor: '#F7F7F9',
        }}
        disabled
        page={page}
        pageSize={pageSize}
        setPage={null}
        setPageSize={null}
        pageTotal={null}
        skeleton={<Skeleton height="16px" width="120px" />}
      />
    </>
  );
};

export default LoadingTable;
