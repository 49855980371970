import * as Styled from './styles';
import { ButtonProps } from './types';

const Button: React.FC<ButtonProps> = ({ label, ...props }) => (
  <Styled.Button data-testid="button" {...props}>
    {label}
  </Styled.Button>
);

export default Button;
