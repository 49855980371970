export type EditEmailDialogProps = {
  open: boolean;
  handleClose: () => void;
};

export enum EditEmailStages {
  First = 1,
  Second = 2,
}

export type NewEmailFormValues = {
  email: string;
  confirmEmail: string;
  password: string;
  verificationCode?: string;
};
