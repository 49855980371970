import { PasswordResetRequestForm } from '~/core/types/auth.types';

export type PasswordResetRequestProps = {
  onSubmit: (value: PasswordResetRequestForm) => void;
};

export enum EmailErrorsEnum {
  Pattern = 'pattern',
  Required = 'required',
}
