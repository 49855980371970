import { DrawerProps } from '@mui/material';
import { useIntercom } from 'react-use-intercom';

import { useContexts } from '~/store/context/useContext';

import { AccountSelector } from '../Selectors';
import { SidebarOptions } from './components';
import * as Styled from './styles';

const SidebarNavigation: React.FC<DrawerProps> = ({ ...elementProps }) => {
  const { shutdown } = useIntercom();
  shutdown();
  const { platformAccounts } = useContexts();

  return (
    <Styled.ContainerBox data-testid="sidebarNavigation">
      <Styled.SidebarContainer>
        <AccountSelector options={platformAccounts} />
        <SidebarOptions />
      </Styled.SidebarContainer>
      <Styled.ContentContainer component="main">{elementProps.children}</Styled.ContentContainer>
    </Styled.ContainerBox>
  );
};

export default SidebarNavigation;
