/* eslint-disable prefer-arrow-callback */
/* eslint-disable func-names */
import { Box, Typography } from '@mui/material';
import * as d3 from 'd3';
import React, { useEffect, useRef, useState } from 'react';

import { useContexts } from '~/store/context/useContext';

import { main } from './helpers';

type VennDiagramProps = {
  intentCoveragePercentage?: number;
  assetCoveragePercentage?: number;
  setKeyPosition: React.Dispatch<React.SetStateAction<number>>;
};

type CustomTooltipProps = {
  content: string;
};

const searchIntentColor = '#90CAF9';
const contentYouHaveColor = '#153897';
// const intersectionColor = '#1D42A6';

const CustomTooltip = ({ content }: CustomTooltipProps) => (
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      padding: '4px 16px',
      border: 'solid 1px #e0e0e0',
      borderRadius: '4px',
      boxShadow:
        '0 1px 3px 0 rgba(100, 116, 139, 0.12), 0 1px 1px 0 rgba(100, 116, 139, 0.14), 0 2px 1px -3px rgba(100, 116, 139, 0.2)',
      backgroundColor: '#fff',
    }}
  >
    <Typography
      sx={{
        fontSize: '14px',
        fontWeight: 'bold',
        lineHeight: 1.5,
        letterSpacing: '0.15px',
        color: 'rgba(33, 33, 52, 0.87)',
      }}
    >
      {content}
    </Typography>
    {/* <Typography
      sx={{
        fontSize: '12px',
        lineHeight: 1.6,
        letterSpacing: '0.15px',
        color: 'rgba(33, 33, 52, 0.38)',
      }}
    >
      {selected ? 'Selected' : 'Click to View'}
    </Typography> */}
  </Box>
);

const VennDiagram = ({ intentCoveragePercentage, assetCoveragePercentage, setKeyPosition }: VennDiagramProps) => {
  // Perform the calculation with the given parameters
  const ref = useRef();
  const { selectedPlatformAccountId, selectedSourceAccountId, selectedCampaignId, selectedDateRange } = useContexts();
  const [showTooltip, setShowTooltip] = useState(false);
  const [tooltipPos, setTooltipPos] = useState({ x: 0, y: 0 });
  const [tooltipContent, setTooltipContent] = useState('');

  // Handle edge case of covereage of 1 causing infinite loop
  let formattedIntentCoveragePercentage = intentCoveragePercentage / 100;
  let formattedAssetCoveragePercentage = assetCoveragePercentage / 100;

  if (formattedIntentCoveragePercentage === 1) {
    formattedIntentCoveragePercentage = 0.99;
  }
  if (!formattedIntentCoveragePercentage) {
    formattedIntentCoveragePercentage = 0.01;
  }

  if (formattedAssetCoveragePercentage === 1) {
    formattedAssetCoveragePercentage = 0.99;
  }
  if (!formattedAssetCoveragePercentage) {
    formattedAssetCoveragePercentage = formattedIntentCoveragePercentage || 0.05;
  }

  const { r2, distance } = main(formattedIntentCoveragePercentage, formattedAssetCoveragePercentage);

  const width = 800;
  const height = 330;
  const circleOneRadius = 100;
  const circleY = height / 2;

  const circle1 = {
    x: width / 2 - distance / 2,
    y: circleY,
    r: circleOneRadius,
    tooltipContent: 'Consumer Search Intent',
  };
  const circle2 = {
    x: width / 2 + distance / 2,
    y: circleY,
    r: r2,
    tooltipContent: 'Content You Have',
  };

  setKeyPosition(circle1.r + circle2.r + 420);

  useEffect(() => {
    // Setup SVG element
    // reset svg so we dont creat inifinte
    d3.select(ref?.current).select('svg').remove();
    const svg = d3.select(ref.current).append('svg').attr('width', width).attr('height', height);

    svg
      .append('circle')
      .attr('class', 'venn-circle')
      .attr('cx', circle1.x)
      .attr('cy', circle1.y)
      .attr('r', circle1.r)
      .style('fill', searchIntentColor)
      .style('stroke', 'rgba(33, 33, 52, 0.6)')
      .style('stroke-width', 1)
      // create pointer hover effect
      .on('mouseover', function (event) {
        setShowTooltip(true);
        setTooltipContent(circle1.tooltipContent);
        const [x, y] = d3.pointer(event);
        setTooltipPos({ x: x - 320, y }); // Adjust as necessary for positioning
        d3.select(this).style('opacity', '0.8');
        const middleCircle = d3.select('#middle-circle');
        middleCircle.style('opacity', '0.8');
      })
      .on('mousemove', function (event) {
        const [x, y] = d3.pointer(event);
        setTooltipPos({ x: x + 220, y }); // Update position on move
      })
      .on('mouseout', function () {
        setShowTooltip(false);
        d3.select(this).style('opacity', '1');
      });
    // .on('click', () => {
    //   searchParams.set('pageState', PageStateEnum.INTENTS);
    //   setSearchParams(searchParams);
    //   setShowTooltip(false);
    // });

    // Draw the second circle
    svg
      .append('circle')
      .attr('class', 'venn-circle')
      .attr('cx', circle2.x)
      .attr('cy', circle2.y)
      .attr('r', circle2.r)
      .style('stroke', 'rgba(33, 33, 52, 0.6)')
      .style('opacity', 0.75)
      .style('stroke-width', 1)
      .style('fill', contentYouHaveColor)
      .on('mouseover', function (event) {
        setShowTooltip(true);
        setTooltipContent(circle2.tooltipContent);
        const [x, y] = d3.pointer(event);
        setTooltipPos({ x: x + 220, y }); // Adjust as necessary for positioning
        d3.select(this).style('opacity', '0.6');
        const middleCircle = d3.select('#middle-circle');
        middleCircle.style('opacity', '0.8');
      })
      .on('mousemove', function (event) {
        const [x, y] = d3.pointer(event);
        setTooltipPos({ x: x + 220, y }); // Update position on move
      })
      .on('mouseout', function () {
        setShowTooltip(false);
        d3.select(this).style('opacity', '0.75');
      });
    // .on('click', () => {
    //   searchParams.set('pageState', PageStateEnum.ASSETS);
    //   setSearchParams(searchParams);
    //   setShowTooltip(false);
    // });

    // Draw the intersection
    // const vennIntersection = svg
    //   .append('g')
    //   .attr('class', 'venn-intersection')
    //   .style('stroke', 'rgba(33, 33, 52, 0.6)')
    //   .style('stroke-width', 1);

    // Add two clipped paths for intersection
    // vennIntersection
    //   .append('clipPath')
    //   .attr('id', 'circle1-clip')
    //   .append('circle')
    //   .attr('cx', circle1.x)
    //   .attr('cy', circle1.y)
    //   .attr('r', circle1.r);

    // vennIntersection
    //   .append('clipPath')
    //   .attr('id', 'circle2-clip')
    //   .append('circle')
    //   .attr('cx', circle2.x)
    //   .attr('cy', circle2.y)
    //   .attr('r', circle2.r);

    // vennIntersection
    //   .append('circle')
    //   .attr('id', 'middle-circle')
    //   .attr('cx', circle1.x)
    //   .attr('cy', circle1.y)
    //   .attr('r', circle1.r)
    //   .attr('clip-path', 'url(#circle2-clip)')
    //   .style('fill', intersectionColor)
    // .on('mouseover', function (e) {
    //   handleMouseOver(e, circle3);
    //   d3.select(this).style('stroke', '#3e6ad8');
    // })
    // .on('mousemove', function (e) {
    //   handleMouseMove(e);
    // })
    // .on('mouseleave', handleMouseOut)
    // .on('click', () => {
    //   setPageState(PageStateEnum.covered);
    //   searchParams.set('pageState', PageStateEnum.covered);
    //   setSearchParams(searchParams);
    // });
    // .on('mouseover', function (event) {
    //   setShowTooltip(true);
    //   setTooltipContent(circle2.tooltipContent);
    //   setSelected(pageState === PageStateEnum.ASSETS);
    //   const [x, y] = d3.pointer(event);
    //   setTooltipPos({ x: x + 220, y }); // Adjust as necessary for positioning
    //   d3.select(this).style('opacity', '0.8');
    //   const middleCircle = d3.select('#middle-circle');
    //   middleCircle.style('opacity', '0.8');
    // })
    // .on('mousemove', function (event) {
    //   const [x, y] = d3.pointer(event);
    //   setTooltipPos({ x: x + 220, y }); // Update position on move
    // })
    // .on('mouseout', function () {
    //   setShowTooltip(false);
    //   d3.select(this).style('opacity', '1');
    // })
    // .on('click', () => {
    //   setPageState(PageStateEnum.ASSETS);
    //   searchParams.set('pageState', PageStateEnum.ASSETS);
    //   setSearchParams(searchParams);
    //   setShowTooltip(false);
    // });

    d3.select(ref?.current)
      .append('foreignObject')
      .attr('class', 'venn-tooltip')
      .attr('width', 562)
      .attr('height', 68)
      .attr('x', 0)
      .attr('y', 0)
      // .style('visibility', 'hidden')
      .style('position', 'absolute')
      .style('z-index', 100);
  }, [selectedPlatformAccountId, selectedSourceAccountId, selectedCampaignId, selectedDateRange]);
  return (
    <>
      <svg
        id="svg-visual"
        style={{
          position: 'absolute',
          top: '-30px',
          left: '20px',
          width: '900px',
          height: '350px',
          marginTop: '50px',
          zIndex: 0,
        }}
        ref={ref}
      />
      {showTooltip && (
        <Box
          sx={{
            position: 'absolute',
            left: `${tooltipPos.x - 180}px`,
            top: `${tooltipPos.y}px`,
            // Add more styles as needed for placement and visibility
            zIndex: 10, // Ensure this is above SVG
          }}
        >
          <CustomTooltip content={tooltipContent} />
        </Box>
      )}
    </>
  );
};

export default VennDiagram;
