import { styled, Typography, Card as MuiCard } from '@mui/material';

export const PageContainer = styled('div')({
  height: '100%',
});

export const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: '32px',
  paddingBottom: '40px',
});

export const ProfileItemContainer = styled('div')({
  display: 'flex',
  padding: '21px 17px',
  gap: '20px',
  alignItems: 'center',
});

export const HeaderContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '21px 17px',
});

export const MainTitle = styled(Typography)({
  fontSize: 20,
  fontWeight: 'bold',
});

export const TitleContainer = styled(Typography)({
  width: 200,
  fontSize: 16,
  fontWeight: 600,
});

export const NoLogoItem = styled('div')({
  width: 167,
  height: 42,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '8px',
  padding: 0,
  borderRadius: 4,
  backgroundColor: '#c4cfd4',
});

export const Card = styled(MuiCard)({
  margin: '0 20px',
});
