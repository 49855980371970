import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { InputAdornment, IconButton, TextFieldProps } from '@mui/material';
import { useState } from 'react';

import { Input } from '../Input';

const PasswordInput: React.FC<TextFieldProps> = ({ value, ...elementProps }) => {
  const [showPassword, setShowPassword] = useState(false);

  const handleShowPasswordChange = () => {
    setShowPassword((showPassword) => !showPassword);
  };

  return (
    <Input
      data-testid="passwordInput"
      type={showPassword ? 'text' : 'password'}
      value={value}
      autoComplete="password"
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton data-testid="handleShowPasswordButton" onClick={handleShowPasswordChange} edge="end">
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        ),
      }}
      {...elementProps}
    />
  );
};

export default PasswordInput;
