import { Paper as MuiPaper, TableContainer as MuiTableContainer, styled } from '@mui/material';

export const Paper = styled(MuiPaper)({
  minWidth: '1250px',
  borderRadius: '8px',
  boxShadow: 'none',
  marginBottom: '24px',
});

export const TableContainer = styled(MuiTableContainer)({
  minWidth: '1250px',
  borderRadius: '8px',
  boxShadow: 'rgba(100, 116, 139, 0.16) 0px 1px 1px, rgba(100, 116, 139, 0.16) 0px 1px 6px',
});
