import { styled, DialogContent as MuiDialogContent, Select, FormControl, Typography } from '@mui/material';

import { Input } from '~/core/components/shared';

export const DialogContent = styled(MuiDialogContent)({
  display: 'flex',
  flexDirection: 'row',
  gap: '24px',
  padding: 0,
  paddingTop: '6px',
});

export const CustomInput = styled(Input)({
  width: '100%',
});

export const CustomSelect = styled(Select)({
  width: '60%',
});

export const HeaderContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
});

export const FormContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: '24px',
  flex: 3,
});

export const LogoContainer = styled('div')({
  flex: 2,
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
});

export const NoLogoItem = styled('div')({
  width: 186,
  height: 47,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '8px',
  padding: 0,
  borderRadius: 4,
  backgroundColor: '#c4cfd4',
});

export const CustomFormControl = styled(FormControl)({
  margin: '0',
});

export const CustomSelectMedium = styled(Select)({
  width: '250px',
});

export const Column = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  width: '45%',
  gap: '20px',
  marginRight: '20px',
});

export const ColumnHeader = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontSize: '16px',
  fontWeight: 'bold',
  fontStretch: 'normal',
  fontStyle: 'normal',
  lineHeight: '1.75',
  letterSpacing: '0.15px',
  textAlign: 'left',
  paddingBottom: '14px',
}));

export const CustomInputMedium = styled(Input)({
  width: '250px',
});

export const FlexBetween = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
});

export const CustomInputShort = styled(Input)({
  width: '220px',
});

export const CustomSelectShort = styled(Select)({
  width: '220px',
});
