/* eslint-disable */

import { Box, Container, Paper, Skeleton } from '@mui/material';
import { SyntheticEvent, useCallback, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { Breadcrumbs, SearchField } from '~/core/components/shared';
import Tab from '~/core/components/shared/Tab/Tab';
import { Tabs } from '~/core/components/shared/Tabs';
import { GetAssetRecommendationsQueryVariables } from '~/core/services/graphql/assets/assets.queries.generated';
import { useGetAssetRecommendations } from '~/core/services/graphql/assets/hooks';
import { AssetRecommendationsSortEnum } from '~/core/types/graphql.types';
import { useContexts } from '~/store/context/useContext';

import CollapsibleRow from './CollapsibleRow';
import * as Styled from './styles';

export default function Recommendations() {
  const [activeTab, setActiveTab] = useState<string>('headlines');
  const [searchParams] = useSearchParams();
  const [searchValue, setSearchValue] = useState<string>('');
  const {
    selectedPlatformAccountId,
    selectedSourceAccountId,
    selectedCampaignId,
    selectedClusterId,
    selectedDateRange,
  } = useContexts();

  const handleTabChange = (event: SyntheticEvent, newValue: string) => {
    setActiveTab(newValue);
  };

  const getQueryValue = useCallback(
    (selected: string | null, queryParam: string) => selected || searchParams.get(queryParam),
    [searchParams],
  );

  const platformAccountId = useMemo(
    () => getQueryValue(String(selectedPlatformAccountId), 'platformAccountId'),
    [getQueryValue, selectedPlatformAccountId],
  );
  const sourceAccountId =
    useMemo(
      () => getQueryValue(selectedSourceAccountId, 'sourceAccountId'),
      [getQueryValue, selectedSourceAccountId],
    ) || searchParams.get('sourceAccountId');
  const campaignId =
    useMemo(() => getQueryValue(selectedCampaignId, 'campaignId'), [getQueryValue, selectedCampaignId]) ||
    searchParams.get('campaignId');
  const clusterId =
    useMemo(() => getQueryValue(selectedClusterId, 'intentId'), [getQueryValue, selectedClusterId]) ||
    searchParams.get('intentId');
  const dateRange =
    useMemo(() => getQueryValue(selectedDateRange, 'dateRange'), [getQueryValue, selectedDateRange]) ||
    searchParams.get('dateRange');

  const importedState = useMemo(() => {
    const searchParamsFiltered = {
      orderBy: searchParams.get('orderBy'),
      desc: searchParams.get('desc'),
      page: searchParams.get('page'),
      filterKey: searchParams.get('filterKey'),
      pageSize: searchParams.get('pageSize') || '0', // Consider default value if 'pageSize' should not be excluded when it's null
      covered: searchParams.get('covered'),
    };
    const params = { platformAccountId, sourceAccountId, campaignId, dateRange, ...searchParamsFiltered };
    return Object.entries(params).reduce((acc: any, [key, value]) => {
      if (value !== null) {
        acc[key] = value;
      }
      return acc;
    }, {});
  }, [platformAccountId, sourceAccountId, campaignId, clusterId, dateRange, searchParams]);

  const assetRecommendationsSkip = !platformAccountId || !sourceAccountId || !clusterId || !dateRange;
  const assetRecommendationsVariables: GetAssetRecommendationsQueryVariables = {
    platformAccountId: Number(platformAccountId),
    customerIds: [sourceAccountId],
    intentIds: [clusterId],
    dateRange,
    sortBy: {
      field: AssetRecommendationsSortEnum.AssetText,
      desc: true,
    },
  };
  const { assetRecommendationsLoading, getAssetRecommendations } = useGetAssetRecommendations(
    assetRecommendationsSkip,
    assetRecommendationsVariables,
  );

  const headlines = getAssetRecommendations?.filter((recommendation) => recommendation?.assetType === 'HEADLINE');

  const descriptions = getAssetRecommendations?.filter((recommendation) => recommendation?.assetType === 'DESCRIPTION');

  const [filteredHeadlines, setFilteredHeadlines] = useState([]);
  const [filteredDescriptions, setFilteredDescriptions] = useState([]);

  if (!searchValue && !filteredHeadlines?.length && headlines?.length) {
    setFilteredHeadlines(headlines);
  }

  if (!searchValue && !filteredDescriptions?.length && descriptions?.length) {
    setFilteredDescriptions(descriptions);
  }

  const handleSearch = (str: string) => {
    setFilteredHeadlines(
      headlines?.filter((headline) => headline?.assetText?.toLowerCase()?.includes(str.toLowerCase())),
    );

    setFilteredDescriptions(
      descriptions?.filter((description) => description?.assetText?.toLowerCase()?.includes(str.toLowerCase())),
    );
  };

  return (
    <>
      <Breadcrumbs importedState={importedState} />
      <Container
        sx={{
          padding: '32px 0',
          // maxWidth: '420px',
        }}
      >
        <Paper
          sx={{
            margin: '0 100px',
            minWidth: '650px',
            minHeight: '88vh',
          }}
        >
          <Box
            sx={{
              padding: '12px 16px',
            }}
          >
            <Styled.Heading>
              {assetRecommendationsLoading ? (
                <Skeleton variant="text" width={500} />
              ) : (
                `We found ${getAssetRecommendations?.length} assets in your account that can align to this intent.`
              )}
            </Styled.Heading>
            <SearchField
              onChange={(e) => {
                setSearchValue(e.target.value);
                handleSearch(e.target.value);
              }}
              disabled={assetRecommendationsLoading}
              sx={{
                minWidth: '300px',
              }}
              size="small"
              placeholder="Find within assets"
            />
          </Box>
          <Tabs value={activeTab} onChange={(_, newValue) => handleTabChange(_, newValue)}>
            <Tab label="Headlines" value="headlines" />
            <Tab label="Descriptions" value="descriptions" />
          </Tabs>
          <Box
            sx={{
              padding: '12px 16px',
            }}
          >
            {activeTab === 'headlines' && (
              <Box>
                {assetRecommendationsLoading ? (
                  <CollapsibleRow key="loading" asset={null} assetLoading={assetRecommendationsLoading} />
                ) : filteredHeadlines?.length > 0 ? (
                  filteredHeadlines?.map((data) => <CollapsibleRow key={data.assetText} asset={data} />)
                ) : (
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: '100%',
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        fontSize: '16px',
                        fontWeight: 'bold',
                        lineHeight: 1.57,
                        letterSpacing: '0.1px',
                      }}
                    >
                      No recommendations available
                    </Box>
                  </Box>
                )}
              </Box>
            )}

            {activeTab === 'descriptions' && (
              <Box>
                {assetRecommendationsLoading ? (
                  <CollapsibleRow key="loading" asset={null} assetLoading={assetRecommendationsLoading} />
                ) : filteredDescriptions?.length > 0 && !searchValue ? (
                  filteredDescriptions?.map((data) => (
                    <CollapsibleRow key={data.assetText} asset={data} assetLoading={assetRecommendationsLoading} />
                  ))
                ) : (
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      fontSize: '16px',
                      fontWeight: 'bold',
                      lineHeight: 1.57,
                      letterSpacing: '0.1px',
                    }}
                  >
                    <Box>No recommendations available</Box>
                  </Box>
                )}
              </Box>
            )}
          </Box>
        </Paper>
      </Container>
    </>
  );
}
