import { Close } from '@mui/icons-material';
import { Box, IconButton, InputLabel, MenuItem, Typography } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';

import { Button } from '~/core/components/shared';
import { Dialog } from '~/core/components/shared/Dialog';
import { DialogFooter } from '~/core/components/shared/DialogFooter';
import { PHONE_NUMBER_PATTERN, WEBSITE_PATTERN, ZIP_CODE_PATTERN } from '~/core/constants/validationPatterns.constants';
import { parseSnakeUppercaseToNormal } from '~/core/helpers';
import {
  CompanyTypeEnum,
  CountryEnum,
  IndustryEnum,
  PlatformAccountUpdateSchema,
  StateEnum,
} from '~/core/types/graphql.types';

import * as Styled from './styles';
import { EditCompanyProfileDialogProps } from './types';

const EditCompanyProfileDialog: React.FC<EditCompanyProfileDialogProps> = ({
  open,
  defaultValues,
  handleClose,
  onSubmit,
}) => {
  const {
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm<PlatformAccountUpdateSchema>({
    defaultValues,
    reValidateMode: 'onSubmit',
  });

  const watchCountry = watch('country');

  return (
    <Dialog
      data-testid="editCompanyProfileDialog"
      maxWidth="md"
      handleCloseButton={handleClose}
      hideCloseButton
      open={open}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Styled.HeaderContainer>
          <Typography fontSize={20} fontWeight={600}>
            Update your company profile
          </Typography>
          <IconButton onClick={handleClose}>
            <Close />
          </IconButton>
        </Styled.HeaderContainer>
        <Styled.DialogContent>
          <Styled.FormContainer>
            <Box sx={{ display: 'flex' }}>
              <Styled.Column>
                <Styled.ColumnHeader>Business Information</Styled.ColumnHeader>
                <Controller
                  name="name"
                  rules={{ required: true }}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <Styled.CustomInput value={value} onChange={onChange} label="Company Name" required />
                  )}
                />
                <Controller
                  name="website"
                  rules={{
                    required: true,
                    pattern: {
                      message: 'Enter valid website',
                      value: WEBSITE_PATTERN,
                    },
                  }}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <Styled.CustomInput
                      required
                      value={value}
                      onChange={onChange}
                      label="Corporate Website"
                      error={!!errors?.website}
                      helperText={!!errors?.website && 'Enter valid website'}
                    />
                  )}
                />
                <Controller
                  name="editableCompanyType"
                  rules={{ required: true }}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <Styled.CustomFormControl required>
                      <InputLabel>Company Type</InputLabel>
                      <Styled.CustomSelectMedium value={value} label="Company Type" onChange={onChange}>
                        {(Object.keys(CompanyTypeEnum) as (keyof typeof CompanyTypeEnum)[]).map((key) => (
                          <MenuItem key={CompanyTypeEnum[key]} value={CompanyTypeEnum[key]}>
                            {CompanyTypeEnum[key]}
                          </MenuItem>
                        ))}
                      </Styled.CustomSelectMedium>
                    </Styled.CustomFormControl>
                  )}
                />
                <Controller
                  name="industry"
                  rules={{ required: true }}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <Styled.CustomFormControl required>
                      <InputLabel>Industry</InputLabel>
                      <Styled.CustomSelectMedium value={value} label="Industry" onChange={onChange}>
                        {(Object.keys(IndustryEnum) as (keyof typeof IndustryEnum)[]).map((key) => (
                          <MenuItem key={IndustryEnum[key]} value={IndustryEnum[key]}>
                            {parseSnakeUppercaseToNormal(IndustryEnum[key])}
                          </MenuItem>
                        ))}
                      </Styled.CustomSelectMedium>
                    </Styled.CustomFormControl>
                  )}
                />
                <Controller
                  name="phoneNumber"
                  rules={{
                    required: true,
                    pattern: {
                      value: PHONE_NUMBER_PATTERN,
                      message: 'Wrong number',
                    },
                  }}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <Styled.CustomInputMedium
                      required
                      value={value}
                      onChange={onChange}
                      label="Corporate Phone Number"
                      error={!!errors?.phoneNumber}
                      helperText={!!errors?.phoneNumber && 'Enter valid phone number'}
                    />
                  )}
                />
                <Controller
                  name="faxNumber"
                  rules={{
                    required: false,
                    pattern: {
                      value: PHONE_NUMBER_PATTERN,
                      message: 'Wrong number',
                    },
                  }}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <Styled.CustomInputMedium
                      value={value}
                      onChange={onChange}
                      label="Corporate Fax Number"
                      error={!!errors?.faxNumber}
                      helperText={!!errors?.faxNumber && 'Enter valid fax number'}
                    />
                  )}
                />
              </Styled.Column>
              <Styled.Column>
                <Styled.ColumnHeader>Business Address</Styled.ColumnHeader>
                <Controller
                  name="address_1"
                  rules={{
                    required: true,
                  }}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <Styled.CustomInput value={value} onChange={onChange} label="Address Line 1" required />
                  )}
                />
                <Controller
                  name="address_2"
                  rules={{
                    required: false,
                  }}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <Styled.CustomInput value={value} onChange={onChange} label="Address Line 2" />
                  )}
                />
                <Controller
                  name="city"
                  rules={{
                    required: true,
                  }}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <Styled.CustomInputShort value={value} onChange={onChange} label="City" required />
                  )}
                />
                <Controller
                  name="country"
                  rules={{ required: true }}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <Styled.CustomFormControl required>
                      <InputLabel>Country</InputLabel>
                      <Styled.CustomSelect value={value} label="Country" onChange={onChange}>
                        {(Object.keys(CountryEnum) as (keyof typeof CountryEnum)[]).map((key) => (
                          <MenuItem key={key} value={CountryEnum[key]}>
                            {CountryEnum[key]}
                          </MenuItem>
                        ))}
                      </Styled.CustomSelect>
                    </Styled.CustomFormControl>
                  )}
                />
                <Styled.FlexBetween>
                  {watchCountry === CountryEnum.Usa && (
                    <Controller
                      name="state"
                      rules={{ required: true }}
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <Styled.CustomFormControl required>
                          <InputLabel>State</InputLabel>
                          <Styled.CustomSelectShort value={value} label="State" onChange={onChange}>
                            {watchCountry === CountryEnum.Usa &&
                              (Object.keys(StateEnum) as (keyof typeof StateEnum)[]).map((key) => (
                                <MenuItem key={key} value={StateEnum[key]}>
                                  {StateEnum[key]}
                                </MenuItem>
                              ))}
                          </Styled.CustomSelectShort>
                        </Styled.CustomFormControl>
                      )}
                    />
                  )}
                  <Controller
                    name="zipCode"
                    rules={{
                      required: true,
                      pattern: {
                        value: ZIP_CODE_PATTERN,
                        message: 'Invalid zip code',
                      },
                    }}
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <Styled.CustomInputShort
                        required
                        sx={watchCountry === CountryEnum.Usa && { marginLeft: '80px' }}
                        value={value}
                        onChange={onChange}
                        label="Zip Code"
                        error={!!errors?.zipCode}
                        helperText={!!errors?.zipCode && 'Enter valid zip code'}
                      />
                    )}
                  />
                </Styled.FlexBetween>
              </Styled.Column>
            </Box>
          </Styled.FormContainer>
        </Styled.DialogContent>
        <DialogFooter>
          <Button data-testid="handleClose" label="Cancel" variant="outlined" onClick={handleClose} />
          <Button data-testid="handleSubmit" label="Update" type="submit" variant="contained" />
        </DialogFooter>
      </form>
    </Dialog>
  );
};

export default EditCompanyProfileDialog;
