import { Lock } from '@mui/icons-material';
import { Box, styled, Typography } from '@mui/material';

export const Container = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
});

export const Content = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',
});

export const LockIcon = styled(Lock)`
  color: #3154aa;
  transform: scale(1.5, 1.325);
  width: 46.8px;
  height: 54.3px;
`;

export const Heading = styled(Typography)`
  height: 32px;
  font-size: 20px;
  font-weight: bold;
  line-height: 1.6;
  letter-spacing: 0.15px;
  text-align: center;
  color: var(--light-text-primary);
  margin-top: 22.2px;
  white-space: nowrap;
`;

export const SubHeading = styled(Typography)`
  height: 28px;
  font-size: 16px;
  line-height: 1.75;
  letter-spacing: 0.15px;
  text-align: center;
  color: var(--light-text-primary);
  white-space: nowrap;
`;
