import { TablePagination as MuiTablePagination } from '@mui/material';

import * as Styled from './styles';
import { TablePaginationProps } from './types';

export interface TablePagination extends TablePaginationProps {
  disabled?: boolean;
}

const TablePagination: React.FC<TablePagination> = ({
  page,
  onPageChange,
  rowsPerPage,
  onRowsPerPageChange,
  count,
  disabled = false,
  ...elementProps
}) => (
  <Styled.TablePaginationContainer>
    <MuiTablePagination
      component="div"
      data-testid="tablePagination"
      count={count}
      rowsPerPage={rowsPerPage}
      page={page}
      onPageChange={onPageChange}
      onRowsPerPageChange={onRowsPerPageChange}
      SelectProps={{
        disabled,
      }}
      {...elementProps}
    />
  </Styled.TablePaginationContainer>
);

export default TablePagination;
