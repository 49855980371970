import { Box, FormControl, styled, TableContainer as MuiTableContainer } from '@mui/material';

import { Button, FilterDropdown, SearchField, TableBody as MuiTableBody } from '~/core/components/shared';

export const TableContainer = styled(MuiTableContainer)({});

export const UserListContainer = styled(Box)({
  paddingTop: '24px',
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
});

export const InviteButton = styled(Button)({
  height: 42,
  maxWidth: '176px',
  marginLeft: '16px',
});

export const SearchContainer = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
  alignItems: 'center',
});

export const SearchFilters = styled('div')({
  display: 'flex',
  gap: '22px',
  flex: 1,
});

export const SearchFieldContainer = styled(SearchField)({
  maxWidth: 340,
});

export const StatusFilterContainer = styled(FilterDropdown)({
  maxWidth: 180,
});

export const RoleDropdown = styled(FormControl)({
  '& .MuiOutlinedInput-notchedOutline': {
    borderRadius: 6,
  },
});

export const TableBody = styled(MuiTableBody)({
  '& > *': {
    flex: 1,
  },
});
