import { createReducer } from 'typesafe-actions';

import { PageInfoSchema, PlatformAccountSchema, PlatformAccountWithAdminSchema } from '~/core/types/graphql.types';

import {
  createAccountAction,
  updatePlatformAccountAction,
  accountAction,
  AccountActionUnion,
  allAccountsAction,
  updateAccountBillingInfoAction,
  updateAccountProfileInfoAction,
} from '../actions/account.action';

export interface State {
  createdAccountId: number;
  updatedAccount: PlatformAccountSchema;
  account: PlatformAccountSchema;
  allAccounts: PlatformAccountWithAdminSchema[];
  allAccountsPagination: PageInfoSchema;
  isLoading: boolean;
  error: string;
}

export const initialState: State = {
  account: null,
  createdAccountId: null,
  updatedAccount: null,
  allAccounts: null,
  allAccountsPagination: null,
  isLoading: false,
  error: null,
};

export const reducer = createReducer<State, AccountActionUnion>(initialState)
  .handleAction(createAccountAction.success, (state, action) => ({
    ...state,
    isLoading: false,
    createdAccountId: action.payload,
    error: null,
  }))
  .handleAction(createAccountAction.failure, (state, action) => ({
    ...state,
    isLoading: false,
    error: action.payload,
  }))
  .handleAction(createAccountAction.request, (state) => ({
    ...state,
    isLoading: true,
    error: null,
  }))
  .handleAction(updatePlatformAccountAction.success, (state, action) => ({
    ...state,
    isLoading: false,
    updatedAccount: action.payload,
    error: null,
  }))
  .handleAction(updatePlatformAccountAction.failure, (state, action) => ({
    ...state,
    isLoading: false,
    error: action.payload,
  }))
  .handleAction(updatePlatformAccountAction.request, (state) => ({
    ...state,
    isLoading: true,
    error: null,
  }))
  .handleAction(accountAction.success, (state, action) => ({
    ...state,
    isLoading: false,
    account: action.payload,
    error: null,
  }))
  .handleAction(accountAction.failure, (state, action) => ({
    ...state,
    isLoading: false,
    error: action.payload,
  }))
  .handleAction(accountAction.request, (state) => ({
    ...state,
    isLoading: true,
    error: null,
  }))
  .handleAction(allAccountsAction.success, (state, action) => ({
    ...state,
    isLoading: false,
    allAccounts: action.payload.accounts.edges,
    allAccountsPagination: action.payload.accounts.pageInfo,
    error: null,
  }))
  .handleAction(allAccountsAction.failure, (state, action) => ({
    ...state,
    isLoading: false,
    error: action.payload,
  }))
  .handleAction(allAccountsAction.request, (state) => ({
    ...state,
    isLoading: true,
    error: null,
  }))
  .handleAction(updateAccountProfileInfoAction.success, (state, { payload }) => ({
    ...state,
    isLoading: false,
    account: payload.updateAccount,
    error: null,
  }))
  .handleAction(updateAccountProfileInfoAction.failure, (state, action) => ({
    ...state,
    isLoading: false,
    error: action.payload,
  }))
  .handleAction(updateAccountProfileInfoAction.request, (state) => ({
    ...state,
    isLoading: true,
    error: null,
  }))
  .handleAction(updateAccountBillingInfoAction.success, (state, { payload }) => ({
    ...state,
    isLoading: false,
    account: payload.updateAccount,
    error: null,
  }))
  .handleAction(updateAccountBillingInfoAction.failure, (state, action) => ({
    ...state,
    isLoading: false,
    error: action.payload,
  }))
  .handleAction(updateAccountBillingInfoAction.request, (state) => ({
    ...state,
    isLoading: true,
    error: null,
  }));
