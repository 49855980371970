import { CardActions as MuiCardActions, CardContent as MuiCardContent, styled } from '@mui/material';

import { Input, VerticalStepper as VerticalStepperComponent } from '~/core/components/shared';

export const CardContent = styled(MuiCardContent)({
  padding: '0px 0px !important',
  display: 'flex',
  justifyContent: 'center',
  alignSelf: 'center',
});

export const VerticalStepper = styled(VerticalStepperComponent)({
  height: '315px',
  width: '200px',
});

export const StepContent = styled('div')({
  borderLeft: '1px solid lightgrey',
  paddingLeft: '32px',
  width: '100%',
  backgroundColor: '#fff',
});

export const HeaderContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '24px 0',
});

export const FormContainer = styled('div')({
  display: 'flex',
  height: '630px',
  flexDirection: 'column',
  gap: '24px',
  flex: 3,
  paddingRight: '28px',
});

export const CustomInput = styled(Input)({
  margin: '12px 0',
  width: '355px',
});

export const CardActions = styled(MuiCardActions)({
  display: 'flex',
  justifyContent: 'flex-end',
  borderTop: '1px solid lightgrey',
  padding: '22px 16px',
  height: '70px',
});
