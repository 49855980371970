import { SetNewPassword, User, VerifyNewEmailForm } from '~/core/types/auth.types';

import ApiClient from './api.service';

export const login = async (email: string, password: string) => {
  const {
    data: {
      AuthenticationResult: { RefreshToken, AccessToken, IdToken },
      User,
    },
  } = await ApiClient.post<{
    AuthenticationResult: {
      AccessToken: string;
      RefreshToken: string;
      IdToken: string;
    };
    User: User;
  }>('user/sign-in', { email, password });

  return { RefreshToken, AccessToken, IdToken, User };
};

export const createAccount = async (token: string, givenName: string, familyName: string, password: string) => {
  const { data } = await ApiClient.post('user/sign-up', { token, password, familyName, givenName });

  return data;
};

export const createFreeAccount = async (
  email: string,
  companyName: string,
  givenName: string,
  familyName: string,
  password: string,
) => {
  const { data } = await ApiClient.post('user/sign-up-free', { email, companyName, password, familyName, givenName });

  return data;
};

export const forgotPasswordRequest = async (email: string) => {
  await ApiClient.post('user/forgot-password', { email });
};

export const setNewPasswordRequest = async (payload: SetNewPassword) => {
  await ApiClient.post('user/forgot-password/set-new', payload);
};

export const confirmEmailRequest = async (email: string, confirmationCode: string) => {
  await ApiClient.post('user/sign-up/confirm', { email, confirmationCode });
};

export const resendConfirmationCodeRequest = async (email: string) => {
  await ApiClient.post('user/sign-up/confirm/resend', { email });
};

// TODO: Swap fetch calls with axios
// * Ticket: SE-1417
export const refreshTokenRequest = async (refreshToken: string) => {
  const {
    AuthenticationResult: { AccessToken, IdToken },
    User,
  } = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/v1/user/refresh`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ refreshToken }),
  }).then((response) => {
    if (response.status === 401) {
      localStorage.clear();
      window.location.reload();
    }
    return response.json();
  });

  return { AccessToken, IdToken, User };
};

export const verifyNewEmail = async (payload: VerifyNewEmailForm) => {
  const { userId, confirmationCode } = payload;
  await ApiClient.post('user/verify-new-email', { userId, confirmationCode });
};

export const verifyNewEmailResend = async () => {
  await ApiClient.post('user/verify-new-email/resend');
};
