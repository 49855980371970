import * as Styled from './styles';
import { MenuItemsProps } from './types';

const MenuItem: React.FC<MenuItemsProps> = ({ mainHeader, subComponent, ...props }) => (
  <Styled.Card data-testid="menuItem" {...props}>
    <Styled.MainBox>
      {mainHeader && <Styled.MainHeading data-testid="mainHeader">{mainHeader}</Styled.MainHeading>}
    </Styled.MainBox>
    <Styled.SubBox>{subComponent}</Styled.SubBox>
  </Styled.Card>
);

export default MenuItem;
