import { TypographyProps } from '@mui/material';

import * as Styled from './styles';

const FormTitle: React.FC<TypographyProps> = ({ children, ...elementProps }) => (
  <Styled.FormTitle data-testid="formTitle" {...elementProps}>
    {children}
  </Styled.FormTitle>
);

export default FormTitle;
