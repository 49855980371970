import { styled, Tab as MuiTab } from '@mui/material';

export const Tab = styled(MuiTab)({
  fontWeight: 600,
  height: '22px',
  flexGrow: 1,
  fontSize: '13px',
  lineHeight: 1.69,
  letterSpacing: '0.46px',
  textTransform: 'none',
  color: 'rgba(33, 33, 52, 0.6)',
  '&.Mui-selected': {
    color: '#3154aa',
    background: '#f6f6f7',
  },
});
