import { gql } from '@apollo/client';

export const GET_KEYWORDS = gql`
  query getKeywords(
    $platformAccountId: Int!
    $customerIds: [String!]!
    $campaignIds: [String!]
    $adGroupIds: [String!]
    $dateRange: String!
    $sortBy: KeywordSortSchema
    $filterField: BaseFilterSchema
    $searchValue: String
    $page: Int
    $pageSize: Int
  ) {
    getKeywords(
      platformAccountId: $platformAccountId
      customerIds: $customerIds
      campaignIds: $campaignIds
      adGroupIds: $adGroupIds
      dateRange: $dateRange
      sortBy: $sortBy
      filterField: $filterField
      searchValue: $searchValue
      page: $page
      pageSize: $pageSize
    ) {
      pageInfo {
        pageSize
        page
        total
        pages
        previousPage
        nextPage
      }
      edges {
        start_date
        end_date
        campaign_id
        ad_group_id
        keyword_text
        keyword_match_type
        creative_quality_score
        quality_score
        search_predicted_ctr
        criterion_resource_name
        impressions
        clicks
        conversions
        all_conversions
        interactions
        auctions
        cost
        customer_id
        date_range
      }
    }
  }
`;

export const GET_KEYWORDS_TEXT = gql`
  query getKeywordsText(
    $platformAccountId: Int!
    $customerIds: [String!]!
    $campaignIds: [String!]
    $adGroupIds: [String!]
    $dateRange: String!
    $sortBy: KeywordSortSchema
    $filterField: BaseFilterSchema
    $searchValue: String
    $page: Int
    $pageSize: Int
  ) {
    getKeywords(
      platformAccountId: $platformAccountId
      customerIds: $customerIds
      campaignIds: $campaignIds
      adGroupIds: $adGroupIds
      dateRange: $dateRange
      sortBy: $sortBy
      filterField: $filterField
      searchValue: $searchValue
      page: $page
      pageSize: $pageSize
    ) {
      pageInfo {
        pageSize
        page
        total
        pages
        previousPage
        nextPage
      }
      edges {
        keyword_text
      }
    }
  }
`;

export const GET_KEYWORD_COHERENCY_KPIS = gql`
  query getKeywordCoherencyKpis(
    $platformAccountId: Int!
    $customerIds: [String!]!
    $dateRange: String!
    $filterField: BaseFilterSchema
  ) {
    getKeywordCoherencyKpis(
      platformAccountId: $platformAccountId
      customerIds: $customerIds
      dateRange: $dateRange
      filterField: $filterField
    ) {
      keywordCoherency
      impressions
      clicks
      conversions
      cost
    }
  }
`;
