import { styled, Container, IconButton } from '@mui/material';

export const DialogContainer = styled(Container)({
  display: 'flex',
  flexDirection: 'column',
  padding: '16px 24px',
  gap: '24px',
});

export const CloseButton = styled(IconButton)({
  alignSelf: 'flex-start',
  position: 'absolute',
  top: '9px',
  right: '9px',
});
