import { Tabs as MuiTabs, TabsProps as MuiTabsProps } from '@mui/material';

interface TabsProps extends MuiTabsProps {
  children: React.ReactNode;
}

const Tabs = ({ children, ...props }: TabsProps) => (
  <MuiTabs
    variant="fullWidth"
    sx={{
      background: '#f6f6f7',
      borderBottom: '1px solid rgba(33, 33, 52, 0.12)',
    }}
    {...props}
  >
    {children}
  </MuiTabs>
);
export default Tabs;
