import { Close } from '@mui/icons-material';
import { IconButton, Typography } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';

import { Button, Input } from '~/core/components/shared';
import { Dialog } from '~/core/components/shared/Dialog';
import { DialogFooter } from '~/core/components/shared/DialogFooter';
import { EMAIL_PATTERN_REGEX, PHONE_NUMBER_PATTERN } from '~/core/constants/validationPatterns.constants';
import { PlatformAccountUpdateSchema } from '~/core/types/graphql.types';

import * as Styled from './styles';
import { EditCompanyBillingInfoDialogProps } from './types';

const EditCompanyBillingInfoDialog: React.FC<EditCompanyBillingInfoDialogProps> = ({
  open,
  handleClose,
  onSubmit,
  defaultValues,
}) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<PlatformAccountUpdateSchema>({
    defaultValues,
    reValidateMode: 'onSubmit',
  });

  return (
    <Dialog data-testid="editCompanyBillingInfoDialog" hideCloseButton handleCloseButton={handleClose} open={open}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Styled.HeaderContainer>
          <Typography fontSize={20} fontWeight={600}>
            Update your company billing info
          </Typography>
          <IconButton onClick={handleClose}>
            <Close />
          </IconButton>
        </Styled.HeaderContainer>
        <Styled.DialogContent>
          <Controller
            name="contactFirstName"
            rules={{ required: true }}
            control={control}
            render={({ field: { onChange, value } }) => (
              <Input value={value} onChange={onChange} label="Contact First Name" />
            )}
          />
          <Controller
            name="contactLastName"
            rules={{ required: true }}
            control={control}
            render={({ field: { onChange, value } }) => (
              <Input value={value} onChange={onChange} label="Contact Last Name" />
            )}
          />
          <Controller
            name="contactEmail"
            rules={{
              required: true,
              pattern: {
                value: EMAIL_PATTERN_REGEX,
                message: 'Please enter a valid email address',
              },
            }}
            control={control}
            render={({ field: { onChange, value } }) => (
              <Input
                value={value}
                onChange={onChange}
                label="Contact Email"
                error={!!errors?.contactEmail}
                helperText={!!errors?.contactEmail && 'Enter valid email'}
              />
            )}
          />
          <Controller
            name="contactPhone"
            rules={{
              required: true,
              pattern: {
                value: PHONE_NUMBER_PATTERN,
                message: 'Wrong number',
              },
            }}
            control={control}
            render={({ field: { onChange, value } }) => (
              <Styled.CustomInput
                value={value}
                onChange={onChange}
                label="Contact Phone Number"
                error={!!errors?.contactPhone}
                helperText={!!errors?.contactPhone && 'Enter valid phone number'}
              />
            )}
          />
        </Styled.DialogContent>
        <DialogFooter>
          <Button data-testid="handleClose" label="Cancel" variant="outlined" onClick={handleClose} />
          <Button data-testid="handleSubmit" label="Update" type="submit" variant="contained" />
        </DialogFooter>
      </form>
    </Dialog>
  );
};

export default EditCompanyBillingInfoDialog;
