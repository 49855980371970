import { Box, Tab as MuiTab, Typography, styled } from '@mui/material';

export const Container = styled(Box)({
  padding: '24px',
  width: '100%',
  minWidth: '850px',
});

export const Heading = styled(Typography)`
  font-size: 20px;
  font-weight: bold;
  line-height: 1.6;
  letter-spacing: 0.15px;
  color: rgba(33, 33, 52, 0.87);
`;

export const TabsContainer = styled(Box)({
  borderBottom: '1px solid rgba(33,33,52,0.12)',
  minWidth: '850px',
  marginTop: '32px',
});

export const Tab = styled(MuiTab)({
  flexGrow: 1,
  fontSize: '14px',
  fontWeight: 600,
  lineHeight: 1.71,
  letterSpacing: '0.4px',
  textTransform: 'none',
  color: 'rgba(33, 33, 52, 0.6)',
  '&.Mui-selected': {
    color: '#3154aa',
  },
});
