import { Controller, useForm } from 'react-hook-form';

import { Button, Dialog, DialogFooter, DialogHeader, Input } from '~/core/components/shared';

import * as Styled from './styles';
import { EditNameDialogProps, EditNameInputs } from './types';

const EditNameDialog: React.FC<EditNameDialogProps> = ({ open, handleClose, onSubmit, defaultValues }) => {
  const {
    handleSubmit,
    control,
    formState: { errors, isDirty },
  } = useForm<EditNameInputs>({
    reValidateMode: 'onSubmit',
    defaultValues,
  });

  return (
    <Dialog open={open} handleCloseButton={handleClose} data-testid="editNameDialog">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Styled.DialogContainer>
          <DialogHeader title="Edit Name" />
          <Styled.DialogContent>
            <Controller
              name="firstName"
              rules={{ required: true }}
              control={control}
              render={({ field: { onChange, value } }) => (
                <Input
                  label="First Name"
                  value={value}
                  onChange={onChange}
                  autoComplete="given-name"
                  error={!!errors?.firstName}
                />
              )}
            />
            <Controller
              name="lastName"
              rules={{ required: true }}
              control={control}
              render={({ field: { onChange, value } }) => (
                <Input
                  label="Last Name"
                  value={value}
                  onChange={onChange}
                  autoComplete="family-name"
                  error={!!errors?.lastName}
                />
              )}
            />
          </Styled.DialogContent>
          <DialogFooter>
            <Button onClick={handleClose} label="Cancel" variant="outlined">
              Cancel
            </Button>
            <Button type="submit" label="Update" variant="contained" disabled={!isDirty}>
              Update
            </Button>
          </DialogFooter>
        </Styled.DialogContainer>
      </form>
    </Dialog>
  );
};

export default EditNameDialog;
