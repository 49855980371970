import { EditOutlined } from '@mui/icons-material';
import { Divider, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Button, CircularProgress, Snackbar } from '~/core/components/shared';
import { parseSnakeUppercaseToNormal } from '~/core/helpers/parseSnakUpperCase.helpers';
import { PlatformAccountUpdateSchema } from '~/core/types/graphql.types';
import {
  accountAction,
  updateAccountBillingInfoAction,
  updateAccountProfileInfoAction,
} from '~/store/actions/account.action';
import { useContexts } from '~/store/context/useContext';
import { accountErrorSelector, accountLoadingSelector, accountSelector } from '~/store/selectors/account.selector';

import { EditCompanyBillingInfoDialog } from './components/EditCompanyBillingInfoDialog';
import { EditCompanyProfileDialog } from './components/EditCompanyProfileDialog';
import * as Styled from './styles';
import { CompanySettingsProps } from './types';

const CompanySettings: React.FC<CompanySettingsProps> = ({ forceAccountId, alternativeHeader }) => {
  const dispatch = useDispatch();
  const { selectedPlatformAccountId } = useContexts();
  const currentAccount = useSelector(accountSelector);
  const isLoading = useSelector(accountLoadingSelector);
  const error = useSelector(accountErrorSelector);

  const [isEditBillingInfoOpen, setIsEditBillingInfoOpen] = useState<boolean>(false);
  const [isEditCompanyInfoOpen, setIsEditCompanyInfoOpen] = useState<boolean>(false);

  const currentAccountId = forceAccountId || selectedPlatformAccountId;

  const handleIsEditCompanyInfoOpenTrigger = () => {
    setIsEditCompanyInfoOpen((isEditCompanyInfoOpen) => !isEditCompanyInfoOpen);
  };

  const handleIsEditBillingInfoOpenTrigger = () => {
    setIsEditBillingInfoOpen((isEditBillingInfoOpen) => !isEditBillingInfoOpen);
  };

  const updateCompanyInfo = (accountData: PlatformAccountUpdateSchema) => {
    dispatch(updateAccountProfileInfoAction.request({ accountId: currentAccountId, accountData }));
    setIsEditCompanyInfoOpen(false);
  };

  const updateBillingInfo = (accountData: PlatformAccountUpdateSchema) => {
    dispatch(updateAccountBillingInfoAction.request({ accountId: currentAccountId, accountData }));
    setIsEditBillingInfoOpen(false);
  };

  useEffect(() => {
    if (currentAccountId) {
      dispatch(accountAction.request(currentAccountId));
    }
  }, [dispatch, currentAccountId]);

  return (
    <Styled.PageContainer data-testid="companySettings">
      {!currentAccount || isLoading ? (
        <CircularProgress />
      ) : (
        <Styled.Container>
          {alternativeHeader || (
            <Typography fontSize={24} fontWeight="bold">
              Company Settings
            </Typography>
          )}
          <Styled.Card>
            <Styled.HeaderContainer>
              <Styled.MainTitle>Company Profile</Styled.MainTitle>
              <Button
                data-testid="openEditCompanyInfoButton"
                label="Edit"
                variant="contained"
                startIcon={<EditOutlined />}
                onClick={handleIsEditCompanyInfoOpenTrigger}
              />
            </Styled.HeaderContainer>
            <Divider />
            <Styled.ProfileItemContainer>
              <Styled.TitleContainer>Name</Styled.TitleContainer>
              <Typography>{currentAccount.name}</Typography>
            </Styled.ProfileItemContainer>
            <Divider />
            <Styled.ProfileItemContainer>
              <Styled.TitleContainer>Company Type</Styled.TitleContainer>
              <Typography>{currentAccount.editableCompanyType}</Typography>
            </Styled.ProfileItemContainer>
            <Divider />
            <Styled.ProfileItemContainer>
              <Styled.TitleContainer>Industry</Styled.TitleContainer>
              <Typography>{parseSnakeUppercaseToNormal(currentAccount.industry)}</Typography>
            </Styled.ProfileItemContainer>
            <Divider />
            <Styled.ProfileItemContainer>
              <Styled.TitleContainer>Address</Styled.TitleContainer>
              <Typography>{currentAccount.address_1}</Typography>
            </Styled.ProfileItemContainer>
            <Divider />
            <Styled.ProfileItemContainer>
              <Styled.TitleContainer>Website</Styled.TitleContainer>
              <Typography>{currentAccount.website}</Typography>
            </Styled.ProfileItemContainer>
            <Divider />
            <Styled.ProfileItemContainer>
              <Styled.TitleContainer>Phone Number</Styled.TitleContainer>
              <Typography>{currentAccount.phoneNumber}</Typography>
            </Styled.ProfileItemContainer>
            <Divider />
            <Styled.ProfileItemContainer>
              <Styled.TitleContainer>Fax Number</Styled.TitleContainer>
              <Typography>{currentAccount.faxNumber}</Typography>
            </Styled.ProfileItemContainer>
          </Styled.Card>

          <Styled.Card>
            <Styled.HeaderContainer>
              <Styled.MainTitle>Billing Information</Styled.MainTitle>
              <Button
                data-testid="openEditBillingInfoButton"
                label="Edit"
                variant="contained"
                startIcon={<EditOutlined />}
                onClick={handleIsEditBillingInfoOpenTrigger}
              />
            </Styled.HeaderContainer>
            <Divider />
            <Styled.ProfileItemContainer>
              <Styled.TitleContainer>Contact Name</Styled.TitleContainer>
              <Typography>{`${currentAccount.contactFirstName} ${currentAccount.contactLastName}`}</Typography>
            </Styled.ProfileItemContainer>
            <Divider />
            <Styled.ProfileItemContainer>
              <Styled.TitleContainer>Contact Email</Styled.TitleContainer>
              <Typography>{currentAccount.contactEmail}</Typography>
            </Styled.ProfileItemContainer>
            <Divider />
            <Styled.ProfileItemContainer>
              <Styled.TitleContainer>Phone Number</Styled.TitleContainer>
              <Typography>{currentAccount.contactPhone}</Typography>
            </Styled.ProfileItemContainer>
          </Styled.Card>
          {isEditBillingInfoOpen && (
            <EditCompanyBillingInfoDialog
              defaultValues={{
                contactFirstName: currentAccount.contactFirstName,
                contactLastName: currentAccount.contactLastName,
                contactEmail: currentAccount.contactEmail,
                contactPhone: currentAccount.contactPhone,
              }}
              open={isEditBillingInfoOpen}
              handleClose={handleIsEditBillingInfoOpenTrigger}
              onSubmit={updateBillingInfo}
            />
          )}
          {isEditCompanyInfoOpen && (
            <EditCompanyProfileDialog
              defaultValues={{
                city: currentAccount.city,
                companyType: currentAccount.companyType,
                country: currentAccount.country,
                editableCompanyType: currentAccount.editableCompanyType,
                industry: currentAccount.industry,
                name: currentAccount.name,
                phoneNumber: currentAccount.phoneNumber,
                website: currentAccount.website,
                zipCode: currentAccount.zipCode,
                faxNumber: currentAccount.faxNumber,
                address_1: currentAccount.address_1,
                address_2: currentAccount.address_2,
                state: currentAccount.state,
              }}
              open={isEditCompanyInfoOpen}
              handleClose={handleIsEditCompanyInfoOpenTrigger}
              onSubmit={updateCompanyInfo}
            />
          )}
        </Styled.Container>
      )}
      <Snackbar message={error} />
    </Styled.PageContainer>
  );
};

export default CompanySettings;
