import { useEffect, useRef } from 'react';
import * as d3 from 'd3';

const PieChart = ({ coveredPercent = 0 }) => {
  const svgRef = useRef(null);

  useEffect(() => {
    // Clear the previous SVG content
    d3.select(svgRef.current).selectAll('*').remove();
    const data = [
      { value: coveredPercent, color: '#3054AA', name: 'Required' },
      { value: 100 - coveredPercent, color: '#DEE1F3', name: 'Other' },
    ];

    const svg = d3.select(svgRef.current);
    const width = svg.node().getBoundingClientRect()?.width || 300;
    const height = width;
    const radius = (Math.min(width, height) / 2) * 0.8; // Reduced to 80% to make room for labels

    // Expand viewBox to accommodate labels
    svg.attr('viewBox', `0 0 ${width * 1.2} ${height * 1.2}`).attr('preserveAspectRatio', 'xMidYMid meet');

    const g = svg.append('g').attr('transform', `translate(${width * 0.6},${height * 0.4})`);

    const pie = d3.pie().value((d) => d.value);
    const arc = d3.arc().innerRadius(0).outerRadius(radius);

    // Create a group for the pie
    const pieG = g.append('g');

    const arcs = pieG.selectAll('.arc').data(pie(data)).enter().append('g').attr('class', 'arc');

    arcs
      .append('path')
      .attr('d', arc)
      .attr('fill', (d) => d.data.color);

    // Create a separate group for labels
    const labelG = g.append('g');

    // Update the transform for labels to ensure they are always on top
    labelG
      .selectAll('.label')
      .attr('transform', (d) => {
        const pos = arc.centroid(d);
        const midAngle = d.startAngle + (d.endAngle - d.startAngle) / 2;
        const x = Math.sin(midAngle) * (radius + 10); // Push out slightly from radius
        const y = -Math.cos(midAngle) * (radius + 10); // Push out slightly from radius
        // Ensure labels are within the SVG viewable area
        const adjustedX = Math.max(-width * 0.1, Math.min(x, width * 0.1));
        const adjustedY = Math.max(-height * 0.1, Math.min(y, height * 0.1));
        return `translate(${adjustedX},${adjustedY})`;
      })
      .style('visibility', 'visible') // Ensure labels are always visible
      .attr('dy', '.35em')
      .attr('text-anchor', 'middle')
      .text((d) => `${d.data.value.toFixed(0)}%`)
      .style('fill', (d) => (d.data.name === 'Required' ? d.data.color : 'rgba(33, 33, 52, 0.6);'))
      .style('font-size', '10px')
      .style('font-weight', 500);

    // Add lines connecting slices to labels
    labelG
      .selectAll('.label-line')
      .data(pie(data))
      .enter()
      .append('polyline')
      .attr('class', 'label-line')

      .style('fill', 'none')
      .style('stroke', 'black')
      .style('stroke-width', '1px');

    console.log('SVG content after rendering:', svg.node().outerHTML);
  }, [coveredPercent]);

  return (
    <div className="w-full h-full" style={{}}>
      <svg
        ref={svgRef}
        style={{
          height: '117px',
          width: 'auto',
        }}
      />
    </div>
  );
};

export default PieChart;