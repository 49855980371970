import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Button } from '~/core/components/shared';
import { accountAction, deleteAccountAction } from '~/store/actions/account.action';
import { postGoogleAuthSignInAction } from '~/store/actions/integrationAccounts.action';
import { accountSelector, createdAccountSelector, updatedAccountSelector } from '~/store/selectors/account.selector';

import { CreateAccount, GoogleAuth, InviteUser } from './components/Steps';
import * as Styled from './styles';
import { StepsEnum } from './types';

const CreateNewPlatformAccount: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const createdAccountId = useSelector(createdAccountSelector);
  const createdAccount = useSelector(accountSelector);
  const updatedAccount = useSelector(updatedAccountSelector);
  const cachedId = +localStorage.getItem('accountId');
  const [cachedAccountId, setCachedAccountId] = useState(null);
  const [accountData, setAccountData] = useState(null);

  const [activeStep, setActiveStep] = useState<string>(localStorage.getItem('activeStep') || StepsEnum.CreateAccount);

  const cancelAccountHandler = () => {
    if (cachedId) {
      dispatch(deleteAccountAction.request(cachedId));
    }
    localStorage.removeItem('accountId');
    localStorage.removeItem('activeStep');
    setCachedAccountId(null);

    navigate('../platform-admin-console', { replace: true });
  };

  useEffect(() => {
    if (createdAccount && !accountData && cachedAccountId) {
      setAccountData(createdAccount);
    }
  }, [createdAccount, cachedAccountId, accountData, setAccountData, dispatch]);

  useEffect(() => {
    if (cachedId) {
      setCachedAccountId(cachedId);
      dispatch(accountAction.request(cachedId));
    } else if (createdAccountId) {
      setCachedAccountId(createdAccountId);
      localStorage.setItem('accountId', createdAccountId.toString());
    }
  }, [cachedId, createdAccountId, dispatch]);

  useEffect(() => {
    if (updatedAccount && cachedAccountId) {
      setAccountData(updatedAccount);
    }
  }, [updatedAccount, cachedAccountId, setAccountData, dispatch]);

  useEffect(() => {
    if (cachedId) {
      dispatch(
        postGoogleAuthSignInAction.request({
          accountId: cachedId,
          // TODO: Need to add some mock envs
          redirectionUri: process.env.REACT_APP_API_BASE_URL
            ? `${process.env.REACT_APP_API_BASE_URL}/api/v1/google/auth/oauth-data`
            : `${window.location.protocol}//backend-${window.location.hostname}/api/v1/google/auth/oauth-data`,
        }),
      );
    }
  }, [dispatch, cachedId]);

  const StepComponents = {
    '0': (
      <CreateAccount
        activeStep={activeStep}
        setActiveStep={setActiveStep}
        accountId={cachedAccountId}
        accountData={accountData}
        setAccountData={setAccountData}
      />
    ),
    '1': <GoogleAuth activeStep={activeStep} setActiveStep={setActiveStep} accountId={cachedAccountId} />,
    '2': (
      <InviteUser
        activeStep={activeStep}
        setActiveStep={setActiveStep}
        accountId={cachedAccountId}
        accountData={accountData}
      />
    ),
  };

  return (
    <Styled.CardContainer data-testid="createNewPlatformAccount">
      <Styled.CancelContainer>
        <Styled.Heading data-testid="heading">Create a new platform account</Styled.Heading>
        <div>
          <Button
            data-testid="cancelButton"
            label={cachedAccountId ? 'Delete Account' : 'Cancel'}
            onClick={cancelAccountHandler}
            variant="outlined"
            color="primary"
          />
        </div>
      </Styled.CancelContainer>
      <Styled.Card>{(StepComponents as any)[activeStep]}</Styled.Card>
    </Styled.CardContainer>
  );
};

export default CreateNewPlatformAccount;
