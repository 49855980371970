import { Refresh } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import SearchEmpty from '~/assets/misc/searchempty.png';
import { Button } from '~/core/components/shared';
import useGoogleAuthPopup from '~/core/hooks/useGoogleAuthPopup';
import ApiClient from '~/core/services/rest/api.service';
import { linkNewAccountAction } from '~/store/actions/integrationAccounts.action';
import { useContexts } from '~/store/context/useContext';

import * as Styled from './styles';

const redirectionUri = `${process.env.REACT_APP_API_BASE_URL}/api/v1/google/auth/oauth-data`;

const LinkAccount: React.FC = () => {
  const dispatch = useDispatch();
  const { selectedPlatformAccountId } = useContexts();
  const [signInUrl, setSignInUrl] = useState(null);
  const [fetchingSignInUrl, setFetchingSignInUrl] = useState(true);
  const [, setError] = useState(null);

  const handleLinkNewAccount = () => {
    dispatch(linkNewAccountAction.request(selectedPlatformAccountId));
    setTimeout(() => {
      window.location.reload();
    }, 1500);
  };

  const { handleOpenGooglePopup } = useGoogleAuthPopup(signInUrl?.url, handleLinkNewAccount);

  const fetchSignInUrl = async () => {
    await ApiClient.post('google/auth/sign-in', {
      account_id: selectedPlatformAccountId,
      redirection_uri: redirectionUri,
    })
      .then((response) => {
        setSignInUrl(response.data);
        setFetchingSignInUrl(false);
      })
      .catch((error) => {
        setError(error);
        setFetchingSignInUrl(false);
      });
  };

  useEffect(() => {
    if (signInUrl) return;
    fetchSignInUrl();
  }, []);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        backgroundColor: '#F0F0F3',
      }}
    >
      {!fetchingSignInUrl && signInUrl && (
        <Styled.IntentContent>
          <Styled.ImageContainer>
            <img src={SearchEmpty} alt="Searching for an account when there isn't one" />
          </Styled.ImageContainer>
          <Styled.Heading fontSize={16}>Identify Opportunities To Align Ad Content To Intent</Styled.Heading>
          {/* <Styled.SubHeading fontSize={16}>Connect a Google Ads account to start an assessment</Styled.SubHeading> */}
          <Button
            onClick={handleOpenGooglePopup}
            label="Connect Google Ads Account"
            data-testid="linkGoogleAdsAccountButton"
            variant="contained"
          />
        </Styled.IntentContent>
      )}
      {!signInUrl && !fetchingSignInUrl && (
        <Styled.IntentContent>
          <Typography fontSize={16}>Please disable your adblocker if you have it enabled and refresh.</Typography>
          <Button
            onClick={() => handleLinkNewAccount()}
            label="Refresh"
            data-testid="refreshAccountButton"
            startIcon={<Refresh />}
            variant="contained"
          />
        </Styled.IntentContent>
      )}
    </Box>
  );
};

export default LinkAccount;
