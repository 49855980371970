import { PlatformAccountSortableEnum } from '~/core/types/graphql.types';

export const headerCells = [
  {
    id: PlatformAccountSortableEnum.Name,
    label: 'Name',
    numeric: false,
    disablePadding: true,
  },
  {
    id: PlatformAccountSortableEnum.ContactEmail,
    label: 'Admin Email',
    numeric: false,
    disablePadding: true,
  },
  {
    id: PlatformAccountSortableEnum.CompanyType,
    label: 'Account Type',
    numeric: false,
    disablePadding: true,
  },
  {
    id: 'status',
    label: 'Account Status',
    numeric: false,
    disablePadding: true,
  },
  {
    id: 'CREATED_AT',
    label: 'Account Created',
    numeric: false,
    disablePadding: true,
  },
  {
    id: 'status',
    label: '',
    numeric: true,
    disablePadding: true,
  },
];
