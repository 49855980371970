import { CheckCircle } from '@mui/icons-material';
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';

import { Button } from '~/core/components/shared';

import * as Styled from '../styles';

const dummyData = [
  {
    id: 1,
    name: 'Explore Product Features',
  },
  {
    id: 2,
    name: 'Compare Pricing',
  },
  {
    id: 3,
    name: 'Read Customer Testimonials',
  },
  {
    id: 4,
    name: 'Understand Service Plans',
  },
  {
    id: 5,
    name: 'Find Local Dealers',
  },
  {
    id: 6,
    name: 'Learn About Company',
  },
  {
    id: 7,
    name: 'Learn About Company',
  },
  {
    id: 8,
    name: 'Learn About Company',
  },
];

const DemoTable = () => (
  <TableContainer>
    <Styled.SearchContainer
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <Styled.SearchBarHeading>Consumer Search Intent</Styled.SearchBarHeading>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Styled.SearchBar
          placeholder="Find within search intents"
          sx={{
            '.MuiInputBase-root': {
              minWidth: '300px',
              marginRight: '12px',
            },
          }}
        />
      </Box>
    </Styled.SearchContainer>
    <Table>
      <TableHead>
        <TableRow>
          <TableCell
            sx={{
              paddingLeft: '40px',
            }}
          >
            Search Intent
          </TableCell>
          <TableCell>Impressions</TableCell>
          <TableCell>Conversions</TableCell>
          <TableCell>Content Coverage</TableCell>
          <TableCell />
        </TableRow>
      </TableHead>
      <TableBody>
        {dummyData.map((row) => (
          <TableRow key={row.id}>
            <TableCell
              sx={{
                paddingLeft: '40px',
              }}
            >
              {row.name}
            </TableCell>
            <TableCell>{1000}</TableCell>
            <TableCell>{1000}</TableCell>
            <TableCell
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 1,
                height: '70px',
              }}
            >
              {1000}{' '}
              <CheckCircle
                sx={{
                  color: '#66bb6a',
                }}
              />
            </TableCell>
            <TableCell>
              <Button variant="contained" color="secondary" label="View Content" size="small" />
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer>
);

export default DemoTable;
