import { TablePagination, TablePaginationBaseProps } from '@mui/material';
import { useSearchParams } from 'react-router-dom';

interface PaginationComponentProps extends TablePaginationBaseProps {
  page: number;
  pageSize: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  setPageSize: React.Dispatch<React.SetStateAction<number>>;
  pageTotal: number;
  disabled?: boolean;
  skeleton?: React.ReactNode;
}

const CustomTablePagination: React.FC<PaginationComponentProps> = ({
  page,
  pageSize,
  setPage,
  setPageSize,
  pageTotal,
  disabled,
  skeleton,
  ...props
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage);
    setSearchParams(searchParams);
  };

  const handleChangePageSize = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setPageSize(parseInt(event.target.value, 10));
    setPage(0);
    searchParams.set('pageSize', String(event.target.value));
    setSearchParams(searchParams);
  };

  return (
    <TablePagination
      sx={{
        backgroundColor: 'white',
        width: 'inherit',
      }}
      disabled={disabled}
      count={pageTotal}
      page={page}
      onPageChange={handleChangePage}
      rowsPerPage={pageSize}
      onRowsPerPageChange={handleChangePageSize}
      rowsPerPageOptions={[25, 50, 100]}
      labelDisplayedRows={({ from, to, count }) =>
        disabled && skeleton ? skeleton : `${from}-${to} of ${count?.toLocaleString()}`
      }
      {...props}
    />
  );
};

export default CustomTablePagination;
