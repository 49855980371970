import { BoxProps } from '@mui/material';

import * as Styled from './styles';

const NoPermissions: React.FC<BoxProps> = (props) => (
  <Styled.Container {...props} data-testid="noPermissions">
    <Styled.Content>
      <Styled.LockIcon />
      <Styled.Heading>You don&apos;t have permission to view this account.</Styled.Heading>
      <Styled.SubHeading>
        Try logging out and back in to resolve this.
        <br />
        If the problem persists, check with your account administrator to regain access.
      </Styled.SubHeading>
    </Styled.Content>
  </Styled.Container>
);

export default NoPermissions;
