import { Chip, Skeleton } from '@mui/material';
import { Link } from 'react-router-dom';
import Stripe from 'stripe';

import { Button } from '~/core/components/shared';
import { formatTimestampToLocaleDateString } from '~/core/lib/utils';

import * as Styled from './styles';

type LoadingTableProps = {
  columns: number;
  rows: number;
};

const LoadingTable: React.FC<LoadingTableProps> = ({ columns, rows }) => (
  <>
    {Array.from({ length: rows }, (_, rowIndex) => (
      // eslint-disable-next-line
      <Styled.CustomTr key={rowIndex}>
        {Array.from({ length: columns }, (_, columnIndex) => (
          // eslint-disable-next-line
          <Styled.CustomTd key={columnIndex}>
            <Skeleton />
          </Styled.CustomTd>
        ))}
      </Styled.CustomTr>
    ))}
  </>
);

type InvoiceTableProps = {
  invoiceData: Stripe.Invoice[] | undefined;
  isLoading: boolean;
};

const InvoiceTable: React.FC<InvoiceTableProps> = ({ invoiceData, isLoading }) => {
  const filteredInvoiceData = invoiceData?.filter(
    (invoice) => invoice.status !== 'draft' && (invoice.amount_paid > 0 || invoice.amount_due > 0),
  );
  return (
    <Styled.CustomContainer>
      <Styled.CustomTitle>Past Invoices</Styled.CustomTitle>
      <Styled.CustomTable>
        <thead>
          <Styled.CustomTr>
            <Styled.CustomTh>Date</Styled.CustomTh>
            <Styled.CustomTh>Amount</Styled.CustomTh>
            <Styled.CustomTh>Status</Styled.CustomTh>
            <Styled.CustomTh />
          </Styled.CustomTr>
        </thead>
        <tbody>
          {isLoading ? (
            <LoadingTable columns={4} rows={6} />
          ) : filteredInvoiceData?.length ? (
            filteredInvoiceData.map((invoice) => (
              <Styled.CustomTr key={invoice?.id}>
                <Styled.CustomTd>{formatTimestampToLocaleDateString(invoice.period_start)}</Styled.CustomTd>
                <Styled.CustomTd>
                  {invoice.amount_paid
                    ? `$${(invoice.amount_paid / 100).toFixed(2)}`
                    : invoice.amount_due
                    ? `$${(invoice.amount_due / 100).toFixed(2)}`
                    : '—'}
                </Styled.CustomTd>
                <Styled.CustomTd>
                  <Chip
                    label={invoice.status}
                    color={invoice.status === 'paid' ? 'success' : 'error'}
                    sx={{ textTransform: 'capitalize' }}
                  />
                </Styled.CustomTd>
                <Styled.CustomTd sx={{ textAlign: 'right' }}>
                  <Link to={invoice.hosted_invoice_url} target="_blank">
                    <Button label={invoice.status === 'paid' ? 'View' : 'Pay'} variant="outlined" />
                  </Link>
                </Styled.CustomTd>
              </Styled.CustomTr>
            ))
          ) : (
            <Styled.CustomTr>
              <Styled.CustomTd colSpan={4} sx={{ textAlign: 'center', paddingTop: 1 }}>
                No invoices to display
              </Styled.CustomTd>
            </Styled.CustomTr>
          )}
        </tbody>
      </Styled.CustomTable>
    </Styled.CustomContainer>
  );
};

export default InvoiceTable;
