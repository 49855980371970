import React, { Dispatch, SetStateAction, createContext, useMemo, useState } from 'react';

interface StateContextProps {
  selectedPlatformAccountId: number | null;
  selectedSourceAccountId: string | null;
  selectedAccountName: string | null;
  selectedCampaignId: string | null;
  selectedAdGroupId: string | null;
  selectedClusterId: string | null;
  selectedDateRange: string | null;
  setSelectedPlatformAccountId: Dispatch<SetStateAction<number | null>>;
  setSelectedSourceAccountId: Dispatch<SetStateAction<string>>;
  setSelectedAccountName: Dispatch<SetStateAction<string>>;
  setSelectedCampaignId: Dispatch<SetStateAction<string | null>>;
  setSelectedAdGroupId: Dispatch<SetStateAction<string | null>>;
  setSelectedClusterId: Dispatch<SetStateAction<string | null>>;
  setSelectedDateRange: Dispatch<SetStateAction<string | null>>;
}

export const StateContext = createContext<StateContextProps | undefined>(undefined);

export const StateProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [selectedPlatformAccountId, setSelectedPlatformAccountId] = useState<number | null>(null);
  const [selectedSourceAccountId, setSelectedSourceAccountId] = useState<string>('');
  const [selectedAccountName, setSelectedAccountName] = useState<string>('');
  const [selectedCampaignId, setSelectedCampaignId] = useState<string | null>(null);
  const [selectedAdGroupId, setSelectedAdGroupId] = useState<string | null>(null);
  const [selectedClusterId, setSelectedClusterId] = useState<string | null>(null);
  const [selectedDateRange, setSelectedDateRange] = useState<string | null>(null);

  const state: StateContextProps = useMemo(
    () => ({
      selectedSourceAccountId,
      selectedAccountName,
      selectedPlatformAccountId,
      selectedCampaignId,
      selectedAdGroupId,
      selectedClusterId,
      selectedDateRange,
      setSelectedSourceAccountId,
      setSelectedAccountName,
      setSelectedPlatformAccountId,
      setSelectedCampaignId,
      setSelectedAdGroupId,
      setSelectedClusterId,
      setSelectedDateRange,
    }),
    [
      selectedSourceAccountId,
      selectedAccountName,
      selectedPlatformAccountId,
      selectedCampaignId,
      selectedAdGroupId,
      selectedClusterId,
      selectedDateRange,
      setSelectedPlatformAccountId,
      setSelectedAccountName,
      setSelectedSourceAccountId,
      setSelectedCampaignId,
      setSelectedAdGroupId,
      setSelectedClusterId,
      setSelectedDateRange,
    ],
  );
  return <StateContext.Provider value={state}>{children}</StateContext.Provider>;
};
