import { createReducer } from 'typesafe-actions';

import { GoogleAdsUrl } from '~/core/types/googleAdsAccounts.types';
import { IntegrationAccountsSchema, PageInfoSchema } from '~/core/types/graphql.types';

import {
  clearIntegrationAccountsAction,
  getIntegrationAccountsAction,
  IntegrationAccountsActionUnion,
  linkNewAccountAction,
  postGoogleAuthSignInAction,
  refreshIntegrationAccountsAction,
} from '../actions/integrationAccounts.action';

export interface State {
  isLoading: boolean;
  fetchingUrl: boolean;
  error: string;
  signInUrlError: string;
  googleAdsSignInUrl: GoogleAdsUrl;
  integrationAccounts: IntegrationAccountsSchema[];
  refreshLoading: boolean;
  paginationInfo: PageInfoSchema;
  hasAccountData: boolean;
}

export const initialState: State = {
  isLoading: false,
  fetchingUrl: false,
  error: null,
  signInUrlError: null,
  integrationAccounts: null,
  paginationInfo: null,
  googleAdsSignInUrl: null,
  refreshLoading: false,
  hasAccountData: false,
};

export const reducer = createReducer<State, IntegrationAccountsActionUnion>(initialState)
  .handleAction(postGoogleAuthSignInAction.success, (state, action) => ({
    ...state,
    fetchingUrl: false,
    googleAdsSignInUrl: action.payload,
    signInUrlError: null,
  }))
  .handleAction(postGoogleAuthSignInAction.failure, (state, action) => ({
    ...state,
    fetchingUrl: false,
    googleAdsSignInUrl: null,
    signInUrlError: action.payload,
  }))
  .handleAction(postGoogleAuthSignInAction.request, (state) => ({
    ...state,
    fetchingUrl: true,
    signInUrlError: null,
  }))
  .handleAction(refreshIntegrationAccountsAction.success, (state, { payload }) => ({
    ...state,
    refreshLoading: false,
    integrationAccounts: state.paginationInfo?.page === 1 ? payload.ownIntegrationAccounts.edges : null,
    error: null,
  }))
  .handleAction(refreshIntegrationAccountsAction.failure, (state) => ({
    ...state,
    refreshLoading: false,
  }))
  .handleAction(refreshIntegrationAccountsAction.request, (state) => ({
    ...state,
    refreshLoading: true,
    error: null,
  }))
  .handleAction(linkNewAccountAction.success, (state, action) => ({
    ...state,
    isLoading: false,
    integrationAccounts: action.payload.ownIntegrationAccounts.edges,
    paginationInfo: action.payload.ownIntegrationAccounts.pageInfo,
    error: null,
  }))
  .handleAction(linkNewAccountAction.failure, (state) => ({
    ...state,
    isLoading: false,
  }))
  .handleAction(linkNewAccountAction.request, (state) => ({
    ...state,
    isLoading: true,
    error: null,
  }))
  .handleAction(getIntegrationAccountsAction.success, (state, { payload }) => ({
    ...state,
    isLoading: false,
    integrationAccounts: payload.ownIntegrationAccounts.edges,
    paginationInfo: payload.ownIntegrationAccounts.pageInfo,
    hasAccountData: state.hasAccountData || payload.ownIntegrationAccounts.edges?.length >= 1,
    error: null,
  }))
  .handleAction(getIntegrationAccountsAction.failure, (state, action) => ({
    ...state,
    isLoading: false,
    error: action.payload,
  }))
  .handleAction(getIntegrationAccountsAction.request, (state) => ({
    ...state,
    isLoading: true,
    error: null,
  }))
  .handleAction(clearIntegrationAccountsAction, () => ({
    ...initialState,
  }));
