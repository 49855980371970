import { styled, Box } from '@mui/material';

export const Card = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  backgroundColor: theme.palette.background.paper,
  width: '100%',
  borderRadius: '8px',
}));

export const MainBox = styled(Box)({
  padding: '24px',
});

export const SubBox = styled(Box)({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  paddingRight: '24px',
});

export const MainHeading = styled(Box)({
  fontSize: '18px',
  fontWeight: '700',
});
