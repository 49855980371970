import { useForm, Controller } from 'react-hook-form';
import { useSelector } from 'react-redux';

import { Button, Input } from '~/core/components/shared';
import { EMAIL_PATTERN_REGEX } from '~/core/constants/validationPatterns.constants';
import { PasswordResetRequestForm as Form } from '~/core/types/auth.types';
import { authErrorSelector, authLoadingSelector } from '~/store/selectors/auth.selector';

import * as Styled from './styles';
import { EmailErrorsEnum, PasswordResetRequestProps } from './types';

const PasswordResetRequestForm: React.FC<PasswordResetRequestProps> = ({ onSubmit }) => {
  const error = useSelector(authErrorSelector);
  const isLoading = useSelector(authLoadingSelector);
  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<Form>({
    reValidateMode: 'onSubmit',
    defaultValues: {
      email: '',
    },
  });

  return (
    <form data-testid="resetPasswordSubmit" onSubmit={handleSubmit(onSubmit)}>
      <Styled.PasswordResetRequestForm disableGutters>
        <Controller
          name="email"
          rules={{
            required: true,
            pattern: {
              value: EMAIL_PATTERN_REGEX,
              message: 'Invalid email',
            },
          }}
          control={control}
          render={({ field: { onChange, value } }) => (
            <Input
              type="email"
              label="Email Address"
              value={value}
              onChange={onChange}
              helperText={
                (errors?.email?.type === EmailErrorsEnum.Pattern && 'Please enter a valid email address') ||
                (error && 'Sorry, we couldn’t find an account with that email')
              }
              error={
                errors?.email?.type === EmailErrorsEnum.Required ||
                errors?.email?.type === EmailErrorsEnum.Pattern ||
                !!errors?.email
              }
              autoComplete="email"
            />
          )}
        />
        <Button
          data-testid="submitButton"
          type="submit"
          label="Send recovery link"
          fullWidth
          disabled={isLoading}
          variant="contained"
          size="large"
        />
      </Styled.PasswordResetRequestForm>
    </form>
  );
};

export default PasswordResetRequestForm;
