import { Box } from '@mui/material';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import { formatDateToMonthAndYear } from '~/core/helpers';
import { useGetSourceAccountInfo } from '~/core/services/graphql/sourceAccounts/hooks';
import { GetSourceAccountInfoQueryVariables } from '~/core/services/graphql/sourceAccounts/sourceAccounts.queries.generated';
import { useContexts } from '~/store/context/useContext';
import { integrationAccountsSelector } from '~/store/selectors/integrationAccounts.selector';

import * as Styled from './styles';

type DateSelectorProps = {
  demoMode?: boolean;
};

export const DateSelector: React.FC<DateSelectorProps> = ({ demoMode }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const {
    selectedPlatformAccountId,
    selectedSourceAccountId,
    selectedDateRange,
    setSelectedDateRange,
    campaignSummariesLoading,
  } = useContexts();
  const integrationAccounts = useSelector(integrationAccountsSelector);

  const dateKeysMap: { [key: string]: string } = {};
  const sourceAccountIds = integrationAccounts?.map(({ customerId }) => customerId);

  const getSourceAccountInfoSkip = !selectedPlatformAccountId || !sourceAccountIds?.length;
  const getSourceAccountInfoVariables: GetSourceAccountInfoQueryVariables = {
    platformAccountId: selectedPlatformAccountId,
    customerIds: sourceAccountIds,
  };
  const { sourceAccountInfoLoading, getSourceAccountInfo } = useGetSourceAccountInfo(
    getSourceAccountInfoSkip,
    getSourceAccountInfoVariables,
  );

  const availableDates: string[] =
    getSourceAccountInfo?.length > 0 &&
    getSourceAccountInfo
      ?.filter(({ customerId, dateRange }) => customerId === selectedSourceAccountId && dateRange)
      .map(({ dateRange }) => dateRange);

  if (getSourceAccountInfo && availableDates?.length > 0) {
    availableDates
      .sort((a, b) => b.localeCompare(a))
      .forEach((key) => {
        const startDate = key.split(' | ')[0];
        dateKeysMap[key] = formatDateToMonthAndYear(startDate);
      });
  }

  const handleDateChange = (value: string) => {
    if (demoMode) return;
    searchParams.set('dateRange', value);
    setSearchParams(searchParams, { replace: true });
    setSelectedDateRange(value);
  };

  return !sourceAccountIds || sourceAccountInfoLoading || campaignSummariesLoading ? (
    <Box display="flex" alignItems="center" height="55px">
      <Styled.CustomSkeleton variant="text" animation="wave" />
    </Box>
  ) : (
    <Styled.Select
      data-testid="dateSelector"
      placeholder={!selectedDateRange ? 'Loading...' : undefined}
      value={selectedDateRange}
      disabled={!selectedDateRange || !availableDates?.length}
    >
      {Object.keys(dateKeysMap).map((key, index) => {
        const availableDate = dateKeysMap[key];
        return (
          <Styled.CustomMenuItem
            key={key}
            data-testid={`menuItem-${index}`}
            onClick={() => handleDateChange(key)}
            value={key}
          >
            {availableDate}
          </Styled.CustomMenuItem>
        );
      })}
    </Styled.Select>
  );
};

export default DateSelector;
