import { styled, Container } from '@mui/material';

export const Link = styled('div')(({ theme }) => ({
  fontSize: '16',
  color: theme.palette.primary.main,
  cursor: 'pointer',
}));

export const SignUpFormContent = styled(Container)({
  display: 'flex',
  flexDirection: 'column',
  rowGap: '20px',
  width: '100%',
});

export const FooterContent = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
});

export const ResendContainer = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  gap: '8px',
});
