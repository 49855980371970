import { useEffect } from 'react';

import { CircularProgress } from '~/core/components/shared';

import { NotFound } from '../NotFound';

const RedirectGooglePage = () => {
  useEffect(() => {
    const params = window.location.search;
    if (window.opener) {
      window.opener.postMessage(params);
      window.close();
    }
  });

  return window.opener ? <CircularProgress height="100vh" /> : <NotFound data-testid="notFound" />;
};

export default RedirectGooglePage;
