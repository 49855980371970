import { ApolloProvider } from '@apollo/client';
import { ThemeProvider } from '@emotion/react';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import React from 'react';
import ReactGA from 'react-ga4';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { IntercomProvider } from 'react-use-intercom';

import App, { AppRoutes } from './App';
import { adFactoryClient } from './core/services';
import './index.css';
import { store } from './store';
import { StateProvider } from './store/context';
import { AccountsProvider } from './store/context/accounts.context';
import { SummariesProvider } from './store/context/summaries.context';
import { theme } from './theme';
// import { worker } from './mocks/browser';

// * This is for the MSW worker
// if (process.env.NODE_ENV === 'development') {
//   worker.start();
// }

// Initialize Sentry.io Observability
Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,

  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 1.0,

  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: 1.0,
  environment: process.env.REACT_APP_ENV,
  integrations: [
    new Integrations.BrowserTracing({
      tracePropagationTargets: [process.env.REACT_APP_API_BASE_URL],
    }),
    new Sentry.Replay({
      networkDetailAllowUrls: [/^.*$/],
      maskAllText: false,
      maskAllInputs: false,
      blockAllMedia: false,
      networkResponseHeaders: ['Content-Type'],
      unblock: ['.sentry-block'],
      unmask: ['.sentry-mask'],
      stickySession: false,
    }),
  ],
  tracesSampleRate: 1.0,
});

ReactGA.initialize(process.env.REACT_APP_GA_ID);

const rootElement = createRoot(document.getElementById('root'));
rootElement.render(
  <React.StrictMode>
    <ApolloProvider client={adFactoryClient}>
      <Provider store={store}>
        <StateProvider>
          <AccountsProvider>
            <SummariesProvider>
              <ThemeProvider theme={theme}>
                <IntercomProvider appId={process.env.REACT_APP_INTERCOM_ID} autoBoot>
                  <BrowserRouter>
                    <AppRoutes />
                    <App />
                  </BrowserRouter>
                </IntercomProvider>
              </ThemeProvider>
            </SummariesProvider>
          </AccountsProvider>
        </StateProvider>
      </Provider>
    </ApolloProvider>
  </React.StrictMode>,
);
