import { ActionType, createAction, createAsyncAction } from 'typesafe-actions';

import {
  AccountUsersQuery,
  AccountUsersQueryVariables,
  InviteAccountUserMutationVariables,
  UpdateUserAccessMutationVariables,
} from '~/core/services/graphql/accountUsers/accountUsers.queries.generated';

export enum AccountUsersTypes {
  Users = '[UserTypes] Users',
  UsersSuccess = '[UserTypes] UsersSuccess',
  UsersFailed = '[UserTypes] UsersFailed',
  InviteAccountUser = '[UserTypes] InviteCustomerUser',
  InviteAccountUserSuccess = '[UserTypes] InviteCustomerUserSuccess',
  InviteAccountUserFailed = '[UserTypes] InviteCustomerUserFailed',
  UpdateUserAccess = '[UserTypes] UpdateUserAccess',
  UpdateUserAccessSuccess = '[UserTypes] UpdateUserAccessSuccess',
  UpdateUserAccessFailed = '[UserTypes] UpdateUserAccessFailed',
  ResetMessage = '[UserTypes] ResetMessage',
}

export const accountUsersAction = createAsyncAction(
  AccountUsersTypes.Users,
  AccountUsersTypes.UsersSuccess,
  AccountUsersTypes.UsersFailed,
)<AccountUsersQueryVariables, AccountUsersQuery, string>();

export const inviteAccountUserAction = createAsyncAction(
  AccountUsersTypes.InviteAccountUser,
  AccountUsersTypes.InviteAccountUserSuccess,
  AccountUsersTypes.InviteAccountUserFailed,
)<
  {
    inviteAccountUserData: InviteAccountUserMutationVariables;
    userInviteInput?: AccountUsersQueryVariables;
  },
  AccountUsersQuery,
  string
>();

export const updateUserAccessAction = createAsyncAction(
  AccountUsersTypes.UpdateUserAccess,
  AccountUsersTypes.UpdateUserAccessSuccess,
  AccountUsersTypes.UpdateUserAccessFailed,
)<
  {
    updateUserAccessData: UpdateUserAccessMutationVariables;
    userInviteInput: AccountUsersQueryVariables;
  },
  AccountUsersQuery,
  string
>();

export const resetMessageAction = createAction(AccountUsersTypes.ResetMessage)<void>();

export type AccountUsersActionUnion = ActionType<
  typeof accountUsersAction | typeof inviteAccountUserAction | typeof updateUserAccessAction | typeof resetMessageAction
>;
