import { Box } from '@mui/material';

import GoogleLogo from '~/assets/google/icon-google-g-logo.svg';
import { useContexts } from '~/store/context/useContext';

import { PageHeader } from '../../PageHeader';
import { CampaignSelector } from '../CampaignSelector';
import { DateSelector } from '../DateSelector';
import { SourceAccountSelector } from '../SourceAccountSelector';
import * as Styled from './styles';

type SelectorMenuProps = {
  demoMode?: boolean;
};

const SelectorMenu: React.FC<SelectorMenuProps> = ({ demoMode }) => {
  const { selectedDateRange } = useContexts();

  return (
    <PageHeader
      sx={{
        padding: '0px',
      }}
    >
      <Box
        width="100%"
        alignItems="center"
        justifyContent="space-between"
        sx={{
          display: selectedDateRange ? 'flex' : 'hidden',
          background: demoMode ? 'rgba(0, 0, 0, 0.5)' : 'transparent',
        }}
      >
        <Box display="flex" alignItems="center">
          <Box>
            <SourceAccountSelector
              demoMode={demoMode}
              displayLogo={
                <Styled.LogoContainer>
                  <img alt="GoogleAds logo" src={GoogleLogo} height={11.4} />
                </Styled.LogoContainer>
              }
            />
          </Box>
          <CampaignSelector demoMode={demoMode} />
        </Box>
        <DateSelector demoMode={demoMode} />
      </Box>
    </PageHeader>
  );
};

export default SelectorMenu;
