import { TableBodyProps } from '@mui/material';

import * as Styled from './styles';

const TableBody: React.FC<TableBodyProps> = ({ children, ...elementProps }) => (
  <Styled.TableBody {...elementProps} data-testid="tableBody">
    {children}
  </Styled.TableBody>
);

export default TableBody;
