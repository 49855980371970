import { useCallback, useEffect, useState } from 'react';

const useGoogleAuthPopup = (url: string, onCloseWithAuthorizedAccount?: () => void) => {
  const [queryParams, setQueryParams] = useState(null);
  const [windowOptions, setWindowOptions] = useState(null);

  const handleOpenGooglePopup = () => {
    if (url) {
      const newTab = window.open(url, '_blank');
      if (newTab) {
        newTab.focus();
        setWindowOptions(newTab);
      }
    }
  };

  const handlePostMessage = useCallback(
    (event: MessageEvent) => {
      if (typeof event.data !== 'string' && event.origin !== process.env.REACT_APP_API_BASE_URL) return;

      const params = new URLSearchParams(event.data);
      const entries = params.entries();
      const queryParams = Object.fromEntries(entries);
      setQueryParams(queryParams);

      if (windowOptions?.closed) {
        onCloseWithAuthorizedAccount();
      }
    },
    [windowOptions?.closed],
  );

  useEffect(() => {
    windowOptions?.focus();
  }, [windowOptions]);

  useEffect(() => {
    window.addEventListener('message', handlePostMessage);

    return () => window.removeEventListener('message', handlePostMessage);
  }, [handlePostMessage]);

  return { handleOpenGooglePopup, queryParams };
};

export default useGoogleAuthPopup;
