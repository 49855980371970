import { Effect, call, put, takeLatest } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';

import { apolloServiceRequest, graphqlClient } from '~/core/services';
import { graphqlServices } from '~/core/services/graphql';
import { AccountUsersQuery } from '~/core/services/graphql/accountUsers/accountUsers.queries.generated';

import { accountUsersAction, inviteAccountUserAction, updateUserAccessAction } from '../actions/accountUsers.action';

export class AccountUserSagaWorker {
  static *accountUsers({ payload }: ActionType<typeof accountUsersAction.request>) {
    try {
      const data: AccountUsersQuery = yield call(
        apolloServiceRequest,
        graphqlClient,
        'query',
        graphqlServices.userAccounts.usersFromAgencyGql,
        payload,
      );

      yield put(accountUsersAction.success(data));
    } catch (error: any) {
      yield put(accountUsersAction.failure(error.message));
    }
  }

  static *inviteAccountUser({ payload }: ActionType<typeof inviteAccountUserAction.request>) {
    try {
      yield call(
        apolloServiceRequest,
        graphqlClient,
        'mutation',
        graphqlServices.userAccounts.inviteAccountUserGql,
        payload.inviteAccountUserData,
      );

      const data: AccountUsersQuery = yield call(
        apolloServiceRequest,
        graphqlClient,
        'query',
        graphqlServices.userAccounts.usersFromAgencyGql,
        payload.userInviteInput,
      );
      yield put(inviteAccountUserAction.success(data));
    } catch (error: any) {
      yield put(inviteAccountUserAction.failure(error.message));
    }
  }

  static *updateUserAccess({ payload }: ActionType<typeof updateUserAccessAction.request>) {
    try {
      yield call(
        apolloServiceRequest,
        graphqlClient,
        'mutation',
        graphqlServices.userAccounts.updateUserAccessGql,
        payload.updateUserAccessData,
      );

      const data: AccountUsersQuery = yield call(
        apolloServiceRequest,
        graphqlClient,
        'query',
        graphqlServices.userAccounts.usersFromAgencyGql,
        payload.userInviteInput,
      );

      yield put(updateUserAccessAction.success(data));
    } catch (error: any) {
      yield put(updateUserAccessAction.failure(error.message));
    }
  }
}

export function* accountUsersSaga(): Generator<Effect, void> {
  yield takeLatest(accountUsersAction.request, AccountUserSagaWorker.accountUsers);
  yield takeLatest(inviteAccountUserAction.request, AccountUserSagaWorker.inviteAccountUser);
  yield takeLatest(updateUserAccessAction.request, AccountUserSagaWorker.updateUserAccess);
}
