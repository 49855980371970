import { Step, StepContent, Typography } from '@mui/material';

import * as Styled from './styles';
import { VerticalStepperProps } from './types';

const VerticalStepper = ({ steps, activeStep, ...elementProps }: VerticalStepperProps) => (
  <Styled.Box data-testid="verticalStepper">
    <Styled.Stepper
      {...elementProps}
      activeStep={activeStep}
      orientation="vertical"
      connector={<Styled.StepConnector />}
    >
      {steps.map(({ label, description }) => (
        <Step key={label}>
          <Styled.StepLabel>{label}</Styled.StepLabel>
          <StepContent>
            <Typography>{description}</Typography>
          </StepContent>
        </Step>
      ))}
    </Styled.Stepper>
  </Styled.Box>
);

export default VerticalStepper;
