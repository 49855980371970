import { styled, Container, ContainerProps, MenuItem as MuiMenuItem, Select as MuiSelect, Box } from '@mui/material';

export const DropdownContainer = styled(Container)<ContainerProps>({
  marginLeft: '0px',
  paddingLeft: '0px',
  width: '250px',
  float: 'left',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  '&.MuiContainer-root': {
    marginLeft: '10px',
    paddingLeft: '0px',
  },
});

export const MenuItem = styled(MuiMenuItem)<{ selected: boolean }>(({ selected }) => ({
  opacity: selected ? '30%' : 'inherit',
  backgroundColor: 'inherit',
  color: '#111827',
  fontSize: '16px',
  '&.Mui-selected': {
    opacity: '30%',
  },
}));

export const Select = styled(MuiSelect)({
  background: '#111827',
  border: '.5px solid #6C6B77',
  fontSize: '14px',
  fontWeight: 'bold',
  height: '32px',
  borderRadius: '4px',
  color: 'white',
  textAlign: 'left',
  '& .MuiSelect-icon': {
    color: 'rgba(255, 255, 255, 0.56)',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
});

export const Content = styled(Box)({
  width: 220,
});
