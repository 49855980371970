import { call, Effect, put, takeLatest } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';

import { SuccessUserUpdate } from '~/core/constants/notificatonMessages.constants';
import { apolloServiceRequest, graphqlClient } from '~/core/services';
import { graphqlServices } from '~/core/services/graphql';

import { notificationAction } from '../actions/notifications.action';
import { getUserAction, updateUserAction } from '../actions/user.action';

export class UserSagaWorker {
  static *getUser({ payload: { userId, username = '' } }: ActionType<typeof getUserAction.request>) {
    try {
      const { user } = yield call(apolloServiceRequest, graphqlClient, 'query', graphqlServices.user.getUserGql, {
        userId,
        username,
      });

      yield put(getUserAction.success(user));
    } catch (error: any) {
      yield put(getUserAction.failure(error.message));
    }
  }

  static *updateUser({ payload: { userId, userData } }: ActionType<typeof updateUserAction.request>) {
    try {
      const { updateUser } = yield call(
        apolloServiceRequest,
        graphqlClient,
        'mutation',
        graphqlServices.user.updateUserGql,
        {
          userId,
          userData,
        },
      );
      localStorage.setItem(
        'user',
        JSON.stringify({
          Email: updateUser.email,
          FirstName: updateUser.firstName,
          IsPlatformAdmin: updateUser.isPlatformAdmin,
          LastName: updateUser.lastName,
          UserId: updateUser.id,
          UserStatus: updateUser.userStatus,
        }),
      );
      yield put(updateUserAction.success(updateUser));

      const passwordMessage = userData.newPassword && SuccessUserUpdate.Password;
      const nameMessage = userData.firstName && SuccessUserUpdate.Name;

      if (passwordMessage || nameMessage) {
        yield put(notificationAction.success({ message: passwordMessage || nameMessage }));
      }
    } catch (error: any) {
      yield put(updateUserAction.failure(error.message));
    }
  }
}

export function* userSaga(): Generator<Effect, void> {
  yield takeLatest(getUserAction.request, UserSagaWorker.getUser);
  yield takeLatest(updateUserAction.request, UserSagaWorker.updateUser);
}
