import { useQuery } from '@apollo/client';

import { AssetRecommendationsSchema, AssetSchema } from '~/core/types/graphql.types';

import { GET_ASSETS, GET_ASSET_RECOMMENDATIONS } from './assets.queries';
import { GetAssetRecommendationsQueryVariables, GetAssetsQueryVariables } from './assets.queries.generated';

export const useGetAssets = (skip: boolean, variables: GetAssetsQueryVariables) => {
  const {
    loading: assetsLoading,
    error: assetsError,
    data: assetsData,
  } = useQuery<{ getAssets: AssetSchema[] }>(GET_ASSETS, {
    skip,
    variables,
  });

  const getAssets = assetsData?.getAssets;

  return { assetsLoading, assetsError, getAssets };
};

export const useGetAssetRecommendations = (skip: boolean, variables: GetAssetRecommendationsQueryVariables) => {
  const {
    loading: assetRecommendationsLoading,
    error: assetRecommendationsError,
    data: assetRecommendationsData,
  } = useQuery<{ getAssetRecommendations: AssetRecommendationsSchema[] }>(GET_ASSET_RECOMMENDATIONS, {
    skip,
    variables,
  });

  const getAssetRecommendations = assetRecommendationsData?.getAssetRecommendations;

  return { assetRecommendationsLoading, assetRecommendationsError, getAssetRecommendations };
};
