import { createSelector } from 'reselect';

import { AppState } from '../reducers';

const accountUsersStoreSelector = (state: AppState) => state.accountUsers;

export const accountUsersLoadingSelector = createSelector(accountUsersStoreSelector, (state) => state.isLoading);
export const accountUsersErrorSelector = createSelector(accountUsersStoreSelector, (state) => state.error);
export const accountUsersSelector = createSelector(accountUsersStoreSelector, (state) => state.users);
export const accountUsersPaginationSelector = createSelector(accountUsersStoreSelector, (state) => state.pagination);
export const accountEditSuccessMessageSelector = createSelector(
  accountUsersStoreSelector,
  (state) => state.successMessage,
);
