import { Chip as MuiChip, Tooltip } from '@mui/material';

import { ChipProps } from './types';

const Chip: React.FC<ChipProps> = ({ tooltipProps, ...elementProps }) =>
  tooltipProps ? (
    <Tooltip {...tooltipProps}>
      <MuiChip {...elementProps} />
    </Tooltip>
  ) : (
    <MuiChip {...elementProps} />
  );

export default Chip;
