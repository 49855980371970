import { styled } from '@mui/material';

export const Link = styled('div')(({ theme }) => ({
  fontFamily: 'Roboto',
  fontSize: '16',
  color: theme.palette.primary.main,
  cursor: 'pointer',
}));

export const HeaderContent = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '8px',
});

export const FooterContent = styled('div')({
  display: 'flex',
  gap: '8px',
  justifyContent: 'center',
});
