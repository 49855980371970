export enum BreakPoints {
  Xs = 'xs',
  Sm = 'sm',
  Md = 'md',
  Lg = 'lg',
  Xl = 'xl',
}

export enum UserProfileMenuRoutes {
  MyAccount = '/my-account',
  CompanySettings = '/admin/company-settings',
  Users = '/admin/manage-users',
  Customers = '/admin/customers',
  Agencies = '/admin/manage-agencies',
}
