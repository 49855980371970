import { Effect, call, put, takeLatest } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';

import { apolloServiceRequest, graphqlClient, graphqlServices } from '~/core/services';
import {
  AccountQuery,
  AccountsQuery,
  UpdateBillingInfoMutation,
  UpdateCompanyProfileMutation,
} from '~/core/services/graphql/account/account.queries.generated';
import { PlatformAccountSchema, PlatformUserAccountsSchema } from '~/core/types/graphql.types';

import {
  accountAction,
  allAccountsAction,
  createAccountAction,
  updateAccountBillingInfoAction,
  updateAccountProfileInfoAction,
  updatePlatformAccountAction,
} from '../actions/account.action';
import { platformAccountsAction } from '../actions/platformAccounts.action';

export class AccountSagaWorker {
  static *createAccount({ payload }: ActionType<typeof createAccountAction.request>) {
    try {
      const data: number = yield call(
        apolloServiceRequest,
        graphqlClient,
        'mutation',
        graphqlServices.account.createAccountGql,
        payload,
      );
      yield put(createAccountAction.success(data));
    } catch (error: any) {
      yield put(createAccountAction.failure(error.message));
    }
  }

  static *account({ payload }: ActionType<typeof accountAction.request>) {
    try {
      const { account }: AccountQuery = yield call(
        apolloServiceRequest,
        graphqlClient,
        'query',
        graphqlServices.account.accountGql,
        payload,
      );
      yield put(accountAction.success(account));
    } catch (error: any) {
      yield put(accountAction.failure(error.message));
    }
  }

  static *updatePlatformAccount({
    payload: { accountId, accountData },
  }: ActionType<typeof updatePlatformAccountAction.request>) {
    try {
      const data: PlatformAccountSchema = yield call(
        apolloServiceRequest,
        graphqlClient,
        'mutation',
        graphqlServices.account.updateAccountGql,
        { accountId, accountData },
      );
      yield put(updatePlatformAccountAction.success(data));
    } catch (error: any) {
      yield put(updatePlatformAccountAction.failure(error.message));
    }
  }

  static *allAccounts({ payload }: ActionType<typeof allAccountsAction.request>) {
    try {
      const data: AccountsQuery = yield call(
        apolloServiceRequest,
        graphqlClient,
        'query',
        graphqlServices.account.allAccountsGql,
        payload,
      );

      yield put(allAccountsAction.success(data));
    } catch (error: any) {
      yield put(allAccountsAction.failure(error.message));
    }
  }

  static *updateAccountProfileInfo({ payload }: ActionType<typeof updateAccountProfileInfoAction.request>) {
    try {
      const data: UpdateCompanyProfileMutation = yield call(
        apolloServiceRequest,
        graphqlClient,
        'mutation',
        graphqlServices.account.updateCompanyProfileGql,
        payload,
      );

      yield put(updateAccountProfileInfoAction.success(data));

      const platformAccounts: PlatformUserAccountsSchema[] = yield call(
        apolloServiceRequest,
        graphqlClient,
        'query',
        graphqlServices.platformAccounts.GET_PLATFORM_ACCOUNTS,
        null,
      );

      yield put(platformAccountsAction.success({ userAccounts: platformAccounts }));
    } catch (error: any) {
      yield put(accountAction.failure(error.message));
    }
  }

  static *updateAccountBillingInfo({ payload }: ActionType<typeof updateAccountBillingInfoAction.request>) {
    try {
      const data: UpdateBillingInfoMutation = yield call(
        apolloServiceRequest,
        graphqlClient,
        'mutation',
        graphqlServices.account.updateBillingInfoGql,
        payload,
      );

      yield put(updateAccountBillingInfoAction.success(data));
    } catch (error: any) {
      yield put(accountAction.failure(error.message));
    }
  }
}

export function* accountSaga(): Generator<Effect, void> {
  yield takeLatest(createAccountAction.request, AccountSagaWorker.createAccount);
  yield takeLatest(accountAction.request, AccountSagaWorker.account);
  yield takeLatest(updatePlatformAccountAction.request, AccountSagaWorker.updatePlatformAccount);
  yield takeLatest(updateAccountProfileInfoAction.request, AccountSagaWorker.updateAccountProfileInfo);
  yield takeLatest(updateAccountBillingInfoAction.request, AccountSagaWorker.updateAccountBillingInfo);
  yield takeLatest(allAccountsAction.request, AccountSagaWorker.allAccounts);
}
