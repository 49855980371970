import jwtDecode from 'jwt-decode';
import { call, Effect, put, SagaReturnType, select, takeLatest } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';

import { successNotificationMessage } from '~/core/constants/notificatonMessages.constants';
import {
  confirmEmailRequest,
  createAccount,
  createFreeAccount,
  forgotPasswordRequest,
  login,
  resendConfirmationCodeRequest,
  setNewPasswordRequest,
  verifyNewEmail,
  verifyNewEmailResend,
} from '~/core/services/rest/auth.service';
import { AccessUser, User } from '~/core/types/auth.types';

import {
  confirmEmailAction,
  createAccountAction,
  forgotPasswordAction,
  getTokens,
  loginAction,
  logoutAction,
  resendConfirmationCodeAction,
  setAccessTokenAction,
  setNewPasswordAction,
  verifyNewEmailAction,
  verifyNewEmailResendAction,
} from '../actions/auth.action';
import { notificationAction } from '../actions/notifications.action';
import { authUserSelector } from '../selectors/auth.selector';

export class AuthSagaWorker {
  static *login({ payload }: ActionType<typeof loginAction.request>) {
    try {
      const { RefreshToken, AccessToken, IdToken, User }: SagaReturnType<typeof login> = yield call(
        login,
        payload.email,
        payload.password,
      );

      yield localStorage.setItem('accessToken', AccessToken);
      yield localStorage.setItem('refreshToken', RefreshToken);
      yield localStorage.setItem('idToken', IdToken);
      yield localStorage.setItem('user', JSON.stringify(User));

      const decodedAccessToken: AccessUser = jwtDecode(AccessToken);

      yield put(loginAction.success({ RefreshToken, AccessToken, IdToken, user: User, decodedAccessToken }));
    } catch (error: any) {
      yield put(loginAction.failure(error.response.data.detail));
    }
  }

  static *createAccount({ payload }: ActionType<typeof createAccountAction.request>) {
    try {
      if (payload.email) {
        yield call(
          createFreeAccount,
          payload.email,
          payload.companyName,
          payload.givenName,
          payload.familyName,
          payload.password,
        );
      } else {
        yield call(createAccount, payload.token, payload.givenName, payload.familyName, payload.password);
      }
      yield put(createAccountAction.success());
    } catch (error: any) {
      yield put(createAccountAction.failure(error.response.data.detail));
    }
  }

  static *getTokens() {
    try {
      const AccessToken: string = yield localStorage.getItem('accessToken');
      const RefreshToken: string = yield localStorage.getItem('refreshToken');
      const user: User = yield JSON.parse(localStorage.getItem('user'));
      const decodedAccessToken: AccessUser = jwtDecode(AccessToken);

      yield put(getTokens.success({ decodedAccessToken, AccessToken, RefreshToken, user }));
    } catch {
      yield localStorage.clear();
      yield put(getTokens.failure());
    }
  }

  static *logout() {
    try {
      yield localStorage.clear();
      yield put(logoutAction.success());
    } catch {
      yield put(logoutAction.failure());
    }
  }

  static *forgotPassword({ payload }: ActionType<typeof forgotPasswordAction.request>) {
    try {
      yield call(forgotPasswordRequest, payload.email);
      yield put(forgotPasswordAction.success());
      yield put(notificationAction.success({ message: successNotificationMessage.successSentConfirmationCode }));
    } catch {
      yield put(forgotPasswordAction.failure());
    }
  }

  static *setNewPassword({ payload }: ActionType<typeof setNewPasswordAction.request>) {
    try {
      yield call(setNewPasswordRequest, payload);
      yield put(setNewPasswordAction.success());
      yield put(notificationAction.success({ message: successNotificationMessage.successChangePassword }));
    } catch (error: any) {
      yield put(setNewPasswordAction.failure(error.response.data.detail));
    }
  }

  static *confirmEmail({ payload }: ActionType<typeof confirmEmailAction.request>) {
    try {
      yield call(confirmEmailRequest, payload.email, payload.confirmationCode);
      yield put(confirmEmailAction.success());
      yield put(notificationAction.success({ message: successNotificationMessage.successSignUp }));
    } catch (error: any) {
      yield put(confirmEmailAction.failure(error.response.data.detail));
    }
  }

  static *resendConfirmationCode({ payload }: ActionType<typeof resendConfirmationCodeAction.request>) {
    try {
      yield call(resendConfirmationCodeRequest, payload.email);
      yield put(resendConfirmationCodeAction.success());
      yield put(notificationAction.success({ message: successNotificationMessage.successSentConfirmationCode }));
    } catch (error: any) {
      yield put(resendConfirmationCodeAction.failure(error.response.data.detail));
    }
  }

  static *setAccessToken({ payload }: ActionType<typeof setAccessTokenAction.request>) {
    try {
      const { AccessToken, IdToken, User } = payload;

      yield localStorage.setItem('accessToken', AccessToken);
      yield localStorage.setItem('idToken', IdToken);
      yield localStorage.setItem('user', JSON.stringify(User));

      const decodedAccessToken: AccessUser = jwtDecode(AccessToken);

      yield put(setAccessTokenAction.success({ AccessToken, IdToken, user: User, decodedAccessToken }));
    } catch (error: any) {
      yield put(setAccessTokenAction.failure());
    }
  }

  static *verifyNewEmail({ payload }: ActionType<typeof verifyNewEmailAction.request>) {
    try {
      const user: User = yield select(authUserSelector);

      yield call(verifyNewEmail, { ...payload, userId: payload.userId || user.UserId });
      yield put(verifyNewEmailAction.success());
      yield put(notificationAction.success({ message: successNotificationMessage.successVerifyEmail }));
    } catch (error: any) {
      yield put(verifyNewEmailAction.failure(error.response.data.detail));
    }
  }

  static *verifyNewEmailResend() {
    try {
      yield call(verifyNewEmailResend);
      yield put(verifyNewEmailResendAction.success());
      yield put(notificationAction.success({ message: successNotificationMessage.successSentConfirmationCode }));
    } catch (error: any) {
      yield put(verifyNewEmailResendAction.failure(error.response.data.detail));
    }
  }
}

export function* authSaga(): Generator<Effect, void> {
  yield takeLatest(loginAction.request, AuthSagaWorker.login);
  yield takeLatest(createAccountAction.request, AuthSagaWorker.createAccount);
  yield takeLatest(getTokens.request, AuthSagaWorker.getTokens);
  yield takeLatest(logoutAction.request, AuthSagaWorker.logout);
  yield takeLatest(forgotPasswordAction.request, AuthSagaWorker.forgotPassword);
  yield takeLatest(setNewPasswordAction.request, AuthSagaWorker.setNewPassword);
  yield takeLatest(confirmEmailAction.request, AuthSagaWorker.confirmEmail);
  yield takeLatest(resendConfirmationCodeAction.request, AuthSagaWorker.resendConfirmationCode);
  yield takeLatest(setAccessTokenAction.request, AuthSagaWorker.setAccessToken);
  yield takeLatest(verifyNewEmailAction.request, AuthSagaWorker.verifyNewEmail);
  yield takeLatest(verifyNewEmailResendAction.request, AuthSagaWorker.verifyNewEmailResend);
}
