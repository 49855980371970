export const customerAccountSteps = [
  {
    label: 'Company Details',
    heading: 'Add company details',
  },
  {
    label: 'Account Authorization',
    heading: 'Add a Google account to the platform',
  },
  {
    label: 'Admin User',
    heading: 'Invite an admin for this account',
  },
];

export const agencyAccountSteps = [
  {
    label: 'Company Details',
    heading: 'Add company details',
  },
  {
    label: 'Admin User',
    heading: 'Invite an admin for this account',
  },
];
