/* eslint react/jsx-no-literals: "off", no-nested-ternary: "off" */

import { Box, Table, TableBody, TableContainer } from '@mui/material';
import { KeyboardEvent, MouseEvent, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { TableHead } from '~/core/components/shared';
import { AdGroupSummarySortEnum, QueryGetAdGroupSummariesArgs } from '~/core/types/graphql.types';
import { useGetAdGroupSummaries } from '~/core/services/graphql/adGroups/hooks';
import { SortType } from '~/core/types/table.types';
import { useContexts } from '~/store/context/useContext';

import LoadingTable from './LoadingTable';
import * as Styled from '../styles';
import { adGroupHeaderCells } from '../tableHeader.data';
import { CustomTablePagination } from '../../components';
import CollapsibleRow from './CollapisbleRow';

const AdGroupsTable = () => {
  const { selectedPlatformAccountId, selectedSourceAccountId, selectedCampaignId, selectedDateRange } = useContexts();
  const [searchParams, setSearchParams] = useSearchParams();
  const [orderBy, setOrderBy] = useState<AdGroupSummarySortEnum>(AdGroupSummarySortEnum.KeywordsWithTraffic);
  const [desc, setDesc] = useState<boolean>(true);
  const [page, setPage] = useState<number>((searchParams.get('page') && Number(searchParams.get('adGroupsPage'))) || 0);
  const [pageSize, setPageSize] = useState<number>(
    (searchParams.get('pageSize') && Number(searchParams.get('pageSize'))) || 25,
  );
  const [inputValue, setInputValue] = useState<string>('');
  const [searchValue, setSearchValue] = useState<string>('');

  const adGroupsSkip = !selectedPlatformAccountId || !selectedSourceAccountId || !selectedDateRange;
  const adGroupsVariables: QueryGetAdGroupSummariesArgs = {
    platformAccountId: selectedPlatformAccountId,
    customerIds: [selectedSourceAccountId],
    dateRange: selectedDateRange,
    searchValue,
    page: page + 1,
    pageSize,
    sortBy: {
      field: orderBy,
      desc,
    },
  };

  if (selectedCampaignId) {
    adGroupsVariables.filterField = {
      filters: [
        {
          operation: 'eq',
          fieldName: 'campaign_id',
          value: selectedCampaignId,
        },
      ],
    };
  }

  const { adGroupSummariesLoading, getAdGroupSummaries } = useGetAdGroupSummaries(adGroupsSkip, adGroupsVariables);

  const handleRequestSort = (event: MouseEvent<unknown>, property: any) => {
    const isAsc = property === orderBy ? !desc : true;
    setDesc(isAsc);
    setOrderBy(property);
    searchParams.set('orderBy', property);
    searchParams.set('desc', String(!isAsc));
    setSearchParams(searchParams);
  };

  const handleSearch = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      setSearchValue(inputValue);
    }
  };

  return adGroupSummariesLoading ? (
    <>
      <Styled.SearchContainer sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Styled.SearchBarHeading>Ad Groups</Styled.SearchBarHeading>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Styled.SearchBar
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            onKeyDown={handleSearch}
            disabled={adGroupSummariesLoading}
            placeholder="Search for an Ad Group"
            sx={{
              '.MuiInputBase-root': {
                minWidth: '300px',
                marginRight: '12px',
              },
            }}
          />
        </Box>
      </Styled.SearchContainer>
      <LoadingTable />
    </>
  ) : (
    <>
      <Styled.SearchContainer
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Styled.SearchBarHeading>Ad Groups</Styled.SearchBarHeading>
        <Styled.SearchBar
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          onKeyDown={handleSearch}
          disabled={adGroupSummariesLoading}
          placeholder="Search for an Ad Group"
          sx={{
            '.MuiInputBase-root': {
              minWidth: '300px',
              marginRight: '12px',
            },
          }}
        />
      </Styled.SearchContainer>

      {getAdGroupSummaries?.edges?.length > 0 ? (
        <TableContainer>
          <Table stickyHeader aria-label="sticky table">
            <TableHead
              data-testid="tableHead"
              headCells={adGroupHeaderCells}
              order={desc ? SortType.Desc : SortType.Asc}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {getAdGroupSummaries.edges.map((row, index) => (
                <CollapsibleRow key={row?.adGroupId} row={row} loading={false} index={index} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <TableContainer>
          <Table stickyHeader aria-label="sticky table">
            <TableHead
              data-testid="tableHead"
              headCells={adGroupHeaderCells}
              order={desc ? SortType.Desc : SortType.Asc}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              <Styled.TableCell
                sx={{
                  textAlign: 'center',
                  height: '150px',
                }}
                colSpan={7}
              >
                No Ad Groups to Display
              </Styled.TableCell>
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          borderTop: '1px solid #E5E5E5',
        }}
      >
        <CustomTablePagination
          page={page}
          pageSize={pageSize}
          setPage={setPage}
          setPageSize={setPageSize}
          pageTotal={getAdGroupSummaries?.pageInfo?.total}
        />
      </Box>
    </>
  );
};

export default AdGroupsTable;
