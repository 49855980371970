import {
  Box,
  Container,
  ContainerProps,
  ListItemButton as MuiListItemButton,
  MenuItem as MuiMenuItem,
  Select as MuiSelect,
  Skeleton,
  styled,
  Typography,
} from '@mui/material';

import { colors } from '../../../../../theme';
import { Chip } from '../../Chip';

export const AccountName = styled(Typography)`
  font-size: 14px;
  font-weight: bold;
  line-height: 1.43;
  letter-spacing: 0.17px;
  color: ${colors.text.light.primary} !important;
  margin-left: 5px;
  padding: 0;
`;

export const DropdownContainer = styled(Container)<ContainerProps>({
  width: '100%',
  display: 'flex',
});

export const MenuItem = styled(MuiMenuItem)({
  width: 'auto',
  height: '33px',
  backgroundColor: 'inherit',
  color: '#111827',
  fontSize: '14px',
  '&.Mui-selected': {
    background: 'rgba(33, 33, 52, 0.04)',
  },
  '&.MuiMenuItem-root': {
    minHeight: '33px',
  },
});

export const ListItemButton = styled(MuiListItemButton)({
  width: '100%',
  display: 'flex',
  whiteSpace: 'pre-wrap',
  wordBreak: 'break-word',
  '&:hover': {
    backgroundColor: 'rgba(255,255,255,0.08)',
  },
  '.Mui-disabled': {
    opacity: '1 !important',
  },
  '& .MuiPopover-root': {
    minWidth: '108px !important',
  },
});

export const Select = styled(MuiSelect)({
  paddingRight: '5px',
  fontWeight: 'bold',
  '& .MuiSelect-select': `
    padding: 0;
    font-size: 14px;
    font-weight: bold;
    line-height: 1.43;
    letter-spacing: 0.17px;
    color: rgba(33, 33, 52, 0.87);
    margin-left: 5px;
    `,
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
    padding: '0px !important',
  },
  '& .MuiSvgIcon-root': {
    color: 'rgb(33,33,52, 0.54) !important',
  },
  borderRadius: '0px',
  '.selectName': {
    color: '#000',
  },
  borderRight: '1px solid rgba(33, 33, 52, 0.12)',
  '.MuiSvgIcon-root': {
    color: '#345EB6',
    fontWeight: 'bold',
    marginRight: '5px',
  },

  '.MuiListItemButton-root': {
    width: '126%',
    borderRight: '1px solid rgba(33, 33, 52, 0.12)',
  },
});

export const CustomChip = styled(Chip)({
  height: '20px',
  color: '#fff',
  background: '#3154aa',
  marginLeft: '15.5px',
  '-webkit-text-fill-color': '#fff',
});

export const LoadingContainer = styled(Box)({
  borderRadius: '4px',
  borderRight: '1px solid rgba(33, 33, 52, 0.12)',
  height: '55px',
  display: 'flex',
  alignItems: 'center',
});

export const CustomSkeleton = styled(Skeleton)({
  width: '200px',
  height: '40px',
  margin: '0 15px',
});
