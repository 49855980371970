const dateKeysMap = {
  '01': 'Jan.',
  '02': 'Feb.',
  '03': 'Mar.',
  '04': 'Apr.',
  '05': 'May.',
  '06': 'Jun.',
  '07': 'Jul.',
  '08': 'Aug.',
  '09': 'Sep.',
  '10': 'Oct.',
  '11': 'Nov.',
  '12': 'Dec.',
};

export const formatDateToMonthAndYear = (startDate: string) => {
  const month = startDate?.split('-')[1] as keyof typeof dateKeysMap;
  const year = startDate?.split('-')[0];
  return `${dateKeysMap[month]} ${year}`;
};
