/* eslint-disable react/no-unused-state */
/* eslint-disable react/jsx-no-useless-fragment */
import * as Sentry from '@sentry/react';
import React from 'react';

type ErrorBoundaryProps = {
  hasError: boolean | null;
  eventId: string | null;
};
class ErrorBoundary extends React.Component<any, ErrorBoundaryProps> {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false, eventId: null };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: any, errorInfo: any) {
    Sentry.withScope((scope) => {
      scope.setExtras(errorInfo);
      const eventId = Sentry.captureException(error);
      this.setState({ eventId });
    });
  }

  render() {
    const { state, props } = this;

    if (state.hasError) {
      return <h1 data-testid="errorBoundary">Something went wrong.</h1>;
    }

    return <>{props.children}</>;
  }
}

export default ErrorBoundary;
