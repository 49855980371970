import { gql } from '@apollo/client';

export const integrationAccountsGql = gql`
  query ownIntegrationAccounts(
    $page: Int!
    $pageSize: Int!
    $platformAccountId: Int!
    $sortBy: IntegrationAccountsSortSchema
    $searchValue: String
    $includeMcc: Boolean
  ) {
    ownIntegrationAccounts(
      platformAccountId: $platformAccountId
      page: $page
      pageSize: $pageSize
      sortBy: $sortBy
      searchValue: $searchValue
      includeMcc: $includeMcc
    ) {
      pageInfo {
        pageSize
        page
        total
        pages
        previousPage
        nextPage
      }
      edges {
        name
        timezone
        currencyCode
        accountId
        platformAccountId
        accountType
        conversionActions
        customerId
        status
        hidden
        level
        manager
        access
        id
        createdAt
        updatedAt
      }
    }
  }
`;
