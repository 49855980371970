import { createReducer } from 'typesafe-actions';

import { PageInfoSchema, PlatformAccountUsersSchema } from '~/core/types/graphql.types';

import {
  accountUsersAction,
  AccountUsersActionUnion,
  inviteAccountUserAction,
  resetMessageAction,
  updateUserAccessAction,
} from '../actions/accountUsers.action';
import { UsersActionMessageEnum } from '../types';

export interface State {
  users: PlatformAccountUsersSchema[];
  pagination: PageInfoSchema;
  successMessage: UsersActionMessageEnum;
  isLoading: boolean;
  error: string;
}

export const initialState: State = {
  users: [],
  pagination: null,
  successMessage: null,
  isLoading: false,
  error: null,
};

export const reducer = createReducer<State, AccountUsersActionUnion>(initialState)
  .handleAction(resetMessageAction, (state) => ({
    ...state,
    successMessage: null,
    error: null,
  }))
  .handleAction(accountUsersAction.success, (state, action) => ({
    ...state,
    isLoading: false,
    users: action.payload.accountUsers.edges,
    pagination: action.payload.accountUsers.pageInfo,
    error: null,
  }))
  .handleAction(accountUsersAction.failure, (state, action) => ({
    ...state,
    isLoading: false,
    error: action.payload,
  }))
  .handleAction(accountUsersAction.request, (state) => ({
    ...state,
    isLoading: true,
    error: null,
  }))
  .handleAction(inviteAccountUserAction.success, (state, action) => ({
    ...state,
    isLoading: false,
    users: action.payload.accountUsers.edges,
    successMessage: UsersActionMessageEnum.INVITE_SUCCESS,
    pagination: action.payload.accountUsers.pageInfo,
    error: null,
  }))
  .handleAction(inviteAccountUserAction.failure, (state, action) => ({
    ...state,
    isLoading: false,
    successMessage: null,
    error: action.payload,
  }))
  .handleAction(inviteAccountUserAction.request, (state) => ({
    ...state,
    isLoading: true,
    successMessage: null,
    error: null,
  }))
  .handleAction(updateUserAccessAction.success, (state, action) => ({
    ...state,
    isLoading: false,
    users: action.payload.accountUsers.edges,
    successMessage: UsersActionMessageEnum.UPDATE_SUCCESS,
    pagination: action.payload.accountUsers.pageInfo,
    error: null,
  }))
  .handleAction(updateUserAccessAction.failure, (state, action) => ({
    ...state,
    isLoading: false,
    successMessage: null,
    error: action.payload,
  }))
  .handleAction(updateUserAccessAction.request, (state) => ({
    ...state,
    isLoading: true,
    successMessage: null,
    error: null,
  }));
