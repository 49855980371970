import { Alert } from '@mui/material';
import { passwordStrength } from 'check-password-strength';
import { Controller, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';

import { Button, PasswordChecker } from '~/core/components/shared';
import { FormValidationType } from '~/core/constants/formValidationTypes.constants';
import { EMAIL_PATTERN_REGEX } from '~/core/constants/validationPatterns.constants';
import { CreateAccountPayload } from '~/core/types/auth.types';
import { authLoadingSelector } from '~/store/selectors/auth.selector';

import * as Styled from './styles';
import { CreateAccountFormProps } from './types';

const CreateAccountForm: React.FC<CreateAccountFormProps> = ({ onSubmit, defaultEmail }) => {
  const isLoading = useSelector(authLoadingSelector);
  const {
    handleSubmit,
    watch,
    control,
    formState: { errors },
  } = useForm<CreateAccountPayload>({
    defaultValues: {
      givenName: '',
      familyName: '',
      password: '',
      email: defaultEmail,
      companyName: '',
    },
  });

  const password = watch('password');
  const passwordStrengthCheck = passwordStrength(password);
  const isPasswordSecure = passwordStrengthCheck.contains.length >= 4 && passwordStrengthCheck.length >= 8;

  return (
    <>
      <Controller
        name="givenName"
        rules={{ required: true }}
        control={control}
        render={({ field: { onChange, value } }) => (
          <Styled.CustomInput
            size="small"
            value={value}
            onChange={onChange}
            placeholder="First Name"
            required
            error={!!errors?.givenName || !!errors?.familyName}
          />
        )}
      />
      <Controller
        name="familyName"
        rules={{ required: true }}
        control={control}
        render={({ field: { onChange, value } }) => (
          <Styled.CustomInput
            size="small"
            value={value}
            onChange={onChange}
            placeholder="Last Name"
            required
            error={!!errors?.familyName}
          />
        )}
      />
      {!!defaultEmail || (
        <Controller
          name="email"
          rules={{
            required: true,
            pattern: {
              value: EMAIL_PATTERN_REGEX,
              message: 'Please enter a valid email address',
            },
          }}
          control={control}
          render={({ field: { onChange, value } }) => (
            <Styled.CustomInput
              data-testid="emailInput"
              type="email"
              placeholder="Email"
              size="small"
              required
              value={value}
              onChange={onChange}
              helperText={errors?.email?.type === FormValidationType.Pattern && 'Please enter a valid email address'}
              error={
                errors?.email?.type === FormValidationType.Required ||
                errors?.email?.type === FormValidationType.Pattern
              }
              autoComplete="email"
            />
          )}
        />
      )}
      {!!defaultEmail || (
        <Controller
          name="companyName"
          rules={{ required: true }}
          control={control}
          render={({ field: { onChange, value } }) => (
            <Styled.CustomInput
              size="small"
              value={value}
              onChange={onChange}
              placeholder="Company"
              required
              error={!!errors?.companyName}
            />
          )}
        />
      )}
      <Controller
        name="password"
        control={control}
        rules={{ required: true, validate: () => isPasswordSecure }}
        render={({ field: { onChange, value } }) => (
          <Styled.CustomPasswordInput
            placeholder="Password"
            size="small"
            data-testid="passwordInput"
            value={value}
            onChange={onChange}
            required
            error={!!errors?.password}
          />
        )}
      />
      {isPasswordSecure ? (
        <Alert
          sx={{
            width: '100%',
          }}
          severity="success"
        >
          Password is secure
        </Alert>
      ) : (
        password && <PasswordChecker error={!!errors.password} passwordStrength={passwordStrengthCheck} />
      )}

      <Button
        fullWidth
        data-testid="submitButton"
        variant="contained"
        size="large"
        disabled={isLoading}
        onClick={handleSubmit(onSubmit)}
        label="Create Account"
      />
    </>
  );
};

export default CreateAccountForm;
