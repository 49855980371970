import { Box, styled } from '@mui/material';

import { PageHeader as CustomPageHeader } from '~/core/components/shared';

export const Container = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  minWidth: '600px',
  position: 'relative',
});

export const PageHeader = styled(CustomPageHeader)({
  // height: '40px',
  padding: '0px',
});

export const LogoContainer = styled('div')`
  width: 22px;
  height: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(202, 213, 224, 0.45);
  border-radius: 25px;
`;

export const FlexContainer = styled(Box)({
  display: 'flex',
  background: '#fff',
  overflow: 'hidden',
});

export const CenterContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
});

export const MapButton = styled('button')({
  width: '30px',
  border: 'none',
  color: 'rgba(74, 74, 106, 0.5)',
  background: 'none',
  '&:hover': {
    cursor: 'pointer',
  },
});
