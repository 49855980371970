import { styled, TableCell as MuiTableCell, tableCellClasses } from '@mui/material';

export const TableCell = styled(MuiTableCell)({
  [`&.${tableCellClasses.head}`]: {
    color: '#111827',
    fontSize: '12px',
  },
  [`&.${tableCellClasses.body}`]: { fontSize: '14px' },
  [`&.${tableCellClasses.root}`]: { padding: '16px 16px 16px 38px' },
});
