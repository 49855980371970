import { Alert, Link, Typography } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { useState } from 'react';

import { Button, Input, PasswordInput } from '~/core/components/shared';
import { FormValidationType } from '~/core/constants/formValidationTypes.constants';
import { EMAIL_PATTERN_REGEX } from '~/core/constants/validationPatterns.constants';
import { SignInPayload } from '~/core/types/auth.types';
import { authErrorSelector, authLoadingSelector } from '~/store/selectors/auth.selector';
import { resendConfirmationCodeAction } from '~/store/actions/auth.action';

import { defaultFormValues, ErrorsFormEnum } from './signInDefaultValues';
import * as Styled from './styles';
import { SignInFormProps } from './types';

const SignInForm: React.FC<SignInFormProps> = ({ onSubmit }) => {
  const authError = useSelector(authErrorSelector);
  const isLoading = useSelector(authLoadingSelector);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<SignInPayload>({
    defaultValues: defaultFormValues,
    reValidateMode: 'onSubmit',
  });
  const [email, setEmail] = useState<string>('');

  const onSubmitHandler = (data: SignInPayload) => {
    setEmail(data.email);
    onSubmit(data);
  };

  const onResendConfirmationCode = () => {
    dispatch(resendConfirmationCodeAction.request({ email }));
    if (email) {
      navigate(`../confirm-email`, { replace: true, state: { email } });
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmitHandler)}>
      <Styled.SignInFormContainer disableGutters>
        <Controller
          name="email"
          rules={{
            required: true,
            pattern: {
              value: EMAIL_PATTERN_REGEX,
              message: 'Please enter a valid email address',
            },
          }}
          control={control}
          render={({ field: { onChange, value } }) => (
            <Input
              data-testid="emailInput"
              type="email"
              size="small"
              label="Email Address"
              value={value}
              onChange={onChange}
              helperText={errors?.email?.type === FormValidationType.Pattern && 'Please enter a valid email address'}
              error={
                errors?.email?.type === FormValidationType.Required ||
                authError === ErrorsFormEnum.SignInError ||
                errors?.email?.type === FormValidationType.Pattern
              }
              autoComplete="email"
            />
          )}
        />
        <Controller
          name="password"
          rules={{ required: true }}
          control={control}
          render={({ field: { onChange, value } }) => (
            <PasswordInput
              size="small"
              label="Password"
              value={value}
              onChange={onChange}
              error={authError === ErrorsFormEnum.SignInError || errors?.password?.type === FormValidationType.Required}
            />
          )}
        />
        {(errors?.email?.type === FormValidationType.Required || errors?.password) && (
          <Alert severity="error">Please enter an email and password</Alert>
        )}
        {(authError === ErrorsFormEnum.SignInError || authError === ErrorsFormEnum.UserNotFound) && (
          <Alert severity="error">Invalid user or password</Alert>
        )}
        {authError === 'The account was blocked due to numerous attempts to log in. Try again later' && (
          <Alert severity="error">
            <Typography fontWeight={600} fontSize={14}>
              Too many failed attempts
            </Typography>
            <Typography fontSize={14}>Please try again in 30 minutes</Typography>
          </Alert>
        )}

        {authError === 'Your email address has not been confirmed yet' && (
          <Alert severity="error">
            <Typography fontWeight={600} fontSize={14}>
              Email confirmation required
            </Typography>
            <Typography fontSize={14}>
              If you did not receive a confirmation email, please check your spam folder or click{' '}
              <Link
                onClick={onResendConfirmationCode}
                sx={{
                  cursor: 'pointer',
                }}
              >
                here
              </Link>{' '}
              to resend the confirmation email.
            </Typography>
          </Alert>
        )}
        <Button
          data-testid="submitButton"
          type="submit"
          label="Sign In"
          fullWidth
          disabled={isLoading}
          variant="contained"
          size="large"
        />
      </Styled.SignInFormContainer>
    </form>
  );
};

export default SignInForm;
