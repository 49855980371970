import { styled, Button as MuiButton } from '@mui/material';

export const Button = styled(MuiButton)({
  borderRadius: '6px',
  textTransform: 'none',
  fontFamily: 'Inter',
  fontWeight: 600,
  lineHeight: 1.69,
  letterSpacing: '0.46px',
});
