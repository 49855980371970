import { gql } from '@apollo/client';

export const GET_ASSESSMENT_STATUSES = gql`
  query getAssessmentLatest($sourceAccountIds: [String!]!) {
    getAssessmentLatest(sourceAccountIds: $sourceAccountIds) {
      sourceAccountId
      status
      latestAvailable
    }
  }
`;

export const GET_SOURCE_ACCOUNT_INFO = gql`
  query getSourceAccountInfo($platformAccountId: Int!, $customerIds: [String!]!) {
    getSourceAccountInfo(platformAccountId: $platformAccountId, customerIds: $customerIds) {
      name
      status
      impressions
      customerId
      dateRange
    }
  }
`;
