import { createTheme } from '@mui/material/styles';

import colors from './colors';

export default createTheme({
  spacing: 8,
  palette: {
    text: colors.text.light,
    primary: colors.primary.light,
    secondary: colors.secondary.light,
    error: colors.error.light,
    warning: colors.warning.light,
    info: colors.info.light,
    success: colors.success.light,
    background: colors.background.light,
  },
  typography: {
    fontFamily: 'Inter',
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        body {
          height: 100%;
          width: 100%;
        }
        html {
          height: 100%;
          width: 100%;
        }
      `,
    },
    MuiLink: {
      styleOverrides: {
        root: {
          fontFamily: 'Roboto',
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          background: 'transparent',
          '&:before': {
            display: 'none',
          },
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        content: {
          margin: 0,
          padding: 0,
          '&.Mui-expanded': {
            margin: 0,
          },
        },
        root: {
          minHeight: 0,
          padding: 0,
        },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          padding: 0,
        },
      },
    },
  },
});
