import { GoogleAds } from '~/core/types/googleAdsAccounts.types';

import ApiClient from '../api.service';

export const getAdwordsAccountsRequest = async (accountId: number) => {
  const { data } = await ApiClient.get<GoogleAds[]>('google/adwords/accounts', {
    params: {
      account_id: accountId,
    },
  });

  const parseData = data?.map((item) => ({
    ...item,
    id: item.id,
    customerId: item.customer_id,
    updatedAt: item.updated_at,
    createdAt: item.created_at,
    platformAccountId: item.platform_account_id,
  }));

  return parseData;
};

export const postGoogleAuthSignIn = async (accountId: number, redirectionUri: string) => {
  const { data } = await ApiClient.post('google/auth/sign-in', {
    account_id: accountId,
    redirection_uri: redirectionUri,
  });
  return data;
};
