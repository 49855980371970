import { useQuery } from '@apollo/client';
import { Box, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useSearchParams } from 'react-router-dom';
import { PopupButton } from 'react-calendly';

import { Button, CircularProgress, NoPermissions, SelectorMenu } from '~/core/components/shared';
import { graphqlClient } from '~/core/services';
import { useGetIntentsAvailability } from '~/core/services/graphql/intents/hooks';
import { GetIntentsAvailabilityQueryVariables } from '~/core/services/graphql/intents/intents.queries.generated';
import { GET_PLATFORM_ACCOUNTS } from '~/core/services/graphql/platformAccounts/platformAccounts.queries';
import { useGetAssessmentStatuses } from '~/core/services/graphql/sourceAccounts/hooks';
import { GetAssessmentLatestQueryVariables } from '~/core/services/graphql/sourceAccounts/sourceAccounts.queries.generated';
import { AssessmentProcessStatusEnum, IntegrationAccountsSchema } from '~/core/types/graphql.types';
import { linkNewAccountAction } from '~/store/actions/integrationAccounts.action';
import { useContexts } from '~/store/context/useContext';
import { authUserSelector } from '~/store/selectors/auth.selector';
import {
  integrationAccountsLoadingSelector,
  integrationAccountsSelector,
} from '~/store/selectors/integrationAccounts.selector';
import { getProductUrl } from '~/core/services/rest/stripe/stripe';
import { aiSubscriptionId } from '~/core/services/rest/stripe/constants';

import { ContentGapAnalysis, LinkAccount, SegmentationAnalysis } from './components';
import * as Styled from './styles';
import FreeTrialHero from './components/LinkAccount/FreeTrialHero';

const MotiveMap: React.FC = () => {
  const currentRoute = window.location.pathname;
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const {
    selectedPlatformAccount,
    selectedPlatformAccountId,
    selectedDateRange,
    selectedSourceAccountId,
    campaignSummariesLoading,
    setSelectedDateRange,
  } = useContexts();

  const [startFetches, setStartFetches] = useState(true);
  const integrationAccounts = useSelector(integrationAccountsSelector);
  const stripeSubscriptionId = selectedPlatformAccount?.stripeSubscriptionId;
  const stripeCustomerId = selectedPlatformAccount?.stripeCustomerId;
  const callbackUrl = `${window.location.hostname === 'localhost' ? 'http' : 'https'}://${
    window.location.host
  }/content-gap-analysis`;
  const dateRange = searchParams.get('dateRange') || selectedDateRange;
  if (dateRange && !selectedDateRange) {
    setSelectedDateRange(dateRange);
  }
  const user = useSelector(authUserSelector);
  const isPlatformAdmin = user?.IsPlatformAdmin;

  const isLoading = useSelector(integrationAccountsLoadingSelector);

  const [stripeUrl, setStripeUrl] = useState<string | null>('');
  const demoMode = !stripeSubscriptionId && !isPlatformAdmin;

  const sourceAccountIds = integrationAccounts?.map(({ customerId }) => customerId);

  // Account Summaries

  const { loading: platformAccountsLoading } = useQuery(GET_PLATFORM_ACCOUNTS, {
    skip: !user,
    client: graphqlClient,
  });

  // Source Account Info
  const getIntentsAvailabilitySkip = !selectedPlatformAccountId || !sourceAccountIds?.length;
  const getIntentsAvailabilityVariables: GetIntentsAvailabilityQueryVariables = {
    platformAccountId: selectedPlatformAccountId,
    customerIds: sourceAccountIds,
  };
  const { availabilityLoading } = useGetIntentsAvailability(
    getIntentsAvailabilitySkip,
    getIntentsAvailabilityVariables,
  );

  // Assessment Statuses
  const getAssessmentLatestSkip = !sourceAccountIds || integrationAccounts?.length === 0;
  const getAssessmentLatestVariables: GetAssessmentLatestQueryVariables = { sourceAccountIds };
  const { assessmentStatusesLoading, getAssessmentLatest } = useGetAssessmentStatuses(
    getAssessmentLatestSkip,
    getAssessmentLatestVariables,
  );
  // Conditions for rendered components
  const displayAssessmentSummaries = !!integrationAccounts?.length;
  const displayLinkAccount = selectedPlatformAccountId && integrationAccounts?.length < 1 && !isLoading;
  const displayNoPermissions =
    !selectedPlatformAccountId &&
    !availabilityLoading &&
    !isLoading &&
    !assessmentStatusesLoading &&
    !campaignSummariesLoading &&
    !platformAccountsLoading &&
    !demoMode;

  const sortedOptions: IntegrationAccountsSchema[] = [];

  integrationAccounts?.forEach((option: any) => sortedOptions.push(option));
  sortedOptions?.sort((a, b) => a.name.localeCompare(b.name));

  const summmaryDetails = getAssessmentLatest?.filter(
    ({ sourceAccountId }) => sourceAccountId === selectedSourceAccountId,
  )?.[0];
  const account = integrationAccounts?.find((account) => account.customerId === selectedSourceAccountId);
  const createdAt = account?.createdAt;

  const status = summmaryDetails?.status;
  const currentDate = new Date();
  const accountDate = new Date(createdAt);
  const difference = currentDate.getTime() - accountDate.getTime();
  const differenceHours = Math.ceil(difference / (1000 * 3600));
  const summaryIsInProgress = status === AssessmentProcessStatusEnum.InProgress && differenceHours < 12;

  useEffect(() => {
    setStartFetches(true);
    setTimeout(() => {
      setStartFetches(false);
    }, 200);
  }, [selectedPlatformAccountId]);

  const [isVisible, setIsVisible] = useState(true);

  const handleLinkNewAccount = () => {
    if (!demoMode) {
      dispatch(linkNewAccountAction.request(selectedPlatformAccountId));
      if (stripeSubscriptionId) {
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      }
    }
  };

  useEffect(() => {
    if (stripeCustomerId) {
      const fetchStripeUrl = async () => {
        const url = await getProductUrl({
          productId: aiSubscriptionId,
          quantity: 1,
          successUrl: callbackUrl,
          cancelUrl: callbackUrl,
          customerId: stripeCustomerId,
        });
        setStripeUrl(url);
      };

      fetchStripeUrl();
    }
  }, [selectedPlatformAccount]);

  // Check for Google Authentication and trigger link and reload page
  useEffect(() => {
    if (window.location.pathname.includes('content-gap-analysis')) {
      const handleVisibilityChange = () => {
        setIsVisible(!document.hidden);

        if (integrationAccounts?.length === 0 && !isVisible) {
          handleLinkNewAccount();
        }
      };
      document.addEventListener('visibilitychange', handleVisibilityChange);
    }
  }, [isVisible, document.hidden]);

  if (!stripeSubscriptionId && !isPlatformAdmin && !integrationAccounts?.length) {
    return <FreeTrialHero />;
  }

  return (
    <Styled.Container
      data-testid="motiveMap"
      sx={{
        overflow: demoMode ? 'hidden' : 'auto',
      }}
    >
      {demoMode && (
        <Box
          className="scheduleMeeting"
          sx={{
            position: 'absolute',
            backdropFilter: 'blur(10px)',
            display: 'flex',
            top: '0',
            left: '0',
            right: '0',
            bottom: '0',
            zIndex: 1001,
          }}
        >
          <Box
            sx={{
              fontSize: '2rem',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <Box
              sx={{
                background: 'white',
                width: '550px',
                height: '280px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '20px',
              }}
            >
              <Typography
                sx={{
                  fontSize: '1.5rem',
                  fontWeight: 'bold',
                }}
              >
                Your Subscription Has Expired
              </Typography>
              <Typography>
                In order to continue accessing +AI Platform features, <br /> please renew your subcription for $29.99
                per month.
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '10px',
                }}
              >
                <PopupButton
                  className="scheduleMeetingButton"
                  rootElement={document.querySelector('.dashboard')}
                  url="https://calendly.com/triley1mm/sales"
                  text="Talk To Us First"
                  pageSettings={{
                    backgroundColor: 'ffffff',
                    primaryColor: '3154AA',
                    textColor: '4d5055',
                  }}
                  styles={{
                    backgroundColor: '#fff',
                    color: '#3154AA',
                    border: '1px solid #3154AA',
                    fontSize: '14px',
                    height: '35.66px',
                    padding: '6px 16px',
                    borderRadius: '6px',
                    textTransform: 'none',
                    fontFamily: 'Inter',
                    fontWeight: 600,
                    lineHeight: 1.69,
                    letterSpacing: '0.46px',
                    marginTop: '4px',
                    cursor: 'pointer',
                  }}
                />
                <Link to={stripeUrl}>
                  <Button label="Continue Subscription" variant="contained" />
                </Link>
              </Box>
            </Box>
          </Box>
        </Box>
      )}

      {(startFetches || integrationAccounts?.length > 0 || demoMode) && <SelectorMenu demoMode={demoMode} />}

      <Styled.ContentContainer>
        {isLoading && !demoMode && (
          <Box
            data-testid="motiveMapLoading"
            sx={{
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <CircularProgress />
          </Box>
        )}

        {summaryIsInProgress && (
          <Styled.ImageContainer>
            <CircularProgress height="40px" />
            <Styled.ImageHeading marginTop="10px">
              {currentRoute.includes('/segmentation-analysis') ? 'Finding Segments' : 'Finding Content Gaps'}
            </Styled.ImageHeading>
            <Styled.ImageHeading sx={{ fontWeight: '400' }}>
              <Box
                sx={{
                  color: 'rgba(33, 33, 52, 0.6)',
                }}
              >
                This can take a while, check back in a few hours.
              </Box>
            </Styled.ImageHeading>
          </Styled.ImageContainer>
        )}

        {displayLinkAccount && <LinkAccount />}

        {displayNoPermissions && <NoPermissions />}

        {/* Content Gap Analysis */}
        {displayAssessmentSummaries &&
          !summaryIsInProgress &&
          selectedSourceAccountId &&
          currentRoute.includes('/content-gap-analysis') && <ContentGapAnalysis demoMode={demoMode} />}

        {/* Segmentation Analysis */}
        {displayAssessmentSummaries &&
          !summaryIsInProgress &&
          selectedSourceAccountId &&
          currentRoute.includes('/segmentation-analysis') && <SegmentationAnalysis demoMode={demoMode} />}
      </Styled.ContentContainer>
    </Styled.Container>
  );
};
export default MotiveMap;
