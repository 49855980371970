import * as d3 from 'd3';
import { useEffect, useRef } from 'react';

import { CircularProgressChartProps } from './types';

const DonutChart: React.FC<CircularProgressChartProps> = ({
  svgFrameDimension = 130,
  colors = {
    base: '#e0e0e0',
    progress: '#43a047',
  },
  data,
  numeratorText,
  denominatorText,
}) => {
  const svgRef = useRef(null);

  useEffect(() => {
    const svgProgress = d3.select(svgRef.current);
    svgProgress.selectAll('*').remove();

    const innerRadius = 0.36 * svgFrameDimension;
    const outerRadius = 0.45 * svgFrameDimension;

    const chart = svgProgress.append('g').attr('width', svgFrameDimension).attr('height', svgFrameDimension);

    const baseArc = d3
      .arc<any>()
      .innerRadius(innerRadius)
      .outerRadius(outerRadius)
      .startAngle(0)
      .endAngle(2 * Math.PI);

    const progressArc = d3
      .arc<any>()
      .innerRadius(innerRadius)
      .outerRadius(outerRadius)
      .startAngle(0)
      .endAngle((2 * Math.PI * data.numerator) / data.denominator)
      .cornerRadius(12);

    chart
      .append('path')
      .attr('d', baseArc)
      .attr('fill', colors.base)
      .attr('transform', `translate(${svgFrameDimension / 2},${svgFrameDimension / 2})`);

    chart
      .append('path')
      .attr('d', progressArc)
      .attr('fill', colors.progress)
      .attr('transform', `translate(${svgFrameDimension / 2},${svgFrameDimension / 2})`);

    if (numeratorText) {
      svgProgress
        .append('text')
        .attr('data-testid', 'numerator')
        .text(numeratorText)
        .attr('transform', `translate(${svgFrameDimension / 2},${svgFrameDimension / 2 - 0.04 * svgFrameDimension})`)
        .style('text-anchor', 'middle')
        .style('font-weight', 'bold')
        .style('font-size', (12 / 95) * svgFrameDimension);
    }

    if (denominatorText) {
      svgProgress
        .append('text')
        .attr('data-testid', 'denominator')
        .text(denominatorText)
        .attr('transform', `translate(${svgFrameDimension / 2},${svgFrameDimension / 2 + 0.12 * svgFrameDimension})`)
        .style('text-anchor', 'middle')
        .style('font-weight', '500')
        .style('color', 'rgba(33, 33, 52, 0.6)')
        .style('font-size', (10 / 95) * svgFrameDimension);
    }
  }, [svgFrameDimension, data, colors, numeratorText, denominatorText]);

  return <svg width={svgFrameDimension} height={svgFrameDimension} ref={svgRef} />;
};

export default DonutChart;
