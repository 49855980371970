/* eslint react/jsx-no-literals: "off", no-nested-ternary: "off" */

import { Box, FormControl, MenuItem, Table, TableBody, TableContainer } from '@mui/material';
import { KeyboardEvent, MouseEvent, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { TableHead } from '~/core/components/shared';
import { GetAssetRecommendationsQueryVariables } from '~/core/services/graphql/assets/assets.queries.generated';
import { useGetAssetRecommendations } from '~/core/services/graphql/assets/hooks';
import { useGetIntents } from '~/core/services/graphql/intents/hooks';
import { IntentsSortEnum, QueryGetIntentsArgs } from '~/core/types/graphql.types';
import { SortType } from '~/core/types/table.types';
import { useContexts } from '~/store/context/useContext';

import { CustomTablePagination } from '../../components';
import LoadingTable from '../LoadingTable';
import * as Styled from '../styles';
import { headerCells } from '../tableHeader.data';
import CollapsibleRow from './CollapisbleRow';

type IntentsTableProps = {
  coveredState?: string;
  setCoveredState?: (value: string) => void;
};

const IntentsTable = ({ coveredState, setCoveredState }: IntentsTableProps) => {
  const { selectedPlatformAccountId, selectedSourceAccountId, selectedCampaignId, selectedDateRange } = useContexts();
  const [searchParams, setSearchParams] = useSearchParams();
  const [orderBy, setOrderBy] = useState<IntentsSortEnum>(IntentsSortEnum.Impressions);
  const [desc, setDesc] = useState<boolean>(true);
  const [page, setPage] = useState<number>((searchParams.get('page') && Number(searchParams.get('intentsPage'))) || 0);
  const [pageSize, setPageSize] = useState<number>(
    (searchParams.get('pageSize') && Number(searchParams.get('pageSize'))) || 25,
  );
  const [inputValue, setInputValue] = useState<string>('');
  const [searchValue, setSearchValue] = useState<string>('');

  const intentsSkip = !selectedPlatformAccountId || !selectedSourceAccountId || !selectedDateRange;
  const intentsVariables: QueryGetIntentsArgs = {
    platformAccountId: selectedPlatformAccountId,
    customerIds: [selectedSourceAccountId],
    campaignIds: selectedCampaignId ? [selectedCampaignId] : null,
    dateRange: selectedDateRange,
    sortBy: {
      field: orderBy,
      desc,
    },
    searchValue,
    page: page + 1,
    pageSize,
    aligned: coveredState === 'covered' ? true : coveredState === 'uncovered' ? false : undefined,
  };

  const { intentsLoading, getIntents } = useGetIntents(intentsSkip, intentsVariables);

  const intentIds = getIntents?.edges?.map((intent) => intent?.id);

  const assetRecommendationsSkip =
    !selectedPlatformAccountId || !selectedSourceAccountId || !selectedDateRange || !intentIds?.length;
  const assetRecommendationsVariables: GetAssetRecommendationsQueryVariables = {
    platformAccountId: selectedPlatformAccountId,
    customerIds: [selectedSourceAccountId],
    dateRange: selectedDateRange,
    intentIds,
  };

  const { getAssetRecommendations } = useGetAssetRecommendations(
    assetRecommendationsSkip,
    assetRecommendationsVariables,
  );

  const intentWithRecommendationsIds = getAssetRecommendations?.map((recommendation) => recommendation?.intentId);

  const handleRequestSort = (event: MouseEvent<unknown>, property: any) => {
    const isAsc = property === orderBy ? !desc : true;
    setDesc(isAsc);
    setOrderBy(property);
    searchParams.set('orderBy', property);
    searchParams.set('desc', String(!isAsc));
    setSearchParams(searchParams);
  };

  const handleSearch = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      setSearchValue(inputValue);
    }
  };

  return intentsLoading ? (
    <>
      <Styled.SearchContainer sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Styled.SearchBarHeading>Consumer Search Intent</Styled.SearchBarHeading>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              height: '38px',
              alignItems: 'center',
            }}
          >
            <FormControl sx={{ m: 1, minWidth: 120 }}>
              <Styled.Select
                size="small"
                value={coveredState}
                displayEmpty
                onChange={(e) => {
                  setPage(0);
                  setCoveredState(e.target.value as string);
                }}
                MenuProps={{
                  PaperProps: {
                    style: {
                      marginTop: 4,
                      borderRadius: 8,
                      color: 'rgba(33, 33, 52, 0.6)',
                    },
                  },
                }}
              >
                <MenuItem sx={{ fontSize: '12px', color: 'rgba(33, 33, 52, 0.6)' }} value="all">
                  All search intent
                </MenuItem>
                <MenuItem sx={{ fontSize: '12px', color: 'rgba(33, 33, 52, 0.6)' }} value="covered">
                  Search intents with assets
                </MenuItem>
                <MenuItem sx={{ fontSize: '12px', color: 'rgba(33, 33, 52, 0.6)' }} value="uncovered">
                  Search intents without assets
                </MenuItem>
              </Styled.Select>
            </FormControl>
          </Box>
          <Styled.SearchBar
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            onKeyDown={handleSearch}
            disabled={intentsLoading}
            placeholder="Find within search intents"
            sx={{
              '.MuiInputBase-root': {
                minWidth: '300px',
                marginRight: '12px',
              },
            }}
          />
        </Box>
      </Styled.SearchContainer>
      <LoadingTable />
    </>
  ) : (
    <>
      <Styled.SearchContainer
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Styled.SearchBarHeading>Consumer Search Intent</Styled.SearchBarHeading>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              height: '38px',
              alignItems: 'center',
            }}
          >
            <FormControl sx={{ m: 1, minWidth: 120 }}>
              <Styled.Select
                size="small"
                value={coveredState}
                displayEmpty
                onChange={(e) => {
                  setPage(0);
                  setCoveredState(e.target.value as string);
                }}
                MenuProps={{
                  PaperProps: {
                    style: {
                      marginTop: 4,
                      borderRadius: 8,
                      color: 'rgba(33, 33, 52, 0.6)',
                    },
                  },
                }}
              >
                <MenuItem sx={{ fontSize: '12px' }} value="all">
                  All search intent
                </MenuItem>
                <MenuItem sx={{ fontSize: '12px' }} value="covered">
                  Search intents with assets
                </MenuItem>
                <MenuItem sx={{ fontSize: '12px' }} value="uncovered">
                  Search intents without assets
                </MenuItem>
              </Styled.Select>
            </FormControl>
          </Box>
          <Styled.SearchBar
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            onKeyDown={handleSearch}
            disabled={intentsLoading}
            placeholder="Find within search intents"
            sx={{
              '.MuiInputBase-root': {
                minWidth: '300px',
                marginRight: '12px',
              },
            }}
          />
        </Box>
      </Styled.SearchContainer>

      {getIntents?.edges?.length > 0 ? (
        <TableContainer>
          <Table stickyHeader aria-label="sticky table">
            <TableHead
              data-testid="tableHead"
              headCells={headerCells}
              order={desc ? SortType.Desc : SortType.Asc}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {getIntents.edges.map((row, index) => (
                <CollapsibleRow
                  key={row?.id}
                  row={row}
                  loading={false}
                  index={index}
                  clusterId={row?.id}
                  hasRecommendations={intentWithRecommendationsIds?.includes(row?.id)}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <TableContainer>
          <Table stickyHeader aria-label="sticky table">
            <TableHead
              data-testid="tableHead"
              headCells={headerCells}
              order={desc ? SortType.Desc : SortType.Asc}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              <Styled.TableCell
                sx={{
                  textAlign: 'center',
                  height: '150px',
                }}
                colSpan={5}
              >
                No Search Intents to Display
              </Styled.TableCell>
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          borderTop: '1px solid #E5E5E5',
        }}
      >
        <CustomTablePagination
          page={page}
          pageSize={pageSize}
          setPage={setPage}
          setPageSize={setPageSize}
          pageTotal={getIntents?.pageInfo?.total}
        />
      </Box>
    </>
  );
};

export default IntentsTable;
