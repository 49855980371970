import { styled, Card as MuiCard, Typography } from '@mui/material';

export const CardContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  background: '#f6f6f7',
  marginTop: '34px',
});

export const CancelContainer = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  width: '1200px',
  padding: '0',
  margin: '0',
});

export const Heading = styled(Typography)(({ theme }) => ({
  fontSize: '20px',
  fontWeight: 'bold',
  lineHeight: 1.6,
  letterSpacing: '0.15px',
  color: theme.palette.secondary.main,
}));

export const Card = styled(MuiCard)({
  width: '1200px',
  marginTop: '34px',
});
