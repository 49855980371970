import { Box, styled, Typography } from '@mui/material';

import { PageHeader as CustomPageHeader, SearchField as SearchFieldComponent } from '~/core/components/shared';

export const Container = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  position: 'relative',
});

export const PageHeader = styled(CustomPageHeader)({
  // height: '40px',
  padding: '0px',
  minHeight: '55px',
});

export const HeadingContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: 'fill-available',
});

export const Heading = styled(Typography)`
  height: 27px;
  font-family: Inter;
  font-size: 20px;
  font-weight: bold;
  line-height: 1.33;
  text-align: left;
`;

export const SubHeading = styled(Typography)`
  font-size: 12px;
  line-height: 1.67;
  letter-spacing: 0.4px;
  color: rgba(33, 33, 52, 0.6);
`;

export const ContentContainer = styled(Box)({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
});

export const SearchContainer = styled('div')({
  display: 'flex',
  margin: '13px 0 0 20px',
});

export const SearchField = styled(SearchFieldComponent)`
  width: 341px;
  height: 40px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  gap: 3px;
  padding: 0;
`;

export const LogoContainer = styled('div')`
  width: 22px;
  height: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(202, 213, 224, 0.45);
  border-radius: 25px;
`;

export const CampaignSelectorText = styled(Box)`
  font-size: 14px;
  line-height: 1.43;
  letter-spacing: 0.17px;
  color: rgba(33, 33, 52, 0.87);
  margin: 0 11px;
`;

export const ImageContainer = styled(Box)({
  height: '93.5vh',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignSelf: 'center',
});

export const Image = styled('img')({
  height: '228px',
  width: '228px',
  margin: '0 auto',
});

export const ImageHeading = styled(Typography)`
  font-size: 14px;
  font-weight: bold;
  line-height: 1.57;
  letter-spacing: 0.1px;
  text-align: center;
  color: rgba(33, 33, 52, 0.87);
`;

export const CampaignContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  position: 'relative',
});

export const FlexContainer = styled(Box)({
  display: 'flex',
  background: '#fff',
});

export const CenterContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
});
