import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Box, Slider } from '@mui/material';

import ErrorSVG from '~/assets/misc/error.svg';
import { QueryGetKeywordCoherencyKpisArgs } from '~/core/types/graphql.types';
import { useGetKeywordCoherencyKpis } from '~/core/services/graphql/keywords/hooks';
import { useContexts } from '~/store/context/useContext';
import BarChart from '~/core/components/shared/BarChart/BarChart';
import { CircularProgress } from '~/core/components/shared';
import { formatData } from '~/core/components/shared/BarChart/helpers';
import { MetricKey } from '~/core/components/shared/BarChart/types';

import { Image, ImageContainer, ImageHeading, SupportLink } from '../ContentGapAnalysis/styles';
import { AdGroupsTable } from '../ContentGapAnalysis/Tables/AdGroupsTable';
import * as Styled from './styles';

type SegmentationAnalysisProps = {
  demoMode: boolean;
};

export const SegmentationAnalysis: React.FC<SegmentationAnalysisProps> = () => {
  const { selectedPlatformAccountId, selectedSourceAccountId, selectedDateRange } = useContexts();

  console.log('selectedDate', selectedDateRange);
  const getKeywordCoherencyKpisSkip = !selectedPlatformAccountId || !selectedSourceAccountId || !selectedDateRange;
  const getKeywordCoherencyKpisVars: QueryGetKeywordCoherencyKpisArgs = {
    platformAccountId: selectedPlatformAccountId,
    customerIds: [selectedSourceAccountId],
    dateRange: selectedDateRange,
  };
  const { getKeywordCoherencyKpis, coherencyKpisLoading } = useGetKeywordCoherencyKpis(
    getKeywordCoherencyKpisSkip,
    getKeywordCoherencyKpisVars,
  );

  const [keys, setKeys] = useState<MetricKey[]>(['impressions', 'conversions', 'clicks', 'cost']);

  const coherencyValues = useMemo(() => {
    const valuesSet = new Set<number>();

    // Add values from getKeywordCoherencyKpis
    getKeywordCoherencyKpis?.forEach((item) => valuesSet.add(item.keywordCoherency));

    // Add values from 1 to 10
    for (let i = 1; i <= 10; i += 1) {
      valuesSet.add(i);
    }

    // Convert Set to Array and sort
    return Array.from(valuesSet).sort((a, b) => a - b);
  }, [getKeywordCoherencyKpis]);

  const minCoherency = coherencyValues[0];
  const maxCoherency = coherencyValues[coherencyValues.length - 1];

  const [range, setRange] = useState([minCoherency, maxCoherency]);

  const findClosestValue = (value: number) =>
    coherencyValues.reduce((prev, curr) => (Math.abs(curr - value) < Math.abs(prev - value) ? curr : prev));

  const handleSlider = (event: Event, newValue: number | number[]) => {
    if (Array.isArray(newValue)) {
      let [newMin, newMax] = newValue.map(findClosestValue);

      // Ensure newMin is always less than newMax
      if (newMin >= newMax) {
        const minIndex = coherencyValues.indexOf(newMin);
        const maxIndex = coherencyValues.indexOf(newMax);

        if (minIndex < maxIndex) {
          newMax = coherencyValues[minIndex + 1] || newMax;
        } else {
          newMin = coherencyValues[maxIndex - 1] || newMin;
        }
      }

      setRange([newMin, newMax]);
    }
  };
  const handleScroll = useCallback(
    (event: WheelEvent) => {
      event.preventDefault();
      const direction = event.deltaY < 0 ? -1 : 1;

      setRange((prevRange) => {
        const [prevMin, prevMax] = prevRange;
        const currentMinIndex = coherencyValues.indexOf(prevMin);
        const currentMaxIndex = coherencyValues.indexOf(prevMax);

        // Calculate new indices
        let newMinIndex = currentMinIndex;
        let newMaxIndex = currentMaxIndex;

        if (direction < 0) {
          // Scrolling up: decrease range
          newMinIndex = Math.min(currentMinIndex + 1, coherencyValues.length - 2);
          newMaxIndex = Math.max(newMinIndex + 1, currentMaxIndex);
        } else {
          // Scrolling down: increase range
          newMaxIndex = Math.max(currentMaxIndex - 1, 1);
          newMinIndex = Math.min(newMaxIndex - 1, currentMinIndex);
        }

        // Ensure there's always at least one value between min and max
        if (newMinIndex === newMaxIndex) {
          if (direction < 0) {
            newMaxIndex = Math.min(newMinIndex + 1, coherencyValues.length - 1);
          } else {
            newMinIndex = Math.max(newMaxIndex - 1, 0);
          }
        }

        return [coherencyValues[newMinIndex], coherencyValues[newMaxIndex]];
      });
    },
    [coherencyValues],
  );

  useEffect(() => {
    const divElement = document.querySelector('.your-div-class') as HTMLDivElement;
    if (divElement) {
      divElement.addEventListener('wheel', handleScroll, { passive: false });
    }
    return () => {
      if (divElement) {
        divElement.removeEventListener('wheel', handleScroll);
      }
    };
  }, [handleScroll]);

  const chartData: any[] = useMemo(() => {
    if (getKeywordCoherencyKpis && getKeywordCoherencyKpis.length > 0) {
      return formatData(getKeywordCoherencyKpis, keys, range);
    }
    return [];
  }, [getKeywordCoherencyKpis, keys, range]);

  useEffect(() => {
    setRange([minCoherency, maxCoherency]);
  }, [selectedSourceAccountId, selectedDateRange, minCoherency, maxCoherency]);

  console.log('getKeywordCoherencyKpis', getKeywordCoherencyKpis);
  console.log(coherencyKpisLoading);

  if (coherencyKpisLoading) {
    return <CircularProgress height="80vh" />;
  }

  if (
    (!coherencyKpisLoading && getKeywordCoherencyKpis?.length <= 0) ||
    (!getKeywordCoherencyKpis && !coherencyKpisLoading)
  ) {
    return (
      <ImageContainer>
        <Image src={ErrorSVG} alt="Could Not Load Data" />
        <ImageHeading sx={{ fontWeight: '400' }}>
          <Box>There currently isn&apos;t enough data in this ad account to complete the assessment</Box>
          <SupportLink
            href="https://motivemetrics.atlassian.net/servicedesk/customer/portal/1/group/1/create/6"
            target="_blank"
          >
            Contact Support
          </SupportLink>
        </ImageHeading>
      </ImageContainer>
    );
  }

  return (
    <div style={{ backgroundColor: '#fff' }}>
      <div
        style={{
          height: '350px',
          width: '50%',
          margin: '20px auto',
          paddingBottom: '90px',
        }}
      >
        {coherencyKpisLoading && <CircularProgress height={300} />}
        {getKeywordCoherencyKpis?.length > 0 ? (
          <div className="your-div-class" style={{ height: '100%', position: 'relative' }}>
            <BarChart data={chartData} keys={keys} setKeys={setKeys} />
            <Box
              sx={{
                position: 'absolute',
                bottom: '-53px',
                width: '96%',
                right: '0',
              }}
            >
              <Slider
                size="small"
                getAriaLabel={() => 'Coherency range'}
                value={range}
                onChange={handleSlider}
                valueLabelDisplay="auto"
                min={minCoherency}
                max={maxCoherency}
                step={1}
                slotProps={{
                  thumb: (ownerState) => ({
                    ...ownerState,
                    sx: {
                      ...ownerState.sx,
                      backgroundColor: '#3054AA',
                    },
                  }),
                }}
              />
            </Box>
          </div>
        ) : null}
      </div>

      <Styled.FlexContainer>
        <Styled.CenterContainer>
          <AdGroupsTable />
        </Styled.CenterContainer>
      </Styled.FlexContainer>
    </div>
  );
};

export default SegmentationAnalysis;
