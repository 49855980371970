import { Box, styled } from '@mui/material';

export const LogoContainer = styled(Box)`
  width: 22px;
  height: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(202, 213, 224, 0.45);
  border-radius: 25px;
`;
