import { Tabs } from '@mui/material';
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { useContexts } from '~/store/context/useContext';
import { authUserSelector } from '~/store/selectors/auth.selector';

import { PaidSearchIntegration, UserList } from '..';
import * as Styled from './styles';
import { TabLabelEnum } from './types';
import { ManageSubscription } from '../AdminSettings/ManageSubscription';

const AdminView = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const adminTab = searchParams.get('adminTab');
  const [value, setValue] = useState<number>(Number(adminTab) || 0);
  const { selectedPlatformAccount } = useContexts();
  const user = useSelector(authUserSelector);
  const isPlatformAdmin = user?.IsPlatformAdmin;
  const stripeCustomerId = selectedPlatformAccount?.stripeCustomerId;
  const showBillingTab = (isPlatformAdmin || user?.Email === selectedPlatformAccount?.contactEmail) && stripeCustomerId;

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    searchParams.set('adminTab', newValue.toString());
    setSearchParams(searchParams);
  };

  return (
    <Styled.Container data-testid="adminView">
      <Styled.Heading>Admin Settings</Styled.Heading>
      <Styled.TabsContainer>
        <Tabs value={value} onChange={handleChange} variant="fullWidth">
          {/*
            // * Will be adding this tab once BE updates are in place relating to billing info
            <Styled.Tab label={TabLabelEnum.CompanyAndBilling} id="full-width-tab-1" />
          */}
          <Styled.Tab label={TabLabelEnum.PaidSearchIntegration} id="full-width-tab-2" />
          <Styled.Tab label={TabLabelEnum.ManageUsers} id="full-width-tab-3" />
          {showBillingTab && <Styled.Tab label="Billing" id="full-width-tab-4" />}
        </Tabs>
      </Styled.TabsContainer>
      {value === 0 && <PaidSearchIntegration />}
      {value === 1 && <UserList />}
      {value === 2 && <ManageSubscription />}
    </Styled.Container>
  );
};

export default AdminView;
