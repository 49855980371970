import { createSelector } from 'reselect';

import { AppState } from '../reducers';

const accountStoreSelector = (state: AppState) => state.account;

export const accountLoadingSelector = createSelector(accountStoreSelector, (state) => state.isLoading);
export const accountErrorSelector = createSelector(accountStoreSelector, (state) => state.error);
export const accountSelector = createSelector(accountStoreSelector, (state) => state.account);
export const updatedAccountSelector = createSelector(accountStoreSelector, (state) => state.updatedAccount);
export const createdAccountSelector = createSelector(accountStoreSelector, (state) => state.createdAccountId);
export const allAccountsSelector = createSelector(accountStoreSelector, (state) => state.allAccounts);
export const allAccountsPaginationSelector = createSelector(
  accountStoreSelector,
  (state) => state.allAccountsPagination,
);
