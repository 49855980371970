import { Close } from '@mui/icons-material';
import { Box, IconButton, styled } from '@mui/material';

import { KeywordsPreview } from './components/KeywordsPreview';
import { PercentageDisplay } from './components/PercentageDisplay';
import { IntentThemesPreview } from './components/IntentThemesPreview';

const CustomContainer = styled(Box)({
  width: '100%',
  height: '100%',
  background: '#fff',
  border: '1px solid #E5E5E5',
  maxWidth: '',
  padding: '10px 12px',
});

const HeadingContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  alignSelf: 'center',
});

const Heading = styled('p')`
  height: 28px;
  flex-grow: 1;
  font-family: Inter;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.75;
  letter-spacing: 0.15px;
  text-align: left;
  color: rgba(33, 33, 52, 0.87);
  margin: 0;
`;

type TrafficSegmentPreviewProps = {
  onSegmentClick: (segmentId?: string) => void;
  segmentId: string;
  totalImpressions?: number;
  segmentImpressions?: number;
  totalIntentThemes?: number;
};

const TrafficSegmentPreview: React.FC<TrafficSegmentPreviewProps> = ({
  onSegmentClick,
  segmentId,
  totalImpressions,
  segmentImpressions,
  totalIntentThemes,
}) => (
  <CustomContainer>
    <Box>
      <HeadingContainer>
        <Heading>Traffic Segment</Heading>
        <IconButton onClick={() => onSegmentClick('close')}>
          <Close
            sx={{
              fontSize: 20,
            }}
          />
        </IconButton>
      </HeadingContainer>
      <PercentageDisplay totalImpressions={totalImpressions} segmentImpressions={segmentImpressions} />
      {segmentId !== 'Others' && (
        <>
          {/* Keywords Display */}
          <KeywordsPreview segmentId={segmentId} />

          {/* Bubble Chart Display */}
          <IntentThemesPreview segmentId={segmentId} totalIntentThemes={totalIntentThemes} />
        </>
      )}
    </Box>
  </CustomContainer>
);

export default TrafficSegmentPreview;
