import axios from 'axios';

import { setAccessTokenAction } from '~/store/actions/auth.action';
import { store } from '~/store/index';

import { refreshTokenRequest } from './auth.service';

// TODO: Need to add some mock envs.
const ApiClient = axios.create({
  baseURL:
    !process.env.NODE_ENV || process.env.NODE_ENV === 'development'
      ? `${process.env.REACT_APP_API_BASE_URL}/api/v1/`
      : `${window.location.protocol}//backend-${window.location.hostname}/api/v1/`,
  // For local development
  // baseURL: 'http://localhost:80/api/v1/',
});

ApiClient.interceptors.request.use(async (config) => {
  const { tokenExpiredTimestamp, AccessToken, RefreshToken } = store.getState().auth;
  const currentTime = new Date().getTime();

  if (tokenExpiredTimestamp && tokenExpiredTimestamp * 1000 < currentTime) {
    const { AccessToken, IdToken, User } = await refreshTokenRequest(RefreshToken);

    store.dispatch(setAccessTokenAction.request({ AccessToken, IdToken, User }));

    config.headers = {
      Authorization: `${AccessToken}`,
    };

    return config;
  }

  config.headers = {
    Authorization: `${AccessToken}`,
  };

  return config;
});

ApiClient.interceptors.response.use((response) => response);

export default ApiClient;
