import { Box, Container, CSSObject, Drawer as MuiDrawer, styled, Theme, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

const drawerWidth = 250;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(8)} + 1px)`,
});

export const ContainerBox = styled(Box)({
  display: 'flex',
  height: '100vh',
});

export const DrawerFooter = styled('div')<{ open: boolean }>(({ open }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: open ? 'flex-end' : 'center',
  height: '48px',
  borderTop: '1px solid rgba(0,0,0,.12)',
  transition: 'background-color .2s',
  '&:hover': {
    backgroundColor: 'rgba(0,0,0,.04)',
    cursor: 'pointer',
  },
}));

export const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  '& .root': {
    position: 'sticky',
  },
  '& .MuiPaper-root': {
    position: 'unset',
    paddingTop: '48px',
  },
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

export const SidebarItems = styled('div')({
  flex: 1,
});

export const MainContainer = styled(Container)({
  marginLeft: '500px',
});

export const AdminSidebarHeader = styled('div')({
  paddingLeft: 14,
});

export const BackToDashboardLink = styled(Link)({
  display: 'flex',
  alignItems: 'center',
  gap: '11px',
  color: '#3A3A4A',
  fontSize: 13,
  fontWeight: 600,
  padding: '12px 0',
  textDecoration: 'none',
});

export const SidebarTitle = styled(Typography)({
  padding: '8px 0',
  fontSize: 16,
  fontWeight: 600,
});

export const ContentContainer = styled(Box)({
  flexGrow: 1,
  padding: '48px 0',
  height: '100%',
  overflowX: 'auto',
  position: 'relative',
});
