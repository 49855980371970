export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** Date with time (isoformat) */
  DateTime: any;
  /** The `JSON` scalar type represents JSON values as specified by ECMA-404 */
  JSON: any;
  /** Represents NULL values */
  Void: any;
};

export enum AccessStatusEnum {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
  Pending = 'PENDING',
}

export type AccountDataSchema = {
  __typename?: 'AccountDataSchema';
  customerId: Scalars['String'];
  dateRange: Scalars['String'];
  impressions: Scalars['Int'];
  name: Scalars['String'];
  status: Scalars['String'];
};

export type AccountFilterInputSchema = {
  inviteStatus?: InputMaybe<AccessStatusEnum>;
};

export enum AccountStatusEnum {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
}

export enum AccountTypeEnum {
  Bing = 'BING',
  Google = 'GOOGLE',
}

export type AdGroupSchema = {
  __typename?: 'AdGroupSchema';
  campaignId: Scalars['String'];
  customerId: Scalars['String'];
  dateRange: Scalars['String'];
  endDate: Scalars['String'];
  id: Scalars['String'];
  name: Scalars['String'];
  startDate: Scalars['String'];
};

export enum AdGroupSortEnum {
  CampaignId = 'CAMPAIGN_ID',
  CustomerId = 'CUSTOMER_ID',
  DateRange = 'DATE_RANGE',
  EndDate = 'END_DATE',
  Id = 'ID',
  Name = 'NAME',
  StartDate = 'START_DATE',
}

export type AdGroupSortSchema = {
  desc?: InputMaybe<Scalars['Boolean']>;
  field: AdGroupSortEnum;
};

export type AdGroupSummarySchema = {
  __typename?: 'AdGroupSummarySchema';
  adGroupId: Scalars['String'];
  adGroupName: Scalars['String'];
  campaignId: Scalars['String'];
  customerId: Scalars['String'];
  dateRange: Scalars['String'];
  impressions: Scalars['Int'];
  intentThemes?: Maybe<Scalars['Int']>;
  keywordsWithTraffic: Scalars['Int'];
  totalEligibleImpressions: Scalars['Int'];
  totalKeywords: Scalars['Int'];
};

export type AdGroupSummarySchemaPaginationSchema = {
  __typename?: 'AdGroupSummarySchemaPaginationSchema';
  edges: Array<AdGroupSummarySchema>;
  pageInfo: PaginationPageInfoSchema;
};

export enum AdGroupSummarySortEnum {
  AdGroupId = 'AD_GROUP_ID',
  AdGroupName = 'AD_GROUP_NAME',
  CustomerId = 'CUSTOMER_ID',
  DateRange = 'DATE_RANGE',
  Impressions = 'IMPRESSIONS',
  KeywordsWithTraffic = 'KEYWORDS_WITH_TRAFFIC',
  TotalEligibleImpressions = 'TOTAL_ELIGIBLE_IMPRESSIONS',
  TotalKeywords = 'TOTAL_KEYWORDS',
}

export type AdGroupSummarySortSchema = {
  desc?: InputMaybe<Scalars['Boolean']>;
  field: AdGroupSummarySortEnum;
};

export type AgencyCustomerSchema = {
  __typename?: 'AgencyCustomerSchema';
  agencyId: Scalars['Int'];
  createdAt: Scalars['String'];
  customerId: Scalars['Int'];
  id: Scalars['Int'];
  inviteStatus: AccessStatusEnum;
  updatedAt: Scalars['String'];
};

export type AgencyCustomerUpdateStatusSchema = {
  __typename?: 'AgencyCustomerUpdateStatusSchema';
  acceptedOn?: Maybe<Scalars['DateTime']>;
  status: AccessStatusEnum;
};

export type AlignmentPercentageSchema = {
  __typename?: 'AlignmentPercentageSchema';
  assetCoveragePercentage?: Maybe<Scalars['Float']>;
  campaignId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  dateRange?: Maybe<Scalars['String']>;
  intentCoveragePercentage?: Maybe<Scalars['Float']>;
};

export type AssessmentProcessResponseSchema = {
  __typename?: 'AssessmentProcessResponseSchema';
  latestAvailable?: Maybe<Scalars['String']>;
  sourceAccountId: Scalars['String'];
  status: AssessmentProcessStatusEnum;
};

export enum AssessmentProcessStatusEnum {
  InProgress = 'IN_PROGRESS',
  Success = 'SUCCESS',
}

export type AssetRecommendationsSchema = {
  __typename?: 'AssetRecommendationsSchema';
  adGroupId?: Maybe<Scalars['String']>;
  assetText?: Maybe<Scalars['String']>;
  assetType?: Maybe<Scalars['String']>;
  bestExistingAssetText?: Maybe<Scalars['String']>;
  bestExistingScore?: Maybe<Scalars['Float']>;
  campaignId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  dateRange?: Maybe<Scalars['String']>;
  intentId?: Maybe<Scalars['String']>;
  intentName?: Maybe<Scalars['String']>;
  score?: Maybe<Scalars['Float']>;
};

export enum AssetRecommendationsSortEnum {
  AdGroupId = 'AD_GROUP_ID',
  AssetText = 'ASSET_TEXT',
  AssetType = 'ASSET_TYPE',
  BestExistingAssetText = 'BEST_EXISTING_ASSET_TEXT',
  BestExistingScore = 'BEST_EXISTING_SCORE',
  CampaignId = 'CAMPAIGN_ID',
  CustomerId = 'CUSTOMER_ID',
  DateRange = 'DATE_RANGE',
  IntentId = 'INTENT_ID',
  IntentName = 'INTENT_NAME',
  Score = 'SCORE',
}

export type AssetRecommendationsSortSchema = {
  desc?: InputMaybe<Scalars['Boolean']>;
  field: AssetRecommendationsSortEnum;
};

export type AssetSchema = {
  __typename?: 'AssetSchema';
  adGroupId?: Maybe<Scalars['String']>;
  adId?: Maybe<Scalars['String']>;
  adType?: Maybe<Scalars['String']>;
  campaignId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  dateRange?: Maybe<Scalars['String']>;
  enabled?: Maybe<Scalars['Boolean']>;
  endDate?: Maybe<Scalars['String']>;
  fieldType?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
};

export enum AssetSortEnum {
  AdGroupId = 'AD_GROUP_ID',
  AdId = 'AD_ID',
  AdType = 'AD_TYPE',
  CampaignId = 'CAMPAIGN_ID',
  CustomerId = 'CUSTOMER_ID',
  DateRange = 'DATE_RANGE',
  Enabled = 'ENABLED',
  EndDate = 'END_DATE',
  FieldType = 'FIELD_TYPE',
  Id = 'ID',
  StartDate = 'START_DATE',
  Text = 'TEXT',
}

export type AssetSortSchema = {
  desc?: InputMaybe<Scalars['Boolean']>;
  field: AssetSortEnum;
};

export type BaseBodyFilterSchema = {
  fieldName: Scalars['String'];
  logicOperator?: InputMaybe<Scalars['String']>;
  operation: Scalars['String'];
  value: Scalars['String'];
};

export type BaseFilterSchema = {
  filters: Array<BaseBodyFilterSchema>;
};

export type CampaignSchema = {
  __typename?: 'CampaignSchema';
  customerId?: Maybe<Scalars['String']>;
  dateRange?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  impressions?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

export enum CampaignSortEnum {
  AllConversions = 'ALL_CONVERSIONS',
  Budget = 'BUDGET',
  CampaignEndDate = 'CAMPAIGN_END_DATE',
  CampaignGroup = 'CAMPAIGN_GROUP',
  CampaignStartDate = 'CAMPAIGN_START_DATE',
  Clicks = 'CLICKS',
  Conversions = 'CONVERSIONS',
  CustomerId = 'CUSTOMER_ID',
  DateRange = 'DATE_RANGE',
  EndDate = 'END_DATE',
  ExperimentType = 'EXPERIMENT_TYPE',
  GeoTargetTypeSettingNegative = 'GEO_TARGET_TYPE_SETTING_NEGATIVE',
  GeoTargetTypeSettingPositive = 'GEO_TARGET_TYPE_SETTING_POSITIVE',
  Id = 'ID',
  Impressions = 'IMPRESSIONS',
  Interactions = 'INTERACTIONS',
  Name = 'NAME',
  OptimizationScore = 'OPTIMIZATION_SCORE',
  ResourceName = 'RESOURCE_NAME',
  StartDate = 'START_DATE',
  Status = 'STATUS',
}

export type CampaignSortSchema = {
  desc?: InputMaybe<Scalars['Boolean']>;
  field: CampaignSortEnum;
};

export enum CompanyTypeEnum {
  B2B = 'B2B',
  B2C = 'B2C',
  C2B = 'C2B',
  C2C = 'C2C',
}

export type ConnectedAccountsSchema = {
  __typename?: 'ConnectedAccountsSchema';
  acceptedOn?: Maybe<Scalars['DateTime']>;
  accountName: Scalars['String'];
  contactEmail: Scalars['String'];
  createdAt: Scalars['String'];
  id: Scalars['Int'];
  invitationId: Scalars['Int'];
  inviteStatus: AccessStatusEnum;
  invitedOn: Scalars['DateTime'];
  updatedAt: Scalars['String'];
};

export type ConnectedAccountsSchemaPaginationSchema = {
  __typename?: 'ConnectedAccountsSchemaPaginationSchema';
  edges: Array<ConnectedAccountsSchema>;
  pageInfo: PageInfoSchema;
};

export type ConnectedAccountsSortSchema = {
  desc?: InputMaybe<Scalars['Boolean']>;
  field: ConnectedAccountsSortableEnum;
};

export enum ConnectedAccountsSortableEnum {
  AcceptedOn = 'ACCEPTED_ON',
  ContactEmail = 'CONTACT_EMAIL',
  InvitedOn = 'INVITED_ON',
  InviteStatus = 'INVITE_STATUS',
  Name = 'NAME',
}

export enum CountryEnum {
  Abw = 'ABW',
  Afg = 'AFG',
  Ago = 'AGO',
  Aia = 'AIA',
  Ala = 'ALA',
  Alb = 'ALB',
  And = 'AND',
  Are = 'ARE',
  Arg = 'ARG',
  Arm = 'ARM',
  Asm = 'ASM',
  Ata = 'ATA',
  Atf = 'ATF',
  Atg = 'ATG',
  Aus = 'AUS',
  Aut = 'AUT',
  Aze = 'AZE',
  Bdi = 'BDI',
  Bel = 'BEL',
  Ben = 'BEN',
  Bes = 'BES',
  Bfa = 'BFA',
  Bgd = 'BGD',
  Bgr = 'BGR',
  Bhr = 'BHR',
  Bhs = 'BHS',
  Bih = 'BIH',
  Blm = 'BLM',
  Blr = 'BLR',
  Blz = 'BLZ',
  Bmu = 'BMU',
  Bol = 'BOL',
  Bra = 'BRA',
  Brb = 'BRB',
  Brn = 'BRN',
  Btn = 'BTN',
  Bvt = 'BVT',
  Bwa = 'BWA',
  Caf = 'CAF',
  Can = 'CAN',
  Cck = 'CCK',
  Che = 'CHE',
  Chl = 'CHL',
  Chn = 'CHN',
  Civ = 'CIV',
  Cmr = 'CMR',
  Cod = 'COD',
  Cog = 'COG',
  Cok = 'COK',
  Col = 'COL',
  Com = 'COM',
  Cpv = 'CPV',
  Cri = 'CRI',
  Cub = 'CUB',
  Cuw = 'CUW',
  Cxr = 'CXR',
  Cym = 'CYM',
  Cyp = 'CYP',
  Cze = 'CZE',
  Deu = 'DEU',
  Dji = 'DJI',
  Dma = 'DMA',
  Dnk = 'DNK',
  Dom = 'DOM',
  Dza = 'DZA',
  Ecu = 'ECU',
  Egy = 'EGY',
  Eri = 'ERI',
  Esh = 'ESH',
  Esp = 'ESP',
  Est = 'EST',
  Eth = 'ETH',
  Fin = 'FIN',
  Fji = 'FJI',
  Flk = 'FLK',
  Fra = 'FRA',
  Fro = 'FRO',
  Fsm = 'FSM',
  Gab = 'GAB',
  Gbr = 'GBR',
  Geo = 'GEO',
  Ggy = 'GGY',
  Gha = 'GHA',
  Gib = 'GIB',
  Gin = 'GIN',
  Glp = 'GLP',
  Gmb = 'GMB',
  Gnb = 'GNB',
  Gnq = 'GNQ',
  Grc = 'GRC',
  Grd = 'GRD',
  Grl = 'GRL',
  Gtm = 'GTM',
  Guf = 'GUF',
  Gum = 'GUM',
  Guy = 'GUY',
  Hkg = 'HKG',
  Hmd = 'HMD',
  Hnd = 'HND',
  Hrv = 'HRV',
  Hti = 'HTI',
  Hun = 'HUN',
  Idn = 'IDN',
  Imn = 'IMN',
  Ind = 'IND',
  Iot = 'IOT',
  Irl = 'IRL',
  Irn = 'IRN',
  Irq = 'IRQ',
  Isl = 'ISL',
  Isr = 'ISR',
  Ita = 'ITA',
  Jam = 'JAM',
  Jey = 'JEY',
  Jor = 'JOR',
  Jpn = 'JPN',
  Kaz = 'KAZ',
  Ken = 'KEN',
  Kgz = 'KGZ',
  Khm = 'KHM',
  Kir = 'KIR',
  Kna = 'KNA',
  Kor = 'KOR',
  Kwt = 'KWT',
  Lao = 'LAO',
  Lbn = 'LBN',
  Lbr = 'LBR',
  Lby = 'LBY',
  Lca = 'LCA',
  Lie = 'LIE',
  Lka = 'LKA',
  Lso = 'LSO',
  Ltu = 'LTU',
  Lux = 'LUX',
  Lva = 'LVA',
  Mac = 'MAC',
  Maf = 'MAF',
  Mar = 'MAR',
  Mco = 'MCO',
  Mda = 'MDA',
  Mdg = 'MDG',
  Mdv = 'MDV',
  Mex = 'MEX',
  Mhl = 'MHL',
  Mkd = 'MKD',
  Mli = 'MLI',
  Mlt = 'MLT',
  Mmr = 'MMR',
  Mne = 'MNE',
  Mng = 'MNG',
  Mnp = 'MNP',
  Moz = 'MOZ',
  Mrt = 'MRT',
  Msr = 'MSR',
  Mtq = 'MTQ',
  Mus = 'MUS',
  Mwi = 'MWI',
  Mys = 'MYS',
  Myt = 'MYT',
  Nam = 'NAM',
  Ncl = 'NCL',
  Ner = 'NER',
  Nfk = 'NFK',
  Nga = 'NGA',
  Nic = 'NIC',
  Niu = 'NIU',
  Nld = 'NLD',
  Nor = 'NOR',
  Npl = 'NPL',
  Nru = 'NRU',
  Nzl = 'NZL',
  Omn = 'OMN',
  Pak = 'PAK',
  Pan = 'PAN',
  Pcn = 'PCN',
  Per = 'PER',
  Phl = 'PHL',
  Plw = 'PLW',
  Png = 'PNG',
  Pol = 'POL',
  Pri = 'PRI',
  Prk = 'PRK',
  Prt = 'PRT',
  Pry = 'PRY',
  Pse = 'PSE',
  Pyf = 'PYF',
  Qat = 'QAT',
  Reu = 'REU',
  Rou = 'ROU',
  Rus = 'RUS',
  Rwa = 'RWA',
  Sau = 'SAU',
  Sdn = 'SDN',
  Sen = 'SEN',
  Sgp = 'SGP',
  Sgs = 'SGS',
  Shn = 'SHN',
  Sjm = 'SJM',
  Slb = 'SLB',
  Sle = 'SLE',
  Slv = 'SLV',
  Smr = 'SMR',
  Som = 'SOM',
  Spm = 'SPM',
  Srb = 'SRB',
  Ssd = 'SSD',
  Stp = 'STP',
  Sur = 'SUR',
  Svk = 'SVK',
  Svn = 'SVN',
  Swe = 'SWE',
  Swz = 'SWZ',
  Sxm = 'SXM',
  Syc = 'SYC',
  Syr = 'SYR',
  Tca = 'TCA',
  Tcd = 'TCD',
  Tgo = 'TGO',
  Tha = 'THA',
  Tjk = 'TJK',
  Tkl = 'TKL',
  Tkm = 'TKM',
  Tls = 'TLS',
  Ton = 'TON',
  Tto = 'TTO',
  Tun = 'TUN',
  Tur = 'TUR',
  Tuv = 'TUV',
  Twn = 'TWN',
  Tza = 'TZA',
  Uga = 'UGA',
  Ukr = 'UKR',
  Umi = 'UMI',
  Ury = 'URY',
  Usa = 'USA',
  Uzb = 'UZB',
  Vat = 'VAT',
  Vct = 'VCT',
  Ven = 'VEN',
  Vgb = 'VGB',
  Vir = 'VIR',
  Vnm = 'VNM',
  Vut = 'VUT',
  Wlf = 'WLF',
  Wsm = 'WSM',
  Yem = 'YEM',
  Zaf = 'ZAF',
  Zmb = 'ZMB',
  Zwe = 'ZWE',
}

export enum DateRangeEnum {
  LastMonth = 'LAST_MONTH',
}

export enum IndustryEnum {
  AutomobilesAndCompanies = 'AUTOMOBILES_AND_COMPANIES',
  Banks = 'BANKS',
  CapitalGoods = 'CAPITAL_GOODS',
  CommercialAndProfessionalServices = 'COMMERCIAL_AND_PROFESSIONAL_SERVICES',
  ConsumerDurablesAndApparel = 'CONSUMER_DURABLES_AND_APPAREL',
  ConsumerServices = 'CONSUMER_SERVICES',
  DiversifiedFinancials = 'DIVERSIFIED_FINANCIALS',
  Energy = 'ENERGY',
  FoodAndStaplesRetailing = 'FOOD_AND_STAPLES_RETAILING',
  FoodBeverageAndTobacco = 'FOOD_BEVERAGE_AND_TOBACCO',
  HealthCareEquipmentAndServices = 'HEALTH_CARE_EQUIPMENT_AND_SERVICES',
  HouseholdAndPersonalProducts = 'HOUSEHOLD_AND_PERSONAL_PRODUCTS',
  Insurance = 'INSURANCE',
  Materials = 'MATERIALS',
  MediaAndEntertainment = 'MEDIA_AND_ENTERTAINMENT',
  PharmaceuticalsBiotechnologyAndLifeSciences = 'PHARMACEUTICALS_BIOTECHNOLOGY_AND_LIFE_SCIENCES',
  RealEstate = 'REAL_ESTATE',
  Retailing = 'RETAILING',
  SemiconductorsAndSemiconductorEquipment = 'SEMICONDUCTORS_AND_SEMICONDUCTOR_EQUIPMENT',
  SoftwareAndServices = 'SOFTWARE_AND_SERVICES',
  TechnologyHardwareAndEquipment = 'TECHNOLOGY_HARDWARE_AND_EQUIPMENT',
  TelecommunicationServices = 'TELECOMMUNICATION_SERVICES',
  Transportation = 'TRANSPORTATION',
  Utilities = 'UTILITIES',
}

export type IntegrationAccountInputSchema = {
  access?: InputMaybe<Scalars['String']>;
  accountId?: InputMaybe<Scalars['String']>;
  accountType: AccountTypeEnum;
  conversionActions?: InputMaybe<Array<Scalars['String']>>;
  currencyCode?: InputMaybe<Scalars['String']>;
  customerId?: InputMaybe<Scalars['String']>;
  hidden?: InputMaybe<Scalars['Boolean']>;
  level?: InputMaybe<Scalars['String']>;
  manager?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  platformAccountId: Scalars['Int'];
  status?: InputMaybe<Scalars['String']>;
  timezone?: InputMaybe<Scalars['String']>;
};

export enum IntegrationAccountSortableEnum {
  Access = 'ACCESS',
  CustomerId = 'CUSTOMER_ID',
  Name = 'NAME',
  UpdatedAt = 'UPDATED_AT',
}

export type IntegrationAccountsSchema = {
  __typename?: 'IntegrationAccountsSchema';
  access?: Maybe<Scalars['String']>;
  accountId?: Maybe<Scalars['String']>;
  accountType: AccountTypeEnum;
  conversionActions?: Maybe<Array<Scalars['String']>>;
  createdAt: Scalars['String'];
  currencyCode?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  hidden?: Maybe<Scalars['Boolean']>;
  id: Scalars['Int'];
  level?: Maybe<Scalars['String']>;
  manager?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  platformAccountId: Scalars['Int'];
  status?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
  updatedAt: Scalars['String'];
};

export type IntegrationAccountsSchemaPaginationSchema = {
  __typename?: 'IntegrationAccountsSchemaPaginationSchema';
  edges: Array<IntegrationAccountsSchema>;
  pageInfo: PageInfoSchema;
};

export type IntegrationAccountsSortSchema = {
  desc?: InputMaybe<Scalars['Boolean']>;
  field: IntegrationAccountSortableEnum;
};

export type IntentThemesSchema = {
  __typename?: 'IntentThemesSchema';
  clicks: Scalars['String'];
  conversions: Scalars['String'];
  customerId: Scalars['String'];
  dateRange: Scalars['String'];
  impressions: Scalars['String'];
  intents: Scalars['String'];
  keywords: Scalars['String'];
  theme: Scalars['String'];
};

export type IntentThemesSchemaPaginationSchema = {
  __typename?: 'IntentThemesSchemaPaginationSchema';
  edges: Array<IntentThemesSchema>;
  pageInfo: PaginationPageInfoSchema;
};

export enum IntentThemesSortEnum {
  Clicks = 'CLICKS',
  Conversions = 'CONVERSIONS',
  Impressions = 'IMPRESSIONS',
  Intents = 'INTENTS',
  Keywords = 'KEYWORDS',
  Theme = 'THEME',
}

export type IntentThemesSortSchema = {
  desc?: InputMaybe<Scalars['Boolean']>;
  field: IntentThemesSortEnum;
};

export type IntentsAssetMapSchema = {
  __typename?: 'IntentsAssetMapSchema';
  adGroupId?: Maybe<Scalars['String']>;
  aligned?: Maybe<Scalars['Boolean']>;
  assetText?: Maybe<Scalars['String']>;
  assetType?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  dateRange?: Maybe<Scalars['String']>;
  intentId?: Maybe<Scalars['String']>;
};

export type IntentsAvailabilitySchema = {
  __typename?: 'IntentsAvailabilitySchema';
  adGroupId?: Maybe<Scalars['String']>;
  campaignId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  dateRange?: Maybe<Scalars['String']>;
  intentsAvailable?: Maybe<Scalars['Boolean']>;
};

export type IntentsSchema = {
  __typename?: 'IntentsSchema';
  adGroupId?: Maybe<Scalars['String']>;
  adGroupName?: Maybe<Scalars['String']>;
  aligned?: Maybe<Scalars['Boolean']>;
  alignedDescriptions?: Maybe<Scalars['Int']>;
  alignedHeadlines?: Maybe<Scalars['Int']>;
  campaignId?: Maybe<Scalars['String']>;
  campaignName?: Maybe<Scalars['String']>;
  closestKeywordMatchType?: Maybe<Scalars['String']>;
  closestKeywordText?: Maybe<Scalars['String']>;
  conversions?: Maybe<Scalars['Float']>;
  customerId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  impressions?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

export type IntentsSchemaPaginationSchema = {
  __typename?: 'IntentsSchemaPaginationSchema';
  edges: Array<IntentsSchema>;
  pageInfo: PaginationPageInfoSchema;
};

export enum IntentsSortEnum {
  AdGroupId = 'AD_GROUP_ID',
  AdId = 'AD_ID',
  AdType = 'AD_TYPE',
  Aligned = 'ALIGNED',
  AllConversions = 'ALL_CONVERSIONS',
  CampaignId = 'CAMPAIGN_ID',
  Clicks = 'CLICKS',
  Conversions = 'CONVERSIONS',
  Cost = 'COST',
  CustomerId = 'CUSTOMER_ID',
  DateRange = 'DATE_RANGE',
  Id = 'ID',
  Impressions = 'IMPRESSIONS',
  Interactions = 'INTERACTIONS',
  KeywordAdGroupCriterion = 'KEYWORD_AD_GROUP_CRITERION',
  KeywordMatchType = 'KEYWORD_MATCH_TYPE',
  KeywordText = 'KEYWORD_TEXT',
  MatchType = 'MATCH_TYPE',
  Name = 'NAME',
}

export type IntentsSortSchema = {
  desc?: InputMaybe<Scalars['Boolean']>;
  field: IntentsSortEnum;
};

export type InviteInputSchema = {
  email: Scalars['String'];
  isAdmin?: Scalars['Boolean'];
};

export type InviteSchema = {
  __typename?: 'InviteSchema';
  accountId: Scalars['Int'];
  createdAt: Scalars['String'];
  email: Scalars['String'];
  id: Scalars['Int'];
  inviteStatus: InviteStatusEnum;
  inviteType: InviteTypeEnum;
  updatedAt: Scalars['String'];
};

export type InviteSchemaPaginationSchema = {
  __typename?: 'InviteSchemaPaginationSchema';
  edges: Array<InviteSchema>;
  pageInfo: PageInfoSchema;
};

export type InviteSortSchema = {
  desc?: InputMaybe<Scalars['Boolean']>;
  field: InviteSortableEnum;
};

export enum InviteSortableEnum {
  AccountId = 'ACCOUNT_ID',
  Email = 'EMAIL',
  InviteStatus = 'INVITE_STATUS',
  InviteType = 'INVITE_TYPE',
}

export enum InviteStatusEnum {
  Done = 'DONE',
  Outdated = 'OUTDATED',
  Sent = 'SENT',
}

export enum InviteTypeEnum {
  Admin = 'ADMIN',
  PlatformAdmin = 'PLATFORM_ADMIN',
  User = 'USER',
}

export type KeywordCoherencyKpIsSchema = {
  __typename?: 'KeywordCoherencyKPIsSchema';
  campaignId?: Maybe<Scalars['String']>;
  clicks?: Maybe<Scalars['Int']>;
  conversions?: Maybe<Scalars['Int']>;
  cost?: Maybe<Scalars['Float']>;
  customerId?: Maybe<Scalars['String']>;
  dateRange?: Maybe<Scalars['String']>;
  impressions?: Maybe<Scalars['Int']>;
  keywordCoherency: Scalars['Int'];
};

export type KeywordSchema = {
  __typename?: 'KeywordSchema';
  ad_group_id?: Maybe<Scalars['String']>;
  all_conversions?: Maybe<Scalars['Float']>;
  auctions?: Maybe<Scalars['String']>;
  campaign_id?: Maybe<Scalars['String']>;
  clicks?: Maybe<Scalars['Int']>;
  conversions?: Maybe<Scalars['Float']>;
  cost?: Maybe<Scalars['Float']>;
  creative_quality_score?: Maybe<Scalars['String']>;
  criterion_resource_name?: Maybe<Scalars['String']>;
  customer_id?: Maybe<Scalars['String']>;
  date_range?: Maybe<Scalars['String']>;
  end_date?: Maybe<Scalars['String']>;
  impressions?: Maybe<Scalars['Int']>;
  interactions?: Maybe<Scalars['String']>;
  keyword_match_type?: Maybe<Scalars['String']>;
  keyword_text?: Maybe<Scalars['String']>;
  quality_score?: Maybe<Scalars['String']>;
  search_predicted_ctr?: Maybe<Scalars['String']>;
  start_date?: Maybe<Scalars['String']>;
};

export type KeywordSchemaPaginationSchema = {
  __typename?: 'KeywordSchemaPaginationSchema';
  edges: Array<KeywordSchema>;
  pageInfo: PaginationPageInfoSchema;
};

export enum KeywordSortEnum {
  AdGroupId = 'AD_GROUP_ID',
  AllConversions = 'ALL_CONVERSIONS',
  Auctions = 'AUCTIONS',
  CampaignId = 'CAMPAIGN_ID',
  Clicks = 'CLICKS',
  Conversions = 'CONVERSIONS',
  Cost = 'COST',
  CreativeQualityScore = 'CREATIVE_QUALITY_SCORE',
  CriterionResourceName = 'CRITERION_RESOURCE_NAME',
  CustomerId = 'CUSTOMER_ID',
  DateRange = 'DATE_RANGE',
  EndDate = 'END_DATE',
  Impressions = 'IMPRESSIONS',
  Interactions = 'INTERACTIONS',
  KeywordMatchType = 'KEYWORD_MATCH_TYPE',
  KeywordText = 'KEYWORD_TEXT',
  QualityScore = 'QUALITY_SCORE',
  SearchPredictedCtr = 'SEARCH_PREDICTED_CTR',
  StartDate = 'START_DATE',
}

export type KeywordSortSchema = {
  desc?: InputMaybe<Scalars['Boolean']>;
  field: KeywordSortEnum;
};

export type Mutations = {
  __typename?: 'Mutations';
  /**
   *
   *         Creates platform account.
   *         Permissions: PlatformAdmin.
   *
   */
  createAccount: PlatformAccountSchema;
  /** Trigger lambda function to start assessment process */
  createAssessment: Scalars['Boolean'];
  /**
   *
   *         Creates Integration account in the database.
   *         Permissions: PlatformAdmin.
   *
   */
  createIntegrationAccount: IntegrationAccountsSchema;
  /**
   *
   *         Create UserAccess.
   *         Permissions: PlatformAdmin.
   *
   */
  createUserAccess: UserAccessSchema;
  /**
   *
   *         Delete Platform account by accountId.
   *         Permissions: PlatformAdmin.
   *
   */
  deleteAccount?: Maybe<Scalars['Void']>;
  /**
   *
   *         Deletes Customer - Agency relation.
   *         Permissions: PlatformAdmin.
   *
   */
  deleteAgencyCustomer?: Maybe<Scalars['Void']>;
  /**
   *
   *         Deletes Integration account with id=integrationAccountId.
   *         Permissions: PlatformAdmin.
   *
   */
  deleteIntegrationAccount?: Maybe<Scalars['Void']>;
  /**
   *
   *         Delete user by userId.
   *         Permissions: PlatformAdmin.
   *
   */
  deleteUser?: Maybe<Scalars['Void']>;
  /**
   *
   *         Delete UserAccess by userAccessId.
   *         Permissions: PlatformAdmin.
   *
   */
  deleteUserAccess?: Maybe<Scalars['Void']>;
  /**
   *
   *         Creates Customer - Agency(es) invitation(s) with status PENDING. Sends emails to all Agency(es) admins.
   *         Permissions: (PlatformAdmin | CustomerAdmin) & OnlyForPaidAccounts.
   *
   */
  inviteAgencyCustomer: Array<AgencyCustomerSchema>;
  /**
   *
   *         Updates an invite with id=inviteId status. If user want to accept the invite set is_accepted to true,
   *         else set to false.
   *         Permissions: CurrentUser.
   *
   */
  replyToInvite: InviteSchema;
  /**
   *
   *         Revokes invitation to Agency. The record is assigned the INACTIVE status.
   *         Permissions: (PlatformAdmin | CustomerAdmin) & OnlyForPaidAccounts.
   *
   */
  revokeAgencyInvitation: AgencyCustomerUpdateStatusSchema;
  /**
   *
   *         Invites the user by email to the platform account with id=accountId. If a user with such an email is not
   *         registered on the MotiveMetrics platform will send the email with an link to register.
   *         Permissions: all admins.
   *
   */
  sendInviteLink: InviteSchema;
  /**
   *
   *         Updates platform account information. Customer or Agency admins can only change their accounts.
   *         PlatformAdmin can update any platform account.
   *         Permissions: all types of admins
   *
   */
  updateAccount: PlatformAccountSchema;
  /**
   *
   *         Updates invitation status. Set ACTIVE if need to accept the invite or INACTIVE if need to reject.
   *         Permissions: PlatformAdmin | AgencyAdmin.
   *
   */
  updateCustomerInvitation: AgencyCustomerUpdateStatusSchema;
  /**
   *
   *         Updates Integration account data with id=integrationAccountId.
   *         Permissions: PlatformAdmin.
   *
   */
  updateIntegrationAccount: IntegrationAccountsSchema;
  /**
   *
   *         Update User first and/or last name, email, password by himself only.
   *         Permissions: CurrentUser
   *
   */
  updateUser: UserSchema;
  /**
   *
   *         Update isAdmin user role and/or update status (set ACTIVE | INACTIVE).
   *         Permissions: all admins.
   *
   */
  updateUserAccess: PlatformAccountUsersSchema;
};

export type MutationsCreateAccountArgs = {
  accountData: PlatformAccountInputSchema;
};

export type MutationsCreateAssessmentArgs = {
  dateRange: DateRangeEnum;
  platformAccountId: Scalars['Int'];
  sourceAccountIds: Array<Scalars['String']>;
};

export type MutationsCreateIntegrationAccountArgs = {
  integrationAccountData: IntegrationAccountInputSchema;
};

export type MutationsCreateUserAccessArgs = {
  userAccessData: UserAccessInputSchema;
};

export type MutationsDeleteAccountArgs = {
  accountId: Scalars['Int'];
};

export type MutationsDeleteAgencyCustomerArgs = {
  agencyCustomerId: Scalars['Int'];
};

export type MutationsDeleteIntegrationAccountArgs = {
  integrationAccountId: Scalars['Int'];
};

export type MutationsDeleteUserArgs = {
  userId: Scalars['Int'];
};

export type MutationsDeleteUserAccessArgs = {
  userAccessId: Scalars['Int'];
};

export type MutationsInviteAgencyCustomerArgs = {
  accountId: Scalars['Int'];
  agencyIds: Array<Scalars['Int']>;
};

export type MutationsReplyToInviteArgs = {
  inviteId: Scalars['Int'];
  isAccepted: Scalars['Boolean'];
  userId: Scalars['Int'];
};

export type MutationsRevokeAgencyInvitationArgs = {
  accountId: Scalars['Int'];
  invitationId: Scalars['Int'];
};

export type MutationsSendInviteLinkArgs = {
  accountId: Scalars['Int'];
  userData: InviteInputSchema;
};

export type MutationsUpdateAccountArgs = {
  accountData: PlatformAccountUpdateSchema;
  accountId: Scalars['Int'];
};

export type MutationsUpdateCustomerInvitationArgs = {
  accountId: Scalars['Int'];
  invitationId: Scalars['Int'];
  updateStatus: AccessStatusEnum;
};

export type MutationsUpdateIntegrationAccountArgs = {
  integrationAccountData: IntegrationAccountInputSchema;
  integrationAccountId: Scalars['Int'];
};

export type MutationsUpdateUserArgs = {
  userData: UserUpdateInputSchema;
  userId: Scalars['Int'];
};

export type MutationsUpdateUserAccessArgs = {
  accountId: Scalars['Int'];
  dataToEdit: UserAccessUpdateInputSchema;
  userAccessId: Scalars['Int'];
};

export type PageInfoSchema = {
  __typename?: 'PageInfoSchema';
  nextPage?: Maybe<Scalars['Int']>;
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  pages: Scalars['Int'];
  previousPage?: Maybe<Scalars['Int']>;
  total: Scalars['Int'];
};

export type PaginationPageInfoSchema = {
  __typename?: 'PaginationPageInfoSchema';
  nextPage?: Maybe<Scalars['Int']>;
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  pages: Scalars['Int'];
  previousPage?: Maybe<Scalars['Int']>;
  total: Scalars['Int'];
};

export type PlatformAccountAccessSchema = {
  __typename?: 'PlatformAccountAccessSchema';
  accountType: PlatformAccountTypeEnum;
  id: Scalars['Int'];
  isFree: Scalars['Boolean'];
  name: Scalars['String'];
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
};

export type PlatformAccountInputSchema = {
  address_1?: InputMaybe<Scalars['String']>;
  address_2?: InputMaybe<Scalars['String']>;
  authId?: InputMaybe<Scalars['String']>;
  cap?: InputMaybe<Scalars['Int']>;
  city?: InputMaybe<Scalars['String']>;
  clientId?: InputMaybe<Scalars['String']>;
  clientSecret?: InputMaybe<Scalars['String']>;
  companyType?: InputMaybe<PlatformAccountTypeEnum>;
  contactEmail?: InputMaybe<Scalars['String']>;
  contactFirstName?: InputMaybe<Scalars['String']>;
  contactLastName?: InputMaybe<Scalars['String']>;
  contactPhone?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<CountryEnum>;
  editableCompanyType?: InputMaybe<CompanyTypeEnum>;
  faxNumber?: InputMaybe<Scalars['String']>;
  industry?: InputMaybe<IndustryEnum>;
  integrationEmail?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  phoneNumber?: InputMaybe<Scalars['String']>;
  redirectUri?: InputMaybe<Scalars['String']>;
  refreshToken?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<StateEnum>;
  status?: InputMaybe<AccountStatusEnum>;
  stripeCustomerId?: InputMaybe<Scalars['String']>;
  stripeSubscriptionId?: InputMaybe<Scalars['String']>;
  website?: InputMaybe<Scalars['String']>;
  zipCode?: InputMaybe<Scalars['String']>;
};

export type PlatformAccountNamesSchema = {
  __typename?: 'PlatformAccountNamesSchema';
  accountType: PlatformAccountTypeEnum;
  id: Scalars['Int'];
  name: Scalars['String'];
};

export type PlatformAccountSchema = {
  __typename?: 'PlatformAccountSchema';
  address_1?: Maybe<Scalars['String']>;
  address_2?: Maybe<Scalars['String']>;
  authId?: Maybe<Scalars['String']>;
  cap?: Maybe<Scalars['Int']>;
  city?: Maybe<Scalars['String']>;
  clientId?: Maybe<Scalars['String']>;
  clientSecret?: Maybe<Scalars['String']>;
  companyType?: Maybe<PlatformAccountTypeEnum>;
  contactEmail?: Maybe<Scalars['String']>;
  contactFirstName?: Maybe<Scalars['String']>;
  contactLastName?: Maybe<Scalars['String']>;
  contactPhone?: Maybe<Scalars['String']>;
  country?: Maybe<CountryEnum>;
  createdAt: Scalars['String'];
  editableCompanyType?: Maybe<CompanyTypeEnum>;
  faxNumber?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  industry?: Maybe<IndustryEnum>;
  integrationEmail?: Maybe<Scalars['String']>;
  isFree: Scalars['Boolean'];
  name: Scalars['String'];
  phoneNumber?: Maybe<Scalars['String']>;
  redirectUri?: Maybe<Scalars['String']>;
  refreshToken?: Maybe<Scalars['String']>;
  state?: Maybe<StateEnum>;
  status?: Maybe<AccountStatusEnum>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  updatedAt: Scalars['String'];
  website?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
};

export type PlatformAccountSortSchema = {
  desc?: InputMaybe<Scalars['Boolean']>;
  field: PlatformAccountSortableEnum;
};

export enum PlatformAccountSortableEnum {
  Cap = 'CAP',
  City = 'CITY',
  CompanyType = 'COMPANY_TYPE',
  ContactEmail = 'CONTACT_EMAIL',
  ContactName = 'CONTACT_NAME',
  ContactPhone = 'CONTACT_PHONE',
  Country = 'COUNTRY',
  Fax = 'FAX',
  Industry = 'INDUSTRY',
  Name = 'NAME',
  Phone = 'PHONE',
  WebsiteUrl = 'WEBSITE_URL',
}

export enum PlatformAccountTypeEnum {
  Agency = 'AGENCY',
  Customer = 'CUSTOMER',
}

export type PlatformAccountUpdateSchema = {
  address_1?: InputMaybe<Scalars['String']>;
  address_2?: InputMaybe<Scalars['String']>;
  cap?: InputMaybe<Scalars['Int']>;
  city?: InputMaybe<Scalars['String']>;
  companyType?: InputMaybe<PlatformAccountTypeEnum>;
  contactEmail?: InputMaybe<Scalars['String']>;
  contactFirstName?: InputMaybe<Scalars['String']>;
  contactLastName?: InputMaybe<Scalars['String']>;
  contactPhone?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<CountryEnum>;
  editableCompanyType?: InputMaybe<CompanyTypeEnum>;
  faxNumber?: InputMaybe<Scalars['String']>;
  industry?: InputMaybe<IndustryEnum>;
  name?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<StateEnum>;
  status?: InputMaybe<AccountStatusEnum>;
  stripeCustomerId?: InputMaybe<Scalars['String']>;
  stripeSubscriptionId?: InputMaybe<Scalars['String']>;
  website?: InputMaybe<Scalars['String']>;
  zipCode?: InputMaybe<Scalars['String']>;
};

export type PlatformAccountUserSortSchema = {
  desc?: InputMaybe<Scalars['Boolean']>;
  field: PlatformAccountUsersSortableEnum;
};

export type PlatformAccountUsersFilterSchema = {
  isAdmin?: InputMaybe<Scalars['Boolean']>;
  status?: InputMaybe<AccessStatusEnum>;
};

export type PlatformAccountUsersSchema = {
  __typename?: 'PlatformAccountUsersSchema';
  accountId: Scalars['Int'];
  email: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  isAdmin: Scalars['Boolean'];
  lastName?: Maybe<Scalars['String']>;
  lastSignOn?: Maybe<Scalars['DateTime']>;
  status: AccessStatusEnum;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  userAccessId?: Maybe<Scalars['Int']>;
  userId?: Maybe<Scalars['Int']>;
  userInviteId?: Maybe<Scalars['Int']>;
};

export type PlatformAccountUsersSchemaPaginationSchema = {
  __typename?: 'PlatformAccountUsersSchemaPaginationSchema';
  edges: Array<PlatformAccountUsersSchema>;
  pageInfo: PageInfoSchema;
};

export enum PlatformAccountUsersSortableEnum {
  AccountStatus = 'ACCOUNT_STATUS',
  Email = 'EMAIL',
  FirstName = 'FIRST_NAME',
  LastName = 'LAST_NAME',
  LastSignOn = 'LAST_SIGN_ON',
  Name = 'NAME',
  Role = 'ROLE',
}

export type PlatformAccountWithAdminSchema = {
  __typename?: 'PlatformAccountWithAdminSchema';
  address_1?: Maybe<Scalars['String']>;
  address_2?: Maybe<Scalars['String']>;
  admin?: Maybe<Array<UserSchema>>;
  authId?: Maybe<Scalars['String']>;
  cap?: Maybe<Scalars['Int']>;
  city?: Maybe<Scalars['String']>;
  clientId?: Maybe<Scalars['String']>;
  clientSecret?: Maybe<Scalars['String']>;
  companyType?: Maybe<PlatformAccountTypeEnum>;
  contactEmail?: Maybe<Scalars['String']>;
  contactFirstName?: Maybe<Scalars['String']>;
  contactLastName?: Maybe<Scalars['String']>;
  contactPhone?: Maybe<Scalars['String']>;
  country?: Maybe<CountryEnum>;
  createdAt: Scalars['String'];
  editableCompanyType?: Maybe<CompanyTypeEnum>;
  faxNumber?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  industry?: Maybe<IndustryEnum>;
  integrationEmail?: Maybe<Scalars['String']>;
  isFree: Scalars['Boolean'];
  name: Scalars['String'];
  phoneNumber?: Maybe<Scalars['String']>;
  redirectUri?: Maybe<Scalars['String']>;
  refreshToken?: Maybe<Scalars['String']>;
  state?: Maybe<StateEnum>;
  status?: Maybe<AccountStatusEnum>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  updatedAt: Scalars['String'];
  website?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
};

export type PlatformAccountWithAdminSchemaPaginationSchema = {
  __typename?: 'PlatformAccountWithAdminSchemaPaginationSchema';
  edges: Array<PlatformAccountWithAdminSchema>;
  pageInfo: PageInfoSchema;
};

export type PlatformAccountsFilterSchema = {
  accountType?: InputMaybe<PlatformAccountTypeEnum>;
  status?: InputMaybe<AccountStatusEnum>;
};

export type PlatformUserAccountsSchema = {
  __typename?: 'PlatformUserAccountsSchema';
  accountType: PlatformAccountTypeEnum;
  contactEmail: Scalars['String'];
  customerAccountAccess: Array<PlatformAccountAccessSchema>;
  id: Scalars['Int'];
  isAdmin: Scalars['Boolean'];
  isFree: Scalars['Boolean'];
  name: Scalars['String'];
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
};

export type Query = {
  __typename?: 'Query';
  /**
   *
   *         Returns platform account by accountId.
   *         Permissions: all types of users.
   *
   */
  account: PlatformAccountSchema;
  /**
   *
   *         Returns list of all Platform Accounts in the system with only common information like name, account_type, id.
   *         Optional: takes the type of the returned account accountsType [CUSTOMER or AGENCY] and returns all accounts of
   *         selected type.
   *         Permissions: PaidCustomerAdmin | PlatformAdmin.
   *
   */
  accountNames: Array<PlatformAccountNamesSchema>;
  /**
   *
   *         Returns PENDING, ACTIVE and INACTIVE users from platform account with all information about user,
   *         account, user-access(or user-invite).
   *         Permissions: all types of admins.
   *
   */
  accountUsers: PlatformAccountUsersSchemaPaginationSchema;
  /**
   *
   *         Returns list of all Platform Accounts in the system with all information.
   *         Permissions: PlatformAdmin.
   *
   */
  accounts: PlatformAccountWithAdminSchemaPaginationSchema;
  /**
   *
   *         Returns list of connected accounts to the account with id=accountId.
   *         Permissions: all admins & OnlyForPaidAccounts.
   *
   */
  connectedAccounts: ConnectedAccountsSchemaPaginationSchema;
  /** Returns specified ad group summaries from Athena. */
  getAdGroupSummaries: AdGroupSummarySchemaPaginationSchema;
  /** Returns specified ad groups from Athena. */
  getAdGroups: Array<AdGroupSchema>;
  /** Returns specified alignment percentages from Athena. */
  getAlignmentPercentages: AlignmentPercentageSchema;
  /** Returns status of assessment process */
  getAssessmentLatest: Array<AssessmentProcessResponseSchema>;
  /** Returns specified asset recommendations based on intent id from Athena. */
  getAssetRecommendations: Array<AssetRecommendationsSchema>;
  /** Returns specified assets from Athena. */
  getAssets: Array<AssetSchema>;
  /** Returns specified campaigns from Athena. */
  getCampaigns: Array<CampaignSchema>;
  /** Returns specified themes from Athena. */
  getIntentThemes: IntentThemesSchemaPaginationSchema;
  /** Returns specified intents from Athena. */
  getIntents: IntentsSchemaPaginationSchema;
  /** Returns specified intents from Athena. */
  getIntentsAssetMap: Array<IntentsAssetMapSchema>;
  /** Returns specified alignment percentages from Athena. */
  getIntentsAvailability: Array<IntentsAvailabilitySchema>;
  /** Returns specified ad group summaries from Athena. */
  getKeywordCoherencyKpis: Array<KeywordCoherencyKpIsSchema>;
  /** Returns specified keywords from Athena. */
  getKeywords: KeywordSchemaPaginationSchema;
  /** Returns specified customer accounts from Athena. */
  getSourceAccountInfo: Array<AccountDataSchema>;
  /** Returns traffic segments from Athena. */
  getTrafficSegments: Array<TrafficSegmentSchema>;
  /**
   *
   *         Returns Integration account by id.
   *         Permissions: all admins.
   *
   */
  integrationAccount: IntegrationAccountsSchema;
  /**
   *
   *         Returns list of all Integration accounts. Can be filtered by platform account.
   *         Permissions: all admins, customerUsers.
   *
   */
  integrationAccounts: Array<IntegrationAccountsSchema>;
  /**
   *
   *         Returns list of invites to the platform account with id=accountId.
   *         Permissions: PlatformAdmin.
   *
   */
  invites: InviteSchemaPaginationSchema;
  /**
   *
   *         Returns list of Agencies for Customer with id=accountId that can be invited.
   *         Permissions: (PlatformAdmin | CustomerAdmin) & OnlyForPaidAccounts.
   *
   */
  listAgenciesToInvite: Array<PlatformAccountSchema>;
  /**
   *
   *         Returns list of own Integration accounts.
   *         Permissions: all admins, customerUsers.
   *
   */
  ownIntegrationAccounts: IntegrationAccountsSchemaPaginationSchema;
  /**
   *
   *         Returns the dict with id keys and account name values.
   *         Permissions: IsInnerService.
   *
   */
  ownerAccountNames: Array<PlatformAccountSchema>;
  /**
   *
   *         Returns the dict with username keys and user email values.
   *         Permissions: IsInnerService.
   *
   */
  ownerUserEmails: Array<UserSchema>;
  /**
   *
   *         Retrieve User by id or username.
   *         Permissions: PlatformAdmin | CurrentUser
   *
   */
  user: UserSchema;
  /**
   *
   *         Returns a list of account names and their types for the user, in which the user is in any status.
   *         Permissions: any type of user.
   *
   */
  userAccounts: Array<PlatformUserAccountsSchema>;
};

export type QueryAccountArgs = {
  accountId: Scalars['Int'];
};

export type QueryAccountNamesArgs = {
  accountsType?: InputMaybe<PlatformAccountTypeEnum>;
};

export type QueryAccountUsersArgs = {
  accountId: Scalars['Int'];
  filterSchema?: InputMaybe<PlatformAccountUsersFilterSchema>;
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  searchValue?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<PlatformAccountUserSortSchema>;
};

export type QueryAccountsArgs = {
  filterSchema?: InputMaybe<PlatformAccountsFilterSchema>;
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  searchValue?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<PlatformAccountSortSchema>;
};

export type QueryConnectedAccountsArgs = {
  accountId: Scalars['Int'];
  accountsType: PlatformAccountTypeEnum;
  filterSchema?: InputMaybe<AccountFilterInputSchema>;
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  searchValue?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<ConnectedAccountsSortSchema>;
};

export type QueryGetAdGroupSummariesArgs = {
  customerIds: Array<Scalars['String']>;
  dateRange: Scalars['String'];
  filterField?: InputMaybe<BaseFilterSchema>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  platformAccountId: Scalars['Int'];
  searchValue?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<AdGroupSummarySortSchema>;
};

export type QueryGetAdGroupsArgs = {
  adGroupIds?: InputMaybe<Array<Scalars['String']>>;
  campaignIds?: InputMaybe<Array<Scalars['String']>>;
  customerIds: Array<Scalars['String']>;
  dateRange: Scalars['String'];
  filterField?: InputMaybe<BaseFilterSchema>;
  platformAccountId: Scalars['Int'];
  searchValue?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<AdGroupSortSchema>;
};

export type QueryGetAlignmentPercentagesArgs = {
  campaignId?: InputMaybe<Scalars['String']>;
  customerId: Scalars['String'];
  dateRange: Scalars['String'];
  filterField?: InputMaybe<BaseFilterSchema>;
  platformAccountId: Scalars['Int'];
};

export type QueryGetAssessmentLatestArgs = {
  sourceAccountIds: Array<Scalars['String']>;
};

export type QueryGetAssetRecommendationsArgs = {
  customerIds: Array<Scalars['String']>;
  dateRange: Scalars['String'];
  filterField?: InputMaybe<BaseFilterSchema>;
  intentIds?: InputMaybe<Array<Scalars['String']>>;
  platformAccountId: Scalars['Int'];
  searchValue?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<AssetRecommendationsSortSchema>;
};

export type QueryGetAssetsArgs = {
  adGroupIds?: InputMaybe<Array<Scalars['String']>>;
  adIds?: InputMaybe<Array<Scalars['String']>>;
  adStatus?: InputMaybe<Scalars['String']>;
  assetIds?: InputMaybe<Array<Scalars['String']>>;
  assetText?: InputMaybe<Scalars['String']>;
  campaignIds?: InputMaybe<Array<Scalars['String']>>;
  customerIds: Array<Scalars['String']>;
  dateRange: Scalars['String'];
  filterField?: InputMaybe<BaseFilterSchema>;
  platformAccountId: Scalars['Int'];
  searchValue?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<AssetSortSchema>;
};

export type QueryGetCampaignsArgs = {
  campaignIds?: InputMaybe<Array<Scalars['String']>>;
  customerIds: Array<Scalars['String']>;
  dateRange: Scalars['String'];
  filterField?: InputMaybe<BaseFilterSchema>;
  platformAccountId: Scalars['Int'];
  searchValue?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<CampaignSortSchema>;
};

export type QueryGetIntentThemesArgs = {
  customerId: Scalars['String'];
  dateRange: Scalars['String'];
  filterField?: InputMaybe<BaseFilterSchema>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  platformAccountId: Scalars['Int'];
  searchValue?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<IntentThemesSortSchema>;
};

export type QueryGetIntentsArgs = {
  adGroupIds?: InputMaybe<Array<Scalars['String']>>;
  aligned?: InputMaybe<Scalars['Boolean']>;
  campaignIds?: InputMaybe<Array<Scalars['String']>>;
  customerIds: Array<Scalars['String']>;
  dateRange: Scalars['String'];
  filterField?: InputMaybe<BaseFilterSchema>;
  intentIds?: InputMaybe<Array<Scalars['String']>>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  platformAccountId: Scalars['Int'];
  searchValue?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<IntentsSortSchema>;
};

export type QueryGetIntentsAssetMapArgs = {
  adGroupId?: InputMaybe<Scalars['String']>;
  aligned?: InputMaybe<Scalars['Boolean']>;
  customerId?: InputMaybe<Scalars['String']>;
  dateRange: Scalars['String'];
  filterField?: InputMaybe<BaseFilterSchema>;
  intentId?: InputMaybe<Scalars['String']>;
  platformAccountId: Scalars['Int'];
};

export type QueryGetIntentsAvailabilityArgs = {
  adGroupIds?: InputMaybe<Array<Scalars['String']>>;
  campaignIds?: InputMaybe<Array<Scalars['String']>>;
  customerIds: Array<Scalars['String']>;
  dateRange?: InputMaybe<Scalars['String']>;
  platformAccountId: Scalars['Int'];
};

export type QueryGetKeywordCoherencyKpisArgs = {
  customerIds: Array<Scalars['String']>;
  dateRange: Scalars['String'];
  filterField?: InputMaybe<BaseFilterSchema>;
  platformAccountId: Scalars['Int'];
};

export type QueryGetKeywordsArgs = {
  adGroupIds?: InputMaybe<Array<Scalars['String']>>;
  campaignIds?: InputMaybe<Array<Scalars['String']>>;
  customerIds: Array<Scalars['String']>;
  dateRange: Scalars['String'];
  filterField?: InputMaybe<BaseFilterSchema>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  platformAccountId: Scalars['Int'];
  searchValue?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<KeywordSortSchema>;
};

export type QueryGetSourceAccountInfoArgs = {
  customerIds: Array<Scalars['String']>;
  platformAccountId: Scalars['Int'];
};

export type QueryGetTrafficSegmentsArgs = {
  adGroupId: Scalars['String'];
  customerId: Scalars['String'];
  dateRange: Scalars['String'];
  filterField?: InputMaybe<BaseFilterSchema>;
  platformAccountId: Scalars['Int'];
  sortBy?: InputMaybe<TrafficSegmentSortSchema>;
};

export type QueryIntegrationAccountArgs = {
  integrationAccountId: Scalars['Int'];
};

export type QueryIntegrationAccountsArgs = {
  platformAccountId?: InputMaybe<Scalars['Int']>;
};

export type QueryInvitesArgs = {
  accountId: Scalars['Int'];
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  sortBy?: InputMaybe<InviteSortSchema>;
};

export type QueryListAgenciesToInviteArgs = {
  accountId: Scalars['Int'];
};

export type QueryOwnIntegrationAccountsArgs = {
  includeMcc?: InputMaybe<Scalars['Boolean']>;
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  platformAccountId: Scalars['Int'];
  searchValue?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<IntegrationAccountsSortSchema>;
};

export type QueryOwnerAccountNamesArgs = {
  ownerAccountIds: Array<Scalars['Int']>;
};

export type QueryOwnerUserEmailsArgs = {
  ownerUserIds: Array<Scalars['String']>;
};

export type QueryUserArgs = {
  userId: Scalars['Int'];
  username: Scalars['String'];
};

export enum StateEnum {
  Alabama = 'ALABAMA',
  Alaska = 'ALASKA',
  Arizona = 'ARIZONA',
  Arkansas = 'ARKANSAS',
  California = 'CALIFORNIA',
  Colorado = 'COLORADO',
  Connecticut = 'CONNECTICUT',
  Delaware = 'DELAWARE',
  Florida = 'FLORIDA',
  Georgia = 'GEORGIA',
  Hawaii = 'HAWAII',
  Idaho = 'IDAHO',
  Illinois = 'ILLINOIS',
  Indiana = 'INDIANA',
  Iowa = 'IOWA',
  Kansas = 'KANSAS',
  Kentucky = 'KENTUCKY',
  Louisiana = 'LOUISIANA',
  Maine = 'MAINE',
  Maryland = 'MARYLAND',
  Massachusetts = 'MASSACHUSETTS',
  Michigan = 'MICHIGAN',
  Minnesota = 'MINNESOTA',
  Mississippi = 'MISSISSIPPI',
  Missouri = 'MISSOURI',
  Montana = 'MONTANA',
  Nebraska = 'NEBRASKA',
  Nevada = 'NEVADA',
  NewHampshire = 'NEW_HAMPSHIRE',
  NewJersey = 'NEW_JERSEY',
  NewMexico = 'NEW_MEXICO',
  NewYork = 'NEW_YORK',
  NorthCarolina = 'NORTH_CAROLINA',
  NorthDakota = 'NORTH_DAKOTA',
  Ohio = 'OHIO',
  Oklahoma = 'OKLAHOMA',
  Oregon = 'OREGON',
  Pennsylvania = 'PENNSYLVANIA',
  RhodeIsland = 'RHODE_ISLAND',
  SouthCarolina = 'SOUTH_CAROLINA',
  SouthDakota = 'SOUTH_DAKOTA',
  Tennessee = 'TENNESSEE',
  Texas = 'TEXAS',
  Utah = 'UTAH',
  Vermont = 'VERMONT',
  Virginia = 'VIRGINIA',
  Washington = 'WASHINGTON',
  WestVirginia = 'WEST_VIRGINIA',
  Wisconsin = 'WISCONSIN',
  Wyoming = 'WYOMING',
}

export type TrafficSegmentSchema = {
  __typename?: 'TrafficSegmentSchema';
  auctions?: Maybe<Scalars['Int']>;
  clicks?: Maybe<Scalars['Int']>;
  conversions?: Maybe<Scalars['Float']>;
  cost?: Maybe<Scalars['Float']>;
  impressions?: Maybe<Scalars['Int']>;
  intentThemes?: Maybe<Scalars['Int']>;
  intents?: Maybe<Scalars['Int']>;
  keywords?: Maybe<Scalars['Int']>;
  segmentId: Scalars['String'];
};

export enum TrafficSegmentSortEnum {
  Auctions = 'AUCTIONS',
  Clicks = 'CLICKS',
  Conversions = 'CONVERSIONS',
  Cost = 'COST',
  Impressions = 'IMPRESSIONS',
  Intents = 'INTENTS',
  IntentThemes = 'INTENT_THEMES',
  Keywords = 'KEYWORDS',
}

export type TrafficSegmentSortSchema = {
  desc?: InputMaybe<Scalars['Boolean']>;
  field: TrafficSegmentSortEnum;
};

export type UserAccessInputSchema = {
  accountId: Scalars['Int'];
  isAdmin: Scalars['Boolean'];
  status?: AccessStatusEnum;
  userId: Scalars['Int'];
};

export type UserAccessSchema = {
  __typename?: 'UserAccessSchema';
  accountId: Scalars['Int'];
  createdAt: Scalars['String'];
  id: Scalars['Int'];
  isAdmin: Scalars['Boolean'];
  status: AccessStatusEnum;
  updatedAt: Scalars['String'];
  userId: Scalars['Int'];
};

export type UserAccessUpdateInputSchema = {
  isAdmin?: InputMaybe<Scalars['Boolean']>;
  status?: InputMaybe<AccessStatusEnum>;
};

export type UserSchema = {
  __typename?: 'UserSchema';
  createdAt: Scalars['DateTime'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  id: Scalars['Int'];
  isPlatformAdmin: Scalars['Boolean'];
  lastName: Scalars['String'];
  updatedAt: Scalars['String'];
  userAttributes: Scalars['JSON'];
  userStatus: AccessStatusEnum;
  username: Scalars['String'];
};

export type UserUpdateInputSchema = {
  confirmPassword?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  newPassword?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
  userAttributes?: InputMaybe<Scalars['JSON']>;
  userStatus?: InputMaybe<AccessStatusEnum>;
};
