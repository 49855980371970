import { Card, styled, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

import { Button } from '~/core/components/shared';

export const PageContainer = styled('div')({
  height: '100%',
});

export const Container = styled('div')({
  minWidth: '780px',
  padding: '24px',
  display: 'flex',
  flexDirection: 'column',
  gap: '32px',
  alignItems: 'flex-start',
});

export const ContentCard = styled(Card)({
  width: '100%',
  boxShadow: 'rgba(100, 116, 139, 0.16) 0px 1px 1px, rgba(100, 116, 139, 0.16) 0px 1px 6px',
});

export const HeaderContainer = styled('div')({
  padding: '32px, 0px',
});

export const MainTitle = styled(Typography)`
  font-size: 20px;
  font-weight: bold;
  line-height: 1.6;
  letter-spacing: 0.15px;
  color: rgba(33, 33, 52, 0.87);
`;

export const ProfileInfoContainer = styled('div')({
  display: 'flex',
  padding: '26px 16px',
  gap: '20px',
  alignItems: 'center',
});

export const Title = styled(Typography)`
  width: 200px;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.43;
  letter-spacing: 0.17px;
  color: rgba(33, 33, 52, 0.87);
`;

export const Content = styled(Typography)`
  font-size: 14px;
  line-height: 1.43;
  letter-spacing: 0.17px;
  color: rgba(33, 33, 52, 0.87);
`;

export const EditButton = styled(Button)({
  marginLeft: 'auto',
});

export const BackToDashboardLink = styled(Link)({
  display: 'flex',
  alignItems: 'center',
  gap: '11px',
  color: '#3A3A4A',
  fontSize: 13,
  fontWeight: 600,
  padding: '12px 0',
  textDecoration: 'none',
});
