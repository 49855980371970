import { FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';

import { Button, Dialog, DialogFooter, DialogHeader, Input } from '~/core/components/shared';
import { EMAIL_PATTERN_REGEX } from '~/core/constants/validationPatterns.constants';

import { AddUserDialogProps, AddUserInput } from './types';

const AddUserDialog: React.FC<AddUserDialogProps> = ({ open, handleClose, onSubmit, accountName }) => {
  const {
    handleSubmit,
    formState: { errors },
    control,
    watch,
  } = useForm<AddUserInput>({
    defaultValues: {
      email: '',
      isAdmin: 'false',
    },
  });

  const email = watch('email');

  return (
    <Dialog data-testid="customerAddUserDialog" open={open} handleCloseButton={handleClose}>
      <DialogHeader title={`Add a user to the ${accountName} team`} />
      <Controller
        name="email"
        rules={{
          required: true,
          pattern: {
            value: EMAIL_PATTERN_REGEX,
            message: 'Invalid Email',
          },
        }}
        control={control}
        render={({ field: { onChange, value } }) => (
          <Input
            value={value}
            onChange={onChange}
            required
            label="Email Address"
            error={errors?.email?.type === 'pattern'}
            helperText={errors?.email?.type === 'pattern' && 'Please enter a valid email address'}
          />
        )}
      />
      <Controller
        rules={{
          required: true,
        }}
        control={control}
        name="isAdmin"
        render={({ field }) => (
          <RadioGroup {...field}>
            <FormLabel>Role</FormLabel>
            <FormControlLabel value="true" control={<Radio />} label="Admin" />
            <FormControlLabel value="false" control={<Radio />} label="Standard User" />
          </RadioGroup>
        )}
      />
      <DialogFooter>
        <Button data-testid="handleClose" label="Cancel" variant="outlined" onClick={handleClose} />
        <Button
          data-testid="handleSubmit"
          label="Send Invite"
          variant="contained"
          onClick={handleSubmit(onSubmit)}
          disabled={!email}
        />
      </DialogFooter>
    </Dialog>
  );
};

export default AddUserDialog;
