import { EditOutlined } from '@mui/icons-material';
import { Divider } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { CircularProgress } from '~/core/components/shared';
import {
  getUserAction,
  resetUserErrorAction,
  resetUserSuccessFlagAction,
  updateUserAction,
} from '~/store/actions/user.action';
import { authUserSelector } from '~/store/selectors/auth.selector';
import { userSelector, userSuccessSelector } from '~/store/selectors/user.selector';

import { EditEmailDialog } from './components/EditEmailDialog';
import { EditNameDialog } from './components/EditNameDialog';
import { EditPasswordDialog } from './components/EditPasswordDialog';
import * as Styled from './styles';

const UserSettings: React.FC = () => {
  const dispatch = useDispatch();

  const authUser = useSelector(authUserSelector);
  const currentUser = useSelector(userSelector);
  const success = useSelector(userSuccessSelector);
  const [isEditNameOpen, setIsEditNameOpen] = useState<boolean>(false);
  const [isEditEmailOpen, setIsEditEmailOpen] = useState<boolean>(false);
  const [isEditPasswordOpen, setIsEditPasswordOpen] = useState<boolean>(false);

  const handleToggleEditNameOpen = () => {
    setIsEditNameOpen((isEditNameOpen) => !isEditNameOpen);
  };

  const handleToggleEditEmailOpen = () => {
    setIsEditEmailOpen((isEditEmailOpen) => !isEditEmailOpen);
  };

  const handleToggleEditPasswordOpen = () => {
    setIsEditPasswordOpen((isEditPasswordOpen) => !isEditPasswordOpen);
  };

  const editNameSubmit = (data: { firstName: string; lastName: string }) => {
    dispatch(updateUserAction.request({ userId: currentUser.id, userData: data }));
    handleToggleEditNameOpen();
  };

  useEffect(() => {
    dispatch(
      getUserAction.request({
        userId: authUser?.UserId,
      }),
    );
  }, [dispatch, authUser?.UserId]);

  useEffect(() => {
    dispatch(resetUserErrorAction());
  }, [isEditEmailOpen, isEditNameOpen, isEditPasswordOpen, dispatch]);

  useEffect(() => {
    if (success) {
      dispatch(resetUserSuccessFlagAction());
    }
  }, [dispatch, success]);

  return (
    <>
      {isEditNameOpen && (
        <EditNameDialog
          data-testid="editNameDialog"
          open={isEditNameOpen}
          handleClose={handleToggleEditNameOpen}
          onSubmit={editNameSubmit}
          defaultValues={{ firstName: currentUser.firstName, lastName: currentUser.lastName }}
        />
      )}
      {isEditPasswordOpen && (
        <EditPasswordDialog
          data-testid="editPasswordDialog"
          open={isEditPasswordOpen}
          handleClose={handleToggleEditPasswordOpen}
        />
      )}
      {isEditEmailOpen && (
        <EditEmailDialog data-testid="editEmailDialog" open={isEditEmailOpen} handleClose={handleToggleEditEmailOpen} />
      )}
      <Styled.PageContainer data-testid="userSettingsContainer">
        {!currentUser ? (
          <CircularProgress />
        ) : (
          <Styled.Container>
            <Styled.HeaderContainer>
              <Styled.MainTitle>My Account</Styled.MainTitle>
            </Styled.HeaderContainer>
            <Styled.ContentCard>
              <Divider />
              <Styled.ProfileInfoContainer>
                <Styled.Title>Name</Styled.Title>
                <Styled.Content>
                  {currentUser.firstName} {currentUser.lastName}
                </Styled.Content>
                <Styled.EditButton
                  data-testid="editNameButton"
                  label="Edit"
                  variant="contained"
                  startIcon={<EditOutlined />}
                  onClick={handleToggleEditNameOpen}
                />
              </Styled.ProfileInfoContainer>
              <Divider />
              <Styled.ProfileInfoContainer>
                <Styled.Title>Email Address</Styled.Title>
                <Styled.Content>{currentUser.email}</Styled.Content>
                <Styled.EditButton
                  data-testid="editEmailButton"
                  label="Edit"
                  variant="contained"
                  startIcon={<EditOutlined />}
                  onClick={handleToggleEditEmailOpen}
                />
              </Styled.ProfileInfoContainer>
              <Divider />
              <Styled.ProfileInfoContainer>
                <Styled.Title>Password</Styled.Title>
                <Styled.Content>Set a unique password to protect your work account</Styled.Content>
                <Styled.EditButton
                  data-testid="editPasswordButton"
                  label="Edit"
                  variant="contained"
                  startIcon={<EditOutlined />}
                  onClick={handleToggleEditPasswordOpen}
                />
              </Styled.ProfileInfoContainer>
            </Styled.ContentCard>
          </Styled.Container>
        )}
      </Styled.PageContainer>
    </>
  );
};

export default UserSettings;
