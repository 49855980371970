import { useQuery } from '@apollo/client';

import { KeywordSchemaPaginationSchema } from '~/core/types/graphql.types';

import { GET_KEYWORD_COHERENCY_KPIS, GET_KEYWORDS, GET_KEYWORDS_TEXT } from './keywords.queries';
import {
  GetKeywordCoherencyKpisQuery,
  GetKeywordCoherencyKpisQueryVariables,
  GetKeywordsQueryVariables,
} from './keywords.queries.generated';

export const useGetKeywords = (skip: boolean, variables: GetKeywordsQueryVariables) => {
  const {
    loading: keywordsLoading,
    error: keywordsError,
    data: keywordsData,
  } = useQuery<{ getKeywords: KeywordSchemaPaginationSchema }>(GET_KEYWORDS, {
    skip,
    variables,
  });

  const getKeywords = keywordsData?.getKeywords;

  return { keywordsLoading, keywordsError, getKeywords };
};

export const useGetKeywordsText = (skip: boolean, variables: GetKeywordsQueryVariables) => {
  const {
    loading: keywordsLoading,
    error: keywordsError,
    data: keywordsData,
  } = useQuery<{ getKeywords: KeywordSchemaPaginationSchema }>(GET_KEYWORDS_TEXT, {
    skip,
    variables,
  });

  const getKeywords = keywordsData?.getKeywords;

  return { keywordsLoading, keywordsError, getKeywords };
};

export const useGetKeywordCoherencyKpis = (skip: boolean, variables: GetKeywordCoherencyKpisQueryVariables) => {
  const {
    loading: coherencyKpisLoading,
    error: coherencyKpisError,
    data: coherencyKpisData,
  } = useQuery<GetKeywordCoherencyKpisQuery>(GET_KEYWORD_COHERENCY_KPIS, {
    skip,
    variables,
  });

  const getKeywordCoherencyKpis = coherencyKpisData?.getKeywordCoherencyKpis;

  return { coherencyKpisLoading, coherencyKpisError, getKeywordCoherencyKpis };
};
