import { Typography } from '@mui/material';
import { useSelector } from 'react-redux';

import GoogleAdsButton from '~/assets/google/btn_google_signin_dark_normal_web.png';
import { Button, CircularProgress } from '~/core/components/shared';
import useGoogleAuthPopup from '~/core/hooks/useGoogleAuthPopup';
import { signInUrlLoadingSelector, signInUrlSelector } from '~/store/selectors/integrationAccounts.selector';

import { customerAccountSteps } from '../../../data';
import { StepsEnum } from '../../../types';
import { StepProps } from '../types';
import * as Styled from './styles';

const GoogleAuth = ({ activeStep, setActiveStep, accountId }: StepProps) => {
  const googleAdsSignInUrl = useSelector(signInUrlSelector);
  const isLoading = useSelector(signInUrlLoadingSelector);

  const { handleOpenGooglePopup, queryParams } = useGoogleAuthPopup(googleAdsSignInUrl?.url);

  const handleBackStep = () => {
    setActiveStep(StepsEnum.CreateAccount);
  };

  const handleNextStep = () => {
    setActiveStep(StepsEnum.InviteUser);
    localStorage.setItem('activeStep', StepsEnum.InviteUser);
  };

  return (
    <>
      <Styled.CardContent data-testid="googleAuth">
        <Styled.VerticalStepper steps={customerAccountSteps} activeStep={Number(activeStep)} />
        <Styled.StepContent>
          {isLoading ? (
            <CircularProgress />
          ) : (
            <>
              <Styled.HeaderContainer>
                <Typography fontSize={20} fontWeight={600}>
                  Add a Google account to the platform
                </Typography>
              </Styled.HeaderContainer>
              <Styled.FormContainer>
                {queryParams?.email ? (
                  <Styled.AuthorizedAccount data-testid="authorizedAccount">
                    <Styled.CheckCircleIcon color="success" /> {queryParams?.email} authorized
                  </Styled.AuthorizedAccount>
                ) : (
                  queryParams && (
                    <Styled.ErrorAuthorizing data-testid="authorizedError">
                      <Styled.CancelIcon color="error" /> {queryParams?.email} Error authorizing account, please try
                      again
                    </Styled.ErrorAuthorizing>
                  )
                )}
                <Typography width={760}>
                  Navigate to 1Password to obtain respective credentials for customer account you wish to authorize.
                  Then click Sign In with Google and follow the stepData in the window to authorize.
                </Typography>
                <Styled.ImgButton
                  data-testid="googleSignInButton"
                  onClick={handleOpenGooglePopup}
                  src={GoogleAdsButton}
                  alt="Google Ads Button"
                />
              </Styled.FormContainer>
            </>
          )}
        </Styled.StepContent>
      </Styled.CardContent>
      <Styled.CardActions>
        <Button data-testid="backButton" onClick={handleBackStep} label="Back" />
        <Button
          data-testid="nextButton"
          disabled={!queryParams?.email}
          onClick={handleNextStep}
          label={accountId ? 'Update Account' : 'Next'}
          variant="contained"
          color="primary"
        />
      </Styled.CardActions>
    </>
  );
};

export default GoogleAuth;
