import { combineReducers } from '@reduxjs/toolkit';

import * as fromAccount from './account.reducer';
import * as fromAccountUsers from './accountUsers.reducer';
import * as fromAuth from './auth.reducer';
import * as fromIntegrationAccounts from './integrationAccounts.reducer';
import * as fromNotifications from './notification.reducer';
import * as fromPaidSearchAccounts from './paidSearchAccounts.reducer';
import * as fromUser from './user.reducer';

export interface AppState {
  auth: fromAuth.State;
  account: fromAccount.State;
  integrationAccounts: fromIntegrationAccounts.State;
  accountUsers: fromAccountUsers.State;
  user: fromUser.State;
  notifications: fromNotifications.State;
  paidSearchAccounts: fromPaidSearchAccounts.State;
}

const rootReducer = combineReducers<AppState>({
  auth: fromAuth.reducer,
  integrationAccounts: fromIntegrationAccounts.reducer,
  account: fromAccount.reducer,
  accountUsers: fromAccountUsers.reducer,
  user: fromUser.reducer,
  notifications: fromNotifications.reducer,
  paidSearchAccounts: fromPaidSearchAccounts.reducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
