import {
  Box,
  Container,
  ContainerProps,
  ListItemButton as MuiListItemButton,
  MenuItem as MuiMenuItem,
  Select as MuiSelect,
  styled,
  Typography,
} from '@mui/material';

export const AccountName = styled(Typography)`
  color: #ffffff !important;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.43;
  letter-spacing: 0.17px;
  margin-left: 12px;
`;

export const DropdownContainer = styled(Container)<ContainerProps>({
  width: '240px',
  display: 'flex',
});

export const MenuItem = styled(MuiMenuItem)({
  width: 'auto',
  height: '33px',
  backgroundColor: 'inherit',
  color: '#111827',
  fontSize: '14px',
  '&.Mui-selected': {
    background: 'rgba(33, 33, 52, 0.04)',
  },
  '&.MuiMenuItem-root': {
    minHeight: '33px',
  },
});

export const ImageContainer = styled(Box)`
  width: 36px;
  height: 36px;
  flex-grow: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 12px 5px;
  border-radius: 6px;
  background-color: #37345b;
  font-size: 12px;
  color: #ffffff;
`;

export const Image = styled('img')({
  height: '14px',
  width: 'auto',
});

export const ListItemButton = styled(MuiListItemButton)({
  width: '240px',
  display: 'flex',
  height: '65px',
  minWidth: '240px',
  margin: '0 0 4px 0',
  padding: '14.5px, 0',
  whiteSpace: 'pre-wrap',
  wordBreak: 'break-word',
  '.MuiSvgIcon-root': {
    color: 'rgb(255,255,255, 0.56)',
  },
  '.MuiTypography-root': {
    width: '140px',
    color: 'rgb(255,255,255, 0.7)',
  },
  '&:hover': {
    backgroundColor: 'rgba(255,255,255,0.08)',
    '.MuiSvgIcon-root': {
      color: 'rgb(255,255,255, 0.56)',
    },
    '.MuiTypography-root': {
      color: 'rgb(255,255,255, 0.7)',
    },
  },
  '.Mui-disabled': {
    opacity: '1 !important',
  },
  '& .MuiPopover-root': {
    minWidth: '108px !important',
    background: 'red !important',
  },
});

export const Select = styled(MuiSelect)({
  width: '240px',
  fontSize: '14px',
  fontWeight: 'bold',
  height: '65px',
  borderRadius: '8px',
  color: 'white',
  textAlign: 'left',
  '& .MuiPaper-root': {
    background: 'red',
  },
  '& .MuiSelect-select': {
    padding: 0,
  },
  '& .MuiSelect-icon': {
    color: 'rgba(255, 255, 255, 0.56)',
    display: 'flex',
    top: '20px',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
});
