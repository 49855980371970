import { Outlet } from 'react-router-dom';

import { SidebarNavigation } from '~/core/components/shared';

import * as Styled from './styles';
import { DashboardProps } from './types';

const Dashboard: React.FC<DashboardProps> = () => (
  <Styled.Container data-testid="dashboard" className="dashboard">
    <SidebarNavigation>
      <Outlet />
    </SidebarNavigation>
  </Styled.Container>
);

export default Dashboard;
