/* eslint-disable max-len */
/* eslint-disable react/no-array-index-key */
import { Box, Skeleton, Typography } from '@mui/material';
import { useCallback, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { Breadcrumbs } from '~/core/components/shared';
import { useGetIntentsAssetMap } from '~/core/services/graphql/intents/hooks';
import { useContexts } from '~/store/context/useContext';

import * as Styled from './styles';

const renderSkeletons = (number: number) => {
  const skeletons = [];
  for (let i = 0; i < number; i += 1) {
    skeletons.push(
      <Box key={i}>
        <Styled.Cell>
          <Skeleton width="95%" />
        </Styled.Cell>
      </Box>,
    );
  }
  return skeletons;
};

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const CustomTabPanel: React.FC<TabPanelProps> = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box padding="3px">
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

const AssetsView: React.FC = () => {
  const [searchParams] = useSearchParams();
  const {
    selectedPlatformAccountId,
    selectedSourceAccountId,
    selectedCampaignId,
    selectedClusterId,
    selectedDateRange,
  } = useContexts();
  const [tab] = useState<number>(1);

  const getQueryValue = useCallback(
    (selected: string | null, queryParam: string) => selected || searchParams.get(queryParam),
    [searchParams],
  );
  const platformAccountId = useMemo(
    () => getQueryValue(selectedPlatformAccountId?.toString(), 'platformAccountId') as string,
    [getQueryValue, selectedPlatformAccountId] as const,
  );
  const sourceAccountId =
    useMemo(
      () => getQueryValue(selectedSourceAccountId, 'sourceAccountId'),
      [getQueryValue, selectedSourceAccountId],
    ) || searchParams.get('sourceAccountId');
  const campaignId =
    useMemo(() => getQueryValue(selectedCampaignId, 'campaignId'), [getQueryValue, selectedCampaignId]) ||
    searchParams.get('campaignId');
  const clusterId =
    useMemo(() => getQueryValue(selectedClusterId, 'intentId'), [getQueryValue, selectedClusterId]) ||
    searchParams.get('intentId');
  const dateRange =
    useMemo(() => getQueryValue(selectedDateRange, 'dateRange'), [getQueryValue, selectedDateRange]) ||
    searchParams.get('dateRange');

  const adGroupId = searchParams.get('adGroup');

  const importedState = useMemo(() => {
    const searchParamsFiltered = {
      orderBy: searchParams.get('orderBy'),
      desc: searchParams.get('desc'),
      page: searchParams.get('page'),
      filterKey: searchParams.get('filterKey'),
      pageSize: searchParams.get('pageSize') || '0', // Consider default value if 'pageSize' should not be excluded when it's null
      covered: searchParams.get('covered'),
    };
    const params = { platformAccountId, sourceAccountId, campaignId, dateRange, ...searchParamsFiltered };
    return Object.entries(params).reduce((acc: any, [key, value]) => {
      if (value !== null) {
        acc[key] = value;
      }
      return acc;
    }, {});
  }, [platformAccountId, sourceAccountId, campaignId, clusterId, dateRange, searchParams]);

  const skip = !platformAccountId || !sourceAccountId || !dateRange || !adGroupId;
  const variables = {
    platformAccountId: Number(platformAccountId),
    customerId: sourceAccountId,
    adGroupId,
    intentId: clusterId,
    dateRange,
    aligned: true,
  };
  const { assetMapLoading, getIntentsAssetMap } = useGetIntentsAssetMap(skip, variables);

  const headlines = getIntentsAssetMap?.filter((asset) => asset?.assetType === 'HEADLINE')?.map((asset) => asset);
  const descriptions = getIntentsAssetMap?.filter((asset) => asset?.assetType === 'DESCRIPTION')?.map((asset) => asset);

  const totalUniqueAssets =
    ((headlines?.length > 0 && headlines.length) || 0) + ((descriptions?.length > 0 && descriptions.length) || 0);

  if (campaignId) importedState.campaignId = campaignId;

  return (
    <>
      <Breadcrumbs importedState={importedState} />
      <Styled.PageContainer>
        <Styled.Navigation>
          <Styled.PaperContainer>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              sx={{
                borderBottom: '1px solid rgba(33, 33, 52, 0.12)',
              }}
            >
              <Styled.TabHeader>
                {assetMapLoading || !getIntentsAssetMap ? (
                  <Skeleton width="500px" height="14px" />
                ) : (
                  <Box>
                    You have {totalUniqueAssets} assets that Google combines to target this specific search intent
                  </Box>
                )}
              </Styled.TabHeader>
            </Box>
            <Box>
              <CustomTabPanel value={tab} index={1}>
                <Styled.BadgeContainer>
                  Headlines {headlines?.length > 0 && `(${headlines?.length})`}
                </Styled.BadgeContainer>
                <Box marginLeft="24px">
                  {assetMapLoading ? (
                    <>{renderSkeletons(5)}</>
                  ) : (
                    <>
                      {headlines?.length > 0 &&
                        headlines?.map((headline, index: number) => (
                          <Box key={index}>
                            <Styled.Cell>&lsquo;{headline?.assetText}&rsquo;</Styled.Cell>
                          </Box>
                        ))}
                      {headlines?.length < 1 && (
                        <Box>
                          <Styled.Cell>No headlines to display</Styled.Cell>
                        </Box>
                      )}
                    </>
                  )}
                </Box>
                <Styled.BadgeContainer>
                  Descriptions {descriptions?.length > 0 && `(${descriptions?.length})`}
                </Styled.BadgeContainer>
                <Box marginLeft="24px">
                  {assetMapLoading || !getIntentsAssetMap ? (
                    <>{renderSkeletons(5)}</>
                  ) : (
                    <>
                      {descriptions?.length > 0 &&
                        descriptions?.map((description, index: number) => (
                          <Box key={index}>
                            <Styled.Cell>&lsquo;{description?.assetText}&rsquo;</Styled.Cell>
                          </Box>
                        ))}

                      {descriptions?.length < 1 && (
                        <Box>
                          <Styled.Cell
                            sx={{
                              color: 'rgba(33, 33, 52, 0.38)',
                            }}
                          >
                            No descriptions to display
                          </Styled.Cell>
                        </Box>
                      )}
                    </>
                  )}
                </Box>
              </CustomTabPanel>
            </Box>
          </Styled.PaperContainer>
        </Styled.Navigation>
      </Styled.PageContainer>
    </>
  );
};

export default AssetsView;
