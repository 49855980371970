import { gql } from '@apollo/client';

export const accountGql = gql`
  query account($accountId: Int!) {
    account(accountId: $accountId) {
      name
      cap
      phoneNumber
      website
      contactEmail
      contactPhone
      address_1
      city
      country
      zipCode
      editableCompanyType
      industry
      status
      companyType
      integrationEmail
      contactFirstName
      contactLastName
      faxNumber
      address_2
      state
      authId
      clientId
      clientSecret
      redirectUri
      refreshToken
      id
      createdAt
      updatedAt
      isFree
    }
  }
`;

export const createAccountGql = gql`
  mutation createAccount($accountData: PlatformAccountInputSchema!) {
    createAccount(accountData: $accountData) {
      name
      cap
      phoneNumber
      website
      contactEmail
      contactFirstName
      contactLastName
      contactPhone
      integrationEmail
      address_1
      city
      country
      zipCode
      editableCompanyType
      industry
      status
      companyType
      faxNumber
      address_2
      state
      authId
      clientId
      clientSecret
      redirectUri
      refreshToken
      id
      createdAt
      updatedAt
    }
  }
`;

export const updatePlatformAccountGql = gql`
  mutation updateAccount($accountId: Int!, $accountData: PlatformAccountUpdateSchema!) {
    updateAccount(accountId: $accountId, accountData: $accountData) {
      name
      cap
      phoneNumber
      website
      contactEmail
      contactFirstName
      contactLastName
      contactPhone
      address_1
      city
      country
      zipCode
      editableCompanyType
      industry
      status
      companyType
      faxNumber
      address_2
      state
      authId
      clientId
      clientSecret
      redirectUri
      refreshToken
      id
      createdAt
      updatedAt
      integrationEmail
    }
  }
`;

export const allAccountsGql = gql`
  query accounts(
    $page: Int!
    $pageSize: Int!
    $filterSchema: PlatformAccountsFilterSchema
    $searchValue: String
    $sortBy: PlatformAccountSortSchema
  ) {
    accounts(
      page: $page
      pageSize: $pageSize
      filterSchema: $filterSchema
      searchValue: $searchValue
      sortBy: $sortBy
    ) {
      pageInfo {
        pageSize
        page
        total
        pages
        previousPage
        nextPage
      }
      edges {
        name
        cap
        phoneNumber
        contactEmail
        contactFirstName
        contactLastName
        contactPhone
        address_1
        city
        country
        zipCode
        editableCompanyType
        industry
        status
        companyType
        faxNumber
        integrationEmail
        address_2
        website
        state
        authId
        clientId
        clientSecret
        redirectUri
        refreshToken
        id
        createdAt
        updatedAt
      }
    }
  }
`;

export const updateCompanyProfileGql = gql`
  mutation updateCompanyProfile($accountId: Int!, $accountData: PlatformAccountUpdateSchema!) {
    updateAccount(accountId: $accountId, accountData: $accountData) {
      name
      contactEmail
      phoneNumber
      faxNumber
      website
      companyType
      contactPhone
      contactLastName
      contactFirstName
      id
      integrationEmail
      createdAt
      updatedAt
      address_1
      city
      country
      zipCode
      editableCompanyType
      industry
      status
      isFree
      address_2
      state
    }
  }
`;

export const updateBillingInfoGql = gql`
  mutation updateBillingInfo($accountId: Int!, $accountData: PlatformAccountUpdateSchema!) {
    updateAccount(accountId: $accountId, accountData: $accountData) {
      name
      contactEmail
      phoneNumber
      faxNumber
      website
      companyType
      contactPhone
      contactLastName
      contactFirstName
      integrationEmail
      id
      createdAt
      updatedAt
      address_1
      city
      country
      zipCode
      editableCompanyType
      industry
      status
      address_2
      state
    }
  }
`;

export const updateAccountGql = gql`
  mutation updateAccountBase($accountId: Int!, $accountData: PlatformAccountUpdateSchema!) {
    updateAccount(accountId: $accountId, accountData: $accountData) {
      name
      contactEmail
      phoneNumber
      faxNumber
      companyType
      contactLastName
      contactFirstName
      id
      integrationEmail
      createdAt
      updatedAt
      address_1
      city
      country
      zipCode
      editableCompanyType
      industry
      status
      address_2
      state
    }
  }
`;
