import { Box, styled, Typography } from '@mui/material';

export const Container = styled(Box)({
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

export const Title = styled(Typography)({
  color: 'lightgrey',
  fontSize: '96px',
});
