import { TableHead as MuiTable, TableCell as MuiTableCell, styled } from '@mui/material';

export const TableHead = styled(MuiTable)({
  height: '50px',
  flexGrow: 1,
  lineHeight: '2.5',
  letterSpacing: '0.5px',
  textAlign: 'left',
});

export const TableCell = styled(MuiTableCell)(({ theme }) => ({
  backgroundColor: '#F6F6F7',
  fontFamily: 'Inter',
  fontSize: '12px',
  fontWeight: 600,
  color: theme.palette.text.primary,
  padding: '10px',
  '& .MuiTableSortLabel-root': {
    display: 'flex',
    alignSelf: 'center',
    flexDirection: 'row-reverse',
    justifyContent: 'flex-end',
  },
}));
