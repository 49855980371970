export const tablePaginationRowsPerPageOptions = [10, 25, 50, 100];

export enum FilterAccessStatusEnum {
  AnyStatus = 'Any Status',
  Active = 'Active',
  Inactive = 'Inactive',
  Pending = 'Pending',
}

export enum FilterAccountTypeEnum {
  AnyType = 'Any Type',
  Agency = 'Agency',
  Customer = 'Customer',
}

export enum FilterAccountStatusEnum {
  AnyStatus = 'Any Status',
  Active = 'Active',
  Inactive = 'Inactive',
}
