import { Box, BoxProps, Typography } from '@mui/material';

interface DialogHeaderProps extends BoxProps {
  title: string;
  description?: string;
}

const DialogHeader: React.FC<DialogHeaderProps> = ({ title, ...elementProps }) => (
  <Box display="flex" flexDirection="column" gap="8px" data-testid="dialogHeader" {...elementProps}>
    <Typography fontSize="20px" fontWeight="bold">
      {title}
    </Typography>
    {elementProps.description && (
      <Typography fontSize="12px" data-testid="dialogDescription">
        {elementProps.description}
      </Typography>
    )}
  </Box>
);

export default DialogHeader;
