import { useEffect, useRef, useState, useCallback } from 'react';
import ReactDOM from 'react-dom';
import * as d3 from 'd3';
import { sankey, sankeyLinkHorizontal } from 'd3-sankey';
import { Box, styled, Typography } from '@mui/material';

import { numberWithCommas } from '~/core/helpers';

import { Chip } from '../Chip';

const Label = styled(Typography)`
  height: 12px;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  letter-spacing: 0.15px;
  text-align: left;
  color: rgba(33, 33, 52, 0.87);
  margin-bottom: 6px;
`;

const CustomTooltip = ({ x, y, data, totals }) => {
  if (!data) return null;
  if (data?.data?.id === 'total') return null;
  return (
    <div
      style={{
        position: 'absolute',
        left: x,
        top: y,
        padding: '10px',
        backgroundColor: 'white',
        border: '1px solid',
        borderRadius: '5px',
        width: '300px',
        height: data?.data?.id === 'Others' ? '130px' : '95px',
      }}
    >
      {data?.data?.id === 'Others' ? (
        <Typography
          variant="h6"
          sx={{
            color: 'rgba(33, 33, 52, 0.87)',
            fontWeight: 'bold',
            fontSize: '14px',
          }}
        >
          <Chip
            label={`${data.data.totalSegments} Segments (Aggregated)`}
            fontSize="12px"
            sx={{
              height: '24px',
              color: 'rgba(33, 33, 52, 0.87)',
              marginBottom: '12px',
              background: '#FFAB40',
            }}
          />
        </Typography>
      ) : null}
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: '2fr 1fr 1fr',
          gap: '10px',
          marginBottom: '10px',
        }}
      >
        <Box>
          <Label />
          <Label>Impressions</Label>
          <Label>Keywords</Label>
          <Label>Intent Themes</Label>
        </Box>
        <Box>
          <Label
            sx={{
              fontWeight: 'bold',
              color: 'rgba(33, 33, 52, 0.6)',
            }}
            marginBottom={6}
          >
            Ad Group
          </Label>
          <Label fontWeight="bold">{numberWithCommas(totals?.impressions)}</Label>
          <Label fontWeight="bold">{numberWithCommas(totals?.keywords)}</Label>
          <Label fontWeight="bold">{numberWithCommas(totals?.intentThemes)}</Label>
        </Box>
        <Box>
          <Label
            sx={{
              fontWeight: 'bold',
              color: 'rgba(33, 33, 52, 0.6)',
            }}
            marginBottom={6}
          >
            {data?.data?.id === 'Others' ? 'Segments' : 'Segment'}
          </Label>
          <Label fontWeight="bold">{numberWithCommas(data?.data?.impressions)}</Label>
          <Label fontWeight="bold">{numberWithCommas(data?.data?.keywords)}</Label>
          <Label fontWeight="bold">{numberWithCommas(data?.data?.intentThemes)}</Label>
        </Box>
      </Box>
    </div>
  );
};

const SankeyChart = ({ nodes, links, onSegmentClick, selectedSegmentId }) => {
  const svgRef = useRef(null);
  const containerRef = useRef(null);
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  const [tooltipData, setTooltipData] = useState({ show: false, x: 0, y: 0, data: null });

  const handleNodeClick = useCallback(
    (event, d) => {
      if (d.id === 'Total') return;
      if (d.id === 'Others') {
        onSegmentClick('close');
        return;
      }
      onSegmentClick(d.id);
    },
    [onSegmentClick],
  );
  // Function to update dimensions
  const updateDimensions = () => {
    if (containerRef.current) {
      const { width, height } = containerRef.current.getBoundingClientRect();
      setDimensions({ width, height });
    }
  };

  // Set up resize observer
  useEffect(() => {
    const resizeObserver = new ResizeObserver(updateDimensions);
    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
    }
    updateDimensions(); // Initial dimension set
    return () => resizeObserver.disconnect();
  }, []);

  // Function to update tooltip
  const updateTooltip = useCallback((event, data, show = true) => {
    setTooltipData({
      show,
      x: event ? event.clientX : 0,
      y: event ? event.clientY : 0,
      data,
    });
  }, []);

  // Global mouse move handler
  useEffect(() => {
    const handleMouseMove = (event) => {
      if (tooltipData.show) {
        setTooltipData((prevState) => ({
          ...prevState,
          x: event.clientX,
          y: event.clientY,
        }));
      }
    };

    window.addEventListener('mousemove', handleMouseMove);

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, [tooltipData.show]);

  // Main effect for drawing the chart
  useEffect(() => {
    if (
      !nodes ||
      !links ||
      nodes.length === 0 ||
      links.length === 0 ||
      dimensions.width === 0 ||
      dimensions.height === 0
    )
      return;

    const { width, height } = dimensions;

    // Clear any existing SVG content
    d3.select(svgRef.current).selectAll('*').remove();

    // Set up the Sankey generator
    const sankeyGenerator = sankey()
      .nodeWidth(15)
      .nodePadding(10)
      .nodeSort((a, b) => {
        if (a.id === 'Total' || b.id === 'Total') return -1;
        if (a.id === 'Others' || b.id === 'Others') return 1;
        return b.value - a.value;
      })
      .extent([
        [width * -0.022, 0],
        [width, height - 5],
      ]);

    // Compute the Sankey layout
    const sankeyData = sankeyGenerator({
      nodes: nodes.map((d) => ({ ...d })),
      links: links.map((d) => ({ ...d })),
    });

    // Create SVG
    const svg = d3
      .select(svgRef.current)
      .attr('width', width)
      .attr('height', height)
      .attr('viewBox', [0, 0, width, height])
      .style('width', '100%')
      .style('height', '100%');

    // Create node and link selections (defined before they're used)
    const node = svg
      .append('g')
      .selectAll('rect')
      .data(sankeyData.nodes)
      .join('rect')
      .attr('x', (d) => d.x0)
      .attr('y', (d) => d.y0)
      .attr('height', (d) => (d.id === 'Others' ? 300 : Math.max(2, d.y1 - d.y0)))
      .attr('width', (d) => d.x1 - d.x0)
      .attr('fill', (d) => (d.id === 'Others' ? '#FFAB40' : d.index === 0 ? '#3E6AD8' : '#4A57BC'))
      // No changes required as per instructions
      .style('cursor', 'pointer');

    const link = svg
      .append('g')
      .attr('fill', 'none')
      .attr('stroke-opacity', 0.5)
      .selectAll('g')
      .data(sankeyData.links)
      .join('g')
      .style('mix-blend-mode', 'multiply')
      .style('cursor', 'pointer');

    link
      .append('path')
      .attr('d', sankeyLinkHorizontal())
      .attr('stroke', (d) => {
        if (d.id === 'Others') {
          // Define a unique gradient ID based on the node index
          const gradientId = `gradient-others-${d.index}`;

          // Create the gradient definition inside the <defs> section of the SVG
          const defs = svg.append('defs');
          const linearGradient = defs.append('linearGradient').attr('id', gradientId);

          // Define the color transition inside the gradient from one color to another
          linearGradient.append('stop').attr('offset', '60%').attr('stop-color', '#9FA6DC'); // Start color

          linearGradient.append('stop').attr('offset', '100%').attr('stop-color', '#FFAB40'); // End color

          // Use the gradient for the stroke by referencing the gradient ID
          return `url(#${gradientId})`;
        }
        return '#DBDDF2'; // Default color for non-'Others' links
      })
      .attr('stroke-width', (d) => (d.id === 'Others' ? 3 : Math.max(2, d.width)));

    // Modify the highlightRightNodes function
    const highlightRightNodes = (d) => {
      if (d.id === 'Total') return;
      // const connectedNodes = d.sourceLinks
      //   ? d.sourceLinks.map((l) => l.target.index)
      //   : d.target
      //   ? [d.target.index]
      //   : [];

      // const { width, height } = dimensions;

      // Expand nodes
      // node
      //   .filter((n) => connectedNodes.includes(n.index))
      //   .transition()
      //   .duration(200)
      //   .attr('width', (n) => (n.x1 - n.x0) * 1.5)
      //   .attr('x', (n) => n.x0 - (n.x1 - n.x0) * 0.2);

      // Highlight the hovered/clicked node
      // node
      //   .filter((n) => n === d)
      //   .transition()
      //   .duration(200)
      //   .attr('width', (n) => (n.x1 - n.x0) * 1.5)
      //   .attr('x', (n) => n.x0 - (n.x1 - n.x0) * 0.2);

      if (d.source && d.target) {
        // If 'd' is a link, only highlight this specific link
        link
          .filter((l) => l === d)
          .transition()
          .duration(200)
          .attr('stroke-opacity', 1)
          .select('path')
          .style('stroke', (d) => {
            // Define a unique gradient ID based on the node index
            const gradientId = `gradient-others-${d.index}`;

            // Create the gradient definition inside the <defs> section of the SVG
            const defs = svg.append('defs');
            const linearGradient = defs.append('linearGradient').attr('id', gradientId);

            // Define the color transition inside the gradient from one color to another
            linearGradient.append('stop').attr('offset', '60%').attr('stop-color', '#9FA6DC'); // Start color

            linearGradient.append('stop').attr('offset', '100%').attr('stop-color', '#FFAB40'); // End color

            if (d.id === 'Others') {
              // Use the gradient for the stroke by referencing the gradient ID
              return `url(#${gradientId})`;
            }

            return '#DBDDF2';
          });
      } else {
        // If 'd' is a node, highlight all connected links
        link
          .filter((l) => l.source === d || l.target === d)
          .transition()
          .duration(200)
          .attr('stroke-opacity', 1)
          .select('path')
          .style('stroke', '#DBDDF2');
      }
    };

    // Modify the resetHighlights function
    const resetHighlights = () => {
      node
        .transition()
        .duration(200)
        .attr('width', (d) => d.x1 - d.x0)
        .attr('x', (d) => d.x0);

      // Remove highlight icons
      svg.selectAll('.highlight-icon').transition().duration(200).attr('opacity', 0).remove();

      link.each(function (d) {
        d3.select(this)
          .transition()
          .duration(200)
          .attr('stroke-opacity', 0.5)
          .select('path')
          .style('stroke', (d) => {
            // Define a unique gradient ID based on the node index
            const gradientId = `gradient-others-${d.index}`;

            // Create the gradient definition inside the <defs> section of the SVG
            const defs = svg.append('defs');
            const linearGradient = defs.append('linearGradient').attr('id', gradientId);

            // Define the color transition inside the gradient from one color to another
            linearGradient.append('stop').attr('offset', '60%').attr('stop-color', '#9FA6DC'); // Start color

            linearGradient.append('stop').attr('offset', '100%').attr('stop-color', '#FFAB40'); // End color

            if (d.id === selectedSegmentId && d.id !== 'Others') return '#929AD7';
            if (d.id === 'Others') return `url(#${gradientId})`;
            return '#F0F1FB';
          });
      });
    };

    // Add interactivity
    node
      .on('mouseover', (event, d) => {
        highlightRightNodes(d);
        updateTooltip(event, d);
      })
      .on('mouseout', (event) => {
        resetHighlights();
        updateTooltip(null, null, false);
      })
      .on('click', (event, d) => {
        handleNodeClick(event, d);
      });

    link
      .on('mouseover', (event, d) => {
        highlightRightNodes(d);
        updateTooltip(event, d);
      })
      .on('mouseout', () => {
        resetHighlights();
        updateTooltip(null, null, false);
      })
      .on('click', (event, d) => {
        handleNodeClick(event, d);
      });
  }, [nodes, links, dimensions, updateTooltip, selectedSegmentId]);

  return (
    <div ref={containerRef} style={{ padding: '20px 0px', height: '75vh' }}>
      <svg ref={svgRef} />
      {tooltipData.show &&
        ReactDOM.createPortal(
          <CustomTooltip
            x={tooltipData.x - 295}
            y={tooltipData?.data?.id === 'Others' ? (tooltipData.y ?? 0) - 135 : (tooltipData.y ?? 0) - 105}
            data={tooltipData}
            totals={nodes[0]}
          />,
          document.body,
        )}
    </div>
  );
};

export default SankeyChart;
