/* eslint-disable max-len */
import { Tooltip } from '~/core/components/shared';
import { IntentThemesSortEnum } from '~/core/types/graphql.types';

import { InfoIcon } from '../../../ContentGapAnalysis/Tables/styles';

export const headerCells = [
  {
    id: IntentThemesSortEnum.Theme,
    label: (
      <>
        <Tooltip offset={[0, -10]} placement="bottom" title="">
          <InfoIcon />
        </Tooltip>
        <span>Intent Theme</span>
      </>
    ),
    numeric: false,
    disablePadding: true,
  },
  {
    id: IntentThemesSortEnum.Intents,
    label: (
      <>
        <Tooltip
          offset={[0, -10]}
          placement="bottom"
          title="Search Intent (also known as “User Intent”) is the purpose behind a search. It is the content they are looking for. Understanding these intents will help you to acquire more customers."
        >
          <InfoIcon />
        </Tooltip>
        <span>Search Intent</span>
      </>
    ),

    numeric: false,
    disablePadding: true,
  },

  {
    id: IntentThemesSortEnum.Keywords,
    label: 'Keywords',
    numeric: false,
    disablePadding: true,
  },
  {
    id: IntentThemesSortEnum.Impressions,
    label: (
      <>
        <Tooltip
          offset={[0, -10]}
          placement="bottom"
          title="Content Coverage indicates which identified search intents have intent-aligned content and which do not."
        >
          <InfoIcon />
        </Tooltip>
        <span>nValue</span>
      </>
    ),
    numeric: false,
    disablePadding: true,
    width: '233.33px',
    sx: {
      minWidth: '233.33px',
    },
  },
];
