import { Typography } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Button } from '~/core/components/shared';
import { EMAIL_PATTERN_REGEX } from '~/core/constants/validationPatterns.constants';
import { PlatformAccountTypeEnum } from '~/core/types/graphql.types';
import { updatePlatformAccountAction } from '~/store/actions/account.action';
import { inviteAccountUserAction } from '~/store/actions/accountUsers.action';

import { agencyAccountSteps, customerAccountSteps } from '../../../data';
import { CreateNewPlatformAccountInput, StepsEnum } from '../../../types';
import { StepProps } from '../types';
import * as Styled from './styles';

const InviteUser = ({ activeStep, setActiveStep, accountId, accountData }: StepProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    handleSubmit,
    watch,
    control,
    formState: { errors },
  } = useForm<CreateNewPlatformAccountInput>({
    reValidateMode: 'onSubmit',
  });

  const userEmail = watch('userEmail');

  const handleBackStep = () =>
    accountData?.companyType === PlatformAccountTypeEnum.Agency
      ? setActiveStep(StepsEnum.CreateAccount)
      : setActiveStep(StepsEnum.GoogleAuth);

  const handleSendInviteAndFinish = () => {
    if (accountId) {
      dispatch(
        inviteAccountUserAction.request({
          inviteAccountUserData: {
            accountId,
            email: userEmail,
            isAdmin: true,
          },
        }),
      );

      dispatch(
        updatePlatformAccountAction.request({
          accountId,
          accountData: {
            contactEmail: userEmail,
          },
        }),
      );

      localStorage.removeItem('accountId');
      localStorage.removeItem('activeStep');
      navigate('../platform-admin-console');
    }
  };

  return (
    <>
      <Styled.CardContent data-testid="inviteUser">
        <Styled.VerticalStepper
          steps={
            accountData?.companyType === PlatformAccountTypeEnum.Agency ? agencyAccountSteps : customerAccountSteps
          }
          activeStep={Number(activeStep)}
        />
        <Styled.StepContent>
          <Styled.HeaderContainer>
            <Typography fontSize={20} fontWeight={600}>
              Invite an admin for this account
            </Typography>
          </Styled.HeaderContainer>
          <Styled.FormContainer>
            <Controller
              name="userEmail"
              rules={{
                required: true,
                pattern: {
                  value: EMAIL_PATTERN_REGEX,
                  message: 'Invalid email',
                },
              }}
              control={control}
              render={({ field: { onChange, value } }) => (
                <Styled.CustomInput
                  data-testid="userEmail"
                  value={value}
                  onChange={onChange}
                  required
                  label="User Email"
                  error={!!errors?.userEmail}
                  helperText={!!errors?.userEmail && 'Enter valid email'}
                />
              )}
            />
          </Styled.FormContainer>
        </Styled.StepContent>
      </Styled.CardContent>
      <Styled.CardActions>
        <Button data-testid="backButton" onClick={handleBackStep} label="Back" />
        <Button
          data-testid="finishButton"
          disabled={!userEmail}
          onClick={handleSubmit(handleSendInviteAndFinish)}
          label="Finish And Send Invite"
          variant="contained"
          color="primary"
        />
      </Styled.CardActions>
    </>
  );
};

export default InviteUser;
