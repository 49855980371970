import { TextField, TextFieldProps } from '@mui/material';

const Input = ({ onChange, ...elementProps }: TextFieldProps) => {
  const handleValueBlur = (event: any) => {
    const { value } = event.target;
    const trimmedValue = value.trim();
    if (!trimmedValue) {
      onChange(trimmedValue);
    }
    onChange(trimmedValue);
  };

  return (
    <TextField data-testid="formTextInput" onChange={onChange} fullWidth onBlur={handleValueBlur} {...elementProps} />
  );
};

export default Input;
