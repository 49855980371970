// Import necessary dependencies
import { ApolloError } from '@apollo/client';
import { Dispatch, FC, ReactNode, SetStateAction, createContext, useMemo, useState } from 'react';

import { PlatformUserAccountsSchema } from '~/core/types/graphql.types';

// Define the context interface
interface AccountsContextProps {
  platformAccounts: PlatformUserAccountsSchema[];
  platformAccountsLoading: boolean;
  platformAccountsError: ApolloError | null;
  selectedPlatformAccount: PlatformUserAccountsSchema;
  setPlatformAccounts: Dispatch<SetStateAction<PlatformUserAccountsSchema[]>>;
  setPlatformAccountsLoading: Dispatch<SetStateAction<boolean>>;
  setPlatformAccountsError: Dispatch<SetStateAction<ApolloError | null>>;
  setSelectedPlatformAccount: Dispatch<SetStateAction<any>>;
}

// Create the context
export const AccountsContext = createContext<AccountsContextProps | undefined>(undefined);

// Create the provider component
export const AccountsProvider: FC<{ children: ReactNode }> = ({ children }) => {
  // Define state variables
  const [platformAccounts, setPlatformAccounts] = useState<PlatformUserAccountsSchema[]>([]);
  const [platformAccountsLoading, setPlatformAccountsLoading] = useState<boolean>(false);
  const [platformAccountsError, setPlatformAccountsError] = useState<ApolloError | null>(null);
  const [selectedPlatformAccount, setSelectedPlatformAccount] = useState<any>(null);

  // Memoize the state
  const state: AccountsContextProps = useMemo(
    () => ({
      platformAccounts,
      platformAccountsLoading,
      platformAccountsError,
      selectedPlatformAccount,
      setPlatformAccounts,
      setPlatformAccountsLoading,
      setPlatformAccountsError,
      setSelectedPlatformAccount,
    }),
    [
      platformAccounts,
      platformAccountsLoading,
      platformAccountsError,
      selectedPlatformAccount,
      setPlatformAccounts,
      setPlatformAccountsLoading,
      setPlatformAccountsError,
      setSelectedPlatformAccount,
    ],
  );

  // Return the provider with the memoized state
  return <AccountsContext.Provider value={state}>{children}</AccountsContext.Provider>;
};
