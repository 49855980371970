import { Alert } from '@mui/material';
import { passwordStrength } from 'check-password-strength';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import { Button, Dialog, DialogFooter, DialogHeader, PasswordChecker, PasswordInput } from '~/core/components/shared';
import { FormValidationType } from '~/core/constants/formValidationTypes.constants';
import { resetUserSuccessFlagAction, updateUserAction } from '~/store/actions/user.action';
import { authUserSelector } from '~/store/selectors/auth.selector';
import { userErrorSelector, userLoadingSelector, userSuccessSelector } from '~/store/selectors/user.selector';

import * as Styled from './styles';
import { EditPasswordDialogProps, EditPasswordForm } from './types';

const EditPasswordDialog: React.FC<EditPasswordDialogProps> = ({ open, handleClose }) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
    watch,
  } = useForm<EditPasswordForm>();

  const dispatch = useDispatch();
  const isLoading = useSelector(userLoadingSelector);
  const userError = useSelector(userErrorSelector);
  const isSuccessful = useSelector(userSuccessSelector);
  const currentUser = useSelector(authUserSelector);

  const passwordStrengthCheck = passwordStrength(watch('newPassword'));
  const isPasswordSecure = passwordStrengthCheck.contains.length >= 4 && passwordStrengthCheck.length >= 8;

  const handleSubmitButton = (data: EditPasswordForm) => {
    dispatch(
      updateUserAction.request({
        userId: currentUser.UserId,
        userData: {
          password: data.password,
          newPassword: data.newPassword,
          confirmPassword: data.confirmPassword,
        },
      }),
    );
  };

  useEffect(() => {
    if (isSuccessful) {
      dispatch(resetUserSuccessFlagAction());
      handleClose();
    }
  }, [isSuccessful, handleClose, dispatch]);

  return (
    <Dialog open={open} handleCloseButton={handleClose} data-testid="editPasswordDialog">
      <form data-testid="editPasswordForm" onSubmit={handleSubmit(handleSubmitButton)}>
        <Styled.DialogContainer disableGutters>
          <DialogHeader title="Edit Password" />
          <Styled.DialogContent>
            <Controller
              name="password"
              rules={{ required: true }}
              control={control}
              render={({ field: { onChange, value } }) => (
                <PasswordInput
                  data-testid="currentPasswordInput"
                  label="Current Password"
                  value={value}
                  onChange={onChange}
                  required
                  helperText={userError?.toString() === 'Error: Incorrect password' && 'Incorrect password.'}
                  error={!!userError}
                />
              )}
            />
            <Controller
              name="newPassword"
              rules={{ required: true, validate: (newPass) => newPass !== watch('password') }}
              control={control}
              render={({ field: { onChange, value } }) => (
                <PasswordInput
                  data-testid="newPasswordInput"
                  label="New Password"
                  value={value}
                  onChange={onChange}
                  required
                  helperText={
                    errors?.newPassword?.type === FormValidationType.Validate &&
                    'New password cannot be the same as old password.'
                  }
                  error={!!errors.newPassword}
                />
              )}
            />
            {!isPasswordSecure ? (
              <PasswordChecker error={!!errors.newPassword} passwordStrength={passwordStrengthCheck} />
            ) : (
              <Alert severity="success">Password is secure</Alert>
            )}
            <Controller
              name="confirmPassword"
              rules={{ required: true, validate: (confPassword) => confPassword === watch('newPassword') }}
              control={control}
              render={({ field: { onChange, value } }) => (
                <PasswordInput
                  data-testid="confirmNewPasswordInput"
                  label="Confirm Password"
                  value={value}
                  onChange={onChange}
                  required
                  helperText={
                    errors?.confirmPassword?.type === FormValidationType.Validate && 'Password does not match'
                  }
                  error={!!errors.confirmPassword}
                />
              )}
            />
          </Styled.DialogContent>
          <DialogFooter>
            <Button data-testid="cancelButton" onClick={handleClose} label="Cancel" variant="outlined">
              Cancel
            </Button>
            <Button data-testid="submitButton" label="Update" variant="contained" type="submit" disabled={isLoading}>
              Update
            </Button>
          </DialogFooter>
        </Styled.DialogContainer>
      </form>
    </Dialog>
  );
};

export default EditPasswordDialog;
