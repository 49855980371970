import AddIcon from '@mui/icons-material/Add';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ChevronRightOutlined from '@mui/icons-material/ChevronRightOutlined';
import {
  Box,
  Breadcrumbs,
  LinearProgress,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  SelectChangeEvent,
  Table,
  TableRow,
  Typography,
} from '@mui/material';
import { ChangeEvent, MouseEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import {
  Button,
  CircularProgress,
  PageHeader,
  SearchBar,
  TableCell,
  TableHead,
  TablePagination,
} from '~/core/components/shared';
import {
  FilterAccountStatusEnum,
  FilterAccountTypeEnum,
  tablePaginationRowsPerPageOptions,
} from '~/core/constants/table.constants';
import { accountStatusEnumReuse, accountTypeEnumReuse, formatDate } from '~/core/helpers';
import useDebounce from '~/core/hooks/useDebounce';
import { AccountStatusEnum, PlatformAccountSortableEnum, PlatformAccountTypeEnum } from '~/core/types/graphql.types';
import { SortType } from '~/core/types/table.types';
import { allAccountsAction, updateAccountBillingInfoAction } from '~/store/actions/account.action';
import {
  accountLoadingSelector,
  allAccountsPaginationSelector,
  allAccountsSelector,
} from '~/store/selectors/account.selector';

import { CompanySettings } from '../../AdminSettings/CompanySettings';
import { headerCells } from './platformAdminHeaderCells';
import * as Styled from './styles';

const PlatformAdminConsole: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [order, setOrder] = useState<SortType>(SortType.Asc);
  const [orderBy, setOrderBy] = useState<PlatformAccountSortableEnum>(PlatformAccountSortableEnum.Name);
  const [page, setPage] = useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [searchValue, setSearchValue] = useState('');
  const [inAccountView, setInAccountView] = useState<boolean>(false);
  const [accountViewId, setAccountViewId] = useState<number>(null);
  const [accountViewName, setAccountViewName] = useState<string>('');
  const [accountTypeFilterValue, setAccountTypeFilterValue] = useState<FilterAccountTypeEnum>(
    FilterAccountTypeEnum.AnyType,
  );
  const [accountStatusFilterValue, setAccountStatusFilterValue] = useState<FilterAccountStatusEnum>(
    FilterAccountStatusEnum.AnyStatus,
  );

  const debouncedSearch = useDebounce(searchValue, 500);
  const allAccounts = useSelector(allAccountsSelector);
  const allAccountsPagination = useSelector(allAccountsPaginationSelector);
  const accountsLoading = useSelector(accountLoadingSelector);

  const handleRequestSort = (event: MouseEvent<unknown>, property: any) => {
    const isAsc = orderBy === property && order === SortType.Asc;
    setOrder(isAsc ? SortType.Desc : SortType.Asc);
    setOrderBy(property);
    setPage(1);
  };

  const handleSearchInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
    // * This is being used to test Sentry releases and sourcemaps
    if (searchValue.toLowerCase() === 'sentry') {
      throw Error('Testing Sentry releases and sourcemaps');
    }
  };

  const handleAccountTypeFilterChange = (event: any) => {
    setAccountTypeFilterValue(event.target.value);
    setPage(1);
  };

  const handleAccountStatusFilterChange = (event: any) => {
    setAccountStatusFilterValue(event.target.value);
    setPage(1);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  const handleAccountStatusChange =
    (accountId: number, currentStatus: AccountStatusEnum) => (event: SelectChangeEvent<AccountStatusEnum>) => {
      if (event.target.value !== currentStatus) {
        dispatch(
          updateAccountBillingInfoAction.request({
            accountId,
            accountData: {
              status:
                event.target.value === AccountStatusEnum.Active ? AccountStatusEnum.Active : AccountStatusEnum.Inactive,
            },
          }),
        );
      }
    };

  const handleCloseAccountView = () => {
    setInAccountView(false);
  };

  const handleViewAccountButton = (accountId: number, accountName: string) => () => {
    setAccountViewId(accountId);
    setAccountViewName(accountName);
    setInAccountView(true);
  };

  const handleNavigateToCreateAccount = () => {
    navigate('../create-new-platform-account');
  };

  useEffect(() => {
    dispatch(
      allAccountsAction.request({
        page,
        pageSize: rowsPerPage,
        sortBy: { field: orderBy, desc: order === SortType.Desc },
        searchValue: debouncedSearch,
        filterSchema: {
          accountType: accountTypeEnumReuse(accountTypeFilterValue),
          status: accountStatusEnumReuse(accountStatusFilterValue),
        },
      }),
    );
  }, [
    dispatch,
    page,
    rowsPerPage,
    orderBy,
    order,
    debouncedSearch,
    accountTypeFilterValue,
    accountStatusFilterValue,
    inAccountView,
  ]);

  const breadcrumbs = [
    <Styled.BreadcrumbsTypography
      data-testid="handleCloseAccountView"
      to="/platform/platform-admin-console"
      onClick={handleCloseAccountView}
      key="1"
    >
      Platform Accounts
    </Styled.BreadcrumbsTypography>,
    <Styled.BreadcrumbsStaticTypography key="accountName">
      {accountViewName && accountViewName}
    </Styled.BreadcrumbsStaticTypography>,
  ];

  return (
    <Styled.Container>
      {inAccountView ? (
        <CompanySettings
          forceAccountId={accountViewId}
          alternativeHeader={
            <PageHeader>
              <Breadcrumbs separator={<ChevronRightOutlined fontSize="small" />}>{breadcrumbs}</Breadcrumbs>
            </PageHeader>
          }
        />
      ) : (
        <Styled.PlatformAdminConsoleContainer data-testid="platformAdminConsoleContainer">
          {accountsLoading && !allAccounts ? (
            <CircularProgress height="80vh" />
          ) : (
            <>
              <PageHeader>
                <Typography fontSize={24} fontWeight="bold">
                  Platform Accounts
                </Typography>
              </PageHeader>
              <Paper sx={{ margin: '0 20px' }}>
                <SearchBar>
                  <Styled.SearchContainer>
                    <Styled.SearchFilters>
                      <Styled.SearchFieldContainer
                        data-testid="searchFieldContainer"
                        size="medium"
                        onChange={handleSearchInputChange}
                        value={searchValue}
                        fullWidth
                        placeholder="Search Accounts"
                      />
                      <Styled.FilterContainer
                        data-testid="handleAccountTypeFilterChange"
                        fullWidth
                        values={Object.values(FilterAccountTypeEnum)}
                        size="small"
                        label="Account Type"
                        value={accountTypeFilterValue}
                        onChange={handleAccountTypeFilterChange}
                      />
                      <Styled.FilterContainer
                        data-testid="handleRoleFilterChange"
                        fullWidth
                        values={Object.values(FilterAccountStatusEnum)}
                        size="small"
                        label="Account Status"
                        value={accountStatusFilterValue}
                        onChange={handleAccountStatusFilterChange}
                      />
                    </Styled.SearchFilters>
                    <Styled.NewAccountButton
                      size="small"
                      label="New Account"
                      variant="contained"
                      startIcon={<AddIcon />}
                      onClick={handleNavigateToCreateAccount}
                    />
                  </Styled.SearchContainer>
                </SearchBar>
                {accountsLoading || !allAccounts ? (
                  <LinearProgress />
                ) : (
                  <Box>
                    <Styled.TableContainer>
                      <Table stickyHeader>
                        <TableHead
                          headCells={headerCells}
                          order={order}
                          orderBy={orderBy}
                          onRequestSort={handleRequestSort}
                        />
                        <Styled.TableBody>
                          {allAccounts.map((account) => (
                            <TableRow hover key={account.id}>
                              <TableCell>{account.name}</TableCell>
                              <TableCell>{account.contactEmail}</TableCell>
                              <TableCell>
                                {account.companyType === PlatformAccountTypeEnum.Agency && 'Agency'}
                                {account.companyType === PlatformAccountTypeEnum.Customer && 'Customer'}
                              </TableCell>
                              <TableCell>
                                <Styled.AccountStatusDropdown variant="outlined" size="small" fullWidth>
                                  <Select
                                    data-testid="statusSelect"
                                    size="small"
                                    value={account.status}
                                    IconComponent={ArrowDropDownIcon}
                                    input={<OutlinedInput size="small" />}
                                    onChange={handleAccountStatusChange(account.id, account.status)}
                                    MenuProps={{
                                      PaperProps: {
                                        style: {
                                          marginTop: 4,
                                          borderRadius: 8,
                                        },
                                      },
                                    }}
                                  >
                                    <MenuItem
                                      key={AccountStatusEnum.Active}
                                      value={AccountStatusEnum.Active}
                                      selected={account.status === AccountStatusEnum.Active}
                                    >
                                      Active
                                    </MenuItem>
                                    <MenuItem
                                      key={AccountStatusEnum.Inactive}
                                      value={AccountStatusEnum.Inactive}
                                      selected={account.status === AccountStatusEnum.Inactive}
                                    >
                                      Inactive
                                    </MenuItem>
                                  </Select>
                                </Styled.AccountStatusDropdown>
                              </TableCell>
                              <TableCell>{formatDate(new Date(account.createdAt))}</TableCell>
                              <TableCell align="right">
                                <Button
                                  data-testid="handleViewAccountButton"
                                  label="View"
                                  variant="contained"
                                  color="secondary"
                                  disabled
                                  onClick={handleViewAccountButton(account.id, account.name)}
                                />
                              </TableCell>
                            </TableRow>
                          ))}
                        </Styled.TableBody>
                      </Table>
                    </Styled.TableContainer>
                    <TablePagination
                      rowsPerPageOptions={tablePaginationRowsPerPageOptions}
                      count={allAccountsPagination.total}
                      rowsPerPage={rowsPerPage}
                      page={page - 1}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                  </Box>
                )}
              </Paper>
            </>
          )}
        </Styled.PlatformAdminConsoleContainer>
      )}
    </Styled.Container>
  );
};

export default PlatformAdminConsole;
