import { Link, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { AuthContainer } from '~/core/components/layouts/AuthContainer';
import { PasswordResetForm as Form } from '~/core/types/auth.types';
import { resetAuthErrorsAction, setNewPasswordAction } from '~/store/actions/auth.action';
import { authErrorSelector, authLoadingSelector } from '~/store/selectors/auth.selector';

import { PasswordResetForm } from './components/PasswordResetForm';

const PasswordReset: React.FC = () => {
  const location = useLocation();
  const { email } = location.state as { email: string };
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const error = useSelector(authErrorSelector);
  const isLoading = useSelector(authLoadingSelector);
  const [confirmCode, setConfirmCode] = useState<string>('');

  const onSubmit = (data: Form) => {
    dispatch(setNewPasswordAction.request({ email, ...data }));
    setConfirmCode(data.confirmationCode);
  };

  useEffect(() => {
    if (!error && !isLoading && confirmCode) {
      navigate('../sign-in', { replace: true });
      dispatch(resetAuthErrorsAction());
    }
  }, [error, isLoading, confirmCode, navigate, dispatch]);

  return (
    <AuthContainer data-testid="resetPasswordContainer">
      <Typography align="center" fontSize={24}>
        Choose a new password
      </Typography>
      <PasswordResetForm onSubmit={onSubmit} />
      <Link data-testid="navigateBack" textAlign="center" underline="none" href="/auth/sign-in">
        Cancel
      </Link>
    </AuthContainer>
  );
};

export default PasswordReset;
