import { Box, LinearProgress, Table, TableContainer } from '@mui/material';

import { TableBody, TableCell, TableHead, TablePagination, TableRow } from '~/core/components/shared/Table';
import { tablePaginationRowsPerPageOptions } from '~/core/constants/table.constants';
import { formatDate, parseSnakeUppercaseToNormal } from '~/core/helpers';

import { headCells } from './data';
import * as Styled from './styles';
import { GoogleAdsAccountsTableProps } from './types';

const GoogleAdsAccountsTable: React.FC<GoogleAdsAccountsTableProps> = ({ accounts, tableOptions, isLoading }) => (
  <Box>
    {isLoading ? (
      <LinearProgress data-testid="progress" />
    ) : (
      <TableContainer
        sx={{
          borderRadius: '0 0 8px 8px',
        }}
      >
        <TablePagination
          rowsPerPageOptions={tablePaginationRowsPerPageOptions}
          count={tableOptions.total}
          rowsPerPage={tableOptions.rowsPerPage}
          page={tableOptions.page - 1}
          onPageChange={tableOptions.handleChangePage}
          onRowsPerPageChange={tableOptions.handleChangeRowsPerPage}
        />
        <Table stickyHeader data-testid="googleAdsAccountsTable">
          <TableHead
            headCells={headCells}
            order={tableOptions.order}
            orderBy={tableOptions.orderBy}
            onRequestSort={tableOptions.handleRequestSort}
          />
          <TableBody>
            {accounts.map(({ customerId, name, access, updatedAt, id, manager }) => {
              const lastUpdate = formatDate(new Date(updatedAt));
              const userAccess = access && parseSnakeUppercaseToNormal(access);

              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={id}>
                  <TableCell component="th" scope="row" padding="none">
                    {manager ? (
                      <>
                        {name}
                        <Styled.MMCChip
                          tooltipProps={{
                            title: 'Manager Account - MCC accounts will not be displayed in MotiveMap',
                            placement: 'right',
                            children: null,
                          }}
                          label="MCC"
                          color="primary"
                          size="small"
                        />
                      </>
                    ) : (
                      name
                    )}
                  </TableCell>
                  <TableCell align="left">{customerId}</TableCell>
                  <TableCell align="left">{userAccess || '-'}</TableCell>
                  <TableCell align="left">{lastUpdate}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    )}
  </Box>
);

export default GoogleAdsAccountsTable;
