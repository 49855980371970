import { Effect, call, put, select, takeLatest } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';

import { errorNotificationMessages, successNotificationMessage } from '~/core/constants/notificatonMessages.constants';
import { apolloServiceRequest, graphqlClient } from '~/core/services';
import { graphqlServices } from '~/core/services/graphql';
import { OwnIntegrationAccountsQuery } from '~/core/services/graphql/integrationAccounts/integrationAccounts.queries.generated';
import {
  getAdwordsAccountsRequest,
  postGoogleAuthSignIn,
} from '~/core/services/rest/integrationAccounts/integrationAccounts.services';
import { GoogleAdsUrl } from '~/core/types/googleAdsAccounts.types';
import { PageInfoSchema } from '~/core/types/graphql.types';

import { notificationAction } from '../actions/notifications.action';
import {
  linkNewPaidSearchAccountAction,
  paidIntegrationAccountsAction,
  postGoogleAuthSignInAction,
  refreshPaidIntegrationAccountsAction,
} from '../actions/paidSearchIntegrations.action';
import { paidSearchAccountsPageInfoSelector } from '../selectors/paidSearchAccounts.selector';

export class PaidSearchIntegrationsAccountsSagaWorker {
  static *postGoogleAuthSignIn({ payload }: ActionType<typeof postGoogleAuthSignInAction.request>) {
    try {
      const data: GoogleAdsUrl = yield call(postGoogleAuthSignIn, payload.accountId, payload.redirectionUri);
      yield put(postGoogleAuthSignInAction.success(data));
    } catch (error: any) {
      yield put(postGoogleAuthSignInAction.failure(error.response.data.detail));
    }
  }

  static *refreshPaidIntegrationAccounts({ payload }: ActionType<typeof refreshPaidIntegrationAccountsAction.request>) {
    try {
      const pageInfo: PageInfoSchema = yield select(paidSearchAccountsPageInfoSelector);

      yield call(getAdwordsAccountsRequest, payload);

      const data: OwnIntegrationAccountsQuery = yield call(
        apolloServiceRequest,
        graphqlClient,
        'query',
        graphqlServices.integrationAccounts.integrationAccountsGql,
        { platformAccountId: payload, ...pageInfo },
      );

      yield put(refreshPaidIntegrationAccountsAction.success(data));
      yield put(notificationAction.success({ message: successNotificationMessage.successRefreshAccounts }));
    } catch (error: any) {
      yield put(refreshPaidIntegrationAccountsAction.failure(error));
      yield put(
        notificationAction.failure({ message: errorNotificationMessages.errorRefreshAccounts, severity: 'error' }),
      );
    }
  }

  static *linkNewPaidSearchAccount({ payload }: ActionType<typeof linkNewPaidSearchAccountAction.request>) {
    try {
      yield call(getAdwordsAccountsRequest, payload);

      const data: OwnIntegrationAccountsQuery = yield call(
        apolloServiceRequest,
        graphqlClient,
        'query',
        graphqlServices.integrationAccounts.integrationAccountsGql,
        { platformAccountId: payload, page: 1, pageSize: 5 },
      );

      yield put(linkNewPaidSearchAccountAction.success(data));
      yield put(notificationAction.success({ message: successNotificationMessage.successLinkAccount }));
    } catch (error: any) {
      yield put(linkNewPaidSearchAccountAction.failure(error));
      yield put(
        notificationAction.failure({ message: errorNotificationMessages.errorLinkingNewAccount, severity: 'error' }),
      );
    }
  }

  static *paidIntegrationAccounts({ payload }: ActionType<typeof paidIntegrationAccountsAction.request>) {
    try {
      const data: OwnIntegrationAccountsQuery = yield call(
        apolloServiceRequest,
        graphqlClient,
        'query',
        graphqlServices.integrationAccounts.integrationAccountsGql,
        payload,
      );

      yield put(paidIntegrationAccountsAction.success(data));
    } catch (error: any) {
      yield put(paidIntegrationAccountsAction.failure(error.message));
    }
  }
}

export function* paidSearchAccountsSaga(): Generator<Effect, void> {
  yield takeLatest(
    paidIntegrationAccountsAction.request,
    PaidSearchIntegrationsAccountsSagaWorker.paidIntegrationAccounts,
  );
  yield takeLatest(postGoogleAuthSignInAction.request, PaidSearchIntegrationsAccountsSagaWorker.postGoogleAuthSignIn);
  yield takeLatest(
    refreshPaidIntegrationAccountsAction.request,
    PaidSearchIntegrationsAccountsSagaWorker.refreshPaidIntegrationAccounts,
  );
  yield takeLatest(
    linkNewPaidSearchAccountAction.request,
    PaidSearchIntegrationsAccountsSagaWorker.linkNewPaidSearchAccount,
  );
}
