import { Alert, LinearProgress, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Button, Dialog, DialogFooter, DialogHeader, Input, PasswordInput } from '~/core/components/shared';
import { EMAIL_PATTERN_REGEX } from '~/core/constants/validationPatterns.constants';
import {
  resetAuthSuccessFlagAction,
  verifyNewEmailAction,
  verifyNewEmailResendAction,
} from '~/store/actions/auth.action';
import { resetUserErrorAction, resetUserSuccessFlagAction, updateUserAction } from '~/store/actions/user.action';
import {
  authErrorSelector,
  authLoadingSelector,
  authSuccessSelector,
  authUserSelector,
} from '~/store/selectors/auth.selector';
import { userErrorSelector, userLoadingSelector, userSuccessSelector } from '~/store/selectors/user.selector';

import { VerificationCodeText } from './data';
import * as Styled from './styles';
import { EditEmailDialogProps, EditEmailStages, NewEmailFormValues } from './types';

const EditEmailDialog: React.FC<EditEmailDialogProps> = ({ open, handleClose }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    handleSubmit,
    control,
    formState: { errors },
    watch,
  } = useForm<NewEmailFormValues>({
    reValidateMode: 'onSubmit',
    defaultValues: { email: '', confirmEmail: '', password: '', verificationCode: '' },
  });

  const email = watch('email');
  const confirmEmail = watch('confirmEmail');
  const verificationCode = watch('verificationCode');

  const [stage, setStage] = useState<EditEmailStages>(EditEmailStages.First);

  const userSuccess = useSelector(userSuccessSelector);
  const userError = useSelector(userErrorSelector);
  const userLoading = useSelector(userLoadingSelector);

  const authSuccess = useSelector(authSuccessSelector);
  const authError = useSelector(authErrorSelector);
  const currentUser = useSelector(authUserSelector);
  const isLoading = useSelector(authLoadingSelector);

  useEffect(() => {
    if (stage === EditEmailStages.First) {
      if (userSuccess) {
        dispatch(resetUserSuccessFlagAction());
        setStage(EditEmailStages.Second);
      }
    }
    if (stage === EditEmailStages.Second) {
      if (authSuccess) {
        dispatch(resetAuthSuccessFlagAction());
        handleClose();
      }
    }
  }, [userSuccess, authSuccess, stage, dispatch, handleClose, navigate, userError]);

  const submitNewEmail = ({ email, password }: NewEmailFormValues) => {
    dispatch(resetUserErrorAction());
    dispatch(
      updateUserAction.request({
        userId: currentUser.UserId,
        userData: { email, password },
      }),
    );
  };

  const submitVerificationCode = ({ verificationCode }: NewEmailFormValues) => {
    dispatch(
      verifyNewEmailAction.request({
        userId: currentUser.UserId,
        confirmationCode: verificationCode,
      }),
    );
  };

  const handleResendVerificationCode = () => {
    dispatch(verifyNewEmailResendAction.request());
  };

  const handleCloseWithNavigation = () => {
    handleClose();
    if (stage === EditEmailStages.Second && !authSuccess) {
      navigate('../auth/confirm');
    }
  };

  return (
    <Dialog open={open} handleCloseButton={handleCloseWithNavigation} data-testid="editEmailDialog">
      <DialogHeader title="Edit Email Address" />
      {(isLoading || userLoading) && <LinearProgress />}
      {stage === EditEmailStages.First && (
        <Styled.DialogForm onSubmit={handleSubmit(submitNewEmail)}>
          {userError && (
            <Alert severity="error">
              <Typography fontSize={16}>{userError}</Typography>
            </Alert>
          )}
          <Styled.DialogContent>
            <Controller
              control={control}
              name="email"
              rules={{
                required: true,
                validate: (value) => EMAIL_PATTERN_REGEX.test(value),
              }}
              render={({ field: { onChange, value } }) => (
                <Input
                  label="New Email Address"
                  autoComplete="off"
                  value={value}
                  onChange={onChange}
                  helperText={errors?.email && 'Invalid email address'}
                  error={!!errors?.email}
                />
              )}
            />

            <Controller
              name="confirmEmail"
              rules={{ required: true, validate: () => confirmEmail === email }}
              control={control}
              render={({ field: { onChange, value } }) => (
                <Input
                  data-testid="confirmEmailInput"
                  label="Confirm Email Address"
                  value={value}
                  onChange={onChange}
                  required
                  helperText={errors?.confirmEmail && 'Emails do not match'}
                  error={errors?.confirmEmail && email !== value}
                />
              )}
            />

            <Controller
              control={control}
              name="password"
              render={({ field: { onChange, value } }) => (
                <PasswordInput label="Current Password" value={value} onChange={onChange} autoComplete="off" />
              )}
            />
          </Styled.DialogContent>
          <DialogFooter>
            <Button onClick={handleClose} label="Cancel" variant="outlined">
              Cancel
            </Button>
            <Button type="submit" label="Update" variant="contained" disabled={!email}>
              Verify
            </Button>
          </DialogFooter>
        </Styled.DialogForm>
      )}
      {stage === EditEmailStages.Second && (
        <Styled.DialogForm onSubmit={handleSubmit(submitVerificationCode)} data-testid="editEmailConfirmForm">
          <Styled.DialogContent>
            <Styled.VerificationCodeText>{VerificationCodeText}</Styled.VerificationCodeText>
            <Controller
              control={control}
              name="verificationCode"
              rules={{
                required: true,
              }}
              render={({ field: { onChange, value } }) => (
                <Input
                  label="Verification Code"
                  value={value}
                  onChange={onChange}
                  helperText={authError && 'Incorrect verification code.'}
                  error={!!authError}
                  autoComplete="off"
                />
              )}
            />
            <Styled.ResendLink onClick={handleResendVerificationCode}>Resend Code</Styled.ResendLink>
          </Styled.DialogContent>
          <DialogFooter>
            <Button onClick={handleCloseWithNavigation} label="Cancel" variant="outlined">
              Cancel
            </Button>
            <Button type="submit" label="Change Email" variant="contained" disabled={verificationCode.length !== 6}>
              Update
            </Button>
          </DialogFooter>
        </Styled.DialogForm>
      )}
    </Dialog>
  );
};

export default EditEmailDialog;
