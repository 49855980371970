import { createReducer } from 'typesafe-actions';

import { User } from '~/core/services/graphql/user/user.types';

import {
  UserActionUnion,
  getUserAction,
  updateUserAction,
  resetUserSuccessFlagAction,
  resetUserErrorAction,
} from '../actions/user.action';

export interface State {
  user: User;
  isLoading: boolean;
  error: string;
  success: boolean;
}

export const initialState: State = {
  user: null,
  isLoading: false,
  error: null,
  success: null,
};

export const reducer = createReducer<State, UserActionUnion>(initialState)
  .handleAction(getUserAction.success, (state, action) => ({
    ...state,
    isLoading: false,
    user: action.payload,
    error: null,
  }))
  .handleAction(getUserAction.failure, (state, action) => ({
    ...state,
    isLoading: false,
    error: action.payload,
  }))
  .handleAction(getUserAction.request, (state) => ({
    ...state,
    isLoading: true,
    error: null,
  }))
  .handleAction(updateUserAction.success, (state, action) => ({
    ...state,
    isLoading: false,
    user: action.payload,
    error: null,
    success: true,
  }))
  .handleAction(updateUserAction.failure, (state, action) => ({
    ...state,
    isLoading: false,
    error: action.payload,
    success: false,
  }))
  .handleAction(updateUserAction.request, (state) => ({
    ...state,
    isLoading: true,
    error: null,
    success: null,
  }))
  .handleAction(resetUserSuccessFlagAction, (state) => ({
    ...state,
    success: null,
  }))
  .handleAction(resetUserErrorAction, (state) => ({ ...state, error: null }));
