export const calculateDaysUntilTrialExpires = (trialEnd: number): number => {
  const SECONDS_IN_A_DAY = 86400;
  const today = Math.floor(Date.now() / 1000);
  const daysLeft = Math.floor((trialEnd - today) / SECONDS_IN_A_DAY);
  if (daysLeft < 0) return 0;
  return daysLeft;
};

export const formatTimestampToLocaleDateString = (timestamp: number): string => {
  const date = new Date(timestamp * 1000);
  return date.toLocaleDateString(undefined, { year: 'numeric', month: 'long', day: 'numeric' });
};
