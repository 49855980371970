import { Close } from '@mui/icons-material';
import { Dialog as MuiDialog } from '@mui/material';

import * as Styled from './styles';
import { DialogProps } from './types';

const Dialog: React.FC<DialogProps> = ({ handleCloseButton, hideCloseButton, ...elementProps }) => {
  const handleBackdropClick = (_: any, reason: string) => {
    if (reason === 'backdropClick') {
      handleCloseButton();
    }
  };

  return (
    <MuiDialog fullWidth onClose={handleBackdropClick} {...elementProps}>
      <Styled.DialogContainer>
        {!hideCloseButton && (
          <Styled.CloseButton onClick={handleCloseButton} data-testid="closeButton">
            <Close />
          </Styled.CloseButton>
        )}
        {elementProps.children}
      </Styled.DialogContainer>
    </MuiDialog>
  );
};

export default Dialog;
