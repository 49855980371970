import {
  CompanyTypeEnum,
  CountryEnum,
  IndustryEnum,
  PlatformAccountTypeEnum,
  StateEnum,
} from '~/core/types/graphql.types';

export interface CreateNewPlatformAccountInput {
  accountType: PlatformAccountTypeEnum;
  companyName: string;
  website: string;
  companyType: CompanyTypeEnum;
  industry: IndustryEnum;
  phoneNumber: string;
  faxNumber?: string;
  addressLineOne: string;
  addressLineTwo?: string;
  city: string;
  state?: StateEnum;
  zipCode: string;
  country: CountryEnum;
  userEmail: string;
  integrationEmail?: string;
}

export enum StepsEnum {
  CreateAccount = '0',
  GoogleAuth = '1',
  InviteUser = '2',
}
