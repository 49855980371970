import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Box, FormControl, OutlinedInput } from '@mui/material';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import { CircularProgress } from '~/core/components/shared';
import { limitCharacters } from '~/core/helpers';
import { useGetIntentsAvailability } from '~/core/services/graphql/intents/hooks';
import { GetIntentsAvailabilityQueryVariables } from '~/core/services/graphql/intents/intents.queries.generated';
import { useGetAssessmentStatuses } from '~/core/services/graphql/sourceAccounts/hooks';
import { GetAssessmentLatestQueryVariables } from '~/core/services/graphql/sourceAccounts/sourceAccounts.queries.generated';
import { AssessmentProcessStatusEnum, IntegrationAccountsSchema } from '~/core/types/graphql.types';
import { getIntegrationAccountsAction } from '~/store/actions/integrationAccounts.action';
import { useContexts } from '~/store/context/useContext';
import { integrationAccountsSelector } from '~/store/selectors/integrationAccounts.selector';

import * as Styled from './styles';
import { SourceAccountSelectorProps } from './types';

const charLimit = 32;

const SourceAccountSelector: React.FC<SourceAccountSelectorProps> = ({ demoMode, displayLogo, ...elementProps }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const updatedSearchParams = new URLSearchParams();
  const dispatch = useDispatch();

  const {
    selectedPlatformAccountId,
    selectedSourceAccountId,
    selectedDateRange,
    setSelectedSourceAccountId,
    setSelectedAccountName,
    setSelectedCampaignId,
    setSelectedDateRange,
  } = useContexts();

  const integrationAccounts = useSelector(integrationAccountsSelector);

  const sourceAccountId = searchParams.get('sourceAccountId');

  const getAssessmentLatestSkip = !integrationAccounts || integrationAccounts?.length === 0;
  const getAssessmentLatestVariables: GetAssessmentLatestQueryVariables = {
    sourceAccountIds: integrationAccounts?.map(({ customerId }) => customerId),
  };
  const { assessmentStatusesLoading, getAssessmentLatest } = useGetAssessmentStatuses(
    getAssessmentLatestSkip,
    getAssessmentLatestVariables,
  );

  const sourceAccountIds = integrationAccounts?.map(({ customerId }) => customerId);

  // Source Account Info
  // const getSourceAccountInfoSkip = !selectedPlatformAccountId || !sourceAccountIds?.length;
  // const getSourceAccountInfoVariables: GetSourceAccountInfoQueryVariables = {
  //   platformAccountId: selectedPlatformAccountId,
  //   customerIds: sourceAccountIds,
  // };
  // const { sourceAccountInfoLoading, getSourceAccountInfo } = useGetSourceAccountInfo(
  //   getSourceAccountInfoSkip,
  //   getSourceAccountInfoVariables,
  // );

  const getIntentsAvailabilitySkip = !selectedPlatformAccountId || !sourceAccountIds?.length;
  const getIntentsAvailabilityVariables: GetIntentsAvailabilityQueryVariables = {
    platformAccountId: selectedPlatformAccountId,
    customerIds: sourceAccountIds,
  };
  const { availabilityLoading, getIntentsAvailability } = useGetIntentsAvailability(
    getIntentsAvailabilitySkip,
    getIntentsAvailabilityVariables,
  );

  const checkStatus = (account: IntegrationAccountsSchema) => {
    // Use 'find' for better performance, as it stops once it finds the first match.
    const summaryDetails = getAssessmentLatest?.find(({ sourceAccountId }) => sourceAccountId === account?.customerId);

    const summaryStatus = summaryDetails?.status;

    // Pre-calculate and reuse the time difference in hours to avoid multiple computations.
    const differenceHours = (new Date().getTime() - new Date(account?.createdAt).getTime()) / (1000 * 3600);

    // Finding the summary for the account by its customer ID.
    const summary = getIntentsAvailability?.find(
      ({ customerId, intentsAvailable }) => customerId === account?.customerId && intentsAvailable,
    );

    // Using direct conditions to check `summaryIsInProgress` and `summaryIsAvailable` to simplify the logic.
    const summaryIsInProgress = summaryStatus === AssessmentProcessStatusEnum.InProgress && differenceHours < 12;
    const summaryIsAvailable = summaryStatus === AssessmentProcessStatusEnum.Success && summary?.intentsAvailable;

    // Simplified the `summaryIsUnavailable` check.
    const summaryIsUnavailable = !summaryIsInProgress && !summaryIsAvailable;

    // Return the appropriate component based on the checks.
    if (summaryIsInProgress) return <Styled.CustomChip size="small" label="In Progress" />;
    if (summaryIsUnavailable) return <Styled.CustomChip size="small" label="No Data" />;
    if (summaryIsAvailable) return null; // Using 'null' for no output, assuming this is React or similar.

    // Default case if none of the above conditions are met.
    return <CircularProgress />;
  };

  const handleAccountChange = (value: string) => {
    if (demoMode) return;
    if (value === selectedSourceAccountId) return;
    if (value) {
      searchParams.delete('sourceAccountId');
      searchParams.delete('accountName');
      searchParams.delete('campaignId');
      searchParams.delete('startDate');
      searchParams.delete('accountName');
      searchParams.delete('endDate');
      searchParams.forEach((value, key) => {
        updatedSearchParams.set(key, value);
      });
      updatedSearchParams.set('sourceAccountId', value);
      const accountName = integrationAccounts?.find((account) => account.customerId === value)?.name;
      if (accountName) updatedSearchParams.set('accountName', accountName);
      setSelectedSourceAccountId(value);
      setSelectedAccountName(integrationAccounts?.find((account) => account.customerId === value)?.name);
      setSelectedCampaignId(null);
      setSearchParams(updatedSearchParams, { replace: true });
      setSelectedDateRange(null);
    }
  };

  const hasData = (id: string) => getIntentsAvailability?.some((data) => data.customerId === id);

  // Add status key to each account object in integrationAccounts
  // eslint-disable-next-line array-callback-return

  type UpdatedAccountProps = IntegrationAccountsSchema & { assessmentStatus: JSX.Element | null };

  let updatedAccounts: UpdatedAccountProps[] = [];
  if (integrationAccounts?.length > 0 && getAssessmentLatest?.length > 0 && !availabilityLoading) {
    updatedAccounts = integrationAccounts
      .filter((option: any) => !option.manager)
      ?.sort((a, b) => {
        // check if getIntentsAvailability has id
        if (!hasData(a?.customerId) && hasData(b?.customerId)) {
          return 1;
        }
        if (hasData(a?.customerId) && !hasData(b?.customerId)) {
          return -1;
        }
        return a?.name.localeCompare(b?.name);
      })
      ?.map((account) => {
        const updatedAccount = { ...account, assessmentStatus: checkStatus(account) };
        return updatedAccount;
      });
  }

  const currentAccount = updatedAccounts?.find(
    (account) => account?.customerId === (selectedSourceAccountId || updatedAccounts[0]?.customerId),
  );

  useEffect(() => {
    setSelectedSourceAccountId(sourceAccountId || updatedAccounts[0]?.customerId || null);
    const currentAcountName = integrationAccounts?.find(
      (account) => account.customerId === selectedSourceAccountId,
    )?.name;
    if (currentAcountName) setSelectedAccountName(currentAcountName);

    if (!selectedDateRange) {
      const availableDate = getAssessmentLatest?.find(
        ({ sourceAccountId }) => sourceAccountId === updatedAccounts[0]?.customerId,
      );
      if (availableDate) {
        setSelectedDateRange(availableDate?.latestAvailable);
      }
    }
  }, [selectedSourceAccountId, getAssessmentLatest, getIntentsAvailability, integrationAccounts]);

  // Gets list of integration accounts pertainig to Platform Account
  useEffect(() => {
    if (selectedPlatformAccountId) {
      dispatch(
        getIntegrationAccountsAction.request({
          platformAccountId: selectedPlatformAccountId,
          page: 1,
          pageSize: 9999,
          includeMcc: false,
        }),
      );
    }
  }, [selectedPlatformAccountId]);

  return updatedAccounts?.length > 0 ? (
    <FormControl data-testid="multipleAccountSelector">
      <Styled.Select
        data-testid="dropdownSelector"
        value={selectedSourceAccountId}
        displayEmpty
        disabled={
          availabilityLoading ||
          assessmentStatusesLoading ||
          updatedAccounts?.length === 0 ||
          getIntentsAvailability?.length < 1 ||
          getIntentsAvailability?.length === 0
        }
        autoWidth
        MenuProps={{
          sx: {
            maxHeight: '500px',
            marginLeft: '25px',
            marginTop: '5px',
          },
        }}
        IconComponent={ArrowDropDownIcon}
        renderValue={() => (
          <Box display="flex">
            {displayLogo}
            <Styled.AccountName className="selectName" data-testid="selectedAccountName">
              {limitCharacters(currentAccount?.name, charLimit)} {!availabilityLoading && checkStatus(currentAccount)}
            </Styled.AccountName>
          </Box>
        )}
        input={<OutlinedInput />}
        {...elementProps}
      >
        {updatedAccounts.map((option: any) => (
          <Styled.MenuItem
            data-testid="name"
            key={option?.customerId}
            value={option?.customerId}
            selected={option?.customerId === selectedSourceAccountId}
            onClick={() => handleAccountChange(option?.customerId)}
            sx={{
              fontWeight: option?.customerId === selectedSourceAccountId ? 'bold' : 'normal',
            }}
          >
            {option?.name} {option?.assessmentStatus}
          </Styled.MenuItem>
        ))}
      </Styled.Select>
    </FormControl>
  ) : (
    <Styled.LoadingContainer>
      <Styled.CustomSkeleton variant="text" animation="wave" />
    </Styled.LoadingContainer>
  );
};

export default SourceAccountSelector;
