import { styled, Typography } from '@mui/material';

export const IntentContent = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
  gap: '8px',
  '& > *': {
    '&:last-child': {
      marginTop: '16px',
    },
  },
});

export const ImageContainer = styled('div')({
  img: {
    height: 'auto',
    width: '230px',
  },
});

export const Heading = styled(Typography)`
  height: 32px;
  font-size: 20px;
  font-weight: bold;
  line-height: 1.6;
  letter-spacing: 0.15px;
  text-align: center;
  color: rgba(33, 33, 52, 0.87);
`;

export const SubHeading = styled(Typography)`
  height: 32px;
  height: 24px;
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: 0.15px;
  text-align: center;
  color: rgba(33, 33, 52, 0.87);
`;
