import { createSelector } from 'reselect';

import { AppState } from '../reducers';

const userStoreSelector = (state: AppState) => state.user;

export const userLoadingSelector = createSelector(userStoreSelector, (state) => state.isLoading);
export const userErrorSelector = createSelector(userStoreSelector, (state) => state.error);
export const userSuccessSelector = createSelector(userStoreSelector, (state) => state.success);
export const userSelector = createSelector(userStoreSelector, (state) => state.user);
