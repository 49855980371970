import { Box, Tab as MuiTab, Tabs as MuiTabs, Paper, Typography, styled } from '@mui/material';

import { Button as MuiButton } from '~/core/components/shared';

export const Title = styled(Typography)`
  font-size: 16px;
  font-weight: bold;
  line-height: 1.5;
  letter-spacing: 0.15px;
`;

export const SubTitle = styled(Typography)`
  font-size: 12px;
  line-height: 1.6;
  letter-spacing: 0.15px;
`;

export const Button = styled(MuiButton)`
  margin: 20px 19px 0 19px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  height: 64px !important;
  width: 8px !important;
  padding: 0px !important;
  &:disabled {
    background-color: #3054aa;
    opacity: 0.5;
    color: #fff;
  }
`;

export const Circle = styled(Box)<{ color: string }>`
  background: ${(props) => props.color};
  width: 32px;
  height: 32px;
  flex-grow: 0;
  margin: 0 8px 0 0;
  padding: 5px 6px 7px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Cell = styled(Box)`
  height: 14px;
  font-size: 14px;
  line-height: 1;
  letter-spacing: 0.15px;
  margin-bottom: 10px;
  height: 20px;
  font-size: 14px;
  line-height: 1.43;
  letter-spacing: 0.17px;
`;

export const PageContainer = styled(Box)`
  padding: 6px 16px 0px 16px;
`;

export const PageHeader = styled(Box)`
  margin-bottom: 22px;
`;

export const Navigation = styled(Box)`
  display: flex;
  align-items: start;
  justify-content: center;
`;

export const PaperContainer = styled(Paper)`
  background: #fff;
  width: 100%;
  max-width: 830px;
  min-width: 400px;
  margin-bottom: 20px;
  margin-top: 32px;
`;

export const TabPanel = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const TabContent = styled(Box)`
  padding: 16px;
`;

export const PageHeaderContainer = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const PageHeaderText = styled(Typography)`
  padding-top: 6px;
  height: 33px;
  font-size: 16px;
  font-weight: bold;
  line-height: 1.5;
  letter-spacing: 0.15px;
`;

export const TabHeader = styled(Typography)({
  fontSize: '16px',
  fontWeight: 'bold',
  lineHeight: 1.5,
  letterSpacing: '0.15px',
  padding: '16px 0 10px 0',
});

export const Tabs = styled(MuiTabs)`
  background-color: #f6f6f7;
`;

export const Tab = styled(MuiTab)`
  text-transform: none;
  padding: 11px 8px;
  flex-grow: 1;
`;

export const TabContainer = styled(Box)`
  display: flex;
  align-items: center;
  padding-bottom: 14px;
`;

export const CircleContainer = styled(Box)<{ color: string }>`
  background: ${(props) => props.color};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const TabTitle = styled(Typography)`
  font-size: 13px;
  font-weight: 600;
  line-height: 1.69;
  letter-spacing: 0.46px;
`;

export const ExistingText = styled(Typography)`
  color: #546e7a;
  margin-left: 48px;
`;

export const LogoContainer = styled('div')`
  width: 22px;
  height: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(202, 213, 224, 0.45);
  border-radius: 25px;
`;

export const BadgeContainer = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  padding: '12px 0px 8px 16px',
  fontWeight: 'bold',
});

export const BadgeText = styled(Typography)({
  fontSize: '13px',
  fontWeight: 600,
  lineHeight: 1.69,
  letterSpacing: '0.46px',
});

export const PreviewContainer = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2px;
  padding: 12px 18px;
  border-radius: 8px;
  box-shadow: 0 1px 3px 0 rgba(100, 116, 139, 0.12), 0 1px 1px 0 rgba(100, 116, 139, 0.14),
    0 2px 1px -3px rgba(100, 116, 139, 0.2);
  border: solid 1px rgba(33, 33, 52, 0.12);
  background-color: #ffffff;
  margin-top: 12px;
  position: relative;
`;

export const ExampleBadge = styled(Box)({
  height: '20px',
  width: '145px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '0 6.5px',
  borderRadius: '64px',
  background: '#81c784',
  color: 'rgba(0, 0, 0, 0.87)',
  fontSize: '12px',
  fontWeight: 500,
  lineHeight: 1.67,
  letterSpacing: '0.14px',
});

export const ExampleHeadlineText = styled(Typography)`
  font-size: 12px;
  font-weight: bold;
  line-height: 1.6;
  letter-spacing: 0.15px;
  color: #311b92;
`;

export const ExampleDescriptionText = styled(Typography)`
  font-size: 11px;
  line-height: 1.6;
  letter-spacing: 0.15px;
  color: 'rgba(33, 33, 52, 0.6)';
`;
