import {
  AccountStatusEnum,
  CompanyTypeEnum,
  CountryEnum,
  IndustryEnum,
  PlatformAccountSchema,
  PlatformAccountTypeEnum,
  StateEnum,
} from '~/core/types/graphql.types';

export const defaultValues = {
  name: '',
  website: '',
  phoneNumber: '',
  faxNumber: '',
  address_1: '',
  address_2: '',
  city: '',
  zipCode: '',
  integrationEmail: '',
};

export const customerAccountDataMock: PlatformAccountSchema = {
  companyType: PlatformAccountTypeEnum.Customer,
  contactEmail: 'jayferrari@motivemetrics.com',
  contactPhone: '555-555-5555',
  createdAt: '2020-01-01T00:00:00.000Z',
  updatedAt: '2020-01-01T00:00:00.000Z',
  id: 1,
  name: 'Ferrari Unlimited',
  website: 'www.ferrari.com',
  editableCompanyType: CompanyTypeEnum.B2B,
  phoneNumber: '555-555-5555',
  faxNumber: '555-555-5555',
  address_1: '123 Main St',
  address_2: 'Apt 1',
  isFree: false,
  city: 'New York',
  state: StateEnum.NewYork,
  zipCode: '10001',
  country: CountryEnum.Usa,
  status: AccountStatusEnum.Active,
  industry: IndustryEnum.AutomobilesAndCompanies,
  integrationEmail: 'jayferrari@motivemetric.com',
};
