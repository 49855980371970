import { ChevronRightOutlined } from '@mui/icons-material';
import { ReactNode } from 'react';
import { createSearchParams, useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import GoogleLogo from '~/assets/google/icon-google-g-logo.svg';
import { PageHeader } from '~/core/components/shared';
import { DashboardPaths } from '~/core/constants/paths.constants';
import { useContexts } from '~/store/context/useContext';

import * as Styled from './styles';
import { BreadcrumbsProps } from './types';

const Breadcrumbs: React.FC<BreadcrumbsProps> = ({
  children,
  icon = <ChevronRightOutlined fontSize="small" />,
  importedState,
  headerStyle,
  ...props
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { selectedAccountName } = useContexts();
  const [searchParams] = useSearchParams();

  const { pathname } = location;
  const pathnames = pathname?.length && location.pathname.split('/').filter((x) => x);

  const accountName = searchParams.get('accountName') || selectedAccountName;
  const adGroupName = searchParams.get('adGroupName');
  const intentName = searchParams.get('intentName');

  const handleNavigate = (path: string) => () => {
    if (path === `/${DashboardPaths.MotiveMap}`) {
      navigate({
        pathname: path,
        search: createSearchParams('').toString(),
      });
      return;
    }
    navigate({
      pathname: path,
      search: createSearchParams({
        ...importedState,
      }).toString(),
    });
  };

  const breadcrumbNames: { [key: string]: ReactNode } = {
    '/content-gap-analysis': (
      <>
        <Styled.LogoContainer>
          <img alt="GoogleAds logo" src={GoogleLogo} height={12} />
        </Styled.LogoContainer>
        <div>{accountName}</div>
      </>
    ),
    '/segmentation-analysis': (
      <>
        <Styled.LogoContainer>
          <img alt="GoogleAds logo" src={GoogleLogo} height={12} />
        </Styled.LogoContainer>
        <div>{accountName}</div>
      </>
    ),
    '/segmentation-analysis/segments': adGroupName,
    '/segmentation-analysis/segments/intent-themes': 'traffic segment',
    '/content-gap-analysis/assets': `Aligned Content for "${intentName}"`,
    '/content-gap-analysis/recommendations': `Finding Content for "${intentName}"`,
    '/motive-map/campaign': pathname?.includes(DashboardPaths.CampaignSummaryView) && (
      <>
        <Styled.LogoContainer>
          <img alt="GoogleAds logo" src={GoogleLogo} height={12} />
        </Styled.LogoContainer>
        <div>{accountName}</div>
      </>
    ),
  };

  return (
    <PageHeader
      sx={{
        ...headerStyle,
      }}
    >
      <Styled.Breadcrumbs {...props} data-testid="breadcrumbs" separator={icon} aria-label="breadcrumb">
        {pathnames &&
          pathnames.map((value, index) => {
            const last = index === pathnames.length - 1;
            const to = `/${pathnames.slice(0, index + 1).join('/')}`;

            return last ? (
              <Styled.CurrentBreadcrumbsTypography data-testid="currentRoute" color="text.primary" key={to}>
                {breadcrumbNames[to]}
              </Styled.CurrentBreadcrumbsTypography>
            ) : (
              <Styled.BreadcrumbsTypography onClick={handleNavigate(to)} key={to}>
                {breadcrumbNames[to]}
              </Styled.BreadcrumbsTypography>
            );
          })}
      </Styled.Breadcrumbs>
      {children}
    </PageHeader>
  );
};

export default Breadcrumbs;
