import { Box, Typography, Chip, Skeleton } from '@mui/material';
import Stripe from 'stripe';

import { Button } from '~/core/components/shared';
import { calculateDaysUntilTrialExpires, formatTimestampToLocaleDateString } from '~/core/lib/utils';

import * as Styled from './styles';

interface SubscriptionDetailsProps {
  subscriptionData: Stripe.Subscription[] | undefined;
  invoiceData: Stripe.Invoice[] | undefined;
  paymentData: Stripe.PaymentMethod | null;
  isLoading: boolean;
  planName: string;
  paymentUrl: string | null;
  billingUrl: string | null;
}

const SubscriptionDetails: React.FC<SubscriptionDetailsProps> = ({
  subscriptionData,
  invoiceData,
  paymentData,
  isLoading,
  planName,
  paymentUrl,
  billingUrl,
}) => {
  const hasSubscription = subscriptionData?.length > 0;
  const hasInvoice = invoiceData?.length > 0;
  const isTrialing = subscriptionData?.[0]?.status === 'trialing';
  const isExpired = hasInvoice && !hasSubscription;

  return (
    <Styled.CustomContainer>
      <Styled.CustomTitle>Your Subscription</Styled.CustomTitle>
      <Box display="flex" justifyContent="space-between" width="100%">
        <Box width="100%">
          <Styled.SubscriptionBox>
            <Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
              <Box>
                {isLoading ? (
                  <Skeleton variant="text" />
                ) : (
                  <Box display="flex" alignItems="center" gap={1}>
                    <Typography variant="body1" fontWeight={800}>
                      {hasSubscription || hasInvoice ? planName : 'Free Plan'}
                    </Typography>
                    {isTrialing && <Chip label="Free Trial" color="warning" size="small" />}
                    {isExpired && <Chip label="Expired" color="error" size="small" />}
                  </Box>
                )}

                {isLoading ? (
                  <Skeleton variant="text" width="120px" />
                ) : (
                  (hasSubscription || hasInvoice) && <Typography variant="body1">$29.99/month</Typography>
                )}
              </Box>
              {isLoading ? (
                <Skeleton variant="rectangular" width={120} height={40} />
              ) : hasSubscription ? (
                <Button
                  label="Manage Plan"
                  variant="contained"
                  onClick={() => {
                    if (billingUrl) window.location.href = billingUrl;
                  }}
                />
              ) : (
                <Button
                  label={hasInvoice ? 'Renew Plan' : 'Start Free Trial'}
                  variant="contained"
                  onClick={() => {
                    if (paymentUrl) {
                      window.location.href = paymentUrl;
                    }
                  }}
                />
              )}
            </Box>
          </Styled.SubscriptionBox>
          <Box display="flex" justifyContent="space-between">
            <Box display="flex" flexDirection="column">
              <Typography variant="body1" fontWeight={200}>
                Next Billing Date
              </Typography>
              <Typography variant="body1">
                {hasSubscription ? formatTimestampToLocaleDateString(subscriptionData[0]?.current_period_end) : '-'}
              </Typography>
              {subscriptionData?.[0]?.trial_end &&
                calculateDaysUntilTrialExpires(subscriptionData[0].trial_end) > 0 && (
                  <Typography sx={{ color: 'warning.main', fontSize: 14 }} variant="body1" fontWeight={300}>
                    {calculateDaysUntilTrialExpires(subscriptionData[0]?.trial_end)} days remaining until auto-renew
                  </Typography>
                )}
            </Box>
            {/* Payment Details */}
            <Box display="flex" flexDirection="column">
              <Typography variant="body1" fontWeight={200}>
                Payment Method
              </Typography>
              <Typography variant="body1">
                {hasSubscription && paymentData?.card
                  ? `
                ${paymentData?.card?.brand.toUpperCase()} ending in ${paymentData?.card?.last4}
              
              `
                  : '-'}
              </Typography>
            </Box>
            {/* <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Typography variant="body1" fontWeight={200}>
                Member Since
              </Typography>
              <Typography variant="body1">
                {hasSubscription ? formatTimestampToLocaleDateString(subscriptionData[0]?.created) : '-'}
              </Typography>
            </Box> */}
          </Box>
        </Box>
      </Box>
    </Styled.CustomContainer>
  );
};

export default SubscriptionDetails;
