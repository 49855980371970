import { useQuery } from '@apollo/client';

import {
  AdGroupSchema,
  AdGroupSummarySchemaPaginationSchema,
  QueryGetAdGroupSummariesArgs,
  QueryGetAdGroupsArgs,
  QueryGetTrafficSegmentsArgs,
  TrafficSegmentSchema,
} from '~/core/types/graphql.types';

import { GET_AD_GROUPS, GET_AD_GROUP_SUMMARIES, GET_TRAFFIC_SEGMENTS } from './adGroups.queries';

export const useGetAdGroups = (skip: boolean, variables: QueryGetAdGroupsArgs) => {
  const {
    loading: adGroupsLoading,
    error: adGroupsError,
    data: adGroupsData,
  } = useQuery<{ getAdGroups: AdGroupSchema[] }>(GET_AD_GROUPS, {
    skip,
    variables,
  });

  const getAdGroups = adGroupsData?.getAdGroups;

  return { adGroupsLoading, adGroupsError, getAdGroups };
};

export const useGetAdGroupSummaries = (skip: boolean, variables: QueryGetAdGroupSummariesArgs) => {
  const {
    loading: adGroupSummariesLoading,
    error: adGroupSummariesError,
    data: adGroupSummariesData,
  } = useQuery<{ getAdGroupSummaries: AdGroupSummarySchemaPaginationSchema }>(GET_AD_GROUP_SUMMARIES, {
    skip,
    variables,
  });

  const getAdGroupSummaries = adGroupSummariesData?.getAdGroupSummaries;

  return { adGroupSummariesLoading, adGroupSummariesError, getAdGroupSummaries };
};

export const useGetTrafficSegments = (skip: boolean, variables: QueryGetTrafficSegmentsArgs) => {
  const {
    loading: trafficSegmentsLoading,
    error: trafficSegmentsError,
    data: trafficSegmentsData,
  } = useQuery<{ getTrafficSegments: TrafficSegmentSchema[] }>(GET_TRAFFIC_SEGMENTS, {
    skip,
    variables,
  });

  const getTrafficSegments = trafficSegmentsData?.getTrafficSegments;

  return { trafficSegmentsLoading, trafficSegmentsError, getTrafficSegments };
};
