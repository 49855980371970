import { Box } from '@mui/material';

import NotFound404 from '~/assets/404/404_1x.png';
import { Button } from '~/core/components/shared';

import * as Styled from './NotFound.styles';

const NotFound: React.FC = () => (
  <Styled.Box id="notFound">
    <Box>
      <img style={{ height: '480px' }} alt="Not found 404 stop sign" src={NotFound404} />
    </Box>
    <Styled.Heading variant="h5">We can&apos;t seem to find the page you are looking for.</Styled.Heading>

    {/*
    // TODO: Link this Button with the component Button based on StyleGuide
    */}
    <Button
      sx={{ marginTop: '20px' }}
      data-testid="redirect"
      variant="contained"
      href="/motive-map"
      label="Back to Dashboard"
    />
  </Styled.Box>
);

export default NotFound;
