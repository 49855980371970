import { Alert, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import { AuthContainer } from '~/core/components/layouts/AuthContainer';
import { ConfirmEmailForm } from '~/core/types/auth.types';
import { confirmEmailAction, resendConfirmationCodeAction } from '~/store/actions/auth.action';
import { authErrorSelector, authLoadingSelector, authResendErrorSelector } from '~/store/selectors/auth.selector';

import { SignUpConfirmForm } from './components/SignUpConfirmForm';
import * as Styled from './styles';

const SignUpConfirm = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const cachedEmail = searchParams.get('email');
  const [code, setCode] = useState<string>('');
  const [email, setEmail] = useState<string>(cachedEmail || '');

  const confirmationCodeError = useSelector(authErrorSelector);
  const resendError = useSelector(authResendErrorSelector);
  const isLoading = useSelector(authLoadingSelector);

  const onConfirmEmail = ({ confirmationCode }: ConfirmEmailForm) => {
    dispatch(confirmEmailAction.request({ email, confirmationCode }));
    setCode(confirmationCode);
  };

  useEffect(() => {
    if (!cachedEmail) {
      try {
        const { email } = location.state as { email: string };
        setEmail(email);
      } catch {
        navigate('../create-account');
      }
    }
  }, [location.state, navigate]);

  useEffect(() => {
    if (!confirmationCodeError && !isLoading && code) {
      navigate('../sign-in', { replace: true });
    }
  }, [confirmationCodeError, isLoading, code, navigate]);

  const onResendConfirmationCode = () => {
    dispatch(resendConfirmationCodeAction.request({ email }));
    setCode(null);
  };

  return (
    <AuthContainer data-testid="signUpContainer">
      <Styled.HeaderContent>
        <Typography fontWeight={700} fontSize={24}>
          Check your email
        </Typography>
        <Typography textAlign="center">
          We’ve sent a verification code to <strong>{email}</strong> to complete your signup. If you don’t see it, check
          your spam folder.
        </Typography>
      </Styled.HeaderContent>
      <SignUpConfirmForm onSubmit={onConfirmEmail} />
      <Styled.FooterContent>
        <Typography>Still don’t see the email?</Typography>
        <Styled.Link data-testid="resendButton" onClick={onResendConfirmationCode}>
          Resend email
        </Styled.Link>
      </Styled.FooterContent>

      {resendError && (
        <Alert severity="error" data-testid="alert">
          {resendError}
        </Alert>
      )}
    </AuthContainer>
  );
};

export default SignUpConfirm;
