import { Box, Typography, styled } from '@mui/material';

import { useContexts } from '~/store/context/useContext';
import { Button } from '~/core/components/shared';
import demoImg from '~/assets/misc/plusaidemo.png';
import { getProductUrl } from '~/core/services/rest/stripe/stripe';
import { aiSubscriptionId } from '~/core/services/rest/stripe/constants';

const CustomContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  width: '98.6%',
  height: '98.6%',
  textAlign: 'center',
  gap: 12,
});

const Image = styled('img')({
  height: 'auto',
  width: '450px',
});

const Messaging = styled(Typography)({
  maxWidth: '650px',
  fontSize: '1.25rem',
  textAlign: 'center',
  fontWeight: 600,
});

const FreeTrialHero = () => {
  const { selectedPlatformAccount } = useContexts();
  const stripeCustomerId = selectedPlatformAccount?.stripeCustomerId;
  const callbackUrl = `${window.location.hostname === 'localhost' ? 'http' : 'https'}://${
    window.location.host
  }/content-gap-analyis`;

  const handleFormSubmit = async () => {
    // eslint-disable-next-line
    if (!stripeCustomerId) return console.error('Stripe customer ID not found');
    const fetchedUrl = await getProductUrl({
      productId: aiSubscriptionId,
      quantity: 1,
      successUrl: callbackUrl,
      cancelUrl: callbackUrl,
      customerId: stripeCustomerId,
      trialPeriodDays: 14,
    });
    window.location.href = fetchedUrl;
  };

  const buttonText = 'Start 14-Day Free Trial';

  const messaging =
    'Start your 14-Day free trial today and unlock AI-driven customer search insights to transform your paid search marketing strategy.';

  return (
    <CustomContainer>
      <Box>
        <Image src={demoImg} alt="PlusAI Demo" />
      </Box>
      <Box display="flex" flexDirection="row" justifyContent="center">
        <Messaging>{messaging}</Messaging>
      </Box>
      <Box>
        <Button onClick={() => handleFormSubmit()} label={buttonText} variant="contained" />
      </Box>
      <Typography fontSize="0.8rem" textAlign="center" color="rgba(0, 0, 0, 0.56)">
        After your 14-day trial, you will be charged $29.99 monthly. Cancel anytime.
      </Typography>
    </CustomContainer>
  );
};

export default FreeTrialHero;
