import { createReducer } from 'typesafe-actions';

import { AccessUser, User } from '~/core/types/auth.types';

import {
  AuthActionUnion,
  confirmEmailAction,
  createAccountAction,
  forgotPasswordAction,
  getTokens,
  loginAction,
  logoutAction,
  resendConfirmationCodeAction,
  resetAuthErrorsAction,
  resetAuthSuccessFlagAction,
  setNewPasswordAction,
  verifyNewEmailAction,
  verifyNewEmailResendAction,
} from '../actions/auth.action';

export interface State {
  AccessToken: string;
  RefreshToken: string;
  isLoading: boolean;
  user: User;
  decodedAccessToken: AccessUser;
  error: string;
  resendError: string;
  isAppInit: boolean;
  tokenExpiredTimestamp: number;
  success: boolean;
}

export const initialState: State = {
  AccessToken: null,
  RefreshToken: null,
  decodedAccessToken: null,
  isLoading: false,
  error: null,
  resendError: null,
  user: null,
  isAppInit: false,
  tokenExpiredTimestamp: null,
  success: null,
};

export const reducer = createReducer<State, AuthActionUnion>(initialState)
  .handleAction(loginAction.success, (state, action) => ({
    ...state,
    isLoading: false,
    ...action.payload,
    error: null,
  }))
  .handleAction(loginAction.failure, (state, action) => ({
    ...state,
    isLoading: false,
    error: action.payload,
  }))
  .handleAction(loginAction.request, (state) => ({
    ...state,
    isLoading: true,
    error: null,
  }))
  .handleAction(createAccountAction.request, (state) => ({
    ...state,
    isLoading: true,
    error: null,
  }))
  .handleAction(createAccountAction.success, (state) => ({
    ...state,
    isLoading: false,
    error: null,
  }))
  .handleAction(createAccountAction.failure, (state, action) => ({
    ...state,
    isLoading: false,
    error: action.payload,
  }))
  .handleAction(getTokens.success, (state, action) => ({
    ...state,
    isAppInit: true,
    ...action.payload,
    tokenExpiredTimestamp: action.payload.decodedAccessToken.exp,
    error: null,
  }))
  .handleAction(getTokens.request, (state) => ({ ...state, isAppInit: false }))
  .handleAction(getTokens.failure, (state) => ({
    ...state,
    isAppInit: true,
  }))
  .handleAction(logoutAction.success, () => initialState)
  .handleAction(forgotPasswordAction.failure, (state) => ({
    ...state,
    isLoading: false,
  }))
  .handleAction(forgotPasswordAction.request, (state) => ({
    ...state,
    isLoading: true,
  }))
  .handleAction(forgotPasswordAction.success, (state) => ({
    ...state,
    isLoading: false,
    error: null,
  }))
  .handleAction(setNewPasswordAction.failure, (state, action) => ({
    ...state,
    isLoading: false,
    error: action.payload,
  }))
  .handleAction(setNewPasswordAction.request, (state) => ({
    ...state,
    isLoading: true,
  }))
  .handleAction(setNewPasswordAction.success, (state) => ({
    ...state,
    isLoading: false,
    error: null,
  }))
  .handleAction(confirmEmailAction.failure, (state, action) => ({
    ...state,
    isLoading: false,
    error: action.payload,
  }))
  .handleAction(confirmEmailAction.request, (state) => ({
    ...state,
    isLoading: true,
  }))
  .handleAction(confirmEmailAction.success, (state) => ({
    ...state,
    isLoading: false,
    error: null,
  }))
  .handleAction(verifyNewEmailAction.success, (state) => ({
    ...state,
    isLoading: false,
    error: null,
    success: true,
  }))
  .handleAction(verifyNewEmailAction.failure, (state, action) => ({
    ...state,
    isLoading: false,
    error: action.payload,
    success: false,
  }))
  .handleAction(verifyNewEmailAction.request, (state) => ({
    ...state,
    isLoading: true,
    error: null,
    success: null,
  }))
  .handleAction(verifyNewEmailResendAction.success, (state) => ({
    ...state,
    isLoading: false,
    error: null,
  }))
  .handleAction(verifyNewEmailResendAction.failure, (state, action) => ({
    ...state,
    isLoading: false,
    error: action.payload,
    success: false,
  }))
  .handleAction(verifyNewEmailResendAction.request, (state) => ({
    ...state,
    isLoading: true,
    error: null,
    success: null,
  }))
  .handleAction(resendConfirmationCodeAction.success, (state) => ({
    ...state,
    resendError: null,
  }))
  .handleAction(resendConfirmationCodeAction.failure, (state) => ({
    ...state,
    resendError: 'There was an error resending the confirmation code. Please try again or contact an administrator.',
  }))
  .handleAction(resendConfirmationCodeAction.request, (state) => ({
    ...state,
    resendError: null,
  }))
  .handleAction(resetAuthSuccessFlagAction, (state) => ({
    ...state,
    success: null,
  }))
  .handleAction(resetAuthErrorsAction, (state) => ({
    ...state,
    error: null,
  }));
