import {
  styled,
  ListItemButton as MuiListItemButton,
  ListItem as MuiListItem,
  ListItemIcon,
  ListItemText as MuiListItemText,
} from '@mui/material';

export const ListItemButton = styled(MuiListItemButton)({
  '&:active': {
    color: '#6C6B77',
  },
  '&.Mui-selected': {
    '.MuiSvgIcon-root': {
      color: '#345EB6',
      fontweight: 'bold',
    },
    '.MuiTypography-root': {
      color: '#345EB6',
    },
  },
  '.MuiTypography-root': {
    fontSize: '14px',
  },
});

export const ListIcon = styled(ListItemIcon)({
  color: '#6C6B77',
  minWidth: 0,
  justifyContent: 'center',
  paddingRight: 10,
});

export const ListItem = styled(MuiListItem)({
  padding: '0',
});

export const ListItemText = styled(MuiListItemText)<{ open: boolean }>(({ open }) => ({
  color: '#6C6B77',
  opacity: open ? 1 : 0,
}));
