import { Add, ExpandLess, ExpandMore } from '@mui/icons-material';
import { AccordionSummary, Skeleton, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import GoogleLogo from '~/assets/google/icon-google-g-logo.svg';
import { Button, MenuItem } from '~/core/components/shared';
import useGoogleAuthPopup from '~/core/hooks/useGoogleAuthPopup';
import { IntegrationAccountSortableEnum } from '~/core/types/graphql.types';
import { SortType } from '~/core/types/table.types';
import {
  linkNewPaidSearchAccountAction,
  paidIntegrationAccountsAction,
  postGoogleAuthSignInAction,
} from '~/store/actions/paidSearchIntegrations.action';
import { useContexts } from '~/store/context/useContext';
import { authUserSelector } from '~/store/selectors/auth.selector';
import {
  paidSearchAccountsGoogleUrl,
  paidSearchAccountsLoadingSelector,
  paidSearchAccountsPageInfoSelector,
  paidSearchAccountsRefreshLoadingSelector,
  paidSearchAccountsSelector,
} from '~/store/selectors/paidSearchAccounts.selector';

import { GoogleAdsAccountsTable } from './components/GoogleAdsAccountsTable';
import * as Styled from './styles';

const PaidSearchIntegration: React.FC = () => {
  const dispatch = useDispatch();
  const [order, setOrder] = useState<SortType>(SortType.Asc);
  const [orderBy, setOrderBy] = useState(IntegrationAccountSortableEnum.Name);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const { selectedPlatformAccountId, platformAccounts } = useContexts();

  const currentUserAccount = platformAccounts?.find((account) => account.id === selectedPlatformAccountId);
  const isLoading = useSelector(paidSearchAccountsLoadingSelector);
  const refreshLoading = useSelector(paidSearchAccountsRefreshLoadingSelector);
  const googleAdsAccounts = useSelector(paidSearchAccountsSelector);
  const user = useSelector(authUserSelector);
  const signInUrl = useSelector(paidSearchAccountsGoogleUrl);
  const integrationAccountsPageInfo = useSelector(paidSearchAccountsPageInfoSelector);

  const [expanded, setExpanded] = useState<boolean>(true);
  const [, setOpen] = useState<boolean>(false);

  // * Commenting out for now, in the case we want to revert to old navigation
  // const { IsPlatformAdmin } = useSelector(authUserSelector);
  // const handleRefreshAccounts = () => {
  //   dispatch(refreshPaidIntegrationAccountsAction.request(selectedPlatformAccountId));
  // };
  // const handleReloadPage = () => {
  //   window.location.reload();
  // };

  const handleLinkAccounts = () => {
    dispatch(linkNewPaidSearchAccountAction.request(selectedPlatformAccountId));
  };

  const { handleOpenGooglePopup } = useGoogleAuthPopup(signInUrl?.url, handleLinkAccounts);

  const handleOpenChange = () => {
    setOpen((open) => !open);
  };

  const handleExpandedChange = () => {
    if (googleAdsAccounts) {
      setExpanded((expanded) => !expanded);
    }
  };

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: any) => {
    const isAsc = orderBy === property && order === SortType.Asc;
    setOrder(isAsc ? SortType.Desc : SortType.Asc);
    setOrderBy(property);
  };

  const handleChangePage = (_: unknown, newPage: number) => {
    setPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(Number(event.target.value));
    setPage(1);
  };

  useEffect(() => {
    if (selectedPlatformAccountId) {
      dispatch(
        paidIntegrationAccountsAction.request({
          platformAccountId: selectedPlatformAccountId,
          page,
          pageSize: rowsPerPage,
          sortBy: {
            field: orderBy,
            desc: order === SortType.Desc,
          },
        }),
      );
    }
  }, [selectedPlatformAccountId, dispatch, order, orderBy, page, rowsPerPage]);

  useEffect(() => {
    if (selectedPlatformAccountId) {
      if (currentUserAccount?.isFree && !signInUrl) {
        dispatch(
          postGoogleAuthSignInAction.request({
            accountId: selectedPlatformAccountId,
            redirectionUri: `${process.env.REACT_APP_API_BASE_URL}/api/v1/google/auth/oauth-data`,
          }),
        );
      }
    }
  }, [currentUserAccount?.isFree, selectedPlatformAccountId, dispatch, signInUrl]);

  // * Commenting out for now, in the case we want to revert to old navigation
  // const isCustomer = currentUserAccount?.accountType === PlatformAccountTypeEnum.Customer;

  return (
    <Styled.PageContainer data-testid="container">
      {isLoading && !googleAdsAccounts ? (
        <Skeleton
          sx={{
            height: '125px',
          }}
        />
      ) : (
        <Styled.Container>
          {/*
            // * Commenting out for now, in the case we want to revert to old navigation
            <PageHeader>
              <Typography fontSize={20} fontWeight="bold">
                Paid Search Integrations
              </Typography>
              <Box data-testid="googleAccountButtons" display="flex" gap="16px">
                <Button
                  data-testid="refreshButton"
                  label="Refresh"
                  variant="contained"
                  onClick={handleReloadPage}
                  disabled={!isCustomer || !googleAdsAccounts || refreshLoading}
                />
                {IsPlatformAdmin && (
                  <Tooltip
                    title="This action will re-trigger assessments. Only do this if
                    you know exactly what you're doing!"
                  >
                    <div>
                      <Styled.UpdateAccountsButton
                        data-testid="updateAccountsButton"
                        label="Update Accounts"
                        variant="contained"
                        onClick={handleRefreshAccounts}
                        startIcon={<Warning />}
                        disabled={!googleAdsAccounts || refreshLoading}
                      />
                    </div>
                  </Tooltip>
                )}
              </Box>
            </PageHeader>
          */}
          <Styled.Accordion disableGutters onChange={handleExpandedChange} data-testid="accordion" expanded={expanded}>
            <AccordionSummary>
              <MenuItem
                sx={{
                  borderRadius: expanded ? '8px 8px 0 0' : '8px',
                  boxShadow: 'none',
                }}
                mainHeader={
                  <Styled.TitleContainer>
                    <img alt="GoogleAds logo" src={GoogleLogo} height={26} width={26} />
                    Google Ads
                  </Styled.TitleContainer>
                }
                subComponent={
                  googleAdsAccounts && googleAdsAccounts.length ? (
                    <Styled.SummaryAuthorizedContainer>
                      <Typography fontWeight={700}>{integrationAccountsPageInfo?.total} accounts linked</Typography>
                      {expanded ? <ExpandLess /> : <ExpandMore />}
                    </Styled.SummaryAuthorizedContainer>
                  ) : (
                    <Styled.SummaryAuthorizedContainer>
                      <Typography fontWeight={700}>No accounts linked</Typography>
                      {(user?.IsPlatformAdmin || currentUserAccount?.isFree) && (
                        <Button
                          sx={{
                            marginLeft: '12px',
                          }}
                          data-testid="noLinkAccounts"
                          variant="contained"
                          size="small"
                          label="Link accounts"
                          startIcon={<Add />}
                          onClick={currentUserAccount?.isFree ? handleOpenGooglePopup : handleOpenChange}
                        />
                      )}
                    </Styled.SummaryAuthorizedContainer>
                  )
                }
              />
            </AccordionSummary>
            <Styled.AccordionDetails>
              {/* {signInUrl && (user?.IsPlatformAdmin || currentUserAccount?.isFree) && (
                <Styled.AddAccountButton
                  variant="contained"
                  data-testid="linkAccounts"
                  label="Link another account"
                  startIcon={<Add />}
                  onClick={currentUserAccount?.isFree ? handleOpenGooglePopup : handleOpenChange}
                />
              )} */}
              {googleAdsAccounts && !!googleAdsAccounts.length && (
                <GoogleAdsAccountsTable
                  isLoading={refreshLoading || isLoading}
                  tableOptions={{
                    handleChangePage,
                    handleChangeRowsPerPage,
                    handleRequestSort,
                    order,
                    orderBy,
                    page,
                    rowsPerPage,
                    total: integrationAccountsPageInfo.total,
                  }}
                  accounts={googleAdsAccounts}
                />
              )}
            </Styled.AccordionDetails>
          </Styled.Accordion>
        </Styled.Container>
      )}
    </Styled.PageContainer>
  );
};

export default PaidSearchIntegration;
