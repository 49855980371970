import { Tooltip as MuiTooltip } from '@mui/material';

import { TooltipProps } from './types';

const Tooltip: React.FC<TooltipProps> = ({ title, children, ...props }) => {
  const { offset = [0, 0], ...rest } = props;
  return (
    <MuiTooltip
      {...rest}
      title={title}
      PopperProps={{
        modifiers: [
          {
            name: 'offset',
            options: {
              offset,
            },
          },
        ],
      }}
    >
      {children}
    </MuiTooltip>
  );
};

export default Tooltip;
