import { createSelector } from 'reselect';

import { AppState } from '../reducers';

const paidSearchAuthStoreSelector = (state: AppState) => state.paidSearchAccounts;

export const paidSearchAccountsLoadingSelector = createSelector(
  paidSearchAuthStoreSelector,
  (state) => state.isLoading,
);

export const paidSearchAccountsErrorSelector = createSelector(paidSearchAuthStoreSelector, (state) => state.error);

export const paidSearchAccountsRefreshLoadingSelector = createSelector(
  paidSearchAuthStoreSelector,
  (state) => state.refreshLoading,
);

export const paidSearchAccountsSelector = createSelector(
  paidSearchAuthStoreSelector,
  (state) => state.paidSearchAccounts,
);

export const paidSearchAccountsPageInfoSelector = createSelector(
  paidSearchAuthStoreSelector,
  (state) => state.paidSearchPageInfo,
);

export const paidSearchAccountsGoogleUrl = createSelector(
  paidSearchAuthStoreSelector,
  (state) => state.googleAdsSignInUrl,
);
