import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import Stripe from 'stripe';

import { useContexts } from '~/store/context/useContext';
import {
  getBillingPortalUrl,
  getDefaultPaymentMethod,
  getProductUrl,
  getStripeInvoiceData,
  getStripeSubscriptionData,
} from '~/core/services/rest/stripe/stripe';
import { aiSubscriptionId } from '~/core/services/rest/stripe/constants';

import InvoiceTable from './InvoiceTable';
import SubscriptionDetails from './SubscriptionDetails';

const ManageSubscription: React.FC = () => {
  const { selectedPlatformAccount } = useContexts();
  const stripeCustomerId = selectedPlatformAccount?.stripeCustomerId;
  const [paymentUrl, setPaymentUrl] = useState<string | null>(null);
  const [billingUrl, setBillingUrl] = useState<string | null>(null);
  const [subscriptionData, setSubscriptionData] = useState<Stripe.Subscription[]>([]);
  const [invoiceData, setInvoiceData] = useState<Stripe.Invoice[]>([]);
  const [paymentData, setPaymentData] = useState<{ payment_method: Stripe.PaymentMethod } | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const callbackUrl = `${window.location.hostname === 'localhost' ? 'http' : 'https'}://${
    window.location.host
  }/admin/settings?adminTab=2`;

  useEffect(() => {
    const fetchData = async () => {
      if (!stripeCustomerId) return;
      setIsLoading(true);

      try {
        const [subscriptionResponse, invoiceResponse, billingUrlResponse] = await Promise.all([
          getStripeSubscriptionData({ customerId: stripeCustomerId }),
          getStripeInvoiceData({ customerId: stripeCustomerId }),
          getBillingPortalUrl({ customerId: stripeCustomerId, returnUrl: callbackUrl }),
        ]);

        setSubscriptionData(subscriptionResponse.data || []);
        setInvoiceData(invoiceResponse || []);
        setBillingUrl(billingUrlResponse.url || null);
      } catch (error) {
        // eslint-disable-next-line
        console.error('Error fetching data:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [stripeCustomerId]);

  useEffect(() => {
    const fetchPaymentUrl = async () => {
      if (!stripeCustomerId) return;

      try {
        const productUrlResponse = await getProductUrl({
          productId: aiSubscriptionId,
          quantity: 1,
          successUrl: callbackUrl,
          cancelUrl: callbackUrl,
          customerId: stripeCustomerId,
          trialPeriodDays: invoiceData.length ? null : 14,
        });

        setPaymentUrl(productUrlResponse);
      } catch (error) {
        // eslint-disable-next-line
        console.error('Error fetching payment URL:', error);
      }
    };

    fetchPaymentUrl();
  }, [stripeCustomerId]);

  useEffect(() => {
    const fetchPaymentData = async () => {
      if (!stripeCustomerId) return;

      try {
        const customerResponse = await getDefaultPaymentMethod({ customerId: stripeCustomerId });
        setPaymentData(customerResponse);
      } catch (error) {
        // eslint-disable-next-line
        console.error('Error fetching customer data:', error);
      }
    };

    fetchPaymentData();
  }, [stripeCustomerId]);

  return (
    <Box>
      <SubscriptionDetails
        subscriptionData={subscriptionData}
        invoiceData={invoiceData}
        paymentData={paymentData?.payment_method}
        isLoading={isLoading}
        planName="Monthly Plan"
        billingUrl={billingUrl}
        paymentUrl={paymentUrl}
      />
      <InvoiceTable invoiceData={invoiceData} isLoading={isLoading} />
    </Box>
  );
};

export default ManageSubscription;
