import { styled, DialogContent as MuiDialogContent } from '@mui/material';

import { Input } from '~/core/components/shared';

export const DialogContent = styled(MuiDialogContent)({
  display: 'flex',
  flexDirection: 'column',
  gap: '24px',
  padding: 0,
  paddingTop: '6px',
});

export const CustomInput = styled(Input)({
  width: 'fit-content',
});

export const HeaderContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
});
