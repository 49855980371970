import { TextField as MuiTextField, styled } from '@mui/material';

export const TextField = styled(MuiTextField)`
  border-radius: 6px;

  .MuiInputBase-input {
    font-size: 12px;
    line-height: 1.67;
    letter-spacing: 0.4px;
    color: rgba(33, 33, 52, 0.6);
  }

  input::placeholder {
    color: rgba(33, 33, 52, 0.6);
    opacity: 1;
  }

  .MuiInputBase-root {
    font-size: 12px;
    line-height: 1.67;
    letter-spacing: 0.4px;
    color: rgba(33, 33, 52, 0.6);
    background-color: #ffffff;
    height: 38px;
  }
`;
