import { Alert } from '@mui/material';
import { passwordStrength } from 'check-password-strength';
import { useForm, Controller } from 'react-hook-form';
import { useSelector } from 'react-redux';

import { Button, Input, PasswordChecker, PasswordInput } from '~/core/components/shared';
import { PasswordResetForm as Form } from '~/core/types/auth.types';
import { authErrorSelector, authLoadingSelector } from '~/store/selectors/auth.selector';

import * as Styled from './styles';
import { PasswordResetFormProps } from './types';

const PasswordResetForm: React.FC<PasswordResetFormProps> = ({ onSubmit }) => {
  const confirmationCodeError = useSelector(authErrorSelector);
  const isLoading = useSelector(authLoadingSelector);
  const {
    handleSubmit,
    formState: { errors },
    watch,
    control,
  } = useForm<Form>({
    defaultValues: {
      confirmationCode: '',
      newPassword: '',
      confirmPassword: '',
    },
    reValidateMode: 'onChange',
  });

  const newPassword = watch('newPassword');
  const confirmPassword = watch('confirmPassword');
  const passwordStrengthCheck = passwordStrength(newPassword);
  const isPasswordSecure = passwordStrengthCheck.contains.length >= 4 && passwordStrengthCheck.length >= 8;

  return (
    <form data-testid="passwordResetForm" onSubmit={handleSubmit(onSubmit)}>
      <Styled.PasswordResetForm disableGutters>
        {errors?.newPassword && (
          <Alert severity="error" data-testid="passwordReqError">
            You haven`t met the password requirements. Please try again.
          </Alert>
        )}
        {errors?.confirmPassword && (
          <Alert severity="error" data-testid="passwordLenError">
            Passwords do not match. Please try again.
          </Alert>
        )}
        <Controller
          name="confirmationCode"
          rules={{ required: true }}
          control={control}
          render={({ field: { onChange, value } }) => (
            <Input
              data-testid="confirmationCode"
              label="Confirmation code"
              value={value}
              onChange={onChange}
              helperText={confirmationCodeError}
              error={!!confirmationCodeError}
              autoComplete="off"
            />
          )}
        />
        <Controller
          name="newPassword"
          rules={{ required: true, validate: () => isPasswordSecure }}
          control={control}
          render={({ field: { onChange, value } }) => (
            <PasswordInput data-testid="newPasswordInput" label="New password" value={value} onChange={onChange} />
          )}
        />
        {!isPasswordSecure ? (
          <PasswordChecker error={!!errors.newPassword} passwordStrength={passwordStrengthCheck} />
        ) : (
          <Alert severity="success">Password is secure</Alert>
        )}
        <Controller
          name="confirmPassword"
          rules={{ required: true, validate: (pass) => pass === newPassword }}
          control={control}
          render={({ field: { onChange, value } }) => (
            <PasswordInput
              data-testid="confirmPasswordInput"
              label="Confirm password"
              value={value}
              onChange={onChange}
              helperText={newPassword !== confirmPassword && 'Passwords do not match'}
              error={newPassword !== confirmPassword}
            />
          )}
        />
        <Button
          data-testid="submitButton"
          type="submit"
          label="Reset Password"
          fullWidth
          disabled={isLoading}
          variant="contained"
          size="large"
        />
      </Styled.PasswordResetForm>
    </form>
  );
};

export default PasswordResetForm;
