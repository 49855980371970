export const createNodesAndLinks = (data, filter) => {
  const nodes = [];
  const links = [];
  filter = 'impressions';

  const createTotalNode = (data) => {
    const total = data.reduce(
      (acc, curr) => ({
        keywords: acc.keywords + curr.keywords,
        impressions: acc.impressions + curr.impressions,
        intentThemes: acc.intentThemes + curr.intentThemes,
      }),
      {
        keywords: 0,
        impressions: 0,
        intentThemes: 0,
      },
    );
    return total;
  };

  const total = createTotalNode(data);

  nodes.push({
    id: 'Total',
    name: 'Total',
    value: total?.[filter],
    impressions: total?.impressions,
    keywords: total?.keywords,
    intentThemes: total?.intentThemes,
  });

  // Sort the data array based on impressions in descending order
  const sortedData = data?.slice()?.sort((a, b) => b.impressions - a.impressions);

  const othersNode = {
    id: 'Others',
    name: 'Others',
    value: 0,
    impressions: 0,
    keywords: 0,
    intentThemes: 0,
    totalSegments: 0,
  };

  // Determine how many individual nodes to show (29 if more than 30 data points, otherwise all)
  const individualNodesToShow = sortedData.length > 49 ? 50 : sortedData.length;

  sortedData.forEach((clusterNode, index) => {
    if (index < individualNodesToShow) {
      nodes.push({
        id: clusterNode.segmentId,
        name: clusterNode.segmentId,
        value: clusterNode?.[filter],
        impressions: clusterNode.impressions,
        keywords: clusterNode.keywords,
        intentThemes: clusterNode.intentThemes,
      });

      links.push({
        source: 0,
        target: nodes.length - 1,
        id: clusterNode.segmentId,
        value: clusterNode?.[filter],
        impressions: clusterNode.impressions,
        keywords: clusterNode.keywords,
        intentThemes: clusterNode.intentThemes,
      });
    } else {
      // Add to Others node
      othersNode.value += clusterNode[filter] || 0;
      othersNode.impressions += clusterNode.impressions;
      othersNode.keywords += clusterNode.keywords;
      othersNode.intentThemes += clusterNode.intentThemes;
      othersNode.totalSegments += 1;
    }
  });

  // Add Others node if there are more than 30 data points
  if (sortedData.length > 30) {
    nodes.push(othersNode);
    links.push({
      source: 0,
      target: nodes.length - 1,
      id: 'Others',
      value: othersNode.value,
      impressions: othersNode.impressions,
      keywords: othersNode.keywords,
      intentThemes: othersNode.intentThemes,
      totalSegments: othersNode.totalSegments,
    });
  }

  return { nodes, links };
};
