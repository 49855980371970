import { gql } from '@apollo/client';

export const getUserGql = gql`
  query getUser($userId: Int!, $username: String!) {
    user(userId: $userId, username: $username) {
      username
      email
      userStatus
      userAttributes
      firstName
      lastName
      isPlatformAdmin
      id
      createdAt
      updatedAt
    }
  }
`;

export const updateUserGql = gql`
  mutation updateUser($userId: Int!, $userData: UserUpdateInputSchema!) {
    updateUser(userId: $userId, userData: $userData) {
      username
      email
      userStatus
      userAttributes
      firstName
      lastName
      isPlatformAdmin
      id
      createdAt
      updatedAt
    }
  }
`;
