import { PlatformAccountUsersSortableEnum } from '~/core/types/graphql.types';

export const headerCells = [
  {
    id: PlatformAccountUsersSortableEnum.Name,
    label: 'Name',
    numeric: false,
    disablePadding: true,
  },
  {
    id: PlatformAccountUsersSortableEnum.Email,
    label: 'Email Address',
    numeric: false,
    disablePadding: true,
  },
  {
    id: PlatformAccountUsersSortableEnum.Role,
    label: 'Role',
    numeric: false,
    disablePadding: true,
  },
  {
    id: PlatformAccountUsersSortableEnum.AccountStatus,
    label: 'User Access',
    numeric: false,
    disablePadding: true,
  },
  {
    id: PlatformAccountUsersSortableEnum.LastSignOn,
    label: 'Last Sign On',
    numeric: false,
    disablePadding: true,
  },
  // {
  //   id: 'actions',
  //   label: '',
  //   numeric: false,
  //   disablePadding: true,
  // },
];
