import {
  styled,
  Select,
  Box as MuiBox,
  CardActions as MuiCardActions,
  CardContent as MuiCardContent,
  Typography,
  FormControl,
} from '@mui/material';
import { Controller as ReactController, ControllerProps } from 'react-hook-form';

import { Input } from '~/core/components/shared';
import { VerticalStepper as VerticalStepperComponent } from '~/core/components/shared/VerticalStepper';

export const CardContent = styled(MuiCardContent)({
  padding: '0px 0px',
  display: 'flex',
  justifyContent: 'center',
  alignSelf: 'center',
});

export const VerticalStepper = styled(VerticalStepperComponent)({
  height: '315px',
  width: '200px',
});

export const StepContent = styled('div')({
  borderLeft: '1px solid lightgrey',
  paddingLeft: '32px',
  paddingBottom: '32px',
  width: '100%',
  backgroundColor: '#fff',
});

export const HeaderContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '24px 0',
});

export const FormContainer = styled('div')({
  display: 'flex',
  height: '630px',
  flexDirection: 'column',
  gap: '24px',
  flex: 3,
  paddingRight: '28px',
});

export const CustomInput = styled(Input)({
  margin: '12px 0',
  width: '355px',
});

export const CustomInputShort = styled(Input)({
  margin: '12px 0',
  width: '220px',
});

export const CustomInputMedium = styled(Input)({
  margin: '12px 0',
  width: '250px',
});

export const CustomSelect = styled(Select)({
  width: '60%',
});

export const CustomSelectShort = styled(Select)({
  width: '220px',
});

export const CustomSelectMedium = styled(Select)({
  width: '250px',
});

export const Box = styled(MuiBox)({
  display: 'flex',
});

export const Column = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  width: '45%',
});

export const ColumnHeader = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontSize: '16px',
  fontWeight: 'bold',
  fontStretch: 'normal',
  fontStyle: 'normal',
  lineHeight: '1.75',
  letterSpacing: '0.15px',
  textAlign: 'left',
  paddingBottom: '14px',
}));

export const FlexBetween = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
});

export const Controller = styled(ReactController)<ControllerProps>({
  padding: '26px 0',
});

export const CustomFormControl = styled(FormControl)({
  margin: '12px 0',
});

export const CardActions = styled(MuiCardActions)({
  display: 'flex',
  justifyContent: 'flex-end',
  borderTop: '1px solid lightgrey',
  padding: '22px 16px',
  height: '70px',
});
