import { ActionType, createAction, createAsyncAction } from 'typesafe-actions';

import { NotificationPayload } from '~/core/types/notifications.types';

export enum NotificationTypes {
  Notification = '[NotificationTypes] Notification',
  NotificationSuccess = '[NotificationTypes] NotificationSuccess',
  NotificationFailed = '[NotificationTypes] NotificationFailed',
  ClearNotifications = '[NotificationTypes] ClearNotifications',
}

export const notificationAction = createAsyncAction(
  NotificationTypes.Notification,
  NotificationTypes.NotificationSuccess,
  NotificationTypes.NotificationFailed,
)<string, NotificationPayload, NotificationPayload>();

export const notificationClearAction = createAction(NotificationTypes.ClearNotifications)<void>();

export type NotificationActionUnion = ActionType<typeof notificationAction | typeof notificationClearAction>;
