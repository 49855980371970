import {
  Box,
  FormControl,
  styled,
  TableBody as MuiTableBody,
  TableContainer as MuiTableContainer,
} from '@mui/material';
import { Link } from 'react-router-dom';

import { Button, FilterDropdown, SearchField } from '~/core/components/shared';

export const Container = styled('div')({
  height: '100%',
});

export const PlatformAdminConsoleContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: '32px',
  height: '100%',
});

export const SearchContainer = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
  alignItems: 'center',
});

export const SearchFilters = styled('div')({
  display: 'flex',
  gap: '22px',
  flex: 1,
});

export const SearchFieldContainer = styled(SearchField)({
  maxWidth: 340,
});

export const FilterContainer = styled(FilterDropdown)({
  maxWidth: 220,
});

export const NewAccountButton = styled(Button)({
  height: 42,
  maxWidth: '176px',
  marginLeft: 20,
});

export const TableContainer = styled(MuiTableContainer)({});

export const TableBody = styled(MuiTableBody)({
  '& > *': {
    flex: 1,
  },
});

export const AccountStatusDropdown = styled(FormControl)({
  '& .MuiOutlinedInput-notchedOutline': {
    borderRadius: 6,
  },
});

export const BreadcrumbsTypography = styled(Link)({
  display: 'flex',
  alignItems: 'center',
  gap: '11px',
  color: 'rgba(33, 33, 52, 0.6)',
  fontSize: 16,
  fontWeight: 600,
  padding: '6px 0',
  textDecoration: 'none',
});

export const BreadcrumbsStaticTypography = styled('span')({
  display: 'flex',
  alignItems: 'center',
  gap: '11px',
  color: 'rgba(33, 33, 52, 0.8)',
  fontSize: 16,
  fontWeight: 700,
  padding: '6px 0',
  textDecoration: 'none',
});
