import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Box, useMediaQuery, Skeleton, Slide, styled, useTheme } from '@mui/material';

import SankeyChart from '~/core/components/shared/SankeyVisual/SankeyV2';
import { useGetAdGroupSummaries, useGetTrafficSegments } from '~/core/services/graphql/adGroups/hooks';
import {
  TrafficSegmentSortEnum,
  QueryGetAdGroupSummariesArgs,
  QueryGetTrafficSegmentsArgs,
} from '~/core/types/graphql.types';
import { useContexts } from '~/store/context/useContext';
import {
  Breadcrumbs,
  CircularProgress,
  DateSelector,
  PageHeader,
  SelectorMenu,
  Tooltip,
} from '~/core/components/shared';
import { numberWithCommas } from '~/core/helpers';

import { createNodesAndLinks } from './helpers';
import { TrafficSegmentPreview } from '../TrafficSegmentPreview';
import { InfoIcon } from '../ContentGapAnalysis/Tables/styles';

const MetricContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  background: '#fff',
  width: '160px',
  padding: '10px',
  borderRadius: '6px',
});

const Label = styled('span')({
  fontSize: '10px',
  marginTop: '4px',
  width: '100%',
  color: '#6D6D6D',
});

const Metric = styled('span')({
  fontSize: '16px',
  width: '100%',
  fontWeight: 'bold',
});

const SegmentsView = () => {
  const { selectedPlatformAccountId, selectedSourceAccountId, selectedDateRange } = useContexts();
  const [searchParams, setSearchParams] = useSearchParams();
  const [nodes, setNodes] = useState([]);
  const [links, setLinks] = useState([]);
  const adGroupId = searchParams.get('adGroupId');
  const campaignName = searchParams.get('campaignName');
  const [show, setShow] = useState(false);
  const [selectedSegmentId, setSelectedSegmentId] = useState(null);
  const theme = useTheme();

  const getQueryValue = useCallback(
    (selected: string | null, queryParam: string) => selected || searchParams.get(queryParam),
    [searchParams],
  );
  const platformAccountId = useMemo(
    () => getQueryValue(selectedPlatformAccountId?.toString(), 'platformAccountId') as string,
    [getQueryValue, selectedPlatformAccountId] as const,
  );
  const sourceAccountId =
    useMemo(
      () => getQueryValue(selectedSourceAccountId, 'sourceAccountId'),
      [getQueryValue, selectedSourceAccountId],
    ) || searchParams.get('sourceAccountId');

  const dateRange =
    useMemo(() => getQueryValue(selectedDateRange, 'dateRange'), [getQueryValue, selectedDateRange]) ||
    searchParams.get('dateRange');

  const adGroupName = useMemo(() => searchParams.get('adGroupName'), [searchParams]);

  const importedState = useMemo(() => {
    const params = { platformAccountId, sourceAccountId, dateRange, adGroupName };
    return Object.entries(params).reduce((acc: any, [key, value]) => {
      if (value !== null) {
        acc[key] = value;
      }
      return acc;
    }, {});
  }, [platformAccountId, sourceAccountId, dateRange, searchParams, adGroupName]);

  const adGroupSummariesSkip = !selectedPlatformAccountId || !selectedSourceAccountId || !selectedDateRange;
  const adGroupSummariesVariables: QueryGetAdGroupSummariesArgs = {
    platformAccountId: selectedPlatformAccountId,
    customerIds: [selectedSourceAccountId],
    dateRange: selectedDateRange,
    filterField: {
      filters: [
        {
          operation: 'eq',
          fieldName: 'ad_group_id',
          value: adGroupId,
        },
      ],
    },
    page: 1,
    pageSize: 1,
  };

  const { adGroupSummariesLoading, getAdGroupSummaries } = useGetAdGroupSummaries(
    adGroupSummariesSkip,
    adGroupSummariesVariables,
  );

  const totalKeywords = getAdGroupSummaries?.edges?.[0]?.totalKeywords;
  const keywordsWithTraffic = getAdGroupSummaries?.edges?.[0]?.keywordsWithTraffic;
  const impressions = getAdGroupSummaries?.edges?.[0]?.impressions;
  const totalEligibleImpressions = getAdGroupSummaries?.edges?.[0]?.totalEligibleImpressions;

  const trafficSegmentsSkip =
    !selectedPlatformAccountId || !selectedSourceAccountId || !selectedDateRange || !adGroupId;
  const trafficSegmentsVariables: QueryGetTrafficSegmentsArgs = {
    platformAccountId: selectedPlatformAccountId,
    customerId: selectedSourceAccountId,
    dateRange: selectedDateRange,
    adGroupId,
    sortBy: {
      field: TrafficSegmentSortEnum.Impressions,
      desc: true,
    },
    filterField: {
      filters: [
        {
          operation: 'eq',
          fieldName: 'ad_group_id',
          value: adGroupId,
        },
      ],
    },
  };

  const { trafficSegmentsLoading, getTrafficSegments } = useGetTrafficSegments(
    trafficSegmentsSkip,
    trafficSegmentsVariables,
  );

  const onSegmentClick = (segmentId?: string) => {
    // eslint-disable-next-line
    setSelectedSegmentId(segmentId);
    if (segmentId) {
      searchParams.set('adGroupName', adGroupName);
      searchParams.set('segmentId', segmentId);
      setSearchParams(searchParams);
    }
    return segmentId === 'close' ? setShow(false) : setShow(true);
  };

  let newNodes: any[] = [];
  let newLinks: any[] = [];
  let nodeAndLinks: any;

  useEffect(() => {
    if (getTrafficSegments?.length > 0) {
      nodeAndLinks = createNodesAndLinks(getTrafficSegments, null);
      newNodes = nodeAndLinks?.nodes;
      newLinks = nodeAndLinks?.links;
      setNodes(newNodes);
      setLinks(newLinks);
    }
  }, [getTrafficSegments, nodeAndLinks]);

  useEffect(() => {
    if (!adGroupId) return;
    searchParams.set('adGroupId', adGroupId);
  }, [adGroupId]);

  useEffect(() => {
    const cachedSegmentId = searchParams.get('segmentId');
    if (!selectedSegmentId && cachedSegmentId) {
      setSelectedSegmentId(cachedSegmentId);
      return;
    }

    searchParams.set('segmentId', selectedSegmentId);
  }, [selectedSegmentId, getTrafficSegments]);

  const loading = adGroupSummariesLoading || trafficSegmentsLoading;

  const segmentImpressions = nodes?.find((segment) => segment?.id === selectedSegmentId)?.impressions;

  return (
    <div
      style={{
        background: '#fff',
        minHeight: '100%',
      }}
    >
      <Box
        sx={{
          display: 'none !important',
        }}
      >
        <SelectorMenu />
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
          borderBottom: '1px solid rgba(0,0,0,.12)',
        }}
      >
        <Breadcrumbs
          importedState={importedState}
          headerStyle={{
            borderBottom: 'none',
          }}
        />
        <DateSelector />
      </Box>
      <PageHeader
        sx={{
          backgroundColor: '#F0F0F3',
          display: 'flex',
          flexDirection: useMediaQuery(theme.breakpoints.down('md')) ? 'column' : 'row',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
          }}
        >
          <h2
            style={{
              margin: '0',
              fontSize: '16px',
              display: 'flex',
              alignItems: 'center',
              width: '100%',
            }}
          >
            {adGroupName}
            <Tooltip title={`In Campaign: ${campaignName}`} placement="right">
              <InfoIcon
                sx={{
                  color: '#8FA0CB',
                }}
              />
            </Tooltip>
          </h2>
        </div>
        <div
          style={{
            width: useMediaQuery(theme.breakpoints.down('md')) ? '100%' : '70%',
            marginTop: useMediaQuery(theme.breakpoints.down('md')) ? '10px' : '0',
            display: 'flex',
            justifyContent: 'flex-end',
            gap: '20px',
          }}
        >
          <MetricContainer>
            <Metric>
              {loading ? (
                <Skeleton variant="text" width="60px" height={20} />
              ) : typeof totalKeywords === 'number' ? (
                numberWithCommas(totalKeywords)
              ) : (
                '-'
              )}
            </Metric>
            <Label>Total Keywords</Label>
          </MetricContainer>
          <MetricContainer>
            <Metric>
              {loading ? (
                <Skeleton variant="text" width="60px" height={20} />
              ) : typeof keywordsWithTraffic === 'number' ? (
                numberWithCommas(keywordsWithTraffic)
              ) : (
                '-'
              )}
            </Metric>
            <Label>Keywords with Traffic</Label>
          </MetricContainer>
          <MetricContainer>
            <Metric>
              {loading ? (
                <Skeleton variant="text" width="60px" height={20} />
              ) : typeof impressions === 'number' ? (
                numberWithCommas(impressions)
              ) : (
                '-'
              )}
            </Metric>
            <Label>Impressions</Label>
          </MetricContainer>
          <MetricContainer>
            <Metric>
              {loading ? (
                <Skeleton variant="text" width="60px" height={20} />
              ) : typeof totalEligibleImpressions === 'number' ? (
                numberWithCommas(totalEligibleImpressions)
              ) : (
                '-'
              )}
            </Metric>
            <Label>Total Eligible Impressions</Label>
          </MetricContainer>
        </div>
      </PageHeader>
      <div
        className="segments-view__content"
        style={{
          height: '100%',
        }}
      >
        <Box
          sx={{
            margin: '0 auto',
            display: 'flex',
            width: '100%',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
            }}
          >
            <Box
              px={2}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                paddingTop: '15px',
                width: '100%',
              }}
            >
              {!getTrafficSegments || trafficSegmentsLoading ? (
                <>
                  <Skeleton variant="text" width="295px" height={20} />
                  <Skeleton variant="text" width="140px" height={20} />
                  <Skeleton variant="text" width="295px" height={20} />
                </>
              ) : (
                <>
                  <Metric>
                    {getTrafficSegments?.length} Traffic Segments
                    <span
                      style={{
                        fontSize: '12px',
                        lineHeight: 1.67,
                        letterSpacing: '0.4px',
                        color: 'rgba(33, 33, 52, 0.6)',
                        marginLeft: '8px',
                        fontWeight: 'normal',
                      }}
                    >
                      Generated from <strong>{numberWithCommas(totalKeywords)}</strong> Keywords
                    </span>
                  </Metric>

                  {!trafficSegmentsLoading && getTrafficSegments?.length === 0 && (
                    <Box
                      sx={{
                        height: '700px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        fontSize: '16px',
                        fontWeight: 'bold',
                      }}
                    >
                      No data available
                    </Box>
                  )}

                  {/* <Box sx={{ width: 300 }}>
                    <RangeSlider
                      min={1}
                      max={getTrafficSegments?.length}
                      setMaxNodes={setMaxNodes}
                      setMinNodes={setMinNodes}
                    />
                  </Box> */}
                </>
              )}
            </Box>
            {/* <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  fontSize: '12px',
                  padding: '30px',
                }}
              >
                <a href="#">View Keywords with No Traffic</a>
              </Box> */}
            <Box
              sx={{
                display: 'flex',
                width: '100%',
              }}
            >
              <Box
                sx={{
                  width: show ? '70%' : '100%',
                }}
              >
                {getTrafficSegments?.length > 0 && (
                  <Box
                    sx={{
                      paddingLeft: '10px',
                      height: '100%',
                      width: show ? '140%' : '100%',
                    }}
                  >
                    {/* <SankeyVisual
                      data={{
                        nodes,
                        links,
                      }}
                      onSegmentClick={onSegmentClick}
                      maxNodes={maxNodes}
                      minNodes={minNodes}
                    /> */}
                    <SankeyChart
                      nodes={nodes}
                      links={links}
                      onSegmentClick={onSegmentClick}
                      selectedSegmentId={selectedSegmentId}
                    />
                  </Box>
                )}
              </Box>
            </Box>
          </Box>

          {getTrafficSegments?.length && show && selectedSegmentId !== null && (
            <Box
              sx={{
                maxHeight: '83vh',
                overflowY: 'auto',
                minWidth: '430px',
                maxWidth: '430px',
              }}
            >
              <Slide direction="left" in={show}>
                <Box
                  sx={{
                    minWidth: '400px',
                    maxWidth: '400px',
                  }}
                >
                  <TrafficSegmentPreview
                    onSegmentClick={onSegmentClick}
                    segmentId={selectedSegmentId}
                    totalImpressions={impressions}
                    segmentImpressions={segmentImpressions}
                    totalIntentThemes={
                      getTrafficSegments?.find((segment) => segment?.segmentId === selectedSegmentId)?.intentThemes
                    }
                  />
                </Box>
              </Slide>
            </Box>
          )}
        </Box>
        {trafficSegmentsLoading && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '600px',
            }}
          >
            <CircularProgress />
          </Box>
        )}
      </div>
    </div>
  );
};

export default SegmentsView;
