import {
  ApolloClient,
  ApolloError,
  ApolloLink,
  DefaultOptions,
  DocumentNode,
  InMemoryCache,
  MutationOptions,
  NormalizedCacheObject,
  QueryOptions,
  createHttpLink,
  from,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { asyncMap } from '@apollo/client/utilities';
import { SentryLink } from 'apollo-link-sentry';

import { setAccessTokenAction } from '~/store/actions/auth.action';
import { store } from '~/store/index';

import { refreshTokenRequest } from './rest/auth.service';

const sentryLink = new SentryLink();

const httpLink = createHttpLink({
  uri:
    !process.env.NODE_ENV || process.env.NODE_ENV === 'development'
      ? `${process.env.REACT_APP_API_BASE_URL}/graphql`
      : `${window.location.protocol}//backend-${window.location.hostname}/graphql`,
  // For local development
  //   uri: 'http://localhost:80/graphql',
});

export const adFactoryLink = createHttpLink({
  uri:
    !process.env.NODE_ENV || process.env.NODE_ENV === 'development'
      ? `${process.env.REACT_APP_API_BASE_URL}/ad-factory/graphql`
      : `${window.location.protocol}//backend-${window.location.hostname}/ad-factory/graphql`,
  // For local development
  // uri: 'http://localhost:82/ad-factory/graphql',
});

export const authLink = setContext(async (_, { headers }) => {
  const { tokenExpiredTimestamp, AccessToken, RefreshToken } = store.getState().auth;
  const currentTime = new Date().getTime();

  if (tokenExpiredTimestamp && tokenExpiredTimestamp * 1000 < currentTime) {
    const { AccessToken, IdToken, User } = await refreshTokenRequest(RefreshToken);

    store.dispatch(setAccessTokenAction.request({ AccessToken, IdToken, User }));

    return {
      headers: {
        ...headers,
        Authorization: AccessToken,
      },
    };
  }

  return {
    headers: {
      ...headers,
      Authorization: AccessToken,
    },
  };
});

export const throwErrorsLink = new ApolloLink((operation, forward) =>
  asyncMap(forward(operation), async (response) => {
    if (response.errors) {
      throw new ApolloError({ clientErrors: response.errors });
    }
    return response;
  }),
);

// TODO: Investigate about Apollo Client Cache
const defaultOptions: DefaultOptions = {
  watchQuery: {
    errorPolicy: 'ignore',
  },
  query: {
    errorPolicy: 'all',
  },
};

const cache = new InMemoryCache({
  addTypename: true,
  resultCaching: true,
  dataIdFromObject: (o) => (o._id ? `${o.__typename}:${o._id}` : null),
});

export const graphqlClient = new ApolloClient({
  link: ApolloLink.concat(from([sentryLink, authLink, throwErrorsLink]), httpLink),
  cache,
  defaultOptions,
  connectToDevTools: true,
});

export const adFactoryClient = new ApolloClient({
  link: ApolloLink.concat(from([sentryLink, authLink, throwErrorsLink]), adFactoryLink),
  cache,
  defaultOptions,
  connectToDevTools: true,
});

export const apolloServiceRequest = async <T, V>(
  client: ApolloClient<NormalizedCacheObject>,
  operation: 'mutation' | 'query',
  query: DocumentNode,
  variables?: V,
): Promise<T | undefined> => {
  let response;

  if (operation === 'mutation') {
    const options: MutationOptions = {
      variables,
      mutation: query,
    };

    response = await client.mutate<T>(options);
  } else if (operation === 'query') {
    const options: QueryOptions = {
      variables,
      query,
    };

    response = await client.query<T>(options);
  }

  return response?.data;
};
