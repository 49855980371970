import { useQuery } from '@apollo/client';

import { AccountDataSchema, AssessmentProcessResponseSchema } from '~/core/types/graphql.types';

import { GET_ASSESSMENT_STATUSES, GET_SOURCE_ACCOUNT_INFO } from './sourceAccounts.queries';
import {
  GetAssessmentLatestQueryVariables,
  GetSourceAccountInfoQueryVariables,
} from './sourceAccounts.queries.generated';

export const useGetAssessmentStatuses = (skip: boolean, variables: GetAssessmentLatestQueryVariables) => {
  const {
    data: assessmentStatusesData,
    loading: assessmentStatusesLoading,
    error: assessmentStatusesError,
  } = useQuery<{ getAssessmentLatest: AssessmentProcessResponseSchema[] }>(GET_ASSESSMENT_STATUSES, {
    skip,
    variables,
  });

  const getAssessmentLatest = assessmentStatusesData?.getAssessmentLatest;

  return { assessmentStatusesLoading, assessmentStatusesError, getAssessmentLatest };
};

export const useGetSourceAccountInfo = (skip: boolean, variables: GetSourceAccountInfoQueryVariables) => {
  const {
    data: sourceAccountInfoData,
    loading: sourceAccountInfoLoading,
    error: sourceAccountInfoError,
  } = useQuery<{ getSourceAccountInfo: AccountDataSchema[] }>(GET_SOURCE_ACCOUNT_INFO, {
    skip,
    variables,
  });

  const getSourceAccountInfo = sourceAccountInfoData?.getSourceAccountInfo;

  return { sourceAccountInfoLoading, sourceAccountInfoError, getSourceAccountInfo };
};
