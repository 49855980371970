import { gql } from '@apollo/client';

export const GET_PLATFORM_ACCOUNTS = gql`
  query userAccounts {
    userAccounts {
      id
      name
      isAdmin
      isFree
      accountType
      stripeCustomerId
      stripeSubscriptionId
      contactEmail
      customerAccountAccess {
        id
        name
        isFree
        accountType
      }
    }
  }
`;
