import { styled, Box, BoxProps, Typography, TypographyProps } from '@mui/material';

import { Chip as MuiChip } from '../Chip';

export const ProfileBox = styled(Box)({
  backgroundColor: '#1118zf27',
  padding: '5px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  alignSelf: 'center',
});

export const HeaderTitle = styled(Typography)(({ theme }) => ({
  height: '32px',
  width: '32px',
  marginRight: '12px',
  borderRadius: '4px',
  backgroundColor: theme.palette.background.paper,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: '14px',
  fontWeight: 'bold',
  textAlign: 'left',
  color: '#000',
}));

export const ContentContainer = styled(Box)<BoxProps>({
  flexDirection: 'column',
});

export const ContentHeader = styled(Typography)<TypographyProps>({
  color: 'gray',
});

export const ContentText = styled(Typography)<TypographyProps>({
  color: 'white',
  fontSize: '14px',
  fontWeight: 'bold',
  width: '100%',
});

export const Chip = styled(MuiChip)({
  cursor: 'pointer',
  fontWeight: 600,
});
