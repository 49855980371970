import { useEffect, useState } from 'react';
import { ErrorRounded, NavigateBefore, NavigateNext } from '@mui/icons-material';
import { Box, Card, IconButton, Skeleton, styled } from '@mui/material';

import { useGetKeywordsText } from '~/core/services/graphql/keywords/hooks';
import { KeywordSortEnum, QueryGetKeywordsArgs } from '~/core/types/graphql.types';
import { useContexts } from '~/store/context/useContext';

const CustomCard = styled(Box)`
  height: 100%;
  align-self: stretch;
  flex-grow: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 10px 12px;
  border-radius: 6px;\
  border: solid 1px rgba(33, 33, 52, 0.12);
  background-color: #fff;
  margin: 8px 0;
`;

const Heading = styled('p')`
  height: 22px;
  align-self: stretch;
  flex-grow: 0;
  font-family: Inter;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: 0.1px;
  text-align: center;
  color: rgba(33, 33, 52, 0.87);
  margin: 0;
`;

const WrapContainer = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexWrap: 'wrap',
  width: 'auto',
  gap: 4,
  minHeight: 50,
});

const KeywordBubble = styled('p')({
  fontSize: 10,
  border: '1px solid #DBDBDB',
  borderRadius: '25px',
  padding: '4px 8px',
  textAlign: 'center',
  margin: 0,
  height: 24,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: 'rgba(33, 33, 52, 0.87)',
  background: '#E4E4E7',
});

const PaginationText = styled('div')({
  fontSize: 10,
  margin: 0,
});

type KeywordsPreviewProps = {
  segmentId: string;
};

const KeywordsPreview: React.FC<KeywordsPreviewProps> = ({ segmentId }) => {
  const [page, setPage] = useState(0);
  const [cacheTotalPages, setCacheTotalPages] = useState(0);
  const [cacheKeywordsTotal, setCacheKeywordsTotal] = useState(0);

  const handleNext = () => {
    setPage((prevPage: number) => prevPage + 1);
  };

  const handlePrevious = () => {
    setPage((prevPage: number) => (prevPage > 0 ? prevPage - 1 : 0));
  };

  const { selectedPlatformAccountId, selectedSourceAccountId, selectedDateRange } = useContexts();
  const getKeywordsVars: QueryGetKeywordsArgs = {
    platformAccountId: selectedPlatformAccountId,
    customerIds: [selectedSourceAccountId],
    dateRange: selectedDateRange,
    page: page + 1,
    pageSize: 10,
    sortBy: {
      field: KeywordSortEnum.Impressions,
      desc: true,
    },
    filterField: {
      filters: [
        {
          operation: 'eq',
          fieldName: 'segment_id',
          value: segmentId,
        },
      ],
    },
  };

  const getKeywordsSkip = !selectedPlatformAccountId || !selectedSourceAccountId || !selectedDateRange || !segmentId;

  const { getKeywords, keywordsLoading, keywordsError } = useGetKeywordsText(getKeywordsSkip, getKeywordsVars);

  if (cacheTotalPages === 0 && getKeywords?.pageInfo?.pages) {
    setCacheTotalPages(getKeywords?.pageInfo?.pages);
  }

  if (cacheKeywordsTotal === 0) {
    setCacheKeywordsTotal(getKeywords?.pageInfo?.total);
  }

  useEffect(() => {
    if (getKeywords?.pageInfo?.pages) {
      setCacheTotalPages(getKeywords?.pageInfo?.pages);
    }
  }, [getKeywords?.pageInfo?.pages]);

  useEffect(() => {
    setPage(0);
  }, [segmentId]);

  if (keywordsError)
    return (
      <CustomCard
        sx={{
          height: '180px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '20px',
          textAlign: 'center',
          fontSize: 18,
          fontWeight: 'bold',
        }}
      >
        <ErrorRounded color="error" />
        Please refresh the page or try again shortly.
      </CustomCard>
    );

  if (keywordsLoading)
    return (
      <CustomCard>
        <Heading>
          <Heading>
            {cacheKeywordsTotal} Keyword{cacheKeywordsTotal === 1 ? '' : 's'}
          </Heading>
        </Heading>
        <WrapContainer>
          {[...Array(10)].map((_, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <KeywordBubble
              key={`skeleton-${index}`}
              sx={{
                width: Math.random() * 100 + 50,
              }}
            >
              <Skeleton variant="text" animation="wave" width="60px" height={10} />
            </KeywordBubble>
          ))}
        </WrapContainer>
        <Box display="flex" alignItems="center">
          <IconButton onClick={handlePrevious} disabled={page === 0}>
            <NavigateBefore
              sx={{
                fontSize: 12,
              }}
            />{' '}
          </IconButton>
          <PaginationText>
            {page + 1} of {cacheTotalPages}
          </PaginationText>
          <IconButton onClick={null} disabled>
            <NavigateNext
              sx={{
                fontSize: 12,
              }}
            />{' '}
          </IconButton>
        </Box>
      </CustomCard>
    );

  return (
    <CustomCard>
      <Heading>
        {getKeywords?.pageInfo?.total} Keyword{getKeywords?.pageInfo?.total === 1 ? '' : 's'}
      </Heading>

      <WrapContainer>
        {getKeywords?.edges?.map((keyword) => (
          <KeywordBubble key={keyword.criterion_resource_name}>{keyword.keyword_text}</KeywordBubble>
        ))}
      </WrapContainer>

      <Box display="flex" alignItems="center">
        <IconButton onClick={handlePrevious} disabled={page === 0}>
          <NavigateBefore
            sx={{
              fontSize: 12,
            }}
          />
        </IconButton>
        <PaginationText>
          {page + 1} of {getKeywords?.pageInfo?.pages}
        </PaginationText>
        <IconButton onClick={handleNext} disabled={page + 1 === getKeywords?.pageInfo?.pages}>
          <NavigateNext
            sx={{
              fontSize: 12,
            }}
          />
        </IconButton>
      </Box>
    </CustomCard>
  );
};

export default KeywordsPreview;
