import { useQuery } from '@apollo/client';
import { useEffect, useMemo } from 'react';
import ReactGA from 'react-ga4';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';

import { graphqlClient } from '~/core/services';
import { GET_PLATFORM_ACCOUNTS } from '~/core/services/graphql/platformAccounts/platformAccounts.queries';
import { PlatformAccountTypeEnum } from '~/core/types/graphql.types';
import { getTokens } from '~/store/actions/auth.action';
import { useContexts } from '~/store/context/useContext';
import { authUserSelector } from '~/store/selectors/auth.selector';

const platformAccount = {
  id: -1,
  name: 'Platform Admin',
  accountType: PlatformAccountTypeEnum.Customer,
  customerAccountAccess: [] as any,
  isFree: false,
  isAdmin: true,
};

const InitApp: React.FC = () => {
  const dispatch = useDispatch();
  const {
    setPlatformAccounts,
    setPlatformAccountsLoading,
    setPlatformAccountsError,
    selectedPlatformAccountId,
    setSelectedPlatformAccount,
    setSelectedPlatformAccountId,
  } = useContexts();
  const user = useSelector(authUserSelector);

  const { loading, error, data } = useQuery(GET_PLATFORM_ACCOUNTS, {
    skip: !user,
    client: graphqlClient,
  });

  const platformAccountsAccess = useMemo(
    () => (user?.IsPlatformAdmin && data?.userAccounts ? [...data.userAccounts, platformAccount] : data?.userAccounts),
    [data],
  );

  useEffect(() => {
    if (platformAccountsAccess?.length > 0) {
      if (!selectedPlatformAccountId) {
        const currentUser = Number(localStorage.getItem('currentUser'));
        setSelectedPlatformAccountId(currentUser || platformAccountsAccess[0]?.id);
        setSelectedPlatformAccount(
          platformAccountsAccess.find((account: any) => account.id === currentUser) || platformAccountsAccess[0],
        );
      }
    }
    setPlatformAccounts(platformAccountsAccess);
    setPlatformAccountsLoading(loading);
    setPlatformAccountsError(error);
  }, [data, loading, error]);

  const location = useLocation();
  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: location.pathname });
  }, [location]);

  useEffect(() => {
    if (!user) {
      dispatch(getTokens.request());
    } else {
      dispatch(getTokens.failure());
    }
  }, [user, dispatch]);

  return null;
};

export default InitApp;
