import { FormControl, InputLabel, Typography, MenuItem } from '@mui/material';
import { useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import { Button } from '~/core/components/shared';
import {
  PHONE_NUMBER_PATTERN,
  ZIP_CODE_PATTERN,
  EMAIL_PATTERN_REGEX,
} from '~/core/constants/validationPatterns.constants';
import { parseSnakeUppercaseToNormal } from '~/core/helpers/parseSnakUpperCase.helpers';
import {
  AccountStatusEnum,
  CompanyTypeEnum,
  CountryEnum,
  IndustryEnum,
  PlatformAccountInputSchema,
  PlatformAccountTypeEnum,
  StateEnum,
} from '~/core/types/graphql.types';
import { createAccountAction, updatePlatformAccountAction } from '~/store/actions/account.action';

import { agencyAccountSteps, customerAccountSteps } from '../../../data';
import { StepsEnum } from '../../../types';
import { StepProps } from '../types';
import { defaultValues } from './data';
import * as Styled from './styles';

const CreateAccount = ({ activeStep, setActiveStep, accountId, accountData, setAccountData }: StepProps) => {
  const dispatch = useDispatch();
  const {
    handleSubmit,
    watch,
    control,
    formState: { errors },
    getValues,
    reset,
  } = useForm<PlatformAccountInputSchema>({
    mode: 'onSubmit',
    defaultValues,
  });

  const companyType = watch('companyType');
  const country = watch('country');

  const handleNextStep = () => {
    const data = getValues();
    if (accountId) {
      dispatch(
        updatePlatformAccountAction.request({
          accountId,
          accountData: {
            ...data,
            cap: 1,
            contactPhone: data.phoneNumber,
          },
        }),
      );
    } else {
      const accountInfo: PlatformAccountInputSchema = {
        ...data,
        cap: 1,
        contactEmail: '',
        contactPhone: data.phoneNumber,
        status: AccountStatusEnum.Active,
      };
      dispatch(
        createAccountAction.request({
          ...accountInfo,
        }),
      );
      setAccountData(accountInfo);
    }
    if (companyType === PlatformAccountTypeEnum.Agency) {
      setActiveStep(StepsEnum.InviteUser);
      localStorage.setItem('activeStep', StepsEnum.InviteUser);
    } else {
      setActiveStep(StepsEnum.GoogleAuth);
      localStorage.setItem('activeStep', StepsEnum.GoogleAuth);
    }
  };

  useEffect(() => {
    reset({
      companyType: accountData?.companyType,
      name: accountData?.name,
      website: accountData?.website,
      editableCompanyType: accountData?.editableCompanyType,
      phoneNumber: accountData?.phoneNumber,
      faxNumber: accountData?.faxNumber,
      address_1: accountData?.address_1,
      address_2: accountData?.address_2,
      city: accountData?.city,
      state: accountData?.state,
      zipCode: accountData?.zipCode,
      country: accountData?.country,
      industry: accountData?.industry,
      integrationEmail: accountData?.integrationEmail,
    });
  }, [accountData, reset]);

  return (
    <>
      <Styled.CardContent data-testid="createAccount">
        <Styled.VerticalStepper
          steps={companyType === PlatformAccountTypeEnum.Agency ? agencyAccountSteps : customerAccountSteps}
          activeStep={Number(activeStep)}
        />
        <Styled.StepContent>
          <Styled.HeaderContainer>
            <Typography fontSize={20} fontWeight={600}>
              Add company details
            </Typography>
          </Styled.HeaderContainer>

          <Styled.FormContainer>
            <Controller
              name="companyType"
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange, value = '' } }) => (
                <FormControl>
                  <InputLabel>Account Type*</InputLabel>
                  <Styled.CustomSelectShort
                    data-testid="companyType"
                    value={value}
                    label="Account Type"
                    required
                    onChange={onChange}
                    error={!!errors?.companyType}
                  >
                    <MenuItem value={PlatformAccountTypeEnum.Agency}>Agency</MenuItem>
                    <MenuItem value={PlatformAccountTypeEnum.Customer}>Customer</MenuItem>
                  </Styled.CustomSelectShort>
                </FormControl>
              )}
            />
            <Styled.Box>
              <Styled.Column>
                <Styled.ColumnHeader>Business Information</Styled.ColumnHeader>
                <Controller
                  name="name"
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <Styled.CustomInput
                      data-testid="name"
                      value={value}
                      label="Company Name"
                      required
                      onChange={onChange}
                      error={!!errors?.name}
                      helperText={!!errors?.name && 'Enter valid name'}
                    />
                  )}
                />
                <Controller
                  name="website"
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <Styled.CustomInput
                      data-testid="website"
                      value={value}
                      label="Corporate Website"
                      required
                      onChange={onChange}
                      error={!!errors?.website}
                      helperText={!!errors?.website && 'Enter valid website'}
                    />
                  )}
                />
                <Controller
                  name="editableCompanyType"
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { onChange, value = '' } }) => (
                    <Styled.CustomFormControl>
                      <InputLabel>Company Type*</InputLabel>
                      <Styled.CustomSelectMedium
                        data-testid="editableCompanyType"
                        value={value}
                        label="Company Type*"
                        required
                        onChange={onChange}
                        error={!!errors?.editableCompanyType}
                      >
                        {(Object.keys(CompanyTypeEnum) as (keyof typeof CompanyTypeEnum)[]).map((key) => (
                          <MenuItem key={key} value={CompanyTypeEnum[key]}>
                            {CompanyTypeEnum[key]}
                          </MenuItem>
                        ))}
                      </Styled.CustomSelectMedium>
                    </Styled.CustomFormControl>
                  )}
                />
                <Controller
                  name="industry"
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { onChange, value = '' } }) => (
                    <Styled.CustomFormControl>
                      <InputLabel>Industry*</InputLabel>
                      <Styled.CustomSelectMedium
                        data-testid="industry"
                        value={value}
                        label="Industry*"
                        required
                        onChange={onChange}
                        error={!!errors?.industry}
                      >
                        {(Object.keys(IndustryEnum) as (keyof typeof IndustryEnum)[]).map((key) => (
                          <MenuItem key={key} value={IndustryEnum[key]}>
                            {parseSnakeUppercaseToNormal(IndustryEnum[key])}
                          </MenuItem>
                        ))}
                      </Styled.CustomSelectMedium>
                    </Styled.CustomFormControl>
                  )}
                />
                <Controller
                  name="phoneNumber"
                  rules={{
                    required: true,
                    pattern: {
                      value: PHONE_NUMBER_PATTERN,
                      message: 'Wrong number',
                    },
                  }}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <Styled.CustomInputMedium
                      data-testid="phoneNumber"
                      value={value}
                      label="Corporate Phone Number"
                      required
                      onChange={onChange}
                      error={!!errors?.phoneNumber}
                      helperText={!!errors?.phoneNumber && 'Enter valid phone number'}
                    />
                  )}
                />
                <Controller
                  name="faxNumber"
                  rules={{
                    pattern: {
                      value: PHONE_NUMBER_PATTERN,
                      message: 'Wrong number',
                    },
                  }}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <Styled.CustomInputMedium
                      data-testid="faxNumber"
                      value={value}
                      onChange={onChange}
                      label="Corporate Fax Number"
                      error={!!errors?.faxNumber}
                      helperText={!!errors?.faxNumber && 'Enter valid fax number'}
                    />
                  )}
                />
              </Styled.Column>
              <Styled.Column>
                <Styled.ColumnHeader>Business Address</Styled.ColumnHeader>
                <Controller
                  name="address_1"
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <Styled.CustomInput
                      data-testid="addressLineOne"
                      value={value}
                      label="Address Line 1"
                      required
                      onChange={onChange}
                      error={!!errors?.address_1}
                      helperText={!!errors?.address_1 && 'Enter valid address'}
                    />
                  )}
                />
                <Controller
                  name="address_2"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <Styled.CustomInput
                      data-testid="addressLineTwo"
                      label="Address Line 2"
                      value={value}
                      onChange={onChange}
                    />
                  )}
                />
                <Controller
                  name="city"
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <Styled.CustomInputShort
                      data-testid="city"
                      value={value}
                      label="City"
                      required
                      onChange={onChange}
                      error={!!errors?.city}
                      helperText={!!errors?.city && 'Enter valid city'}
                    />
                  )}
                />
                <Controller
                  name="country"
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { onChange, value = '' } }) => (
                    <Styled.CustomFormControl>
                      <InputLabel>Country*</InputLabel>
                      <Styled.CustomSelect
                        data-testid="country"
                        value={value}
                        label="Country*"
                        required
                        onChange={onChange}
                      >
                        {(Object.keys(CountryEnum) as (keyof typeof CountryEnum)[]).map((key) => (
                          <MenuItem key={key} value={CountryEnum[key]}>
                            {CountryEnum[key]}
                          </MenuItem>
                        ))}
                      </Styled.CustomSelect>
                    </Styled.CustomFormControl>
                  )}
                />
                <Styled.FlexBetween>
                  {country === CountryEnum.Usa && (
                    <Controller
                      name="state"
                      control={control}
                      rules={{ required: true }}
                      render={({ field: { onChange, value = '' } }) => (
                        <Styled.CustomFormControl>
                          <InputLabel>State*</InputLabel>
                          <Styled.CustomSelectShort
                            data-testid="state"
                            value={value}
                            label="State*"
                            required
                            onChange={onChange}
                            error={!!errors?.state}
                          >
                            {country === CountryEnum.Usa &&
                              (Object.keys(StateEnum) as (keyof typeof StateEnum)[]).map((key) => (
                                <MenuItem key={key} value={StateEnum[key]}>
                                  {parseSnakeUppercaseToNormal(StateEnum[key])}
                                </MenuItem>
                              ))}
                          </Styled.CustomSelectShort>
                        </Styled.CustomFormControl>
                      )}
                    />
                  )}
                  <Controller
                    name="zipCode"
                    rules={{
                      required: true,
                      pattern: {
                        value: ZIP_CODE_PATTERN,
                        message: 'Invalid zip code',
                      },
                    }}
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <Styled.CustomInputShort
                        data-testid="zipCode"
                        sx={country === CountryEnum.Usa && { marginLeft: '80px' }}
                        value={value}
                        label="Zip Code"
                        required
                        onChange={onChange}
                        error={!!errors?.zipCode}
                        helperText={!!errors?.zipCode && 'Enter valid zip code'}
                      />
                    )}
                  />
                </Styled.FlexBetween>
                {companyType === PlatformAccountTypeEnum.Customer && (
                  <Controller
                    name="integrationEmail"
                    rules={{
                      required: true,
                      pattern: {
                        value: EMAIL_PATTERN_REGEX,
                        message: 'Invalid email',
                      },
                    }}
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <Styled.CustomInput
                        data-testid="integrationEmail"
                        disabled={!!accountData?.integrationEmail}
                        value={accountData ? accountData.integrationEmail : value}
                        label="Integration Email"
                        required
                        onChange={onChange}
                        error={!!errors?.integrationEmail}
                        helperText={!!errors?.integrationEmail && 'Enter valid email'}
                      />
                    )}
                  />
                )}
              </Styled.Column>
            </Styled.Box>
          </Styled.FormContainer>
        </Styled.StepContent>
      </Styled.CardContent>
      <Styled.CardActions>
        <Button
          data-testid="nextButton"
          onClick={handleSubmit(handleNextStep)}
          label={accountId ? 'Update Account' : 'Next'}
          variant="contained"
          color="primary"
        />
      </Styled.CardActions>
    </>
  );
};

export default CreateAccount;
