export enum StatusFilterValues {
  ANY_STATUS = 'Any Status',
  PENDING = 'Pending',
  ACTIVE = 'Active',
  INACTIVE = 'Inactive',
}

export enum RoleFilterValues {
  ANY_ROLE = 'Any Role',
  ADMIN = 'Admin',
  STANDARD_USER = 'Standard User',
}

export enum RoleValues {
  ADMIN = 'Admin',
  STANDARD_USER = 'Standard User',
}
