import { gql } from '@apollo/client';

export const GET_CAMPAIGNS = gql`
  query getCampaigns(
    $platformAccountId: Int!
    $customerIds: [String!]!
    $campaignIds: [String!]
    $dateRange: String!
    $sortBy: CampaignSortSchema
    $filterField: BaseFilterSchema
    $searchValue: String
  ) {
    getCampaigns(
      platformAccountId: $platformAccountId
      customerIds: $customerIds
      campaignIds: $campaignIds
      dateRange: $dateRange
      sortBy: $sortBy
      filterField: $filterField
      searchValue: $searchValue
    ) {
      id
      name
      customerId
      dateRange
      impressions
    }
  }
`;
