import { CheckCircle, Cancel } from '@mui/icons-material';
import { styled, CardActions as MuiCardActions, CardContent as MuiCardContent } from '@mui/material';

import { VerticalStepper as VerticalStepperComponent } from '~/core/components/shared/VerticalStepper';

export const CardContent = styled(MuiCardContent)({
  padding: '0px 0px !important',
  display: 'flex',
  justifyContent: 'center',
  alignSelf: 'center',
});

export const VerticalStepper = styled(VerticalStepperComponent)({
  height: '315px',
  width: '200px',
});

export const StepContent = styled('div')({
  borderLeft: '1px solid lightgrey',
  paddingLeft: '32px',
  width: '100%',
  backgroundColor: '#fff',
});

export const HeaderContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '24px 0',
});

export const FormContainer = styled('div')({
  display: 'flex',
  height: '630px',
  flexDirection: 'column',
  gap: '24px',
  flex: 3,
  paddingRight: '28px',
});

export const AuthorizedAccount = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  fontSize: '20px',
  lineHeight: 1.6,
  letterSpacing: '0.15px',
  color: theme.palette.success.dark,
}));

export const ErrorAuthorizing = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  fontSize: '20px',
  lineHeight: 1.6,
  letterSpacing: '0.15px',
  color: theme.palette.error.main,
}));

export const ImgButton = styled('img')({
  cursor: 'pointer',
  height: '50px',
  width: '210px',
  '&:hover': {
    opacity: 0.8,
  },
});

export const CardActions = styled(MuiCardActions)({
  display: 'flex',
  justifyContent: 'flex-end',
  borderTop: '1px solid lightgrey',
  padding: '22px 16px',
  height: '70px',
});

export const CheckCircleIcon = styled(CheckCircle)({
  marginRight: '7px',
});

export const CancelIcon = styled(Cancel)({
  marginRight: '7px',
});
