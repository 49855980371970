import { BoxProps } from '@mui/material';

import * as Styled from './styles';

const PageHeader: React.FC<BoxProps> = ({ children, ...props }) => (
  <Styled.HeaderWrapper data-testid="pageHeader" {...props}>
    {children}
  </Styled.HeaderWrapper>
);

export default PageHeader;
