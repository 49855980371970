import { useDispatch, useSelector } from 'react-redux';

import { notificationClearAction } from '~/store/actions/notifications.action';
import { notificationMessageSelector } from '~/store/selectors/notifications.selector';

import { Snackbar } from '../../shared';

const SnackbarEvents = () => {
  const dispatch = useDispatch();
  const notificationMessage = useSelector(notificationMessageSelector);

  const clearNotifications = () => {
    dispatch(notificationClearAction());
  };

  return (
    notificationMessage.message && (
      <Snackbar
        onClose={clearNotifications}
        message={notificationMessage.message}
        severity={notificationMessage.severity || 'success'}
      />
    )
  );
};

export default SnackbarEvents;
