import { styled, DialogContent as MuiDialogContent, Container } from '@mui/material';

export const DialogContent = styled(MuiDialogContent)({
  display: 'flex',
  flexDirection: 'column',
  gap: '24px',
  padding: 0,
  paddingTop: '6px',
});

export const DialogContainer = styled(Container)({
  display: 'flex',
  flexDirection: 'column',
  gap: '24px',
});
