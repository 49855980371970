import { styled } from '@mui/material';

import { Input, PasswordInput } from '~/core/components/shared';

export const TermsText = styled('span')({
  fontSize: '12px',
});

export const CustomInput = styled(Input)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    fontSize: '10px',
    '& .MuiInputBase-input': {
      fontSize: '10px',
    },

    '& .MuiFormLabel-root': {
      fontSize: '10px',
    },
  },
}));

export const CustomPasswordInput = styled(PasswordInput)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    fontSize: '10px',
    '& .MuiInputBase-input': {
      fontSize: '10px',
    },

    '& .MuiFormLabel-root': {
      fontSize: '10px',
    },
  },
}));
