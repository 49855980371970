import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { logoutAction } from '~/store/actions/auth.action';
import { useContexts } from '~/store/context/useContext';

import { PrivateRouteProps } from './types';

const PrivateRoute: React.FC<PrivateRouteProps> = ({
  children,
  isAllowed,
  isVerified,
  error,
  redirectPath = '/auth/sign-in',
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { platformAccountsError } = useContexts();
  const undefinedAccessToken = error?.includes("cannot read properties of undefined (reading 'accesstoken')");
  const unauthorizedError = platformAccountsError?.message?.includes('401');

  useEffect(() => {
    if (undefinedAccessToken || unauthorizedError) {
      dispatch(logoutAction.request());
      navigate('../auth/sign-in');
    }

    if (!isAllowed) {
      navigate(redirectPath, { replace: true });
    }
    if (isAllowed && !isVerified) {
      navigate('../auth/confirm', { replace: true });
    }
  }, [isAllowed, navigate, redirectPath, isVerified]);

  return isAllowed && children;
};

export default PrivateRoute;
