import { ActionType, createAction, createAsyncAction } from 'typesafe-actions';

import {
  OwnIntegrationAccountsQuery,
  OwnIntegrationAccountsQueryVariables,
} from '~/core/services/graphql/integrationAccounts/integrationAccounts.queries.generated';
import { GoogleAdsUrl } from '~/core/types/googleAdsAccounts.types';

export enum IntegrationAccountsTypes {
  PostGoogleAuthSignIn = '[IntegrationAccountsTypes] PostGoogleAuthSignIn',
  PostGoogleAuthSignInSuccess = '[IntegrationAccountsTypes] PostGoogleAuthSignInSuccess',
  PostGoogleAuthSignInFailed = '[IntegrationAccountsTypes] PostGoogleAuthSignInFailed',
  RefreshIntegrationAccounts = '[IntegrationAccountsTypes] RefreshIntegrationAccounts',
  RefreshIntegrationAccountsSuccess = '[IntegrationAccountsTypes] RefreshIntegrationAccountsSuccess',
  RefreshIntegrationAccountsFailed = '[IntegrationAccountsTypes] RefreshIntegrationAccountsFailed',
  GetIntegrationAccounts = '[IntegrationAccountsTypes] GetIntegrationAccounts',
  GetIntegrationAccountsSuccess = '[IntegrationAccountsTypes] GetIntegrationAccountsSuccess',
  GetIntegrationAccountsFailed = '[IntegrationAccountsTypes] GetIntegrationAccountsFailed',
  LinkNewAccount = '[IntegrationAccountsTypes] LinkNewAccount',
  LinkNewAccountSuccess = '[IntegrationAccountsTypes] LinkNewAccountSuccess',
  LinkNewAccountFailed = '[IntegrationAccountsTypes] LinkNewAccountFailed',
  ClearIntegrationAccounts = '[IntegrationAccountsTypes] ClearIntegrationAccounts',
}

export const postGoogleAuthSignInAction = createAsyncAction(
  IntegrationAccountsTypes.PostGoogleAuthSignIn,
  IntegrationAccountsTypes.PostGoogleAuthSignInSuccess,
  IntegrationAccountsTypes.PostGoogleAuthSignInFailed,
)<{ accountId: number; redirectionUri: string }, GoogleAdsUrl, string>();

export const refreshIntegrationAccountsAction = createAsyncAction(
  IntegrationAccountsTypes.RefreshIntegrationAccounts,
  IntegrationAccountsTypes.RefreshIntegrationAccountsSuccess,
  IntegrationAccountsTypes.RefreshIntegrationAccountsFailed,
)<number, OwnIntegrationAccountsQuery, string>();

export const getIntegrationAccountsAction = createAsyncAction(
  IntegrationAccountsTypes.GetIntegrationAccounts,
  IntegrationAccountsTypes.GetIntegrationAccountsSuccess,
  IntegrationAccountsTypes.GetIntegrationAccountsFailed,
)<OwnIntegrationAccountsQueryVariables, OwnIntegrationAccountsQuery, string>();

export const linkNewAccountAction = createAsyncAction(
  IntegrationAccountsTypes.LinkNewAccount,
  IntegrationAccountsTypes.LinkNewAccountSuccess,
  IntegrationAccountsTypes.LinkNewAccountFailed,
)<number, OwnIntegrationAccountsQuery, string>();

export const clearIntegrationAccountsAction = createAction(IntegrationAccountsTypes.ClearIntegrationAccounts)<void>();

export type IntegrationAccountsActionUnion = ActionType<
  | typeof postGoogleAuthSignInAction
  | typeof refreshIntegrationAccountsAction
  | typeof linkNewAccountAction
  | typeof getIntegrationAccountsAction
  | typeof clearIntegrationAccountsAction
>;
