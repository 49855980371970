import { Typography, styled } from '@mui/material';

export const Heading = styled(Typography)`
  font-size: 16px;
  font-weight: bold;
  line-height: 1.5;
  letter-spacing: 0.15px;
  color: rgba(33, 33, 52, 0.87);
  margin-bottom: 12px;
`;
