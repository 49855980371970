import { styled, Typography } from '@mui/material';

export const Container = styled('div')({
  width: 'inherit',
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
  maxWidth: '450px',
});

export const RuleContainer = styled(Typography)({
  display: 'flex',
  alignItems: 'center',
  gap: '4px',
  fontSize: 13,
  flex: '1 1 48%',
});

export const CheckPasswordRule = styled('span')<{ variant: string }>(({ theme, variant }) => ({
  color: variant,
  opacity: variant ? 1 : 0.36,
  fontSize: 12,
  display: 'flex',
  alignItems: 'center',
  gap: '4px',

  [theme.breakpoints.down('sm')]: {
    fontSize: 8,
  },
}));

export const AdditionalRules = styled('div')({
  display: 'flex',
  flexWrap: 'wrap',
  gap: '10px',
});
