import { Box, styled } from '@mui/material';

export const HeaderWrapper = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  backgroundColor: 'white',
  padding: '12px 20px',
  borderBottom: '1px solid rgba(0,0,0,.12)',
  minHeight: '55px',
});
