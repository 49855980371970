import SearchIcon from '@mui/icons-material/Search';
import { InputAdornment, TextFieldProps } from '@mui/material';

import * as Styled from './styles';

const SearchField: React.FC<TextFieldProps> = ({ label = '', ...elementProps }) => (
  <Styled.TextField
    data-testid="searchField"
    variant="outlined"
    label={label}
    InputProps={{
      startAdornment: (
        <InputAdornment position="start">
          <SearchIcon />
        </InputAdornment>
      ),
    }}
    {...elementProps}
  />
);

export default SearchField;
