import {
  styled,
  ListItem as MuiListItem,
  ListItemButton as MuiListItemButton,
  ListItemText as MuiListItemText,
  ListItemIcon,
} from '@mui/material';

export const ListItemText = styled(MuiListItemText)<{ open: boolean }>(({ open }) => ({
  color: '#6C6B77',
  opacity: open ? 1 : 0,
}));

export const ListItemButton = styled(MuiListItemButton)({
  '&:active': {
    color: '#6C6B77',
  },
  '&.Mui-selected': {
    '.MuiSvgIcon-root': {
      color: '#345EB6',
      fontWeight: 'bold',
    },
    '.MuiTypography-root': {
      color: '#345EB6',
    },
  },
  minHeight: 48,
  padding: '0 0 0 20px',
  '.MuiTypography-root': {
    fontWeight: '600',
    fontSize: '14px',
  },
});

export const ListIcon = styled(ListItemIcon)({
  color: '#6C6B77',
  minWidth: 0,
  justifyContent: 'center',
  paddingRight: 10,
});

export const ListItem = styled(MuiListItem)({
  padding: '0',
});
