const colors = {
  text: {
    light: {
      primary: 'rgb(33,33,52, 0.87)',
      secondary: 'rgb(33,33,52, 0.6)',
      disabled: 'rgb(33,33,52, 0.38)',
    },
    dark: {
      primary: '#fffff',
      secondary: 'rgb(255,255,255, 0.7)',
      disabled: 'rgb(255,255,255, 0.5)',
    },
  },
  primary: {
    light: {
      main: '#3154AA',
      dark: '#153A97',
      light: '#3E6AD8',
      contrastText: '#ffffff',
    },
    dark: {
      main: '#90CAF9',
      dark: '#42A5F5',
      light: '#E3F2FD',
      contrastText: 'rgb(0,0,0, 0.87)',
    },
  },
  secondary: {
    light: {
      main: '#37345B',
      dark: '#1C1A36',
      light: '#6D6A96',
      contrastText: '#ffffff',
    },
    dark: {
      main: '#CE93D8',
      dark: '#AB47BC',
      light: '#F3E5F5',
      contrastText: 'rgb(0,0,0, 0.87)',
    },
  },
  error: {
    light: {
      main: '#DC3838',
      dark: '#A2191F',
      light: '#EE5E52',
      contrastText: '#ffffff',
    },
    dark: {
      main: '#F44336',
      dark: '#D32F2F',
      light: '#E57373',
      contrastText: '#ffffff',
    },
  },
  warning: {
    light: {
      main: '#E97B11',
      dark: '#C36002',
      light: '#FF8F39',
      contrastText: '#ffffff',
    },
    dark: {
      main: '#FFA726',
      dark: '#F57C00',
      light: '#FFB74D',
      contrastText: 'rgb(0,0,0, 0.87)',
    },
  },
  info: {
    light: {
      main: '#0288D1',
      dark: '#01579B',
      light: '#03A9F4',
      contrastText: '#ffffff',
    },
    dark: {
      main: '#29B6F6',
      dark: '#0288D1',
      light: '#4FC3F7',
      contrastText: 'rgb(0,0,0, 0.87)',
    },
  },
  success: {
    light: {
      main: '#328048',
      dark: '#235C3A',
      light: '#5CB176',
      contrastText: '#ffffff',
    },
    dark: {
      main: '#66BB6A',
      dark: '#388E3C',
      light: '#81C784',
      contrastText: 'rgb(0,0,0, 0.87)',
    },
  },
  background: {
    light: {
      default: '#F0F0F3',
      paper: '#ffffff',
    },
    dark: {
      default: '#121212',
    },
  },
};

export default colors;
