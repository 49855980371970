import { MenuItem, TextField } from '@mui/material';

import { FilterDropdownProps } from './types';

const FilterDropdown: React.FC<FilterDropdownProps> = ({ values, ...elementProps }) => (
  <TextField select {...elementProps}>
    {values.map((value) => (
      <MenuItem value={value} key={value}>
        {value}
      </MenuItem>
    ))}
  </TextField>
);

export default FilterDropdown;
