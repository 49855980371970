/* eslint-disable no-nested-ternary */
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import {
  Button,
  Collapse,
  TableHead as MuiTableHead,
  Skeleton,
  TableCell,
  TableRow,
  TableRowProps,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';

import { convertToTitleCase, numberWithCommas } from '~/core/helpers';
import { AdGroupSummarySchema, CampaignSchema } from '~/core/types/graphql.types';
import { useContexts } from '~/store/context/useContext';
import { GetCampaignsQueryVariables } from '~/core/services/graphql/campaigns/campaigns.queries.generated';
import { useGetCampaigns } from '~/core/services/graphql/campaigns/hooks';
import { GetKeywordsQueryVariables } from '~/core/services/graphql/keywords/keywords.queries.generated';
import { useGetKeywords } from '~/core/services/graphql/keywords/hooks';

import * as Styled from '../styles';
import AdGroupDialog from './AdGroupDialog';

interface CollapsibleRowProps extends TableRowProps {
  row: AdGroupSummarySchema;
  loading: boolean;
  index: number;
}

// CollapsibleRow Component Definition
const CollapsibleRow: React.FC<CollapsibleRowProps> = ({ row, ...props }) => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const {
    selectedPlatformAccountId,
    selectedCampaignId,
    setSelectedAdGroupId,
    selectedSourceAccountId,
    selectedAccountName,
    selectedDateRange,
  } = useContexts();
  const [open, setOpen] = useState(false);
  const [adGroupDialogOpen, setAdGroupDialogOpen] = useState(false);
  const [activeTab, setActiveTab] = useState(null);
  const adGroupName = row?.adGroupName;

  const handleDialogOpen = (tab: string) => {
    setActiveTab(tab);
    setAdGroupDialogOpen(true);
  };

  let url = `segments?${searchParams.toString()}&adGroupId=${row?.adGroupId}`;

  if (selectedCampaignId) {
    url += `&campaignId=${selectedCampaignId}`;
  }

  if (selectedDateRange) {
    url += `&dateRange=${selectedDateRange}`;
  }

  if (selectedSourceAccountId) {
    url += `&sourceAccountId=${selectedSourceAccountId}`;
  }

  url += `&adGroup=${row?.adGroupId}`;
  url += `&accountName=${selectedAccountName}`;
  url += `&adGroupName=${row?.adGroupName}`;

  const useGetCampaignsSkip = !selectedPlatformAccountId || !selectedSourceAccountId || !selectedDateRange;
  const useGetCampaignsVariables: GetCampaignsQueryVariables = {
    platformAccountId: selectedPlatformAccountId,
    customerIds: [selectedSourceAccountId],
    dateRange: selectedDateRange,
  };
  const { campaignsLoading, getCampaigns } = useGetCampaigns(useGetCampaignsSkip, useGetCampaignsVariables);
  const createCampaignHashTable = () =>
    getCampaigns?.reduce((acc, campaign) => {
      acc[campaign.id] = campaign;
      return acc;
    }, {} as Record<string, CampaignSchema>);
  const campaignHashTable = createCampaignHashTable();
  const fetchCampaignName = (campaignId: string) => campaignHashTable?.[campaignId]?.name;

  const getKeywordsSkip =
    !selectedPlatformAccountId || !selectedSourceAccountId || !row?.adGroupId || !selectedDateRange || !open;
  const getKeywordsVariables: GetKeywordsQueryVariables = {
    platformAccountId: selectedPlatformAccountId,
    customerIds: [selectedSourceAccountId],
    adGroupIds: [row?.adGroupId],
    dateRange: selectedDateRange,
  };

  const { keywordsLoading, getKeywords } = useGetKeywords(getKeywordsSkip, getKeywordsVariables);
  const totalKeywords = getKeywords?.edges?.length;
  const totalKeywordsWithTraffic = getKeywords?.edges?.filter((keyword) => keyword?.impressions > 0).length;

  const loadingRow = campaignsLoading || keywordsLoading;
  const campaignName = campaignHashTable?.[row?.campaignId]?.name;

  url += `&campaignName=${campaignName}`;

  const handleClick = (event: any, row: AdGroupSummarySchema) => {
    event.stopPropagation();
    setSelectedAdGroupId(row?.adGroupId);
    searchParams.set('campaignName', campaignName);
    setSearchParams(searchParams);

    navigate(url);
  };

  return (
    <>
      <TableRow
        sx={{
          background: '#ffffff',
        }}
        data-testid="parentRow"
        key={row?.adGroupId}
        onClick={() => setOpen(!open)}
        {...props}
      >
        <TableCell
          size="small"
          data-testid="name"
          sx={{ display: 'flex', alignItems: 'center', minWidth: '240px', borderBottom: 'none' }}
        >
          <Styled.IconContainer aria-label="expand row" size="small" disableRipple>
            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </Styled.IconContainer>
          {convertToTitleCase(row?.adGroupName)}
        </TableCell>
        {/* <Styled.MainTableCell size="small">{fetchCampaignName(row?.campaignId)}</Styled.MainTableCell> */}
        <Styled.MainTableCell size="small">{row?.totalKeywords?.toLocaleString()}</Styled.MainTableCell>
        <Styled.MainTableCell size="small">
          {numberWithCommas(Number(row?.keywordsWithTraffic?.toFixed(0)))}
        </Styled.MainTableCell>

        <>
          <Styled.MainTableCell size="small">{numberWithCommas(row?.totalEligibleImpressions)}</Styled.MainTableCell>
          <Styled.MainTableCell size="small">{numberWithCommas(row?.impressions)}</Styled.MainTableCell>
          <Styled.MainTableCell size="small">
            <Button
              color="primary"
              size="small"
              variant="contained"
              onClick={(e) => handleClick(e, row)}
              sx={{
                height: '30px',
                width: '95px',
                padding: '0px',
                fontSize: '10px',
                fontWeight: 600,
                lineHeight: 2.2,
                letterSpacing: '0.46px',
                textTransform: 'none',
              }}
              disabled={row?.keywordsWithTraffic === 0}
            >
              View Analysis
            </Button>
          </Styled.MainTableCell>
        </>
      </TableRow>
      <TableRow
        sx={{
          background: '#ffffff',
        }}
        data-testid="childRow"
      >
        <Styled.MainCell colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Styled.CollapsibleBox>
              <Styled.Table aria-label="ad-groups">
                <Styled.InnerTable>
                  <MuiTableHead data-testid="tableHead">
                    <Styled.HeadCell>Campaign</Styled.HeadCell>
                    <Styled.HeadCell>Total Keywords</Styled.HeadCell>
                    <Styled.HeadCell>Keywords with Traffic</Styled.HeadCell>
                  </MuiTableHead>

                  <TableRow>
                    <Styled.DetailCell>
                      {fetchCampaignName(row?.campaignId)} ({row?.adGroupId}){' '}
                    </Styled.DetailCell>
                    <Styled.DetailCell>
                      {loadingRow ? (
                        <Skeleton variant="text" animation="wave" />
                      ) : (
                        <>
                          {totalKeywords}{' '}
                          <Typography
                            onClick={() => handleDialogOpen('keywords')}
                            sx={{
                              display: 'inline',
                              textDecoration: 'underline',
                              color: '#3154aa',
                              fontSize: '14px',
                              fontWeight: 'bold',
                              lineHeight: 1.43,
                              letterSpacing: '0.17px',
                              '&:hover': {
                                cursor: 'pointer',
                              },
                            }}
                          >
                            View
                          </Typography>
                        </>
                      )}
                    </Styled.DetailCell>
                    <Styled.DetailCell>
                      {loadingRow ? (
                        <Skeleton variant="text" animation="wave" />
                      ) : (
                        <>
                          {totalKeywordsWithTraffic}{' '}
                          <Typography
                            onClick={() => handleDialogOpen('assets')}
                            sx={{
                              display: 'inline',
                              textDecoration: 'underline',
                              color: '#3154aa',
                              fontSize: '14px',
                              fontWeight: 'bold',
                              lineHeight: 1.43,
                              letterSpacing: '0.17px',
                              '&:hover': {
                                cursor: 'pointer',
                              },
                            }}
                          >
                            View
                          </Typography>
                        </>
                      )}
                    </Styled.DetailCell>
                  </TableRow>
                </Styled.InnerTable>
              </Styled.Table>
            </Styled.CollapsibleBox>
          </Collapse>
        </Styled.MainCell>
      </TableRow>

      {open && (
        <AdGroupDialog
          open={adGroupDialogOpen}
          setDialogOpen={setAdGroupDialogOpen}
          adGroupId={row?.adGroupId}
          adGroupName={adGroupName}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
      )}
    </>
  );
};

export default CollapsibleRow;
