import { CircularProgress as MuiCircularProgress } from '@mui/material';

import * as Styled from './styles';
import { CircularProgressProps } from './types';

const CircularProgress: React.FC<CircularProgressProps> = ({ height = '10px', id, ...elementProps }) => (
  <Styled.Container id="circularProgress" height={height} data-testid="circularProgress">
    <MuiCircularProgress className={id} {...elementProps} />
  </Styled.Container>
);

export default CircularProgress;
