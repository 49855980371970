import { createSelector } from 'reselect';

import { AppState } from '../reducers';

const paidSearchAuthStoreSelector = (state: AppState) => state.integrationAccounts;

export const integrationAccountsSelector = createSelector(
  paidSearchAuthStoreSelector,
  (state) => state.integrationAccounts,
);
export const integrationAccountsLoadingSelector = createSelector(
  paidSearchAuthStoreSelector,
  (state) => state.isLoading,
);
export const integrationAccountsErrorSelector = createSelector(paidSearchAuthStoreSelector, (state) => state.error);

export const signInUrlSelector = createSelector(paidSearchAuthStoreSelector, (state) => state.googleAdsSignInUrl);
export const signInUrlLoadingSelector = createSelector(paidSearchAuthStoreSelector, (state) => state.fetchingUrl);
export const signInUrlErrorSelector = createSelector(paidSearchAuthStoreSelector, (state) => state.signInUrlError);

export const refreshLoadingSelector = createSelector(paidSearchAuthStoreSelector, (state) => state.refreshLoading);
export const integrationAccountsPaginationSelector = createSelector(
  paidSearchAuthStoreSelector,
  (state) => state.paginationInfo,
);
export const hasAccountDataSelector = createSelector(paidSearchAuthStoreSelector, (state) => state.hasAccountData);
