import { ApolloError } from '@apollo/client';
import { createContext, useMemo, useState } from 'react';

import {
  AccountDataSchema,
  AssetSchema,
  CampaignSchema,
  IntentsSchemaPaginationSchema,
} from '~/core/types/graphql.types';

interface SummariesContextProps {
  accountSummaries: any[];
  accountSummariesLoading: boolean;
  accountSummariesError: ApolloError | null;
  setAccountSummaries: React.Dispatch<React.SetStateAction<AccountDataSchema[] | null>>;
  setAccountSummariesLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setAccountSummariesError: React.Dispatch<React.SetStateAction<ApolloError | null>>;

  campaignSummaries: CampaignSchema[];
  campaignSummariesLoading: boolean;
  campaignSummariesError: ApolloError | null;
  setCampaignSummaries: React.Dispatch<React.SetStateAction<CampaignSchema[] | null>>;
  setCampaignSummariesLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setCampaignSummariesError: React.Dispatch<React.SetStateAction<ApolloError | null>>;

  intents: { getIntents: IntentsSchemaPaginationSchema } | null;
  intentsLoading: boolean;
  intentsError: ApolloError | null;
  setIntents: React.Dispatch<React.SetStateAction<IntentsSchemaPaginationSchema | null>>;
  setIntentsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setIntentsError: React.Dispatch<React.SetStateAction<ApolloError | null>>;

  assetSummaries: any;
  assetSummariesLoading: boolean;
  assetSummariesError: ApolloError | null;
  setAssetSummaries: React.Dispatch<React.SetStateAction<AssetSchema[]>>;
  setAssetSummariesLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setAssetSummariesError: React.Dispatch<React.SetStateAction<ApolloError | null>>;
}

export const SummariesContext = createContext<SummariesContextProps | undefined>(undefined);

export const SummariesProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  // Account Summaries State
  const [accountSummaries, setAccountSummaries] = useState<AccountDataSchema[]>([]);
  const [accountSummariesLoading, setAccountSummariesLoading] = useState<boolean>(false);
  const [accountSummariesError, setAccountSummariesError] = useState<ApolloError>(null);

  // Campaign Summaries State
  const [campaignSummaries, setCampaignSummaries] = useState<CampaignSchema[]>([]);
  const [campaignSummariesLoading, setCampaignSummariesLoading] = useState<boolean>(false);
  const [campaignSummariesError, setCampaignSummariesError] = useState<ApolloError>(null);

  // Cluster Summaries State
  const [intents, setIntents] = useState<any>(null);
  const [intentsLoading, setIntentsLoading] = useState<boolean>(false);
  const [intentsError, setIntentsError] = useState<ApolloError>(null);

  const [assetSummaries, setAssetSummaries] = useState<any>(null);
  const [assetSummariesLoading, setAssetSummariesLoading] = useState<boolean>(false);
  const [assetSummariesError, setAssetSummariesError] = useState<ApolloError>(null);

  const state: SummariesContextProps = useMemo(
    () => ({
      accountSummaries,
      accountSummariesLoading,
      accountSummariesError,
      setAccountSummaries,
      setAccountSummariesLoading,
      setAccountSummariesError,

      campaignSummaries,
      campaignSummariesLoading,
      campaignSummariesError,
      setCampaignSummaries,
      setCampaignSummariesError,
      setCampaignSummariesLoading,

      intents,
      intentsLoading,
      intentsError,
      setIntents,
      setIntentsLoading,
      setIntentsError,

      assetSummaries,
      assetSummariesLoading,
      assetSummariesError,
      setAssetSummaries,
      setAssetSummariesLoading,
      setAssetSummariesError,
    }),
    [
      accountSummaries,
      accountSummariesLoading,
      accountSummariesError,
      setAccountSummaries,
      setAccountSummariesLoading,
      setAccountSummariesError,

      campaignSummaries,
      campaignSummariesLoading,
      campaignSummariesError,
      setCampaignSummaries,
      setCampaignSummariesError,
      setCampaignSummariesLoading,

      intents,
      intentsLoading,
      intentsError,
      setIntents,
      setIntentsLoading,
      setIntentsError,

      assetSummaries,
      assetSummariesLoading,
      assetSummariesError,
      setAssetSummaries,
      setAssetSummariesLoading,
      setAssetSummariesError,
    ],
  );
  return <SummariesContext.Provider value={state}>{children}</SummariesContext.Provider>;
};
