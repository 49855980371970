import { takeLatest, Effect, call, put } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';

import { errorNotificationMessages, successNotificationMessage } from '~/core/constants/notificatonMessages.constants';
import { apolloServiceRequest, graphqlClient } from '~/core/services';
import { graphqlServices } from '~/core/services/graphql';
import { OwnIntegrationAccountsQuery } from '~/core/services/graphql/integrationAccounts/integrationAccounts.queries.generated';
import {
  getAdwordsAccountsRequest,
  postGoogleAuthSignIn,
} from '~/core/services/rest/integrationAccounts/integrationAccounts.services';
import { GoogleAdsUrl } from '~/core/types/googleAdsAccounts.types';

import {
  postGoogleAuthSignInAction,
  refreshIntegrationAccountsAction,
  linkNewAccountAction,
  getIntegrationAccountsAction,
} from '../actions/integrationAccounts.action';
import { notificationAction } from '../actions/notifications.action';

export class IntegrationAccountsSagaWorker {
  static *postGoogleAuthSignIn({ payload }: ActionType<typeof postGoogleAuthSignInAction.request>) {
    try {
      const data: GoogleAdsUrl = yield call(postGoogleAuthSignIn, payload.accountId, payload.redirectionUri);
      yield put(postGoogleAuthSignInAction.success(data));
    } catch (error: any) {
      yield put(postGoogleAuthSignInAction.failure(error.response.data.detail));
    }
  }

  static *refreshMotiveMapAccounts({ payload }: ActionType<typeof refreshIntegrationAccountsAction.request>) {
    try {
      yield call(getAdwordsAccountsRequest, payload);

      const data: OwnIntegrationAccountsQuery = yield call(
        apolloServiceRequest,
        graphqlClient,
        'query',
        graphqlServices.integrationAccounts.integrationAccountsGql,
        { platformAccountId: payload, page: 1, pageSize: 20 },
      );

      yield put(refreshIntegrationAccountsAction.success(data));
      yield put(notificationAction.success({ message: successNotificationMessage.successRefreshAccounts }));
    } catch (error: any) {
      yield put(refreshIntegrationAccountsAction.failure(error));
      yield put(
        notificationAction.failure({ message: errorNotificationMessages.errorRefreshAccounts, severity: 'error' }),
      );
    }
  }

  static *linkNewAccount({ payload }: ActionType<typeof linkNewAccountAction.request>) {
    try {
      yield call(getAdwordsAccountsRequest, payload);

      const data: OwnIntegrationAccountsQuery = yield call(
        apolloServiceRequest,
        graphqlClient,
        'query',
        graphqlServices.integrationAccounts.integrationAccountsGql,
        { platformAccountId: payload, page: 1, pageSize: 20 },
      );

      yield put(linkNewAccountAction.success(data));
      yield put(notificationAction.success({ message: successNotificationMessage.successLinkAccount }));
    } catch (error: any) {
      yield put(linkNewAccountAction.failure(error));
      yield put(
        notificationAction.failure({ message: errorNotificationMessages.errorLinkingNewAccount, severity: 'error' }),
      );
    }
  }

  static *getIntegrationAccounts({ payload }: ActionType<typeof getIntegrationAccountsAction.request>) {
    try {
      const data: OwnIntegrationAccountsQuery = yield call(
        apolloServiceRequest,
        graphqlClient,
        'query',
        graphqlServices.integrationAccounts.integrationAccountsGql,
        payload,
      );

      yield put(getIntegrationAccountsAction.success(data));
    } catch (error: any) {
      yield put(getIntegrationAccountsAction.failure(error.message));
    }
  }
}

export function* integrationAccountsSaga(): Generator<Effect, void> {
  yield takeLatest(postGoogleAuthSignInAction.request, IntegrationAccountsSagaWorker.postGoogleAuthSignIn);
  yield takeLatest(refreshIntegrationAccountsAction.request, IntegrationAccountsSagaWorker.refreshMotiveMapAccounts);
  yield takeLatest(linkNewAccountAction.request, IntegrationAccountsSagaWorker.linkNewAccount);
  yield takeLatest(getIntegrationAccountsAction.request, IntegrationAccountsSagaWorker.getIntegrationAccounts);
}
