import { useContext } from 'react';

import { StateContext } from '.';
import { AccountsContext } from './accounts.context';
import { SummariesContext } from './summaries.context';

export const useContexts = () => {
  const stateContext = useContext(StateContext);
  const accountsContext = useContext(AccountsContext);
  const summariesContext = useContext(SummariesContext);

  if (!stateContext || !accountsContext || !summariesContext) {
    throw new Error('All contexts must be used within their respective providers');
  }

  return { ...stateContext, ...accountsContext, ...summariesContext };
};
