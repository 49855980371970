import { fork } from 'redux-saga/effects';

import { accountSaga } from './account.saga';
import { accountUsersSaga } from './accountUsers.saga';
import { authSaga } from './auth.saga';
import { integrationAccountsSaga } from './integrationAccounts.saga';
import { paidSearchAccountsSaga } from './paidSearchAccounts.saga';
import { userSaga } from './user.saga';

export function* rootSaga(): Generator {
  yield fork(authSaga);
  yield fork(accountSaga);
  yield fork(integrationAccountsSaga);
  yield fork(accountUsersSaga);
  yield fork(userSaga);
  yield fork(paidSearchAccountsSaga);
}
