import { Box, Container, Typography, styled } from '@mui/material';

export const CustomContainer = styled(Container)(({ theme }) => ({
  marginTop: theme.spacing(2.5),
  borderRadius: '6px',
  backgroundColor: '#fff',
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(6),
  boxShadow: 'rgba(100, 116, 139, 0.16) 0px 1px 1px, rgba(100, 116, 139, 0.16) 0px 1px 6px',
}));

export const CustomTitle = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(2.5),
  fontWeight: 'bold',
  fontSize: '1.5rem',
}));

export const SubscriptionBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  marginBottom: theme.spacing(2),
  borderBottom: '1px solid #E0E0E0',
  paddingBottom: theme.spacing(2),
}));

export const CustomTable = styled('table')({
  textAlign: 'left',
  width: '100%',
  borderCollapse: 'collapse',
});

export const CustomTh = styled('th')(({ theme }) => ({
  padding: theme.spacing(1.25),
  width: '25%',
}));

export const CustomTr = styled('tr')(({ theme }) => ({
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
}));

export const CustomTd = styled('td')(({ theme }) => ({
  borderTop: `1px solid ${theme.palette.grey[300]}`,
  padding: theme.spacing(1.25),
}));
