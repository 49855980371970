import { createSelector } from 'reselect';

import { AppState } from '../reducers';

const notificationsSelector = (state: AppState) => state.notifications;

export const notificationMessageSelector = createSelector(notificationsSelector, (state) => ({
  message: state.notificationMessage,
  severity: state.alertColor,
}));
