import {
  Box,
  ListItemButton as MuiListItemButton,
  ListItemIcon,
  ListItemText as MuiListItemText,
  MenuItem as MuiMenuItem,
  styled,
  Typography,
} from '@mui/material';
import { Link } from 'react-router-dom';

export const AnchorTag = styled('a')({
  textDecoration: 'none',
});

export const Container = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  height: '100%',
});

export const LinkContainer = styled(Link)({
  textDecoration: 'none',
});

export const ListIcon = styled(ListItemIcon)({
  width: '16.7px',
  height: '15.8px',
  color: 'rgba(255, 255, 255, 0.56)',
  padding: '0 14px 0 18px',
  margin: '0',
  display: 'flex',
  alignItems: 'center',
});

export const ListItemButton = styled(MuiListItemButton)({
  height: '37px',
  minWidth: '240px',
  padding: '6px 0',
  margin: '0 0 4px 0',
  '.MuiSvgIcon-root': {
    color: 'rgb(255,255,255, 0.56)',
  },
  '.MuiTypography-root': {
    color: 'rgb(255,255,255, 0.7)',
  },
  '&:hover': {
    backgroundColor: 'rgba(255,255,255,0.08)',
    '.MuiSvgIcon-root': {
      color: 'rgb(255,255,255, 0.56)',
    },
    '.MuiTypography-root': {
      color: 'rgb(255,255,255, 0.7)',
    },
  },
  '&.Mui-selected': {
    backgroundColor: 'rgba(144,202,249,0.08)',
    '.MuiSvgIcon-root': {
      color: '#ffffff',
    },
    '.MuiTypography-root': {
      color: '#ffffff',
      lineHeight: 1.5,
      letterSpacing: '0.15px',
    },
  },
});

export const ListItemText = styled(MuiListItemText)({
  color: 'rgba(255, 255, 255, 0.7)',
  lineHeight: 1.5,
  display: 'flex',
  alignItems: 'center',
  letterSpacing: '0.15px',
  '.MuiTypography-root': {
    fontSize: '14px',
  },
  '.Mui-selected': {
    color: 'red',
  },
});

export const MenuContainer = styled(Box)({
  flexGrow: 0,
  backgroundColor: '#ffffff',
  borderRadius: '8px',
});

export const MenuItem = styled(MuiMenuItem)({
  display: 'flex',
  gap: '12px',
  alignItems: 'center',
});

export const MenuLink = styled(Link)({
  textDecoration: 'none',
  color: 'inherit',
});

export const MenuText = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontSize: '16px',
  lineHeight: 1.5,
  letterSpacing: '0.15px',
}));
