import { useForm, Controller } from 'react-hook-form';
import { useSelector } from 'react-redux';

import { Button, Input } from '~/core/components/shared';
import { ConfirmEmailForm } from '~/core/types/auth.types';
import { authErrorSelector, authLoadingSelector } from '~/store/selectors/auth.selector';

import * as Styled from './styles';
import { SignUpConfirmFormProps } from './types';

const SignInForm: React.FC<SignUpConfirmFormProps> = ({ onSubmit }) => {
  const confirmationPasswordError = useSelector(authErrorSelector);
  const isLoading = useSelector(authLoadingSelector);
  const { handleSubmit, control, watch } = useForm<ConfirmEmailForm>({
    defaultValues: {
      confirmationCode: '',
    },
  });

  const confirmationCode = watch('confirmationCode');

  return (
    <form data-testid="submitForm" onSubmit={handleSubmit(onSubmit)}>
      <Styled.SignUpFormContent disableGutters>
        <Controller
          name="confirmationCode"
          rules={{
            required: true,
          }}
          control={control}
          render={({ field: { onChange, value } }) => (
            <Input
              data-testid="confirmCodeInput"
              label="Confirmation code"
              value={value}
              onChange={onChange}
              helperText={confirmationPasswordError}
              error={!!confirmationPasswordError}
              autoComplete="off"
            />
          )}
        />
        <Button
          data-testid="submitButton"
          type="submit"
          label="Confirm Email"
          fullWidth
          disabled={isLoading || confirmationCode.length !== 6}
          variant="contained"
          size="large"
        />
      </Styled.SignUpFormContent>
    </form>
  );
};

export default SignInForm;
