// Function to calculate the area of a circle
export function circleArea(radius) {
  const pi = Math.PI;
  return pi * radius ** 2;
}

// Function to adjust intent coverage to avoid division by zero issues
export function adjustIntentCoverage(intentCoverage) {
  if (intentCoverage === 1) {
    // Smallest positive number representable by a float64
    const smallNumber = 1e-11;
    intentCoverage -= smallNumber;
  }
  return intentCoverage;
}

// Function to calculate the radius of the second circle using numerical method
export function calculateRadiusC2(intentCoverage, areaC1, activeAssets) {
  const pi = Math.PI;
  let low = 0;
  let high = 10000; // A large number to ensure the solution is within the range
  let r2 = 0;
  const areaOverlapC1 = intentCoverage * areaC1;

  while (high - low > 1e-10) {
    // Precision threshold
    r2 = (low + high) / 2;
    const areaOverlapC2 = activeAssets * pi * r2 ** 2;
    if (areaOverlapC2 > areaOverlapC1) {
      high = r2;
    } else {
      low = r2;
    }
  }
  return r2;
}

function calculateOverlapArea(r1, r2, distance) {
  // Return zero if circles are separate or one is completely inside the other
  if (distance >= r1 + r2 || distance <= Math.abs(r1 - r2)) {
    return 0;
  }

  const angle1 = Math.acos((r1 * r1 + distance * distance - r2 * r2) / (2 * r1 * distance));
  const angle2 = Math.acos((r2 * r2 + distance * distance - r1 * r1) / (2 * r2 * distance));
  const segmentArea1 = r1 * r1 * (angle1 - Math.sin(angle1) * Math.cos(angle1));
  const segmentArea2 = r2 * r2 * (angle2 - Math.sin(angle2) * Math.cos(angle2));

  // Sum of segment areas is the total overlap area
  return segmentArea1 + segmentArea2;
}

function calculateDistance(r1, r2, desiredOverlapArea) {
  const precision = 0.01; // Desired precision for the solution
  let minDistance = Math.abs(r1 - r2); // Minimum possible distance between circle centers
  let maxDistance = r1 + r2; // Maximum possible distance between circle centers
  let guessDistance = (minDistance + maxDistance) / 2; // Initial guess for the distance
  let closestDistance = guessDistance;
  let smallestAreaDifference = Infinity;

  while (maxDistance - minDistance > precision) {
    const currentOverlapArea = calculateOverlapArea(r1, r2, guessDistance);
    const areaDifference = Math.abs(currentOverlapArea - desiredOverlapArea);

    // Update closestDistance if the current iteration is closer to the desired area
    if (areaDifference < smallestAreaDifference) {
      closestDistance = guessDistance;
      smallestAreaDifference = areaDifference;
    }

    // Binary search adjustment
    if (currentOverlapArea > desiredOverlapArea) {
      maxDistance = guessDistance;
    } else {
      minDistance = guessDistance;
    }

    // Update the guess for the next iteration
    guessDistance -= 0.0001;
  }

  return closestDistance;
}

export function main(intentCoverage, activeAssets) {
  // Initial Variables
  const r1 = 100;
  const areaC1 = circleArea(r1);
  intentCoverage = adjustIntentCoverage(intentCoverage);
  const r2 = calculateRadiusC2(intentCoverage, areaC1, activeAssets);
  const distance = calculateDistance(r1, r2, intentCoverage * areaC1);

  return { r2, distance };
}
