import { Box, MenuItem, Select as MuiSelect, Skeleton, styled } from '@mui/material';

import { colors } from '../../../../../theme';
import { Chip } from '../../Chip';

export const Container = styled(Box)``;

export const Select = styled(MuiSelect)({
  color: colors.text.light.primary,
  paddingRight: '5px',
  fontWeight: 'bold',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  maxWidth: '380px',
  '& .MuiSelect-select': {
    padding: '0px',
    fontSize: '14px',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
    padding: '0px !important',
  },
});

export const TotalIntents = styled('span')({
  marginLeft: '8px',
  fontSize: '14px',
  color: '#A6A6A6',
});

export const LoadingContainer = styled(Box)({
  borderRadius: '4px',
  borderRight: '1px solid rgba(33, 33, 52, 0.12)',
  height: '55px',
  display: 'flex',
  alignItems: 'center',
});

export const CustomSkeleton = styled(Skeleton)({
  width: '400px',
  height: '40px',
  margin: '0 15px',
});

export const TextContainer = styled(Box)({
  marginLeft: '10px',
  fontSize: '14px',
  lineHeight: '1.43',
  letterSpacing: '0.17px',
  marginRight: '8px',
});

export const CustomMenuItem = styled(MenuItem)({
  fontSize: '14px',
});

export const CustomChip = styled(Chip)({
  height: '20px',
  color: '#fff',
  background: '#3154aa',
  marginLeft: '15.5px',
  '-webkit-text-fill-color': '#fff',
});
