import { AlertColor } from '@mui/material';
import { createReducer } from 'typesafe-actions';

import { notificationAction, NotificationActionUnion, notificationClearAction } from '../actions/notifications.action';

export interface State {
  notificationMessage: string;
  alertColor: AlertColor;
}

export const initialState: State = {
  notificationMessage: null,
  alertColor: null,
};

export const reducer = createReducer<State, NotificationActionUnion>(initialState)
  .handleAction(notificationAction.success, (state, { payload }) => ({
    ...state,
    notificationMessage: payload.message,
    alertColor: payload.severity,
  }))
  .handleAction(notificationAction.failure, (state, { payload }) => ({
    ...state,
    notificationMessage: payload.message,
    alertColor: payload.severity,
  }))
  .handleAction(notificationClearAction, () => ({
    ...initialState,
  }));
