import CheckCircleFilled from '@mui/icons-material/CheckCircle';
import HighlightOffOutlined from '@mui/icons-material/HighlightOffOutlined';
import { Typography, useTheme } from '@mui/material';

import * as Styled from './styles';
import { PasswordCheckerProps } from './types';

const PasswordChecker: React.FC<PasswordCheckerProps> = ({ passwordStrength, error }) => {
  const { contains, length } = passwordStrength;
  const theme = useTheme();

  const lowerCase = contains.includes('lowercase');
  const minimumLength = length >= 8;
  const containsNumber = contains.includes('number');
  const containsUpperCase = contains.includes('uppercase');
  const containsSpecialCharacter = contains.includes('symbol');

  const checkRuleVariant = (value: boolean) => {
    if (value) {
      return theme.palette.success.main;
    }

    if (error) {
      return theme.palette.error.main;
    }

    return '';
  };

  return (
    <Styled.Container data-testid="passwordChecker">
      <Typography fontSize={12}>Password must:</Typography>
      <Styled.RuleContainer>
        <Styled.CheckPasswordRule data-testid="checkRuleVariant" variant={checkRuleVariant(minimumLength)}>
          {minimumLength ? (
            <CheckCircleFilled data-testid="minimumLengthTrue" fontSize="small" color="success" />
          ) : (
            <HighlightOffOutlined
              data-testid="minimumLengthFalse"
              fontSize="small"
              color={error ? 'error' : 'disabled'}
            />
          )}
          Be minimum 8 characters in length
        </Styled.CheckPasswordRule>
      </Styled.RuleContainer>
      <Typography fontSize={12}>Have at least 4 of the following:</Typography>
      <Styled.AdditionalRules>
        <Styled.RuleContainer>
          <Styled.CheckPasswordRule variant={checkRuleVariant(lowerCase)}>
            {lowerCase ? (
              <CheckCircleFilled data-testid="containsLowercaseTrue" fontSize="small" color="success" />
            ) : (
              <HighlightOffOutlined
                data-testid="containsLowercaseFalse"
                fontSize="small"
                color={error ? 'error' : 'disabled'}
              />
            )}
            1 lowercase (a - z)
          </Styled.CheckPasswordRule>
        </Styled.RuleContainer>
        <Styled.RuleContainer>
          <Styled.CheckPasswordRule variant={checkRuleVariant(containsNumber)}>
            {containsNumber ? (
              <CheckCircleFilled data-testid="containsNumberTrue" fontSize="small" color="success" />
            ) : (
              <HighlightOffOutlined
                data-testid="containsNumberFalse"
                fontSize="small"
                color={error ? 'error' : 'disabled'}
              />
            )}
            1 number (0 - 9)
          </Styled.CheckPasswordRule>
        </Styled.RuleContainer>
        <Styled.RuleContainer>
          <Styled.CheckPasswordRule variant={checkRuleVariant(containsUpperCase)}>
            {containsUpperCase ? (
              <CheckCircleFilled data-testid="containsUpperCaseTrue" fontSize="small" color="success" />
            ) : (
              <HighlightOffOutlined
                data-testid="containsUpperCaseFalse"
                fontSize="small"
                color={error ? 'error' : 'disabled'}
              />
            )}
            1 uppercase letter (A - Z)
          </Styled.CheckPasswordRule>
        </Styled.RuleContainer>
        <Styled.RuleContainer>
          <Styled.CheckPasswordRule variant={checkRuleVariant(containsSpecialCharacter)}>
            {containsSpecialCharacter ? (
              <CheckCircleFilled data-testid="containsSpecialCharacterTrue" fontSize="small" color="success" />
            ) : (
              <HighlightOffOutlined
                data-testid="containsSpecialCharacterFalse"
                fontSize="small"
                color={error ? 'error' : 'disabled'}
              />
            )}
            1 special character (!?%@, etc)
          </Styled.CheckPasswordRule>
        </Styled.RuleContainer>
      </Styled.AdditionalRules>
    </Styled.Container>
  );
};
export default PasswordChecker;
