import { Search, ZoomInMapOutlined, ZoomInOutlined, ZoomOutOutlined } from '@mui/icons-material';
import { Autocomplete, Box, Chip, Slider, Stack, TextField, Typography } from '@mui/material';
import Papa from 'papaparse';
import { useEffect, useRef, useState } from 'react';
// import { ForceGraph3D } from 'react-force-graph';
import { useDispatch, useSelector } from 'react-redux';
// import SpriteText from 'three-spritetext';

import GoogleLogo from '~/assets/google/icon-google-g-logo.svg';
import { CircularProgress, SourceAccountSelector } from '~/core/components/shared';
import { IntegrationAccountsSchema } from '~/core/types/graphql.types';
import { getIntegrationAccountsAction } from '~/store/actions/integrationAccounts.action';
import { useContexts } from '~/store/context/useContext';
import {
  integrationAccountsLoadingSelector,
  integrationAccountsSelector,
} from '~/store/selectors/integrationAccounts.selector';

import mockData from './mockData';
// import { createLinksArrayDemo } from './randomData';
import * as Styled from './styles';

type CSVUploadProps = {
  setJsonData: React.Dispatch<React.SetStateAction<any[]>>;
};
const CSVUpload: React.FC<CSVUploadProps> = ({ setJsonData }) => {
  const handleFileUpload = (event: any) => {
    const file = event.target.files[0];
    const jsonData: any[] = [];
    let count = 0;
    let headerColumns: any[] = [];

    Papa.parse(file, {
      skipEmptyLines: true,
      step(result: any) {
        // complete if index passes 1000
        // if (jsonData?.length > 10000) {
        //   parser.abort(); // Abort the parsing
        // }
        // skip the first two rows
        result.data[0].split(',');
        // Parse the data results, which come after the header result
        const resultData: any = {};
        if (count === 2) {
          headerColumns = result.data;
        }

        if (headerColumns?.length > 0 && count > 3) {
          headerColumns.forEach((header: any, index: number) => {
            const rowData = result.data[index];
            resultData[header] = rowData;
          });
          jsonData.push(resultData);
        }
        count += 1;
      },
      complete: () => {
        setJsonData(jsonData);
      },
    });
  };

  return (
    <div>
      <input type="file" accept=".csv" onChange={handleFileUpload} />
    </div>
  );
};

type MultiWordInputProps = {
  words: string[];
  setWords: React.Dispatch<React.SetStateAction<string[]>>;
};

const MultiWordInput: React.FC<MultiWordInputProps> = ({ words, setWords }) => {
  const [inputValue, setInputValue] = useState('');

  const handleKeyDown = (event: any) => {
    if (event.key === 'Enter' && inputValue.trim()) {
      setWords((prevWords) => [...prevWords, inputValue.trim()]);
      setInputValue(''); // Reset the input field after adding the word
    }
  };

  const handleChange = (event: any) => {
    setInputValue(event.target.value);
  };

  const handleDelete = (wordToDelete: any) => () => {
    setWords((prevWords) => prevWords.filter((word) => word !== wordToDelete));
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <TextField
        size="small"
        sx={{
          fontSize: '12px',
          marginLeft: '20px',
          marginTop: '12px',
          width: '300px',
        }}
        label="Filter by intent"
        value={inputValue}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        variant="outlined"
        placeholder="Type a word and hit Enter"
        fullWidth
      />
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Typography
          sx={{
            color: 'rgba(74, 74, 106, 0.5)',
            marginLeft: '20px',
            fontSize: '12px',
            marginTop: '12px',
            marginRight: words.length < 1 ? '120px' : '0px',
            minWidth: '100px',
          }}
        >
          Selected words:
        </Typography>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            alignSelf: 'center',
            justifyContent: 'space-between',
            gap: '12px',
            flexWrap: 'wrap',
            width: '100%',
          }}
        >
          <Stack direction="row" spacing={1} mt={2}>
            {words.map((word) => (
              <Chip size="small" key={word} label={word} onDelete={handleDelete(word)} />
            ))}
          </Stack>
        </Box>
      </Box>
    </Box>
  );
};

type ControlBarProps = {
  handleZoomIn: () => void;
  handleZoomOut: () => void;
  handleResetZoom: () => void;
  setThreshold: (value: any) => void;
  setMaximumNodes: (value: any) => void;
  threshold: number;
  maximimumNodes: number;
  filteredWords: string[];
  setFilteredWords: (value: any) => void;
};

const ControlBar: React.FC<ControlBarProps> = ({
  handleZoomIn,
  handleZoomOut,
  handleResetZoom,
  setThreshold,
  setMaximumNodes,
  threshold,
  maximimumNodes,
  filteredWords,
  setFilteredWords,
}) => {
  const minThreshold = 0; // Define the minimum threshold value
  const maxThreshold = 1; // Define the maximum threshold value

  const handleThresholdSlider = (event: any, newValue: any) => {
    setThreshold(newValue); // Update the threshold state with the new value
  };

  const handleNodesSlider = (event: any, newValue: any) => {
    setMaximumNodes(newValue);
  };

  return (
    <Box
      sx={{
        height: '150px',
        width: '100%',
        padding: '10px 0',
        backgroundColor: '#F8F8F9',
        display: 'flex',
        flexDirection: 'column',
        marginBottom: '30px',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            alignSelf: 'center',
            marginTop: '5px',
            marginLeft: '10px',
          }}
        >
          <Styled.MapButton onClick={handleZoomIn}>
            <ZoomInOutlined
              sx={{
                fontSize: '14px',
              }}
            />
          </Styled.MapButton>
          <Styled.MapButton onClick={handleZoomOut}>
            <ZoomOutOutlined
              sx={{
                fontSize: '14px',
              }}
            />
          </Styled.MapButton>
          <Styled.MapButton onClick={handleResetZoom}>
            <ZoomInMapOutlined
              sx={{
                fontSize: '14px',
              }}
            />
          </Styled.MapButton>
          <Typography sx={{ color: 'rgba(74, 74, 106, 0.5)', marginLeft: '5px', fontSize: '12px' }}>
            Click or use mouse scroll to zoom in/out
          </Typography>
        </Box>

        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            marginTop: '5px',
            paddingRight: '10px',
            width: '300px',
          }}
        >
          <Typography
            sx={{
              color: 'rgba(74, 74, 106, 0.5)',
              marginLeft: '5px',
              fontSize: '12px',
              width: '400px',
              marginRight: '10px', // Added margin to space out the label from the slider
            }}
          >
            Maximum Relationships:
          </Typography>
          <Slider
            step={1}
            value={maximimumNodes}
            onChange={handleNodesSlider}
            min={2}
            max={50}
            valueLabelDisplay="auto" // Show value label on the thumb
            valueLabelFormat={(value) => value} // Format label
            sx={{
              maxWidth: 200, // Set the max width of the Slider
              marginLeft: '5px',
              marginRight: '5px',
              height: '1px',
            }}
          />
          <Typography
            sx={{
              color: 'rgba(74, 74, 106, 0.5)',
              fontSize: '10px',
              marginLeft: '10px',
            }}
          >
            {maximimumNodes}
          </Typography>
        </Box>

        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            marginTop: '5px',
            paddingRight: '10px',
            width: '300px',
          }}
        >
          <Typography
            sx={{
              color: 'rgba(74, 74, 106, 0.5)',
              marginLeft: '5px',
              fontSize: '12px',
              width: '200px',
              marginRight: '10px', // Added margin to space out the label from the slider
            }}
          >
            Simalarity Scale:
          </Typography>
          <Slider
            step={0.01}
            value={threshold}
            onChange={handleThresholdSlider}
            min={minThreshold}
            max={maxThreshold}
            valueLabelDisplay="auto" // Show value label on the thumb
            valueLabelFormat={(value) => value.toFixed(2)} // Format label
            sx={{
              maxWidth: 200, // Set the max width of the Slider
              marginLeft: '5px',
              marginRight: '5px',
              height: '1px',
            }}
          />
          <Typography
            sx={{
              color: 'rgba(74, 74, 106, 0.5)',
              fontSize: '10px',
            }}
          >
            {threshold.toFixed(2)}
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: '12px',
          justifyContent: 'space-between',
        }}
      >
        <MultiWordInput words={filteredWords} setWords={setFilteredWords} />
      </Box>
    </Box>
  );
};

const MotiveMap = () => {
  const dispatch = useDispatch();
  const fgRef = useRef<any>(null);
  const initialCameraPosition = useRef<any>(null);
  const [, setSelectedKeywordId] = useState<number>(null);
  const [threshold, setThreshold] = useState<number>(0.5);
  const [maximumNodes, setMaximumNodes] = useState<number>(2);
  const [filteredWords, setFilteredWords] = useState<string[]>([]);
  const { selectedPlatformAccountId } = useContexts();

  const [jsonData, setJsonData] = useState<any[]>([]);

  const integrationAccounts = useSelector(integrationAccountsSelector);
  const fetchingIntegrationAccounts = useSelector(integrationAccountsLoadingSelector);

  const sortedOptions: IntegrationAccountsSchema[] = [];
  integrationAccounts?.forEach((option: any) => sortedOptions.push(option));
  sortedOptions?.sort((a, b) => a.name.localeCompare(b.name));

  const handleInputChange = (event: React.SyntheticEvent<Element, Event>, value: any | null) => {
    if (value) setSelectedKeywordId(value.id);
  };

  const handleZoomIn = () => {
    const distanceFactor = 0.8; // Zoom-in factor
    const camera = fgRef.current.camera();
    camera.position.multiplyScalar(distanceFactor); // Move the camera closer
  };

  const handleZoomOut = () => {
    const distanceFactor = 1.2; // Zoom-out factor
    const camera = fgRef.current.camera();
    camera.position.multiplyScalar(distanceFactor); // Move the camera further away
  };

  const handleResetZoom = () => {
    const camera = fgRef.current.camera();
    const resetPosition = initialCameraPosition.current;

    if (resetPosition) {
      camera.position.set(resetPosition.x, resetPosition.y, resetPosition.z);
      camera.lookAt(fgRef.current.scene().position);
      fgRef.current.camera(camera); // Trigger update
    }
  };

  // Gets list of integration accounts pertainig to Platform Account
  useEffect(() => {
    if (selectedPlatformAccountId) {
      dispatch(
        getIntegrationAccountsAction.request({
          platformAccountId: selectedPlatformAccountId,
          page: 1,
          pageSize: 9999,
          includeMcc: false,
        }),
      );
    }
  }, [selectedPlatformAccountId, dispatch]);

  useEffect(() => {
    // Check if the ref is available at intervals
    const intervalId = setInterval(() => {
      if (fgRef.current && fgRef.current.camera) {
        clearInterval(intervalId); // Clear the interval once we have the ref and camera
        const camera = fgRef.current.camera();
        initialCameraPosition.current = {
          x: camera.position.x,
          y: camera.position.y,
          z: camera.position.z,
        };
        camera.lookAt(fgRef.current.scene().position); // Ensure initial look at center
      }
    }, 100); // Interval to check every 100 ms

    return () => {
      clearInterval(intervalId); // Clear interval if the component unmounts
    };
  }, []); // Empty array ensures effect only runs once after initial render

  return (
    <Styled.Container data-testid="motiveMap">
      {fetchingIntegrationAccounts || !integrationAccounts?.length ? (
        <CircularProgress
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
          }}
        />
      ) : (
        <>
          <Styled.PageHeader>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                width: '100%',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Box>
                  <SourceAccountSelector
                    displayLogo={
                      <Styled.LogoContainer>
                        <img alt="GoogleAds logo" src={GoogleLogo} height={11.4} />
                      </Styled.LogoContainer>
                    }
                  />
                </Box>
                <Autocomplete
                  popupIcon={null}
                  disablePortal
                  id="combo-box-demo"
                  options={mockData}
                  sx={{
                    display: 'flex',
                    height: '38px',
                    margin: '8px',
                    border: '1px solid rgba(33, 33, 62, 0.23)',
                    borderRadius: '4px',
                    '& .MuiOutlinedInput-root': {
                      minWidth: 450,
                      // border: "1px solid yellow",
                    },
                    '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                      border: 'none',
                    },
                  }}
                  getOptionLabel={(option) => option.keyword}
                  filterOptions={(options, { inputValue }) => {
                    // Filter options based on the user input
                    const filtered = options.filter((option) =>
                      option.keyword.toLowerCase().includes(inputValue.toLowerCase()),
                    );

                    // Sort the filtered options to preference keywords
                    filtered.sort((a) => (a.keyword.toLowerCase().startsWith(inputValue.toLowerCase()) ? -1 : 1));

                    return filtered;
                  }}
                  onChange={(event, value) => handleInputChange(event, value)}
                  renderInput={(params: any) => (
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        marginLeft: '12px',
                        position: 'relative',
                      }}
                    >
                      <Search
                        sx={{
                          position: 'absolute',
                          left: 0,
                          fontSize: '16px',
                          color: 'rgba(0, 0, 0, 0.54)',
                        }}
                      />
                      <TextField
                        InputLabelProps={{ shrink: false }}
                        sx={{
                          marginLeft: '6px',
                          fontSize: '14px',
                          lineHeight: 1.43,
                          letterSpacing: '0.17px',
                          color: 'rgba(33, 33, 52, 0.6)',
                        }}
                        placeholder="Enter a keyword to create an intent map"
                        {...params}
                      />
                    </Box>
                  )}
                />
              </Box>
            </Box>
          </Styled.PageHeader>

          <Styled.Container data-testid="campaignSummaryView">
            <Styled.Container className="campaignMotiveMap" data-testid="campaignMotiveMap">
              <Styled.FlexContainer>
                <Styled.CenterContainer>
                  <Box>
                    {jsonData?.length < 1 ? (
                      <Box
                        sx={{
                          height: '100%',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <CSVUpload setJsonData={setJsonData} />
                        {/* <Typography variant="h6" sx={{ textAlign: 'center' }}>
                      Select a keyword to view its MotiveMap
                    </Typography> */}
                      </Box>
                    ) : (
                      // <Chart data={createLinksArray(clusterSummaries?.edges)} />
                      <>
                        <ControlBar
                          handleZoomIn={handleZoomIn}
                          handleZoomOut={handleZoomOut}
                          handleResetZoom={handleResetZoom}
                          setThreshold={setThreshold}
                          setMaximumNodes={setMaximumNodes}
                          threshold={threshold}
                          maximimumNodes={maximumNodes}
                          filteredWords={filteredWords}
                          setFilteredWords={setFilteredWords}
                        />
                        {/* <ForceGraph3D
                          ref={fgRef}
                          height={window.innerHeight - 130}
                          width={window.innerWidth - 200}
                          backgroundColor="#fff"
                          // graphData={createLinksArrayDemo(
                          //   mockData.find((d) => d.id === selectedKeywordId)?.intents,
                          //   threshold,
                          //   maximumNodes,
                          //   filteredWords,
                          // )}
                          graphData={createLinksArrayDemo(jsonData, threshold, maximumNodes, filteredWords)}
                          showNavInfo={false}
                          nodeLabel={(node: any) => `${node?.clusterTopic}`}
                          nodeRelSize={4}
                          linkWidth={(node: any) => 0.3 * node.similarity}
                          linkColor={() => '#003F5C'}
                          linkOpacity={0.5}
                          onNodeDragEnd={(node) => {
                            node.fx = node.x;
                            node.fy = node.y;
                            node.fz = node.z;
                          }}
                          nodeThreeObject={(node: any) => {
                            const sprite = new SpriteText(node?.clusterTopic, 12, '#0d47a1');
                            sprite.borderWidth = 0.7;
                            sprite.padding = 1.5;
                            sprite.borderRadius = 5;
                            sprite.borderColor = '#0d47a1';
                            sprite.backgroundColor = '#eceff1';

                            return sprite;
                          }}
                        /> */}
                      </>
                    )}
                  </Box>
                  {jsonData?.length > 0 && <Box>THERE IS DATA</Box>}
                </Styled.CenterContainer>
              </Styled.FlexContainer>
            </Styled.Container>
          </Styled.Container>
        </>
      )}
    </Styled.Container>
  );
};

export default MotiveMap;
