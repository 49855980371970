import React, { useMemo } from 'react';
import { AxisOptions, Chart } from 'react-charts';
import { Box, Typography } from '@mui/material';
import { TooltipRendererProps } from 'react-charts/types/components/TooltipRenderer';

import { InfoIcon } from '~/pages/Opportunities/components/TrafficSegmentPreview/components/IntentThemes/styles';

import { DataPoint, FormattedDataType, MetricKey } from './types';
import './styles.css';
import { Tooltip } from '../Tooltip';

type ChartKey = {
  color: string;
  label: string;
};

type ChartKeyProps = {
  children?: React.ReactNode;
  keys?: MetricKey[];
  setKeys?: (keys: MetricKey[]) => void;
};

const keyItems = [
  { color: '#880E4F', label: 'impressions' },
  { color: '#26BFC7', label: 'conversions' },
  { color: '#183091', label: 'clicks' },
  { color: '#F66A44', label: 'cost' },
];

const ChartKey: React.FC<ChartKeyProps> = ({ children, keys, setKeys }) => {
  const handleKeyClick = (keyLabel: MetricKey) => {
    if (setKeys) {
      if (keys?.includes(keyLabel)) {
        // Remove key from the list
        if (keys.length === 1) return;
        setKeys(keys.filter((k) => k !== keyLabel));
      } else {
        // Add key to the list
        setKeys([...keys, keyLabel]);
      }
    }
  };
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
      }}
    >
      <Typography variant="h6" sx={{ mb: 1, fontWeight: 'bold', color: 'rgb(33,33,52,0.87)' }}>
        Segmentation Analysis
      </Typography>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          gap: 2,
        }}
      >
        {keyItems.map((item: any) => (
          <Box
            key={item.label}
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 1,
              cursor: 'pointer', // Add cursor style for clickable items
              opacity: keys?.includes(item.label) ? 1 : 0.4, // Toggle opacity based on selection
            }}
            onClick={() => handleKeyClick(item.label)} // Add onClick handler
          >
            <Box
              sx={{
                width: 30,
                height: 10,
                backgroundColor: item.color,
              }}
            />
            <Typography sx={{ fontSize: '0.875rem', color: 'rgb(33,33,52,0.87)' }}>
              {item.label.charAt(0).toUpperCase() + item.label.slice(1)}
            </Typography>
          </Box>
        ))}
      </Box>
      {children}
    </Box>
  );
};

type BarChartProps = {
  data: FormattedDataType[];
  keys: MetricKey[];
  setKeys: (keys: MetricKey[]) => void;
};

const BarChart: React.FC<BarChartProps> = ({ data, keys, setKeys }) => {
  const primaryAxis = useMemo<AxisOptions<DataPoint>>(
    () => ({
      position: 'bottom',
      getValue: (datum) => datum?.primary,
      showGrid: false,
    }),
    [],
  );

  const secondaryAxes = useMemo<AxisOptions<DataPoint>[]>(
    () => [
      {
        position: 'left',
        getValue: (datum) => datum?.secondary,
      },
    ],
    [],
  );

  const renderTooltip = React.useMemo(() => {
    // Define a color mapping based on the series labels
    const colorMapping = {
      Impressions: '#880E4F',
      Conversions: '#26BFC7',
      Clicks: '#183091',
      Cost: '#F66A44',
    };

    return function ({ focusedDatum, getOptions }: TooltipRendererProps<any>) {
      if (!focusedDatum) return null;

      // Access the series and datums from the chart options
      const options = getOptions();
      const series = options.data;

      // Find the focused series and datum index
      const focusedSeriesIndex = focusedDatum.seriesIndex;
      const focusedDatumIndex = focusedDatum.index;

      // Construct the tooltip content with a Material Design inspired style
      return (
        <div
          style={{
            padding: '6px',
            background: 'white',
            boxShadow: '0 4px 8px rgba(0,0,0,0.12)',
            borderRadius: '8px',
            color: 'red',
            fontSize: '14px', // Adjusted font size
            lineHeight: '1.4', // Adjusted line height
            maxWidth: '220px', // Adjusted max width for better layout
            fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif', // Material Design font
          }}
        >
          {series.map((s, seriesIndex) => {
            const datum = s.data[focusedDatumIndex];
            const isFocused = seriesIndex === focusedSeriesIndex;
            const value = datum?.secondary;
            const seriesColor = colorMapping[s.label as keyof typeof colorMapping] || '#666';

            return (
              <div
                key={seriesColor}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  fontWeight: isFocused ? 'bold' : 'normal',
                  color: isFocused ? seriesColor : '#666',
                  margin: '4px 0', // Adjusted margin for better spacing
                }}
              >
                <div
                  style={{
                    width: '12px', // Adjusted indicator size
                    height: '12px', // Adjusted indicator size
                    backgroundColor: seriesColor,
                    border: isFocused ? `1px solid ${seriesColor}` : `1px solid #fff`, // Adjusted border style

                    borderRadius: '50%',
                    marginRight: '8px', // Adjusted spacing between indicator and label
                  }}
                />
                {s.label}: {value?.toFixed(2)}%
              </div>
            );
          })}
        </div>
      );
    };
  }, []);

  return (
    <Box
      sx={{
        height: '100%',
        position: 'relative',
      }}
    >
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        <ChartKey keys={keys} setKeys={setKeys} />
        <Typography
          sx={{
            position: 'absolute',
            left: '-80px',
            // rotate the text sideways
            transform: 'rotate(-90deg)',
            top: '165px',
            fontSize: '12px',
          }}
        >
          % of Account Activity
        </Typography>
        <Box
          sx={{
            position: 'absolute',
            bottom: '-85px',
            width: '100%',
            fontSize: '10px',
            textAlign: 'center',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 0.5,
          }}
        >
          <Typography fontSize={12}>Keyword Coherency in Ad Groups</Typography>
          <Tooltip
            offset={[150, -10]}
            placement="bottom"
            title={
              'Keyword Coherency within an ad group refers to organizing closely related or ' +
              "thematically aligned keywords. It's measured using a proprietary coherency index " +
              'based on semantic similarity, not just the absolute number of keywords.'
            }
          >
            <InfoIcon fontSize="small" />
          </Tooltip>
        </Box>
      </Box>
      <Chart
        className="chart"
        options={{
          data,
          primaryAxis,
          secondaryAxes,
          getSeriesStyle: (series) => {
            const colors = [
              {
                impressions: '#880E4F',
                conversions: '#26BFC7',
                clicks: '#183091',
                cost: '#F66A44',
              },
            ];
            return {
              color:
                series.label === 'Impressions'
                  ? colors[0].impressions
                  : series.label === 'Conversions'
                  ? colors[0].conversions
                  : series.label === 'Clicks'
                  ? colors[0].clicks
                  : colors[0].cost,
            };
          },
          tooltip: {
            render: renderTooltip,
          },
        }}
      />
    </Box>
  );
};

export default BarChart;
