import { styled, Box as MuiBox } from '@mui/material';

export const FlexContainer = styled(MuiBox)({
  display: 'flex',
  background: '#fff',
});

export const CenterContainer = styled(MuiBox)({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
});
