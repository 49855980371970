import { Box, Skeleton, styled, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';

import { Button, CircularProgress } from '~/core/components/shared';
import { BubbleChart } from '~/core/components/shared/BubbleChart';
import { numberWithCommas } from '~/core/helpers';
import { useGetIntentThemes } from '~/core/services/graphql/intents/hooks';
import { IntentThemesSortEnum, QueryGetIntentThemesArgs } from '~/core/types/graphql.types';
import { useContexts } from '~/store/context/useContext';

const CustomContainer = styled(Box)`
  align-self: stretch;
  flex-grow: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px 12px;
  border-radius: 6px;
  border: solid 1px rgba(33, 33, 52, 0.12);
  background-color: #fff;
  margin: 8px 0;
`;

const ChartHeader = styled('p')({
  fontSize: '14px',
  fontWeight: 'bold',
  margin: '0',
});

const ChartDetails = styled('p')`
  height: 14px;
  font-size: 10px;
  line-height: 1.4;
  text-align: center;
  color: rgba(33, 33, 52, 0.6);
  margin: 0 0 16px 0;
`;

const ChartBubble = styled(Box)({
  backgroundColor: '#EEF1F9',
  borderRadius: '50%',
  border: '2px solid #D1D6DD',
  padding: 12,
});

const StyledTableContainer = styled(TableContainer)({
  maxWidth: 650,
  height: '100%',
  margin: 'auto',
  border: '1px solid #f5f5f5',
  borderRadius: 4,
  marginTop: 20,
});

const StyledTable = styled(Table)({});

const StyledTableHeaderCell = styled(TableCell)({
  backgroundColor: '#f5f5f5',
  fontWeight: 'bold',
  fontSize: '10px',
  padding: '8px',
});

const StyledTableCell = styled(TableCell)({
  fontSize: '10px',
  padding: '8px 16px',
  maxWidth: 20,
});

const StyledTableRow = styled(TableRow)({
  '&:nth-of-type(even)': {
    backgroundColor: '#f9f9f9',
  },
});

type IntentThemesPreviewProps = {
  segmentId: string;
  totalIntentThemes?: number;
};

const IntentThemesPreview: React.FC<IntentThemesPreviewProps> = ({ segmentId, totalIntentThemes }) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { selectedPlatformAccountId, selectedSourceAccountId, selectedDateRange } = useContexts();
  const url = `intent-themes?${searchParams}`;

  const handleClick = (event: any) => {
    event.stopPropagation();
    navigate(url);
  };

  const intentThemesSkip = !segmentId;
  const intentThemesVariables: QueryGetIntentThemesArgs = {
    platformAccountId: selectedPlatformAccountId,
    customerId: selectedSourceAccountId,
    dateRange: selectedDateRange,
    filterField: {
      filters: [{ fieldName: 'segment_id', value: segmentId, operation: 'eq' }],
    },
    sortBy: { field: IntentThemesSortEnum.Impressions, desc: true },
    page: 1,
    pageSize: 5,
  };

  const { getIntentThemes, intentThemesLoading } = useGetIntentThemes(intentThemesSkip, intentThemesVariables);

  return (
    <CustomContainer>
      <ChartHeader>{numberWithCommas(totalIntentThemes)} Intent Themes</ChartHeader>
      <ChartDetails>Showing Top 5 | Ranked by nValue Score</ChartDetails>
      {intentThemesLoading ? (
        <Box height={100} display="flex" alignItems="center">
          <CircularProgress size={20} />
        </Box>
      ) : (
        <ChartBubble>
          <BubbleChart data={getIntentThemes?.edges} preview />
        </ChartBubble>
      )}
      <StyledTableContainer>
        <StyledTable>
          <TableHead>
            <TableRow>
              <StyledTableHeaderCell>Intent Themes</StyledTableHeaderCell>
              <StyledTableHeaderCell># of Intents</StyledTableHeaderCell>
              <StyledTableHeaderCell># of Keywords</StyledTableHeaderCell>
              <StyledTableHeaderCell>nValue</StyledTableHeaderCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {intentThemesLoading
              ? [...Array(5)].map((_, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <StyledTableRow key={index}>
                    <StyledTableCell>
                      <Skeleton variant="text" animation="wave" width="20%" height={10} />
                    </StyledTableCell>
                    <StyledTableCell>
                      <Skeleton variant="text" animation="wave" width="20%" height={10} />
                    </StyledTableCell>
                    <StyledTableCell>
                      <Skeleton variant="text" animation="wave" width="20%" height={10} />
                    </StyledTableCell>
                    <StyledTableCell>
                      <Skeleton variant="text" animation="wave" width="20%" height={10} />
                    </StyledTableCell>
                  </StyledTableRow>
                ))
              : getIntentThemes?.edges?.map((row) => (
                  <StyledTableRow key={row.theme}>
                    <StyledTableCell>{row.theme}</StyledTableCell>
                    <StyledTableCell>{row.intents}</StyledTableCell>
                    <StyledTableCell>{row.keywords}</StyledTableCell>
                    <StyledTableCell>{row.impressions}</StyledTableCell>
                  </StyledTableRow>
                ))}
          </TableBody>
        </StyledTable>
      </StyledTableContainer>
      <Button
        variant="contained"
        color="primary"
        size="large"
        fullWidth
        label="View All"
        onClick={handleClick}
        sx={{
          backgroundColor: '#37345b',
          margin: '10px',
        }}
      />
    </CustomContainer>
  );
};

export default IntentThemesPreview;
