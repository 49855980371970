import styled from '@emotion/styled';
import { MenuItem, Select as MuiSelect, Skeleton } from '@mui/material';

export const Select = styled(MuiSelect)({
  height: '38px',
  fontSize: '14px',
  lineHeight: 1.5,
  letterSpacing: '0.15px',

  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
});

export const CustomMenuItem = styled(MenuItem)`
  font-size: 14px;
`;

export const CustomSkeleton = styled(Skeleton)`
  margin: 0 15px;
  width: 120px;
  height: 40px;
`;
