import { gql } from '@apollo/client';

export const usersFromAgencyGql = gql`
  query accountUsers(
    $page: Int!
    $pageSize: Int!
    $accountId: Int!
    $searchValue: String
    $filterSchema: PlatformAccountUsersFilterSchema
    $sortBy: PlatformAccountUserSortSchema
  ) {
    accountUsers(
      page: $page
      pageSize: $pageSize
      accountId: $accountId
      searchValue: $searchValue
      filterSchema: $filterSchema
      sortBy: $sortBy
    ) {
      pageInfo {
        pageSize
        page
        total
        pages
        previousPage
        nextPage
      }
      edges {
        firstName
        lastName
        email
        isAdmin
        status
        accountId
        lastSignOn
        userAccessId
        userId
        userInviteId
        fullName
      }
    }
  }
`;

export const updateUserAccessGql = gql`
  mutation updateUserAccess($accountId: Int!, $userAccessId: Int!, $dataToEdit: UserAccessUpdateInputSchema!) {
    updateUserAccess(accountId: $accountId, userAccessId: $userAccessId, dataToEdit: $dataToEdit) {
      firstName
      lastName
      fullName
      isAdmin
      email
      status
      accountId
      lastSignOn
      userAccessId
      userId
      userInviteId
    }
  }
`;

export const inviteAccountUserGql = gql`
  mutation inviteAccountUser($accountId: Int!, $email: String!, $isAdmin: Boolean!) {
    sendInviteLink(accountId: $accountId, userData: { email: $email, isAdmin: $isAdmin }) {
      id
      createdAt
      updatedAt
      email
      accountId
      inviteType
      inviteStatus
    }
  }
`;
