import { Snackbar as MuiSnackbar, Slide, SlideProps, SnackbarOrigin } from '@mui/material';
import { useEffect, useState } from 'react';

import * as Styled from './styles';
import { SnackbarProps } from './types';

export const snackbarDefaultPosition: SnackbarOrigin = { horizontal: 'right', vertical: 'bottom' };

const SlideTransition = (props: SlideProps) => <Slide {...props} direction="up" />;

const Snackbar: React.FC<SnackbarProps> = ({
  message,
  anchorOrigin = snackbarDefaultPosition,
  autoHideDuration = 6000,
  severity = 'error',
  ...elementProps
}) => {
  const [open, setOpen] = useState<boolean>(false);

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (message) {
      setOpen(true);
    }
  }, [message]);

  return (
    <MuiSnackbar
      anchorOrigin={anchorOrigin}
      open={open}
      onClose={handleClose}
      autoHideDuration={autoHideDuration}
      TransitionComponent={SlideTransition}
      {...elementProps}
    >
      <Styled.AlertMessage severity={severity}>{message}</Styled.AlertMessage>
    </MuiSnackbar>
  );
};

export default Snackbar;
