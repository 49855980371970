import { ActionType, createAsyncAction } from 'typesafe-actions';

import {
  OwnIntegrationAccountsQuery,
  OwnIntegrationAccountsQueryVariables,
} from '~/core/services/graphql/integrationAccounts/integrationAccounts.queries.generated';
import { GoogleAdsUrl } from '~/core/types/googleAdsAccounts.types';

export enum PaidSearchIntegrationsAccountsTypes {
  PaidIntegrationAccounts = '[PaidSearchIntegrationsAccountsTypes] GetIntegrationAccounts',
  PaidIntegrationAccountsSuccess = '[PaidSearchIntegrationsAccountsTypes] GetIntegrationAccountsSuccess',
  PaidIntegrationAccountsFailed = '[PaidSearchIntegrationsAccountsTypes] GetIntegrationAccountsFailed',
  PostGoogleAuthSignIn = '[PaidSearchIntegrationsAccountsTypes] PostGoogleAuthSignIn',
  PostGoogleAuthSignInSuccess = '[PaidSearchIntegrationsAccountsTypes] PostGoogleAuthSignInSuccess',
  PostGoogleAuthSignInFailed = '[PaidSearchIntegrationsAccountsTypes] PostGoogleAuthSignInFailed',
  RefreshPaidIntegrationAccounts = '[PaidSearchIntegrationsAccountsTypes] RefreshIntegrationAccounts',
  RefreshPaidIntegrationAccountsSuccess = '[PaidSearchIntegrationsAccountsTypes] RefreshIntegrationAccountsSuccess',
  RefreshPaidIntegrationAccountsFailed = '[PaidSearchIntegrationsAccountsTypes] RefreshIntegrationAccountsFailed',
  LinkNewPaidSearchAccount = '[PaidSearchIntegrationsAccountsTypes] LinkNewPaidSearchAccount',
  LinkNewPaidSearchAccountSuccess = '[PaidSearchIntegrationsAccountsTypes] LinkNewPaidSearchAccountSuccess',
  LinkNewPaidSearchAccountFailed = '[PaidSearchIntegrationsAccountsTypes] LinkNewPaidSearchAccountFailed',
}

export const paidIntegrationAccountsAction = createAsyncAction(
  PaidSearchIntegrationsAccountsTypes.PaidIntegrationAccounts,
  PaidSearchIntegrationsAccountsTypes.PaidIntegrationAccountsSuccess,
  PaidSearchIntegrationsAccountsTypes.PaidIntegrationAccountsFailed,
)<OwnIntegrationAccountsQueryVariables, OwnIntegrationAccountsQuery, string>();

export const postGoogleAuthSignInAction = createAsyncAction(
  PaidSearchIntegrationsAccountsTypes.PostGoogleAuthSignIn,
  PaidSearchIntegrationsAccountsTypes.PostGoogleAuthSignInSuccess,
  PaidSearchIntegrationsAccountsTypes.PostGoogleAuthSignInFailed,
)<{ accountId: number; redirectionUri: string }, GoogleAdsUrl, string>();

export const refreshPaidIntegrationAccountsAction = createAsyncAction(
  PaidSearchIntegrationsAccountsTypes.RefreshPaidIntegrationAccounts,
  PaidSearchIntegrationsAccountsTypes.RefreshPaidIntegrationAccountsSuccess,
  PaidSearchIntegrationsAccountsTypes.RefreshPaidIntegrationAccountsFailed,
)<number, OwnIntegrationAccountsQuery, string>();

export const linkNewPaidSearchAccountAction = createAsyncAction(
  PaidSearchIntegrationsAccountsTypes.LinkNewPaidSearchAccount,
  PaidSearchIntegrationsAccountsTypes.LinkNewPaidSearchAccountSuccess,
  PaidSearchIntegrationsAccountsTypes.LinkNewPaidSearchAccountFailed,
)<number, OwnIntegrationAccountsQuery, string>();

export type PaidSearchIntegrationsAccountsActionUnion = ActionType<
  | typeof postGoogleAuthSignInAction
  | typeof refreshPaidIntegrationAccountsAction
  | typeof linkNewPaidSearchAccountAction
  | typeof paidIntegrationAccountsAction
>;
