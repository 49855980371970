import {
  styled,
  Box as MuiBox,
  Stepper as MuiStepper,
  StepLabel as MuiStepLabel,
  StepConnector as MuiStepConnector,
} from '@mui/material';

export const Box = styled(MuiBox)(({ theme }) => ({
  height: '100%',
  backgroundColor: theme.palette.background.paper,
  padding: '32px 24px',
}));

export const Stepper = styled(MuiStepper)({
  height: '100%',
});

export const StepLabel = styled(MuiStepLabel)(({ theme }) => ({
  '.MuiSteplabel-iconContainer, .Mui-active, .Mui-completed': {
    color: theme.palette.primary.main,
  },
  '.MuiStepLabel-label': {
    color: theme.palette.text.secondary,
    fontSize: '14px',
  },
  '.MuiStepLabel-label.Mui-active': {
    color: theme.palette.text.primary,
  },
}));

export const StepConnector = styled(MuiStepConnector)({
  '.MuiStepConnector-line': {
    height: '100%',
  },
});
