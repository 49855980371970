import { Link } from '@mui/material';
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { AuthContainer } from '~/core/components/layouts/AuthContainer';
import { FormTitle } from '~/core/components/shared';
import { AuthPaths } from '~/core/constants/paths.constants';
import { PasswordResetRequestForm as Form } from '~/core/types/auth.types';
import { forgotPasswordAction, resetAuthErrorsAction } from '~/store/actions/auth.action';
import { authErrorSelector, authLoadingSelector } from '~/store/selectors/auth.selector';

import { PasswordResetRequestForm } from './components/PasswordResetRequestForm';

const PasswordResetRequestPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [email, setEmail] = useState<string>('');

  const error = useSelector(authErrorSelector);
  const isLoading = useSelector(authLoadingSelector);

  const handleSubmitButton = ({ email }: Form) => {
    dispatch(forgotPasswordAction.request({ email }));
    setEmail(email);
  };

  useEffect(() => {
    if (!error && !isLoading && email) {
      navigate('../password-reset', { state: { email } });
      dispatch(resetAuthErrorsAction());
    }
  }, [error, isLoading, email, navigate, dispatch]);

  return (
    <AuthContainer>
      <FormTitle>Enter your email to reset your password</FormTitle>
      <PasswordResetRequestForm onSubmit={handleSubmitButton} />
      <Link underline="none" href={AuthPaths.SignIn}>
        Return to sign in page
      </Link>
    </AuthContainer>
  );
};

export default PasswordResetRequestPage;
