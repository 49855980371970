import { FormattedDataType, KeywordCoherencyKpIsSchema, MetricKey } from './types';

export const formatData = (
  data: KeywordCoherencyKpIsSchema[],
  keys: MetricKey[],
  range: number[],
): FormattedDataType[] =>
  keys.map((metric) => {
    const totalForMetric = data.reduce((sum, item) => sum + (item[metric] || 0), 0);
    // Create a set of unique keywordCoherency values from the data
    const uniqueCoherencies = new Set(data.map((item) => item.keywordCoherency));
    // Add the fixed range 1-10 to the set
    for (let i = 1; i <= 10; i += 1) {
      uniqueCoherencies.add(i);
    }
    // Filter the set based on the provided range and convert to a sorted array
    const coherencyRange = Array.from(uniqueCoherencies)
      .filter((coherency) => coherency >= range[0] && coherency <= range[1])
      .sort((a, b) => a - b);

    return {
      label: metric.charAt(0).toUpperCase() + metric.slice(1),
      data: coherencyRange?.map((coherency) => {
        const item = data.find((d) => d.keywordCoherency === coherency);
        const value = item ? item[metric] || 0 : 0;
        const percentage = totalForMetric > 0 ? (value / totalForMetric) * 100 : 0;

        return {
          primary: `${coherency}`,
          secondary: Number(percentage),
        };
      }),
    };
  });
