import { styled, Box as MuiBox, BoxProps, Typography, TypographyProps } from '@mui/material';

export const Box = styled(MuiBox)<BoxProps>(() => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  width: 'auto',
  justifyContent: 'center',
  alignItems: 'center',
}));

export const Heading = styled(Typography)<TypographyProps>(() => ({
  fontFamily: 'Inter',
  color: 'rgba(33,33,52,0.87)',
  fontSize: '24px',
  marginTop: '10px',
}));
