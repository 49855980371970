import { Box, styled, Typography } from '@mui/material';

export const ContainerBox = styled(Box)({
  display: 'flex',
  height: '100vh',
});

export const SidebarContainer = styled(Box)`
  min-width: 240px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0;
  background-color: #1c1a36;
`;

export const AccountNameText = styled(Typography)({
  marginLeft: '10px',
  fontSize: '14px',
  fontWeight: 'bold',
  lineHeight: 1.57,
  letterSpacing: '0.1px',
  textAlign: 'left',
});

export const ContentContainer = styled(Box)({
  flexGrow: 1,
  height: '100%',
  overflowX: 'auto',
  position: 'relative',
});
