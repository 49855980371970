import { gql } from '@apollo/client';

export const GET_ASSETS = gql`
  query getAssets(
    $platformAccountId: Int!
    $customerIds: [String!]!
    $campaignIds: [String!]
    $adGroupIds: [String!]
    $assetIds: [String!]
    $adIds: [String!]
    $dateRange: String!
    $sortBy: AssetSortSchema
    $filterField: BaseFilterSchema
    $searchValue: String
    $assetText: String
    $adStatus: String
  ) {
    getAssets(
      platformAccountId: $platformAccountId
      customerIds: $customerIds
      campaignIds: $campaignIds
      adGroupIds: $adGroupIds
      assetIds: $assetIds
      adIds: $adIds
      dateRange: $dateRange
      sortBy: $sortBy
      filterField: $filterField
      searchValue: $searchValue
      assetText: $assetText
      adStatus: $adStatus
    ) {
      startDate
      endDate
      campaignId
      adGroupId
      adId
      adType
      fieldType
      id
      text
      customerId
      dateRange
      enabled
    }
  }
`;

export const GET_ASSET_RECOMMENDATIONS = gql`
  query getAssetRecommendations(
    $platformAccountId: Int!
    $customerIds: [String!]!
    $intentIds: [String!]
    $dateRange: String!
    $sortBy: AssetRecommendationsSortSchema
    $filterField: BaseFilterSchema
    $searchValue: String
  ) {
    getAssetRecommendations(
      platformAccountId: $platformAccountId
      customerIds: $customerIds
      intentIds: $intentIds
      dateRange: $dateRange
      sortBy: $sortBy
      filterField: $filterField
      searchValue: $searchValue
    ) {
      score
      intentName
      assetText
      assetType
      campaignId
      adGroupId
      intentId
      bestExistingScore
      bestExistingAssetText
      dateRange
      customerId
    }
  }
`;
