import {
  Accordion as MuiAccordion,
  AccordionDetails as MuiAccordionDetails,
  AccordionSummary as MuiAccordionSummary,
  styled,
} from '@mui/material';

import { Button } from '~/core/components/shared';

export const PageContainer = styled('div')({
  height: '100vh',
});

export const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: '32px',
  marginTop: '24px',
});

export const Accordion = styled(MuiAccordion)({
  backgroundColor: 'rgba(255, 255, 255, 0)',
  boxShadow: 'rgba(100, 116, 139, 0.16) 0px 1px 1px, rgba(100, 116, 139, 0.16) 0px 1px 6px',
});

export const AccordionSummary = styled(MuiAccordionSummary)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
  padding: '24px',
  background: theme.palette.background.paper,
  borderRadius: '0 0 8px 8px',
}));

export const SummaryAuthorizedContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: '12px',
});

export const TitleContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: '6px',
});

export const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  borderRadius: '0 0 8px 8px',
  boxShadow: 'none',
}));

export const AddAccountButton = styled(Button)({
  marginBottom: '24px',
  marginLeft: '16px',
});

export const HeaderContainer = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});

export const UpdateAccountsButton = styled(Button)({
  backgroundColor: 'red',
  '&:hover': {
    backgroundColor: 'rgba(220,60,60,1)',
  },
});
