import { MouseEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Box, Card, styled, Table, TableBody, Typography, useMediaQuery, useTheme } from '@mui/material';
import { ArrowBack } from '@mui/icons-material';

import { Breadcrumbs, Button, CircularProgress, DateSelector, SelectorMenu, TableHead } from '~/core/components/shared';
import { BubbleChart } from '~/core/components/shared/BubbleChart';
import { useContexts } from '~/store/context/useContext';
import { IntentThemesSortEnum, QueryGetIntentThemesArgs } from '~/core/types/graphql.types';
import { useGetIntentThemes } from '~/core/services/graphql/intents/hooks';
import { SortType } from '~/core/types/table.types';
import { numberWithCommas, renderSkeletonRows } from '~/core/helpers';
import { CustomMenuItem, CustomSkeleton, Select } from '~/core/components/shared/Selectors/DateSelector/styles';
import { useGetKeywordsText } from '~/core/services/graphql/keywords/hooks';
import { GetKeywordsTextQueryVariables } from '~/core/services/graphql/keywords/keywords.queries.generated';

import { headerCells } from './tableHeader.data';
import * as Styled from './styles';
import { CustomTablePagination } from '../../../ContentGapAnalysis/components';

const Header = styled(Typography)`
  font-size: 20px;
  font-weight: bold;
  line-height: 1.6;
  letter-spacing: 0.15px;
  color: rgba(33, 33, 52, 0.87);
`;

const CustomContainer = styled(Card)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
  backgroundColor: '#fff',
  height: '70vh',
  padding: '0',
  minHeight: '600px',
});

const ChartHeader = styled('p')`
  height: 24px;
  font-size: 16px;
  font-weight: bold;
  line-height: 1.5;
  letter-spacing: 0.15px;
  color: rgba(33, 33, 52, 0.87);
  margin: 0;
`;

const ChartDetails = styled('p')`
  height: 20px;
  font-size: 12px;
  line-height: 1.67;
  letter-spacing: 0.4px;
  text-align: center;
  color: rgba(33, 33, 52, 0.6);
  margin: 0 0 14px 0;
`;

const ChartKeyContainer = styled(Box)({
  display: 'flex',
  gap: 4,
  alignItems: 'center',
});

const ChartKey = styled(Box)({
  height: 15,
  width: 15,
});

const ChartKeyText = styled('p')({
  fontSize: '12px',
  margin: '0',
});

const ChartBubble = styled(Box)({
  backgroundColor: '#EEF1F9',
  borderRadius: '50%',
  border: '2px solid #D1D6DD',
  padding: 12,
  width: '100%',
  maxWidth: '450px',
});

const IntentThemes = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const theme = useTheme();
  const { selectedPlatformAccountId, selectedSourceAccountId, selectedDateRange } = useContexts();
  const [orderBy, setOrderBy] = useState<IntentThemesSortEnum>(IntentThemesSortEnum.Impressions);
  const [desc, setDesc] = useState<boolean>(true);
  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(25);
  const [intentThemesCache, setIntentThemesCache] = useState<any>({
    pageInfo: { total: null },
    edges: null,
  });
  const [pageTotals, setPageTotals] = useState<number>(0);
  const [selectedKeyword, setSelectedKeyword] = useState<string | null>(null);
  const [cachedIntentThemes, setCachedIntentThemes] = useState<any>(null);

  const getQueryValue = useCallback(
    (selected: string | null, queryParam: string) => selected || searchParams.get(queryParam),
    [searchParams],
  );
  const platformAccountId = useMemo(
    () => getQueryValue(selectedPlatformAccountId?.toString(), 'platformAccountId') as string,
    [getQueryValue, selectedPlatformAccountId] as const,
  );
  const sourceAccountId =
    useMemo(
      () => getQueryValue(selectedSourceAccountId, 'sourceAccountId'),
      [getQueryValue, selectedSourceAccountId],
    ) || searchParams.get('sourceAccountId');

  const dateRange =
    useMemo(() => getQueryValue(selectedDateRange, 'dateRange'), [getQueryValue, selectedDateRange]) ||
    searchParams.get('dateRange');

  const adGroupId = useMemo(() => searchParams.get('adGroupId'), [searchParams]);
  const adGroupName = useMemo(() => searchParams.get('adGroupName'), [searchParams]);
  const segmentId = useMemo(() => searchParams.get('segmentId'), [searchParams]);

  const [filters, setFilters] = useState([{ fieldName: 'segment_id', value: segmentId, operation: 'eq' }]);

  const importedState = useMemo(() => {
    const params = { platformAccountId, sourceAccountId, dateRange, adGroupId, adGroupName, segmentId };
    return Object.entries(params).reduce((acc: any, [key, value]) => {
      if (value !== null) {
        acc[key] = value;
      }
      return acc;
    }, {});
  }, [platformAccountId, sourceAccountId, dateRange, searchParams, adGroupId, adGroupName, segmentId]);

  const intentThemesSkip = !segmentId;

  useEffect(() => {
    if (selectedKeyword) {
      if (selectedKeyword === 'All Keywords') {
        setFilters([{ fieldName: 'segment_id', value: segmentId, operation: 'eq' }]);
        return;
      }
      setFilters([
        {
          fieldName: 'segment_id',
          value: segmentId,
          operation: 'eq',
        },
        { fieldName: 'keyword_text', value: selectedKeyword, operation: 'eq' },
      ]);
    }
  }, [selectedKeyword]);

  const intentThemesVariables: QueryGetIntentThemesArgs = {
    platformAccountId: selectedPlatformAccountId,
    customerId: selectedSourceAccountId,
    dateRange: selectedDateRange,
    filterField: {
      filters,
    },
    sortBy: { field: orderBy, desc },
    page: page + 1,
    pageSize,
  };

  const { getIntentThemes, intentThemesLoading } = useGetIntentThemes(intentThemesSkip, intentThemesVariables);

  const keywordsSkip = !segmentId;
  const keywordsVariables: GetKeywordsTextQueryVariables = {
    platformAccountId: selectedPlatformAccountId,
    customerIds: [selectedSourceAccountId],
    dateRange: selectedDateRange,
    filterField: {
      filters: [{ fieldName: 'segment_id', value: segmentId, operation: 'eq' }],
    },
    page: 1,
    pageSize: 999999,
  };

  const { getKeywords, keywordsLoading } = useGetKeywordsText(keywordsSkip, keywordsVariables);

  const handleRequestSort = (event: MouseEvent<unknown>, property: any) => {
    const isAsc = property === orderBy ? !desc : true;
    setDesc(isAsc);
    setOrderBy(property);
    searchParams.set('orderBy', property);
    searchParams.set('desc', String(!isAsc));
    setSearchParams(searchParams);
  };

  const handleClick = (event: any) => {
    event.stopPropagation();
    navigate(-1);
  };

  useEffect(() => {
    if (page === 0 || cachedIntentThemes === null) {
      setIntentThemesCache(getIntentThemes);
    }
  }, [getIntentThemes, page, selectedKeyword]);

  useEffect(() => {
    const total = getIntentThemes?.pageInfo?.total;
    if (total) {
      setPageTotals(total);
    }

    if (cachedIntentThemes === null && getIntentThemes?.edges?.length > 0) {
      setCachedIntentThemes(getIntentThemes);
    }
  }, [getIntentThemes]);

  const handleChange = (value: string) => {
    setCachedIntentThemes(null);
    setSelectedKeyword(value);
    setPage(0);
  };

  return (
    <Box>
      <Box
        sx={{
          display: 'none !important',
        }}
      >
        <SelectorMenu />
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
          backgroundColor: '#fff',
          borderBottom: '1px solid rgba(0,0,0,.12)',
        }}
      >
        <Breadcrumbs
          importedState={importedState}
          headerStyle={{
            borderBottom: 'none',
          }}
        />
        <DateSelector />
      </Box>
      <Box
        sx={{
          background: '#F0F0F3',
          height: '100vh',
          padding: '12px 24px',
          maxHeight: useMediaQuery(theme.breakpoints.down('lg')) ? '100%' : '650px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            gap: '12px',
            alignItems: 'center',
            paddingBottom: '12px',
          }}
        >
          <Button
            label="All Segments"
            variant="outlined"
            startIcon={<ArrowBack />}
            color="secondary"
            onClick={handleClick}
          />
          <Header>Traffic Segment</Header>
        </Box>
        <Box
          sx={{
            backgroundColor: '#fff',
            padding: '22px 16px',
            fontSize: '16px',
            fontWeight: 'bold',
            lineHeight: 1.5,
            letterSpacing: '0.15px',
            color: 'rgba(33, 33, 52, 0.87)',
            display: 'flex',
            position: 'relative',
          }}
        >
          <Typography>Viewing Intent Themes of</Typography>
          {keywordsLoading || !getKeywords?.edges ? (
            <Box display="flex" alignItems="center" height="20px">
              <CustomSkeleton variant="text" animation="wave" />
            </Box>
          ) : (
            <Box
              sx={{
                position: 'absolute',
                left: '220px',
                top: '14px',
                display: 'flex',
                alignItems: 'center',
                gap: '7px',
              }}
            >
              <Select
                sx={{
                  height: '28px',
                  margin: '0px',
                  padding: '0px',
                  fontSize: '16px !important',
                  borderRadius: '6px',
                  border: 'solid 1px rgba(33, 33, 52, 0.23)',
                }}
                data-testid="dateSelector"
                placeholder={!selectedDateRange ? 'Loading...' : undefined}
                value={selectedKeyword || 'All Keywords'}
                disabled={!selectedDateRange || !getKeywords?.edges?.length}
                MenuProps={{
                  anchorOrigin: {
                    vertical: 'bottom', // Dropdown will be aligned to the bottom of the select
                    horizontal: 'left', // Dropdown will be aligned to the left of the select
                  },
                  transformOrigin: {
                    vertical: 'top', // Dropdown will be transformed from the top
                    horizontal: 'left', // Dropdown will be transformed from the left
                  },
                  PaperProps: {
                    style: {
                      maxHeight: '300px',
                    },
                  },
                }}
              >
                {[{ keyword_text: 'All Keywords' }, ...(getKeywords?.edges || [])].map((key, index) => (
                  <CustomMenuItem
                    key={key.keyword_text}
                    data-testid={`menuItem-${index}`}
                    onClick={() => handleChange(key.keyword_text)}
                    value={key.keyword_text}
                  >
                    {key.keyword_text}
                  </CustomMenuItem>
                ))}
              </Select>
              {selectedKeyword && selectedKeyword !== 'All Keywords' && (
                <Typography
                  sx={{
                    fontSize: '16px',
                    cursor: 'pointer',
                  }}
                  color="secondary"
                  onClick={() => setSelectedKeyword('All Keywords')}
                >
                  Clear
                </Typography>
              )}
            </Box>
          )}
        </Box>
        <CustomContainer
          sx={{
            flexDirection: useMediaQuery(theme.breakpoints.down('lg')) ? 'column' : 'row',
            height: '100%',
            paddingTop: useMediaQuery(theme.breakpoints.down('lg')) ? '225px' : '0',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignSelf: 'center',
              textAlign: 'center',
              alignItems: 'center',
              margin: '0 auto',
              maxWidth: '1000px',
              height: '100%',
              width: '100%',
              borderTop: '1px solid #f5f5f5',
              paddingBottom: useMediaQuery(theme.breakpoints.down('lg')) ? '20px' : '0',
            }}
          >
            {intentThemesCache?.edges?.length <= 0 || cachedIntentThemes === null ? (
              <CircularProgress />
            ) : (
              <>
                <ChartHeader>
                  {numberWithCommas(intentThemesCache?.pageInfo?.total)} Intent{' '}
                  {intentThemesCache?.pageInfo?.total === 1 ? 'Theme' : 'Themes'}
                </ChartHeader>
                <ChartDetails>
                  Showing Top {intentThemesCache?.pageInfo?.total < 25 ? intentThemesCache?.pageInfo?.total : 25} |
                  Ranked by nValue Score
                </ChartDetails>
                <Box display="flex" gap={1} paddingBottom={2}>
                  <ChartKeyContainer>
                    <ChartKey bgcolor="#D7DDFD" />
                    <ChartKeyText>Traffic Segment</ChartKeyText>
                  </ChartKeyContainer>
                  <ChartKeyContainer>
                    <ChartKey bgcolor="#3054AA" />
                    <ChartKeyText>Intent Theme</ChartKeyText>
                  </ChartKeyContainer>
                </Box>
                <Box>
                  <ChartBubble>
                    <BubbleChart data={intentThemesCache?.edges} />
                  </ChartBubble>
                </Box>
              </>
            )}
          </Box>
          <Box
            sx={{
              margin: '0 auto',
              height: '100%',
              width: '100%',
              border: '1px solid #f5f5f5',
            }}
          >
            {/* <StyledTableContainer> */}
            <Box
              sx={{
                display: 'flex',
                width: '100%',
                flexDirection: 'column',
                maxHeight: '93%',
              }}
            >
              <Box
                sx={{
                  width: '100%',
                  minWidth: '670px',
                  overflow: 'auto',
                }}
              >
                <Table
                  stickyHeader
                  sx={{
                    width: '100%',
                  }}
                >
                  <TableHead
                    headCells={headerCells}
                    order={desc ? SortType.Desc : SortType.Asc}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                  />
                  <TableBody>
                    {intentThemesLoading
                      ? renderSkeletonRows(4, 25)
                      : getIntentThemes?.edges.map((row) => (
                          <Styled.TableRow key={row.theme}>
                            <Styled.TableCell>{row.theme}</Styled.TableCell>
                            <Styled.TableCell>{numberWithCommas(row.intents)}</Styled.TableCell>
                            <Styled.TableCell>{numberWithCommas(row.keywords)}</Styled.TableCell>
                            <Styled.TableCell>{numberWithCommas(row.impressions)}</Styled.TableCell>
                          </Styled.TableRow>
                        ))}
                  </TableBody>
                </Table>
              </Box>
            </Box>

            {useMediaQuery(theme.breakpoints.up('lg')) && (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  margin: '0 auto',
                }}
              >
                <CustomTablePagination
                  sx={{
                    borderY: '1px solid #E5E5E5',
                    width: '100%',
                    backgroundColor: '#fff',
                  }}
                  page={page}
                  pageSize={pageSize}
                  setPage={setPage}
                  setPageSize={setPageSize}
                  pageTotal={pageTotals}
                  skeleton={intentThemesLoading}
                />
              </Box>
            )}
          </Box>
          {/* </StyledTableContainer> */}
        </CustomContainer>

        {useMediaQuery(theme.breakpoints.down('lg')) && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              margin: '0 auto',
            }}
          >
            <CustomTablePagination
              sx={{
                borderY: '1px solid #E5E5E5',
                width: '100%',
                backgroundColor: '#fff',
              }}
              page={page}
              pageSize={pageSize}
              setPage={setPage}
              setPageSize={setPageSize}
              pageTotal={pageTotals}
              skeleton={intentThemesLoading}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default IntentThemes;
