import { createSelector } from 'reselect';

import { AppState } from '../reducers';

const authStoreSelector = (state: AppState) => state.auth;

export const authLoadingSelector = createSelector(authStoreSelector, (state) => state.isLoading);
export const authErrorSelector = createSelector(authStoreSelector, (state) => state.error);
export const authUserSelector = createSelector(authStoreSelector, (state) => state.user);
export const authTokensSelector = createSelector(authStoreSelector, (state) => ({
  accessToken: state.AccessToken,
  refreshToken: state.RefreshToken,
}));
export const authIsAppInitSelector = createSelector(authStoreSelector, (state) => state.isAppInit);
export const authSuccessSelector = createSelector(authStoreSelector, (state) => state.success);
export const authResendErrorSelector = createSelector(authStoreSelector, (state) => state.resendError);
