import { Close, ExpandMore } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  IconButton,
  Modal,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { useGetAdGroups } from '~/core/services/graphql/adGroups/hooks';
import { GetAssetsQueryVariables } from '~/core/services/graphql/assets/assets.queries.generated';
import { useGetAssets } from '~/core/services/graphql/assets/hooks';
import { GetCampaignsQueryVariables } from '~/core/services/graphql/campaigns/campaigns.queries.generated';
import { useGetCampaigns } from '~/core/services/graphql/campaigns/hooks';
import {
  AdGroupSchema,
  AssetRecommendationsSchema,
  CampaignSchema,
  QueryGetAdGroupsArgs,
} from '~/core/types/graphql.types';
import { useContexts } from '~/store/context/useContext';

interface CollapsibleRowProps {
  asset?: AssetRecommendationsSchema;
  assetLoading?: boolean;
}

const CollapsibleRow: React.FC<CollapsibleRowProps> = ({ asset, assetLoading }) => {
  const { selectedPlatformAccountId, selectedSourceAccountId, selectedDateRange } = useContexts();
  const [searchParams] = useSearchParams();
  const [accordionOpen, setAccordionOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const skip = !accordionOpen;
  const variables = {
    platformAccountId: selectedPlatformAccountId,
    customerIds: searchParams.get('sourceAccountId') || [selectedSourceAccountId],
    dateRange: searchParams.get('dateRange') || selectedDateRange,
    assetText: asset?.assetText,
  } as GetAssetsQueryVariables;

  const { assetsLoading, getAssets } = useGetAssets(skip, variables);

  const campaignIds = getAssets?.map((asset) => asset.campaignId);
  const adGroupIds = getAssets?.map((asset) => asset.adGroupId);

  // Get ad groups
  const getCampaignsSkip = !campaignIds;
  const getCampaignsVariables: GetCampaignsQueryVariables = {
    platformAccountId: selectedPlatformAccountId,
    customerIds: searchParams.get('sourceAccountId') || [selectedSourceAccountId],
    dateRange: searchParams.get('dateRange') || selectedDateRange,
    campaignIds,
  };
  const { campaignsLoading, getCampaigns } = useGetCampaigns(getCampaignsSkip, getCampaignsVariables);

  // Get ad groups
  const getAdGroupsSkip = !adGroupIds;
  const getAdGroupsVariables: QueryGetAdGroupsArgs = {
    platformAccountId: selectedPlatformAccountId,
    customerIds: (searchParams.get('sourceAccountId') && [searchParams.get('sourceAccountId')]) || [
      selectedSourceAccountId,
    ],
    dateRange: searchParams.get('dateRange') || selectedDateRange,
    adGroupIds,
  };
  const { adGroupsLoading, getAdGroups } = useGetAdGroups(getAdGroupsSkip, getAdGroupsVariables);

  const sortedCampaignIdsByName = getCampaigns
    ?.slice()
    .sort((a, b) => a?.name?.localeCompare(b?.name))
    .map((campaign) => campaign.id);

  const campaignsDict = getCampaigns?.reduce((acc, campaign) => {
    acc[campaign.id] = campaign;
    return acc;
  }, {} as Record<string, CampaignSchema>);

  const adGroupsDict = getAdGroups?.reduce((acc, adGroup) => {
    acc[adGroup.id] = adGroup;
    return acc;
  }, {} as Record<string, AdGroupSchema>);

  return assetLoading ? (
    <Accordion
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        position: 'relative',
        border: '1px solid rgba(33, 33, 52, 0.12)',
        padding: '0 16px 0 16px',
        margin: '4px 0',
      }}
    >
      <AccordionSummary
        sx={{
          height: '48px',
        }}
        expandIcon={<ExpandMore />}
      >
        <Skeleton variant="text" animation="wave" width="50%" />
      </AccordionSummary>
    </Accordion>
  ) : (
    <>
      <Accordion
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          position: 'relative',
          border: '1px solid rgba(33, 33, 52, 0.12)',
          padding: '0 16px 0 16px',
          margin: '4px 0',
        }}
        onClick={() => setAccordionOpen(!accordionOpen)}
      >
        <AccordionSummary
          sx={{
            height: '48px',
          }}
          expandIcon={<ExpandMore />}
        >
          {`'${asset.assetText}'`}
        </AccordionSummary>

        <AccordionDetails>
          <Typography
            sx={{
              fontSize: '14px',
              fontWeight: 'bold',
              lineHeight: 1.57,
              letterSpacing: '0.1px',
            }}
          >
            Where this asset appears in your account.
          </Typography>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell size="small">Campaign</TableCell>
                <TableCell size="small">AdGroup (ID)</TableCell>
                {/* <TableCell size="small">Best Aligned Keyword</TableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
              {assetsLoading || campaignsLoading || adGroupsLoading ? (
                <TableRow>
                  <TableCell>
                    <Skeleton variant="text" animation="wave" />
                  </TableCell>
                  <TableCell>
                    <Skeleton variant="text" />
                  </TableCell>
                </TableRow>
              ) : (
                getAssets?.slice(0, 3).map((asset) => (
                  <TableRow key={asset?.id}>
                    <TableCell size="small">{campaignsDict?.[asset?.campaignId].name}</TableCell>
                    <TableCell size="small">{asset?.adGroupId}</TableCell>
                    {/* <TableCell size="small">{location.keyword}</TableCell> */}
                  </TableRow>
                ))
              )}

              {getAssets?.length > 3 && !campaignsLoading && !adGroupsLoading && (
                <TableRow
                  onClick={(event) => {
                    event.stopPropagation();
                    setModalOpen(true);
                  }}
                >
                  <TableCell>
                    <Typography
                      sx={{
                        color: '#3154aa',

                        fontSize: '16px',
                        lineHeight: 1.5,
                        letterSpacing: '0.15px',
                        padding: 0,
                        margin: 0,
                        cursor: 'pointer',
                        textDecoration: 'underline',
                      }}
                    >
                      View More
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </AccordionDetails>
      </Accordion>

      <Modal open={modalOpen} onClose={handleModalClose}>
        <Box
          sx={{
            width: '80%',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: 'background.paper',
            boxShadow: 24,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              padding: '12px 24px',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginBottom: '16px',
              borderBottom: '1px solid rgba(33, 33, 52, 0.12)',
            }}
          >
            <Typography
              sx={{
                fontSize: '16px',
                fontWeight: 'bold',
                lineHeight: 1.5,
                letterSpacing: '0.15px',
              }}
            >
              Where this asset appears in your account.
            </Typography>
            <IconButton onClick={handleModalClose}>
              <Close />
            </IconButton>
          </Box>

          <Box
            sx={{
              maxHeight: '520px',
              overflow: 'auto',
              padding: '24px',
            }}
          >
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell size="small">Campaign (ID)</TableCell>
                  <TableCell size="small">AdGroup (ID)</TableCell>
                  {/* <TableCell size="small">Best Aligned Keyword</TableCell> */}
                </TableRow>
              </TableHead>
              <TableBody>
                {getAssets
                  ?.filter(({ campaignId }) => sortedCampaignIdsByName?.includes(campaignId))
                  .sort((a, b) => {
                    const indexA = sortedCampaignIdsByName.indexOf(a.campaignId);
                    const indexB = sortedCampaignIdsByName.indexOf(b.campaignId);
                    return indexA - indexB;
                  })
                  .map(({ id, campaignId, adGroupId }) => (
                    <TableRow key={id}>
                      <TableCell size="small">{campaignsDict?.[campaignId].name}</TableCell>
                      <TableCell size="small">
                        {adGroupId}({adGroupsDict?.[adGroupId]?.name})
                      </TableCell>
                      {/* <TableCell size="small">{location.keyword}</TableCell> */}
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default CollapsibleRow;
