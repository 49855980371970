import account from './account';
import userAccounts from './accountUsers';
import integrationAccounts from './integrationAccounts';
import intents from './intents';
import platformAccounts from './platformAccounts';
import sourceAccounts from './sourceAccounts';
import user from './user';

export const graphqlServices = {
  platformAccounts,
  account,
  integrationAccounts,
  userAccounts,
  intents,
  sourceAccounts,
  user,
};
