import { ActionType, createAction, createAsyncAction } from 'typesafe-actions';

import { User, UserUpdateInput } from '~/core/services/graphql/user/user.types';

export enum UserTypes {
  User = '[UserTypes] User',
  UserSuccess = '[UserTypes] UserSuccess',
  UserFailed = '[UserTypes] UserFailed',
  UpdateUser = '[UserTypes] UpdateUser',
  UpdateUserSuccess = '[UserTypes] UpdateUserSuccess',
  UpdateUserFailed = '[UserTypes] UpdateUserFailed',
  ResetUserSuccessFlag = '[UserTypes] ResetUserSuccessFlag',
  ResetUserSuccessFlagSuccess = '[UserTypes] ResetUserSuccessFlagSuccess',
  ResetUserSuccessFlagFailed = '[UserTypes] ResetUserSuccessFlagFailed',
  ResetUserError = '[UserTypes] ResetUserError',
}

export const getUserAction = createAsyncAction(UserTypes.User, UserTypes.UserSuccess, UserTypes.UserFailed)<
  { userId: number; username?: string },
  User,
  string
>();

export const updateUserAction = createAsyncAction(
  UserTypes.UpdateUser,
  UserTypes.UpdateUserSuccess,
  UserTypes.UpdateUserFailed,
)<{ userId: number; userData: UserUpdateInput }, User, string>();

export const resetUserSuccessFlagAction = createAction(UserTypes.ResetUserSuccessFlag)();

export const resetUserErrorAction = createAction(UserTypes.ResetUserError)();

export type UserActionUnion = ActionType<
  typeof getUserAction | typeof updateUserAction | typeof resetUserSuccessFlagAction | typeof resetUserErrorAction
>;
