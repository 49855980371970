import { Container, FormControlLabel, styled, Typography } from '@mui/material';

export const FormControlTerms = styled(FormControlLabel)({
  width: 'fit-content',
});

export const FooterTitle = styled(Typography)(({ theme }) => ({
  display: 'flex',
  gap: '6px',

  [theme.breakpoints.down('sm')]: {
    fontSize: '10px',
  },
}));

export const FormContainer = styled(Container)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  alignSelf: 'center',
  justifyContent: 'center',
  gap: '20px',
  padding: '20px',

  [theme.breakpoints.down('sm')]: {
    maxWidth: '300px',
  },

  [theme.breakpoints.up('md')]: {
    maxWidth: '100%',
  },
}));
