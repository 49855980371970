import { useQuery } from '@apollo/client';

import { CampaignSchema } from '~/core/types/graphql.types';

import { GET_CAMPAIGNS } from './campaigns.queries';
import { GetCampaignsQueryVariables } from './campaigns.queries.generated';

export const useGetCampaigns = (skip: boolean, variables: GetCampaignsQueryVariables) => {
  const {
    loading: campaignsLoading,
    error: campaignsError,
    data: campaignsData,
  } = useQuery<{ getCampaigns: CampaignSchema[] }>(GET_CAMPAIGNS, {
    skip,
    variables,
  });

  const getCampaigns = campaignsData?.getCampaigns;

  return { campaignsLoading, campaignsError, getCampaigns };
};
