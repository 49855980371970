import { gql } from '@apollo/client';

export const GET_INTENTS = gql`
  query getIntents(
    $platformAccountId: Int!
    $customerIds: [String!]!
    $campaignIds: [String!]
    $dateRange: String!
    $sortBy: IntentsSortSchema
    $page: Int
    $pageSize: Int
    $filterField: BaseFilterSchema
    $searchValue: String
    $aligned: Boolean
  ) {
    getIntents(
      platformAccountId: $platformAccountId
      customerIds: $customerIds
      campaignIds: $campaignIds
      dateRange: $dateRange
      sortBy: $sortBy
      page: $page
      pageSize: $pageSize
      filterField: $filterField
      searchValue: $searchValue
      aligned: $aligned
    ) {
      pageInfo {
        pageSize
        page
        total
        pages
        previousPage
        nextPage
      }
      edges {
        id
        customerId
        campaignId
        campaignName
        adGroupId
        adGroupName
        name
        impressions
        conversions
        aligned
        alignedHeadlines
        alignedDescriptions
        closestKeywordText
        closestKeywordMatchType
      }
    }
  }
`;

export const GET_INTENTS_ASSET_MAP = gql`
  query getIntentsAssetMap(
    $platformAccountId: Int!
    $customerId: String!
    $adGroupId: String
    $intentId: String!
    $dateRange: String!
    $filterField: BaseFilterSchema
    $aligned: Boolean
  ) {
    getIntentsAssetMap(
      platformAccountId: $platformAccountId
      customerId: $customerId
      adGroupId: $adGroupId
      intentId: $intentId
      dateRange: $dateRange
      filterField: $filterField
      aligned: $aligned
    ) {
      adGroupId
      intentId
      assetText
      assetType
      aligned
      dateRange
      customerId
    }
  }
`;

export const GET_ALIGNMENT_PERCENTAGES = gql`
  query getAlignmentPercentages(
    $platformAccountId: Int!
    $customerId: String!
    $dateRange: String!
    $campaignId: String
    $filterField: BaseFilterSchema
  ) {
    getAlignmentPercentages(
      platformAccountId: $platformAccountId
      customerId: $customerId
      dateRange: $dateRange
      campaignId: $campaignId
      filterField: $filterField
    ) {
      dateRange
      customerId
      intentCoveragePercentage
      assetCoveragePercentage
    }
  }
`;

export const GET_INTENTS_AVAILABILITY = gql`
  query getIntentsAvailability(
    $platformAccountId: Int!
    $customerIds: [String!]!
    $campaignIds: [String!]
    $dateRange: String
  ) {
    getIntentsAvailability(
      platformAccountId: $platformAccountId
      customerIds: $customerIds
      campaignIds: $campaignIds
      dateRange: $dateRange
    ) {
      dateRange
      customerId
      campaignId
      adGroupId
      intentsAvailable
    }
  }
`;

export const GET_INTENT_THEMES = gql`
  query getIntentThemes(
    $platformAccountId: Int!
    $customerId: String!
    $dateRange: String!
    $filterField: BaseFilterSchema
    $sortBy: IntentThemesSortSchema
    $page: Int
    $pageSize: Int
    $searchValue: String
  ) {
    getIntentThemes(
      platformAccountId: $platformAccountId
      customerId: $customerId
      dateRange: $dateRange
      filterField: $filterField
      sortBy: $sortBy
      page: $page
      pageSize: $pageSize
      searchValue: $searchValue
    ) {
      pageInfo {
        pageSize
        page
        total
        pages
        previousPage
        nextPage
      }
      edges {
        customerId
        dateRange
        theme
        intents
        keywords
        impressions
        clicks
        conversions
      }
    }
  }
`;
