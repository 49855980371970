import { ActionType, createAction, createAsyncAction } from 'typesafe-actions';

import {
  PasswordResetRequestForm,
  SetNewPassword,
  SignInPayload,
  SignInResponse,
  SignUpPayload,
  User,
  VerifyNewEmailForm,
} from '~/core/types/auth.types';

export enum AuthTypes {
  Login = '[AuthTypes] Login',
  LoginSuccess = '[AuthTypes] LoginSuccess',
  LoginFailed = '[AuthTypes] LoginFailed',
  CreateAccount = '[AuthTypes] CreateAccount',
  CreateAccountSuccess = '[AuthTypes] CreateAccountSuccess',
  CreateAccountFailed = '[AuthTypes] CreateAccountFailed',
  GetTokens = '[AuthTypes] GetTokens',
  GetTokensSuccess = '[AuthTypes] GetTokensSuccess',
  GetTokenFailed = '[AuthTypes] GetTokensFailed',
  Logout = '[AuthTypes] Logout',
  LogoutSuccess = '[AuthTypes] LogoutSuccess',
  LogoutFailed = '[AuthTypes] LogoutFailed',
  ForgotPassword = '[AuthTypes] ForgotPassword',
  ForgotPasswordSuccess = '[AuthTypes] ForgotPasswordSuccess',
  ForgotPasswordFailed = '[AuthTypes] ForgotPasswordFailed',
  SetNewPassword = '[AuthTypes] SetNewPassword',
  SetNewPasswordSuccess = '[AuthTypes] SetNewPasswordSuccess',
  SetNewPasswordFailed = '[AuthTypes] SetNewPasswordFailed',
  ConfirmEmail = '[AuthTypes] ConfirmEmail',
  ConfirmEmailSuccess = '[AuthTypes] ConfirmEmailSuccess',
  ConfirmEmailFailed = '[AuthTypes] ConfirmEmailFailed',
  ResendConfirmationCode = '[AuthTypes] ResendConfirmationCode',
  ResendConfirmationCodeSuccess = '[AuthTypes] ResendConfirmationCodeSuccess',
  ResendConfirmationCodeFailed = '[AuthTypes] ResendConfirmationCodeFailed',
  SetAccessTokenAction = '[AuthTypes] SetAccessTokenAction',
  SetAccessTokenActionSuccess = '[AuthTypes] SetAccessTokenActionSuccess',
  SetAccessTokenActionFailed = '[AuthTypes] SetAccessTokenActionFailed',
  VerifyNewEmail = '[AuthTypes] VerifyNewEmail',
  VerifyNewEmailSuccess = '[AuthTypes] VerifyNewEmailSuccess',
  VerifyNewEmailFailed = '[AuthTypes] VerifyNewEmailFailed',
  ResetAuthSuccessFlag = '[AuthTypes] ResetAuthSuccessFlag',
  ResetAuthErrors = '[AuthTypes] ResetAuthErrors',
  VerifyNewEmailResend = '[AuthTypes] VerifyNewEmailResend',
  VerifyNewEmailResendSuccess = '[AuthTypes] VerifyNewEmailResendSuccess',
  VerifyNewEmailResendFailure = '[AuthTypes] VerifyNewEmailResendFailure',
}

export const loginAction = createAsyncAction(AuthTypes.Login, AuthTypes.LoginSuccess, AuthTypes.LoginFailed)<
  SignInPayload,
  SignInResponse,
  string
>();

export const createAccountAction = createAsyncAction(
  AuthTypes.CreateAccount,
  AuthTypes.CreateAccountSuccess,
  AuthTypes.CreateAccountFailed,
)<SignUpPayload, void, string>();

export const logoutAction = createAsyncAction(AuthTypes.Logout, AuthTypes.LogoutSuccess, AuthTypes.LogoutFailed)<
  void,
  void,
  void
>();

export const getTokens = createAsyncAction(AuthTypes.GetTokens, AuthTypes.GetTokensSuccess, AuthTypes.GetTokenFailed)<
  void,
  SignInResponse,
  void
>();

export const forgotPasswordAction = createAsyncAction(
  AuthTypes.ForgotPassword,
  AuthTypes.ForgotPasswordSuccess,
  AuthTypes.ForgotPasswordFailed,
)<PasswordResetRequestForm, void, void>();

export const setNewPasswordAction = createAsyncAction(
  AuthTypes.SetNewPassword,
  AuthTypes.SetNewPasswordSuccess,
  AuthTypes.SetNewPasswordFailed,
)<SetNewPassword, void, string>();

export const confirmEmailAction = createAsyncAction(
  AuthTypes.ConfirmEmail,
  AuthTypes.ConfirmEmailSuccess,
  AuthTypes.ConfirmEmailFailed,
)<{ email: string; confirmationCode: string }, void, string>();

export const resendConfirmationCodeAction = createAsyncAction(
  AuthTypes.ResendConfirmationCode,
  AuthTypes.ResendConfirmationCodeSuccess,
  AuthTypes.ResendConfirmationCodeFailed,
)<PasswordResetRequestForm, void, string>();

export const setAccessTokenAction = createAsyncAction(
  AuthTypes.SetAccessTokenAction,
  AuthTypes.SetAccessTokenActionSuccess,
  AuthTypes.SetAccessTokenActionFailed,
)<{ AccessToken: string; IdToken: string; User: User }, SignInResponse, void>();

export const verifyNewEmailAction = createAsyncAction(
  AuthTypes.VerifyNewEmail,
  AuthTypes.VerifyNewEmailSuccess,
  AuthTypes.VerifyNewEmailFailed,
)<VerifyNewEmailForm, void, string>();

export const verifyNewEmailResendAction = createAsyncAction(
  AuthTypes.VerifyNewEmailResend,
  AuthTypes.VerifyNewEmailResendSuccess,
  AuthTypes.VerifyNewEmailResendFailure,
)<void, void, string>();

export const resetAuthSuccessFlagAction = createAction(AuthTypes.ResetAuthSuccessFlag)();

export const resetAuthErrorsAction = createAction(AuthTypes.ResetAuthErrors)();

export type AuthActionUnion = ActionType<
  | typeof loginAction
  | typeof createAccountAction
  | typeof getTokens
  | typeof logoutAction
  | typeof forgotPasswordAction
  | typeof setNewPasswordAction
  | typeof confirmEmailAction
  | typeof resendConfirmationCodeAction
  | typeof setAccessTokenAction
  | typeof verifyNewEmailAction
  | typeof resetAuthSuccessFlagAction
  | typeof resetAuthErrorsAction
  | typeof verifyNewEmailResendAction
>;
