import { HeadCell } from '~/core/components/shared/Table/TableHead/types';
import { IntegrationAccountSortableEnum } from '~/core/types/graphql.types';

export const headCells: HeadCell[] = [
  {
    id: IntegrationAccountSortableEnum.Name,
    numeric: false,
    disablePadding: true,
    label: 'Account Name',
  },
  {
    id: IntegrationAccountSortableEnum.CustomerId,
    numeric: false,
    disablePadding: true,
    label: 'Ad Account ID',
  },
  {
    id: IntegrationAccountSortableEnum.Access,
    numeric: false,
    disablePadding: true,
    label: 'MotiveMetrics Access',
  },
  {
    id: IntegrationAccountSortableEnum.UpdatedAt,
    numeric: false,
    disablePadding: true,
    label: 'Last Updated',
  },
];

export const headCellsFreeAccount: HeadCell[] = [
  {
    id: IntegrationAccountSortableEnum.Name,
    numeric: false,
    disablePadding: true,
    label: 'Account Name',
  },
  {
    id: IntegrationAccountSortableEnum.CustomerId,
    numeric: false,
    disablePadding: true,
    label: 'Ad Account ID',
  },
  {
    id: IntegrationAccountSortableEnum.UpdatedAt,
    numeric: false,
    disablePadding: true,
    label: 'Last Updated',
  },
];
