import { ContainerProps } from '@mui/material';

import * as Styled from './styles';

interface DialogFooterProps extends ContainerProps {
  children: React.ReactNode;
}

export const DialogFooter: React.FC<DialogFooterProps> = ({ children, ...elementProps }) => (
  <Styled.DialogFooterContainer disableGutters {...elementProps}>
    {children}
  </Styled.DialogFooterContainer>
);

export default DialogFooter;
