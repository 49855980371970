import { ActionType, createAsyncAction } from 'typesafe-actions';

import { PlatformUserAccountsSchema } from '~/core/types/graphql.types';

export enum PlatformAccountTypes {
  PlatformAccounts = '[PlatformAccountsTypes] PlatformAccounts',
  PlatformAccountsSuccess = '[PlatformAccountsTypes] PlatformAccountsSuccess',
  PlatformAccountsFailed = '[PlatformAccountsTypes] PlatformAccountsFailed',
  SetCurrentAccount = '[PlatformAccountsTypes] SetCurrentAccount',
  SetCurrentAccountSuccess = '[PlatformAccountsTypes] SetCurrentAccountSuccess',
  SetCurrentAccountFailed = '[PlatformAccountsTypes] SetCurrentAccountFailed',
}

export const platformAccountsAction = createAsyncAction(
  PlatformAccountTypes.PlatformAccounts,
  PlatformAccountTypes.PlatformAccountsSuccess,
  PlatformAccountTypes.PlatformAccountsFailed,
)<void, { userAccounts: PlatformUserAccountsSchema[] }, string>();

export const setCurrentAccountAction = createAsyncAction(
  PlatformAccountTypes.SetCurrentAccount,
  PlatformAccountTypes.SetCurrentAccountSuccess,
  PlatformAccountTypes.SetCurrentAccountFailed,
)<number, number, string>();

export type platformAccountsActionUnion = ActionType<typeof platformAccountsAction | typeof setCurrentAccountAction>;
