import { createReducer } from 'typesafe-actions';

import { GoogleAdsUrl } from '~/core/types/googleAdsAccounts.types';
import { IntegrationAccountsSchema, PageInfoSchema } from '~/core/types/graphql.types';

import {
  PaidSearchIntegrationsAccountsActionUnion,
  linkNewPaidSearchAccountAction,
  paidIntegrationAccountsAction,
  postGoogleAuthSignInAction,
  refreshPaidIntegrationAccountsAction,
} from '../actions/paidSearchIntegrations.action';

export interface State {
  isLoading: boolean;
  error: string;
  googleAdsSignInUrl: GoogleAdsUrl;
  integrationEmail?: string;
  paidSearchAccounts: IntegrationAccountsSchema[];
  paidSearchPageInfo: PageInfoSchema;
  refreshLoading: boolean;
}

export const initialState: State = {
  isLoading: false,
  error: null,
  paidSearchPageInfo: null,
  googleAdsSignInUrl: null,
  paidSearchAccounts: null,
  refreshLoading: false,
};

export const reducer = createReducer<State, PaidSearchIntegrationsAccountsActionUnion>(initialState)
  .handleAction(postGoogleAuthSignInAction.success, (state, action) => ({
    ...state,
    isLoading: false,
    googleAdsSignInUrl: action.payload,
    error: null,
  }))
  .handleAction(postGoogleAuthSignInAction.failure, (state, action) => ({
    ...state,
    isLoading: false,
    googleAdsSignInUrl: null,
    error: action.payload,
  }))
  .handleAction(postGoogleAuthSignInAction.request, (state) => ({
    ...state,
    isLoading: true,
    error: null,
  }))
  .handleAction(linkNewPaidSearchAccountAction.success, (state, action) => ({
    ...state,
    isLoading: false,
    paidSearchAccounts: action.payload.ownIntegrationAccounts.edges,
    paidSearchPageInfo: action.payload.ownIntegrationAccounts.pageInfo,
    error: null,
  }))
  .handleAction(linkNewPaidSearchAccountAction.failure, (state) => ({
    ...state,
    isLoading: false,
  }))
  .handleAction(linkNewPaidSearchAccountAction.request, (state) => ({
    ...state,
    isLoading: true,
    error: null,
  }))
  .handleAction(paidIntegrationAccountsAction.success, (state, action) => ({
    ...state,
    isLoading: false,
    paidSearchAccounts: action.payload.ownIntegrationAccounts.edges,
    paidSearchPageInfo: action.payload.ownIntegrationAccounts.pageInfo,
    error: null,
  }))
  .handleAction(paidIntegrationAccountsAction.failure, (state, action) => ({
    ...state,
    isLoading: false,
    error: action.payload,
  }))
  .handleAction(paidIntegrationAccountsAction.request, (state) => ({
    ...state,
    isLoading: true,
    error: null,
  }))
  .handleAction(refreshPaidIntegrationAccountsAction.success, (state, { payload }) => ({
    ...state,
    refreshLoading: false,
    paidSearchAccounts: payload.ownIntegrationAccounts.edges,
    paidSearchPageInfo: payload.ownIntegrationAccounts.pageInfo,
    error: null,
  }))
  .handleAction(refreshPaidIntegrationAccountsAction.failure, (state) => ({
    ...state,
    refreshLoading: false,
  }))
  .handleAction(refreshPaidIntegrationAccountsAction.request, (state) => ({
    ...state,
    refreshLoading: true,
    error: null,
  }));
