import { Skeleton, Table, TableBody } from '@mui/material';

import { TableHead } from '~/core/components/shared';
import { renderSkeletonRows } from '~/core/helpers';
import { SortType } from '~/core/types/table.types';

import { CustomTablePagination } from '../../components';
import * as Styled from '../../styles';
import { adGroupHeaderCells } from '../tableHeader.data';

type LoadingTableProps = {
  desc?: boolean;
  orderBy?: string;
  page?: number;
  pageSize?: number;
};

const LoadingTable = ({ desc = true, orderBy = null, page = 0, pageSize = 25 }: LoadingTableProps) => {
  const skeletonRows = adGroupHeaderCells.length;

  return (
    <>
      <Styled.TableContainer>
        <Table stickyHeader aria-label="sticky table">
          <TableHead
            data-testid="tableHead"
            headCells={adGroupHeaderCells}
            order={desc ? SortType.Desc : SortType.Asc}
            orderBy={orderBy}
          />

          <TableBody
            sx={{
              boxShadow: 'none',
            }}
          >
            {renderSkeletonRows(skeletonRows, 25)}
          </TableBody>
        </Table>
      </Styled.TableContainer>
      <CustomTablePagination
        sx={{
          backgroundColor: '#F7F7F9',
        }}
        disabled
        page={page}
        pageSize={pageSize}
        setPage={null}
        setPageSize={null}
        pageTotal={null}
        skeleton={<Skeleton height="16px" width="120px" />}
      />
    </>
  );
};

export default LoadingTable;
