import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { FormControl, OutlinedInput, SelectChangeEvent } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';

import PlusAILogoWhite from '~/assets/logo/plusai_logo_white.svg';
import { limitCharacters } from '~/core/helpers';
import { clearIntegrationAccountsAction } from '~/store/actions/integrationAccounts.action';
import { useContexts } from '~/store/context/useContext';

import * as Styled from './styles';
import { AccountSelectorProps } from './types';

const charLimit = 32;

const AccountSelector: React.FC<AccountSelectorProps> = ({ options, ...elementProps }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    selectedPlatformAccountId,
    setSelectedPlatformAccountId,
    setSelectedSourceAccountId,
    setSelectedPlatformAccount,
    setSelectedCampaignId,
    setSelectedClusterId,
    setSelectedDateRange,
  } = useContexts();

  const handleSelectedAccountChange = (event: SelectChangeEvent<unknown>) => {
    const { value } = event.target;
    // eslint-disable-next-line

    const timeoutID = setTimeout(() => {
      setSelectedSourceAccountId(null);
      setSelectedCampaignId(null);
      setSelectedClusterId(null);
      setSelectedDateRange(null);
      setSelectedPlatformAccountId(value as number);
      setSelectedPlatformAccount(options?.find(({ id }) => id === value));
      dispatch(clearIntegrationAccountsAction());
      localStorage.setItem('currentUser', String(value));

      if (value === -1) {
        navigate('../platform/platform-admin-console');
      } else {
        navigate('../content-gap-analysis', { replace: true });
      }
    }, 500);

    return () => clearTimeout(timeoutID);
  };
  const renderSelectedAccount = (value: unknown) => {
    const name = options?.find(({ id }) => id === value)?.name || '';

    return (
      <Styled.ListItemButton
        data-testid="selectedIndexChange"
        sx={{
          display: 'flex',
          alignItems: 'center',
          alignSelf: 'center',
        }}
      >
        <Styled.ImageContainer>
          <Styled.Image src={PlusAILogoWhite} alt="Plus AI white logo" />
        </Styled.ImageContainer>
        <Styled.AccountName data-testid="selectedAccountName">{limitCharacters(name, charLimit)}</Styled.AccountName>
      </Styled.ListItemButton>
    );
  };

  return options?.length > 1 ? (
    <FormControl data-testid="multipleAccountSelector">
      <Styled.Select
        data-testid="dropdownSelector"
        value={selectedPlatformAccountId}
        displayEmpty
        autoWidth
        MenuProps={{
          sx: {
            maxHeight: '500px',
          },
        }}
        IconComponent={ArrowDropDownIcon}
        onChange={handleSelectedAccountChange}
        input={<OutlinedInput />}
        renderValue={(value) => renderSelectedAccount(value)}
        {...elementProps}
      >
        {options.map(({ id, name }) => (
          <Styled.MenuItem data-testid="name" key={id} value={id} selected={id === selectedPlatformAccountId}>
            {name}
          </Styled.MenuItem>
        ))}
      </Styled.Select>
    </FormControl>
  ) : (
    <Styled.ListItemButton
      data-testid="accountSelector"
      disabled
      sx={{
        opacity: '1 !important',
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <Styled.ImageContainer>
        <Styled.Image src={PlusAILogoWhite} alt="Plus AI white logo" />
      </Styled.ImageContainer>
      <Styled.AccountName data-testid="selectedAccountName">
        {options && limitCharacters(options[0]?.name, charLimit)}
      </Styled.AccountName>
    </Styled.ListItemButton>
  );
};

export default AccountSelector;
