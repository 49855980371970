import { Card, styled, Typography } from '@mui/material';

export const FormContainer = styled(Card)({
  display: 'flex',
  flexDirection: 'column',
  textAlign: 'left',
  backgroundColor: 'white',
  padding: '32px 24px 24px 24px',
  rowGap: '20px',
  width: 552,
});

export const LinksContainer = styled(Typography)({
  display: 'flex',
  flexDirection: 'column',
  gap: 8,
});

export const LinksContainerRow = styled(Typography)({
  display: 'flex',
  gap: 8,
});
