import { styled, DialogContent as MuiDialogContent, Typography, Link } from '@mui/material';

export const DialogContent = styled(MuiDialogContent)({
  display: 'flex',
  flexDirection: 'column',
  gap: '12px',
  padding: 0,
  paddingTop: '6px',
});

export const VerificationCodeText = styled(Typography)({
  fontSize: '14px',
});

export const DialogForm = styled('form')({
  display: 'flex',
  flexDirection: 'column',
  gap: '12px',
});

export const ResendLink = styled(Link)({
  cursor: 'pointer',
  textDecoration: 'none',
});
