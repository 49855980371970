/* eslint-disable max-len */
import { Tooltip } from '~/core/components/shared';
import { AdGroupSummarySortEnum, IntentsSortEnum } from '~/core/types/graphql.types';

import { InfoIcon } from './styles';

export const headerCells = [
  {
    id: IntentsSortEnum.Name,
    label: (
      <>
        <Tooltip
          offset={[0, -10]}
          placement="bottom"
          title="Search Intent (also known as “User Intent”) is the purpose behind a search. It is the content they are looking for. Understanding these intents will help you to acquire more customers."
        >
          <InfoIcon />
        </Tooltip>
        <span>Search Intent</span>
      </>
    ),
    numeric: false,
    disablePadding: true,
  },
  {
    id: IntentsSortEnum.Impressions,
    label: 'Impressions',
    numeric: false,
    disablePadding: true,
  },

  {
    id: IntentsSortEnum.Conversions,
    label: 'Conversions',
    numeric: false,
    disablePadding: true,
  },
  {
    id: IntentsSortEnum.Aligned,
    label: (
      <>
        <Tooltip
          offset={[0, -10]}
          placement="bottom"
          title="Content Coverage indicates which identified search intents have intent-aligned content and which do not."
        >
          <InfoIcon />
        </Tooltip>
        <span>Content Coverage</span>
      </>
    ),
    numeric: false,
    disablePadding: true,
    width: '233.33px',
    sx: {
      minWidth: '233.33px',
    },
  },
  {
    id: '',
    label: '',
    numeric: false,
    disablePadding: true,
    width: '233.33px',
    sx: {
      minWidth: '233.33px',
    },
  },
];

export const adGroupHeaderCells = [
  {
    id: AdGroupSummarySortEnum.AdGroupName,
    label: 'Name',
    numeric: false,
    disablePadding: true,
  },
  {
    id: AdGroupSummarySortEnum.TotalKeywords,
    label: 'Total Keywords',
    numeric: false,
    disablePadding: true,
  },

  {
    id: AdGroupSummarySortEnum.KeywordsWithTraffic,
    label: 'Keywords with Traffic',
    numeric: false,
    disablePadding: true,
  },
  {
    id: AdGroupSummarySortEnum.TotalEligibleImpressions,
    label: 'Total Eligible Impressions',
    numeric: false,
    disablePadding: true,
    width: '233.33px',
    sx: {
      minWidth: '233.33px',
    },
  },
  {
    id: AdGroupSummarySortEnum.Impressions,
    label: 'Impressions',
    numeric: false,
    disablePadding: true,
    width: '233.33px',
    sx: {
      minWidth: '233.33px',
    },
  },
  {
    id: '',
    label: '',
    numeric: false,
    disablePadding: true,
    width: '233.33px',
    sx: {
      minWidth: '233.33px',
    },
  },
];
